import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { CREATE_BOOTH, UPDATE_BOOTH } from "../mutations.boothAdmin";
import { CHECK_ADMIN_INVITE } from "../queries.boothAdmin";
import { BOOTH_DETAILS } from "../../EventAdmin/queries.eventAdmin";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useRouteMatch, useParams } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { useForm } from "react-hook-form";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { formatSocialMediaHandle } from "shared/utils";
import Spacer from "shared/components/Spacer";
import Responsive from "shared/components/Responsive";
import TabContainer from "shared/components/TabContainer";
import BoothMedia from "./BoothMedia";
import MessagePopup from "shared/components/MessagePopup";

interface Props {
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
  asEventAdmin: boolean;
  boothData: any;
  setBoothData: Dispatch<SetStateAction<any>>;
}

const BoothInfo = ({
  userInfo,
  setUserInfo,
  asEventAdmin,
  boothData,
  setBoothData,
}: Props) => {
  const [boothDisplayName, setBoothDisplayName] = useState(
    boothData.boothDisplayName ?? ""
  );
  const [buttonText, setButtonText] = useState("Save Info");
  const [error, setError] = useState("");
  const [limitPopupOpen, setLimitPopupOpen] = useState(false);
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);

  // eventSlug will only be defined when a booth is being created by the event admin
  const { boothId, eventSlug } =
    useParams<{ boothId: string; eventSlug: string }>();

  const [loadBooth] = useLazyQuery(BOOTH_DETAILS, {
    onCompleted: (data) => {
      setBoothData(data.booth);
    },
  });

  const matchEventAdmin = useRouteMatch(ROUTES.EVENT_ADMIN.EDIT);

  const eventSlugParam = CookieService.getCookie(Identifiers.EVENT_SLUG);

  useEffect(() => {
    if (matchEventAdmin && boothId !== "new") {
      loadBooth({ variables: { boothId: Number(boothId) } });
    }

    if (eventSlugParam && userInfo.booths) {
      const existingBoothEventSlugs = userInfo.booths.map(
        (booth) => booth.eventSlug
      );

      if (existingBoothEventSlugs.includes(eventSlugParam)) {
        setLimitPopupOpen(true);

        CookieService.removeCookie(Identifiers.EVENT_SLUG);
      }
    }
  }, []);

  const { register, handleSubmit } = useForm();

  const [checkAdminInvite] = useLazyQuery(CHECK_ADMIN_INVITE, {
    onCompleted: (data) => {
      if (!data.checkAdminInvite) {
        setInvitePopupOpen(true);
      }
    },
  });

  const [createBooth, { loading: createLoading }] = useMutation(CREATE_BOOTH, {
    onError: (error) => {
      if (error.message.includes("Name taken")) {
        setButtonText("Save Info");
        setError("Name taken");

        const nameField = document.getElementById("booth-name");
        nameField?.focus();
      }
    },
    onCompleted: (data) => {
      setButtonText("Saved!");
      setBoothData(data.createOneBooth);
      setUserInfo({
        ...userInfo,
        booths: [...userInfo.booths, data.createOneBooth],
      });

      if (eventSlugParam) {
        if (userInfo.booths) {
          checkAdminInvite({
            variables: {
              adminEmail: userInfo.email,
              eventSlug: eventSlugParam,
            },
          });
        }

        CookieService.removeCookie(Identifiers.EVENT_SLUG);
      }
    },
  });

  const [updateBooth, { loading: updateLoading }] = useMutation(UPDATE_BOOTH, {
    onError: (error) => {
      if (error.message.includes("Name taken")) {
        setButtonText("Save Info");
        setError("Name taken");

        const nameField = document.getElementById("booth-name");
        nameField?.focus();
      }
    },
    onCompleted: (data) => {
      setButtonText("Saved!");
      setBoothData(data.updateOneBooth);
    },
  });

  const onSubmit = (data) => {
    const createData = {
      ...data,
      facebookHandle: formatSocialMediaHandle(data.facebookHandle),
      instagramHandle: formatSocialMediaHandle(data.instagramHandle),
      twitterHandle: formatSocialMediaHandle(data.twitterHandle),
      youtubeHandle: formatSocialMediaHandle(data.youtubeHandle),
      ...((eventSlugParam || eventSlug) && {
        event: {
          connect: {
            slug: eventSlug ?? eventSlugParam,
          },
        },
      }),
      ...(!matchEventAdmin && {
        admin: {
          connect: {
            email: userInfo.email,
          },
        },
      }),
    };

    const updateData = {
      name: { set: data.name },
      boothDisplayName: { set: data.boothDisplayName },
      description: { set: data.description },
      facebookHandle: { set: formatSocialMediaHandle(data.facebookHandle) },
      instagramHandle: { set: formatSocialMediaHandle(data.instagramHandle) },
      twitterHandle: { set: formatSocialMediaHandle(data.twitterHandle) },
      youtubeHandle: { set: formatSocialMediaHandle(data.youtubeHandle) },
      externalLinkUrl: { set: data.externalLinkUrl },
      zoomUrl: { set: data.zoomUrl },
    };

    if (boothData?.id) {
      updateBooth({
        variables: {
          boothId: boothData.id,
          data: updateData,
        },
      });
    } else {
      createBooth({
        variables: {
          data: createData,
        },
      });
    }
  };

  return (
    <>
      {limitPopupOpen && (
        <MessagePopup
          title="Booth Limit Reached"
          message="Booth admins are allowed to create up to one booth per event. If this invite link has already been used, please edit your existing booth for this event instead."
          buttonText="Got It!"
          closePopup={() => setLimitPopupOpen(false)}
        />
      )}
      {invitePopupOpen && (
        <MessagePopup
          title="Permission Required"
          message="Only booth admins specified by the event admin can create booths.  Your booth has been saved, but it will not appear in the event until the event admin has granted you permission."
          buttonText="Got It!"
          closePopup={() => setInvitePopupOpen(false)}
        />
      )}
      <TabContainer>
        <Title>Booth Info</Title>
        <Text>
          Fill in your organization's info and upload any related media to start
          creating your VirtualFest booth.
        </Text>
        <Spacer height={20} />
        <Required>Required inputs*</Required>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Header>Organization Info</Header>
            <FieldLabel>
              Organization Name (Please do not use any abbreviations)
              <Required>*</Required>
              {error === "Name taken" && (
                <Error>Name taken, please choose a different name</Error>
              )}
              <InputField
                id="booth-name"
                name="name"
                placeholder="Enter organization name here"
                defaultValue={boothData.name}
                ref={register({ required: true })}
              />
            </FieldLabel>
            <FieldLabel>
              Booth Display Name
              <Required>*</Required>
              <CharacterLimit>{boothDisplayName.length}/20</CharacterLimit>
              <InputField
                name="boothDisplayName"
                placeholder="Enter booth display name here"
                defaultValue={boothData.boothDisplayName}
                ref={register({ required: true })}
                maxLength={20}
                onChange={(e) => setBoothDisplayName(e.target.value)}
              />
            </FieldLabel>
            <FieldLabel>
              Description
              <Required>*</Required>
              <TextAreaField
                name="description"
                placeholder="Enter organization description here"
                defaultValue={boothData.description}
                ref={register({ required: true })}
              />
            </FieldLabel>
            <FieldLabel>
              Zoom Link
              <div>
                Provide a link to a Zoom meeting in order to interact with event
                guests in the virtual world.
              </div>
              <InputField
                name="zoomUrl"
                placeholder="Enter Zoom meeting URL here"
                defaultValue={boothData.zoomUrl}
                ref={register}
              />
            </FieldLabel>
          </div>
          <div>
            <Header>Organization Social Media Links</Header>
            <Responsive minSize="xl">
              <Spacer height={15} />
            </Responsive>
            <FieldLabel>
              <SocialMediaInput>
                facebook.com/ &nbsp;&nbsp;
                <InputField
                  name="facebookHandle"
                  type="text"
                  placeholder="Enter handle"
                  defaultValue={boothData.facebookHandle}
                  isSocial
                  ref={register}
                />
              </SocialMediaInput>
            </FieldLabel>
            <FieldLabel>
              <SocialMediaInput>
                instagram.com/ &nbsp;&nbsp;
                <InputField
                  name="instagramHandle"
                  type="text"
                  placeholder="Enter handle"
                  defaultValue={boothData.instagramHandle}
                  isSocial
                  ref={register}
                />
              </SocialMediaInput>
            </FieldLabel>
            <FieldLabel>
              <SocialMediaInput>
                twitter.com/ &nbsp;&nbsp;
                <InputField
                  name="twitterHandle"
                  type="text"
                  placeholder="Enter handle"
                  defaultValue={boothData.twitterHandle}
                  isSocial
                  ref={register}
                />
              </SocialMediaInput>
            </FieldLabel>
            <FieldLabel>
              <SocialMediaInput>
                youtube.com/ &nbsp;&nbsp;
                <InputField
                  name="youtubeHandle"
                  type="text"
                  placeholder="Enter handle"
                  defaultValue={boothData.youtubeHandle}
                  isSocial
                  ref={register}
                />
              </SocialMediaInput>
            </FieldLabel>
            <SubHeader>Link to website</SubHeader>
            <div>Add a link to any external resource</div>
            <FieldLabel>
              Website URL
              <InputField
                name="externalLinkUrl"
                placeholder="Enter url here"
                defaultValue={boothData.externalLinkUrl}
                ref={register}
              />
            </FieldLabel>
          </div>
          <Button type="submit" disabled={createLoading || updateLoading}>
            {buttonText}
          </Button>
        </Form>
        {boothData.id && (
          <BoothMedia boothData={boothData} setBoothData={setBoothData} />
        )}
      </TabContainer>
    </>
  );
};

export default BoothInfo;

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 25px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const PreviewButton = styled.button({
  position: "absolute",
  top: 2,
  right: 20,
  color: COLORS.lightBlack,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  border: `2px solid ${COLORS.lightBlack}`,
  borderRadius: 5,
  marginTop: 15,
  height: 42,
  width: 160,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.sm)]: {
    top: 22,
    right: 60,
  },
  [media(Breakpoint.lg)]: {
    top: 52,
    right: 80,
  },
});

const Text = styled.div({
  width: "50%",
});

const Required = styled.span({
  color: COLORS.red,
  fontWeight: 600,
  fontSize: 14,
});

const Header = styled.div({
  fontSize: 22,
  fontWeight: 600,
  margin: "30px 0 25px",
  [media(Breakpoint.xl)]: {
    marginTop: 25,
  },
});

const SubHeader = styled.label({
  display: "block",
  fontSize: 16,
  fontWeight: 600,
  margin: "15px 0 10px",
});

const Form = styled.form({
  display: "grid",
  fontSize: 14,
  [media(Breakpoint.xl)]: {
    gridTemplateColumns: "1fr 1fr",
    gridGap: "0 80px",
  },
});

const FieldLabel = styled.label({
  display: "block",
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  marginTop: 20,
  div: {
    fontWeight: "normal",
  },
});

const InputField = styled.input<{ isSocial?: Boolean }>(({ isSocial }) => ({
  display: isSocial ? "inline-block" : "block",
  backgroundColor: COLORS.white,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lightGrey}`,
  fontSize: 14,
  margin: isSocial ? "0 0 0 auto" : "12px 0 25px",
  width: "100%",
  padding: "0 15px",
  border: `1px solid ${COLORS.grey}`,
  height: 40,
  fontFamily: "Poppins, sans-serif",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: 14,
  },
}));

const Error = styled.div({
  color: COLORS.red,
  fontSize: 14,
  fontWeight: "normal",
  margin: "5px 0 -3px",
});

const CharacterLimit = styled.div({
  fontSize: 12,
  lineHeight: "12px",
  paddingRight: 4,
  textAlign: "right",
  margin: "-10px 0 -5px",
  fontWeight: "normal",
});

const TextAreaField = styled.textarea({
  padding: "10px 15px",
  border: `1px solid ${COLORS.grey}`,
  height: 160,
  width: "100%",
  fontSize: "14px",
  lineHeight: "20px",
  resize: "none",
  fontFamily: "Poppins, sans-serif",
  borderRadius: 5,
  marginTop: 12,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
});

const SocialMediaInput = styled.div({
  display: "grid",
  gridTemplateColumns: "180px 1fr",
  alignItems: "center",
  color: COLORS.darkGrey,
  width: "100%",
  margin: "8px 0 25px",
});

const Button = styled.button<{ disabled: boolean }>(({ disabled }) => ({
  display: "block",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  height: 42,
  width: 160,
  marginTop: 15,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.xl)]: {
    marginTop: 10,
  },
}));
