import { useParams } from "react-router-dom";

export const useBoothSlug = (baseRoutes: Object) => {
  const { boothId } = useParams<{ boothId: string }>();

  const replaceSlug = (newRoutes, routeObject) => {
    for (let key in routeObject) {
      if (typeof routeObject[key] === "string") {
        newRoutes[key] = routeObject[key].replace(
          ":boothId",
          boothId ?? "undefined"
        );
      } else {
        newRoutes[key] = {};
        replaceSlug(newRoutes[key], routeObject[key]);
      }
    }
  };

  const modifiedRoutes = {};

  replaceSlug(modifiedRoutes, baseRoutes);

  return modifiedRoutes;
};
