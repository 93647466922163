import React, { useState, Dispatch, SetStateAction } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROFILE_INFO } from "./queries.threeJs";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Responsive from "shared/components/Responsive";
import Loading from "shared/components/Loading";
import closeX from "shared/icons/Close_button.svg";
import facebook from "./Sidebar/images/facebook.png";
import instagram from "./Sidebar/images/instagram.png";
import twitter from "./Sidebar/images/twitter.png";
import linkedin from "./Sidebar/images/linkedin.png";
import { BASE_Z_INDEX } from "shared/constants";
import { openInNewTab } from "shared/utils";

interface Props {
  setProfilePopup: Dispatch<SetStateAction<any>>;
  guestId: number | undefined;
}

const ProfilePopup = ({ setProfilePopup, guestId }: Props) => {
  const { data, loading, error } = useQuery(GET_PROFILE_INFO, {
    variables: { guestId },
  });

  if (loading || error)
    return (
      <>
        <Dimmer />
        <PopupContainer>
          <Popup>
            <Loading />
          </Popup>
        </PopupContainer>
      </>
    );

  const { guest } = data;

  return (
    <>
      <Dimmer onClick={() => setProfilePopup({ open: false })} />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setProfilePopup({ open: false })}
          />
          <Title>{guest.name}</Title>
          <Subtitle>{guest.location}</Subtitle>
          <SocialIcons>
            {guest.facebookHandle && (
              <SocialIcon
                type="image"
                src={facebook}
                alt="Facebook"
                onClick={() =>
                  openInNewTab(
                    `https://www.facebook.com/${guest.facebookHandle}`
                  )
                }
              />
            )}
            {guest.linkedinHandle && (
              <SocialIcon
                type="image"
                src={linkedin}
                alt="LinkedIn"
                onClick={() =>
                  openInNewTab(
                    `https://www.linkedin.com/in/${guest.linkedinHandle}`
                  )
                }
              />
            )}
            {guest.instagramHandle && (
              <SocialIcon
                type="image"
                src={instagram}
                alt="Instagram"
                onClick={() =>
                  openInNewTab(
                    `https://www.instagram.com/${guest.instagramHandle}`
                  )
                }
              />
            )}
            {guest.twitterHandle && (
              <SocialIcon
                type="image"
                src={twitter}
                alt="Twitter"
                onClick={() =>
                  openInNewTab(`https://twitter.com/${guest.twitterHandle}`)
                }
              />
            )}
          </SocialIcons>
          <div>{guest.bio}</div>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default ProfilePopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 11,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 12,
  top: "18vh",
  left: "5%",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    top: "15vh",
    width: "400px",
    left: "calc(50% - 200px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  textAlign: "center",
  padding: "80px 20px 60px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "22px",
  color: COLORS.lightBlack,
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "80px 50px 70px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 24,
  lineHeight: "32px",
  fontWeight: "bold",
  marginBottom: 10,
});

const Subtitle = styled.div({
  fontSize: 14,
  fontWeight: "bold",
});

const SocialIcons = styled.div({
  display: "flex",
  margin: "25px auto",
});

const SocialIcon = styled.input({
  height: 20,
  width: 20,
  margin: "0 5px",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 25,
  left: 25,
  width: 160,
  height: 42,
  fontSize: 14,
  fontWeight: "bold",
  borderRadius: 5,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
