import React from "react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/browser";
import { ThemeProvider } from "emotion-theming";
import "./reset.css";
import theme from "shared/styles/theme";
import { Debugger } from "@mverissimoo/emotion-grid";
import { ROUTES } from "@virtualfest/common";
import NotFoundScreen from "screens/NotFound";
import ThreeJS from "screens/ThreeJS/ThreeJS";
import BoothAdmin from "screens/BoothAdmin/BoothAdmin";
import EventAdmin from "screens/EventAdmin/EventAdmin";
import YetiAdmin from "screens/YetiAdmin/YetiAdmin";
import EmailVerification from "screens/ThreeJS/Auth/EmailVerification";
import ResetPassword from "screens/ThreeJS/Auth/ResetPassword";
import DirectoryOfEvents from "screens/DirectoryOfEvents/DirectoryOfEvents";
import Intro from "screens/Intro/Intro";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const history = createBrowserHistory();

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
) {
  ReactGA.initialize([
    { trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID },
  ]);
  ReactGA.pageview(
    window.location.pathname + window.location.search + window.location.hash
  );

  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname + location.search + location.hash); // Record a pageview for the given page
  });
}

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_FACEBOOK_PIXEL_ID
) {
  const options = {
    autoConfig: true,
    debug: false,
  };

  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, undefined, options);
  ReactPixel.pageView();
}

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route path={ROUTES.BASE.NEWSCHOOL} component={DirectoryOfEvents} />
            <Route path={ROUTES.BASE.WELCOME} component={Intro} />
            <Route
              path={ROUTES.BASE.ROOT}
              exact
              render={() => <Redirect to={ROUTES.EVENT.ROOT} />}
            />
            <Route
              path={ROUTES.BASE.EVENT}
              exact
              render={() => <Redirect to={ROUTES.EVENT.ROOT} />}
            />
            <Route
              path={ROUTES.BASE.EVENT_ADMIN}
              exact
              render={() => (
                <Redirect
                  to={ROUTES.EVENT_ADMIN.ROOT.replace(":eventSlug", "default")}
                />
              )}
            />
            <Route
              path={ROUTES.BASE.BOOTH_ADMIN}
              exact
              render={() => (
                <Redirect
                  to={ROUTES.BOOTH_ADMIN.ROOT.replace(":boothId", "default")}
                />
              )}
            />

            {/* Virtual World */}
            <Route path={[ROUTES.EVENT.ROOT]} component={ThreeJS} />

            <Route
              path={ROUTES.VERIFY_EMAIL}
              exact
              component={EmailVerification}
            />

            <Route
              path={ROUTES.RESET_PASSWORD}
              exact
              component={ResetPassword}
            />

            {/* Booth Admin Section */}
            <Route path={ROUTES.BOOTH_ADMIN.ROOT} component={BoothAdmin} />

            {/* Event Admin Section */}
            <Route path={ROUTES.EVENT_ADMIN.ROOT} component={EventAdmin} />

            {/* Internal Yeti Admin Section */}
            <Route path="/yeti-admin" component={YetiAdmin} />

            {/* Other */}
            <Route component={NotFoundScreen} />
          </Switch>
        </Router>
        {process.env.NODE_ENV === "development" && <Debugger />}
      </ThemeProvider>
    </>
  );
};

export default App;
