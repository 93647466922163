import React, { useState, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_BOOTH_ADMIN_INVITE } from "./mutations.eventAdmin";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Spacer from "shared/components/Spacer";
import { useForm } from "react-hook-form";
import closeX from "shared/icons/Close_button.svg";
import HiddenClipboardInput from "shared/components/HiddenClipboardInput";

interface Props {
  eventData: any;
  onSuccess: () => void;
  setInvitePopupOpen: Dispatch<SetStateAction<boolean>>;
}

const InvitePopup = ({ eventData, onSuccess, setInvitePopupOpen }: Props) => {
  const [inviteButtonText, setInviteButtonText] = useState("Add Booth Admin");
  const [hasError, setHasError] = useState(false);

  const { register, handleSubmit } = useForm();

  const [addBoothInvite] = useMutation(ADD_BOOTH_ADMIN_INVITE, {
    onCompleted: () => {
      setInviteButtonText("Added!");
      onSuccess();
    },
    onError: () => {
      setHasError(true);
    },
  });

  const boothAdminInviteLink = `${
    process.env.REACT_APP_PUBLIC_URL
  }${ROUTES.BOOTH_ADMIN.INFO.replace(":boothId", "default")}?event=${
    eventData.slug
  }`;

  const onSubmit = (data) => {
    addBoothInvite({
      variables: {
        data: {
          name: data.name,
          email: data.email.toLowerCase(),
          event: {
            connect: { slug: eventData.slug },
          },
        },
      },
    });
  };

  return (
    <>
      <Dimmer />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setInvitePopupOpen(false)}
          />
          <Title>Invite Booth Admin</Title>
          <Text>
            Add a booth admin to create a booth for your event. Only people on
            this list will be able to create a booth, regardless of whether or
            not they have the invite link.
            <Spacer height={15} />
            Up to twelve booths, including your own, may be created per event.
          </Text>
          <Sections>
            <Section>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FieldLabel>
                  Name
                  <InputField
                    name="name"
                    type="text"
                    placeholder="Enter name here"
                    ref={register({ required: true })}
                  />
                </FieldLabel>
                <FieldLabel>
                  Email
                  <InputField
                    name="email"
                    type="email"
                    placeholder="Enter email address here"
                    ref={register({ required: true })}
                  />
                </FieldLabel>
                <Button type="submit">{inviteButtonText}</Button>
                {hasError && (
                  <ErrorText>
                    Something went wrong, this email may already exist.
                  </ErrorText>
                )}
              </form>
            </Section>
            <Divider />
            <Section>
              <Text isSection>
                Copy the invite link below and send it to invited booth admins.
                Booth admin invite links are specific to each event.
              </Text>
              <InviteUrl>{boothAdminInviteLink}</InviteUrl>
              <CopyButtonContainer>
                <HiddenClipboardInput
                  id="inviteUrl"
                  defaultButtonText="Copy to Clipboard"
                  contentToCopy={boothAdminInviteLink}
                />
              </CopyButtonContainer>
            </Section>
          </Sections>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default InvitePopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: 10,
});

const PopupContainer = styled.div({
  position: "absolute",
  zIndex: 11,
  top: "8vh",
  left: "5%",
  width: "90%",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    width: "500px",
    left: "calc(50% - 250px)",
  },
  [media(Breakpoint.md)]: {
    width: "750px",
    left: "calc(50% - 375px)",
  },
  [media(Breakpoint.lg)]: {
    width: "900px",
    left: "calc(50% - 450px)",
  },
  [media(Breakpoint.xl)]: {
    width: "1100px",
    left: "calc(50% - 550px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  padding: "80px 0",
  width: "100%",
  lineHeight: "24px",
  fontFamily: "Poppins, sans-serif",
  color: COLORS.lightBlack,
  borderRadius: 20,
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 30,
  fontWeight: "bold",
  marginBottom: 25,
  lineHeight: "36px",
  textAlign: "center",
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 36,
    lineHeight: "45px",
  },
});

const Text = styled.div<{ isSection?: boolean }>(({ isSection }) => ({
  textAlign: "center",
  padding: isSection ? 0 : "0 20px",
  [media(Breakpoint.md)]: {
    padding: isSection ? 0 : "0 40px",
  },
  [media(Breakpoint.lg)]: {
    padding: isSection ? 0 : "0 60px",
  },
}));

const Sections = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  alignItems: "center",
  marginTop: 20,
  [media(Breakpoint.md)]: {
    gridTemplateColumns: "1fr 1px 1fr",
  },
});

const Section = styled.div({
  padding: "0 40px",
  [media(Breakpoint.lg)]: {
    padding: "0 60px",
  },
});

const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  textAlign: "left",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
});

const Button = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  display: "block",
  cursor: disabled ? "default" : "pointer",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  margin: "50px auto 0",
  height: 50,
  minWidth: 160,
  padding: "0 20px",
  opacity: disabled ? 0.5 : 1,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    marginTop: 40,
  },
}));

const CopyButtonContainer = styled(Button)({
  padding: 0,
});

const ErrorText = styled.div({
  color: "red",
  fontSize: 14,
  marginTop: 15,
  textAlign: "center",
});

const Divider = styled.div({
  width: "80%",
  height: 0,
  margin: "50px auto 40px",
  borderBottom: `1px solid ${COLORS.grey}`,
  [media(Breakpoint.md)]: {
    width: 0,
    height: "100%",
    margin: 0,
    borderRight: `1px solid ${COLORS.grey}`,
    borderBottom: "none",
  },
});

const InviteUrl = styled.div({
  fontSize: 16,
  fontWeight: "bold",
  textAlign: "center",
  margin: "20px 0 -10px",
});
