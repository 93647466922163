import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CURRENT_BOOTH_ADMIN, CURRENT_BOOTH } from "./queries.boothAdmin";
import { DELETE_BOOTH } from "../EventAdmin/mutations.eventAdmin";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import { useBoothSlug } from "shared/hooks/useBoothSlug";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { socket } from "services/SocketIo/SocketIOService";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import virtualFestLogo from "shared/icons/vf-logo.svg";
import boothInactive from "../EventAdmin/images/Booth.svg";
import boothActive from "../EventAdmin/images/Booth_Active.svg";
import chatInactive from "../EventAdmin/images/CustomText.svg";
import chatActive from "../EventAdmin/images/CustomText_active.svg";
import DehazeIcon from "@material-ui/icons/Dehaze";
import { Breakpoint } from "shared/types";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import Loading from "shared/components/Loading";
import BoothAdminLogin from "./BoothAdminLogin";
import BoothAdminRegister from "./BoothAdminRegister";
import VerifyEmail from "../ThreeJS/Auth/VerifyEmail";
import YourBooths from "./YourBooths";
import BoothInfo from "./BoothInfo/BoothInfo";
import BoothAdminChat from "./BoothAdminChat";
import InvalidSlugScreen from "../ThreeJS/InvalidSlugScreen";
import ConfirmationPopup from "shared/components/ConfirmationPopup";

const BoothAdmin = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [emailVerified, setEmailVerified] = useState(false);
  const [boothData, setBoothData] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [deletePopup, setDeletePopup] = useState<{
    boothId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [chatMessages, setChatMessages] = useState<any>({});

  const loggedInRef = useRef(false);
  const chatMessagesRef = useRef<any>({});

  const classes = useStyles();

  const history = useHistory();

  const searchParams = useSearchParams();
  const eventSlugParam = searchParams.get("event");

  const { boothId } = useParams<{ boothId: string }>();

  const { pathname } = useLocation();

  const {
    ROOT: BASE_ROOT,
    LOGIN: BASE_LOGIN,
    REGISTER: BASE_REGISTER,
    INFO: BASE_INFO,
  } = ROUTES.BOOTH_ADMIN;

  if (boothId === "undefined" || boothId === ":boothId") {
    history.replace(BASE_ROOT.replace(":boothId", "default"));
  }

  const { ROOT, LOGIN, REGISTER, INFO, CHAT }: any = useBoothSlug(
    ROUTES.BOOTH_ADMIN
  );

  const matchRoot = useRouteMatch({ path: ROOT });

  const [currentBooth] = useLazyQuery(CURRENT_BOOTH, {
    onCompleted: (data) => {
      setBoothData(data.booth);
      setInitialLoading(false);
    },
  });

  const [currentBoothAdmin, { loading: authLoading }] = useLazyQuery(
    CURRENT_BOOTH_ADMIN,
    {
      onError: () => setInitialLoading(false),
      onCompleted: (data) => {
        setLoggedIn(true);
        setEmailVerified(data.currentBoothAdmin.hasVerifiedEmail);
        setUserInfo(data.currentBoothAdmin);
      },
    }
  );

  const [deleteBooth] = useMutation(DELETE_BOOTH, {
    onCompleted: (data) => {
      setUserInfo({
        ...userInfo,
        booths: userInfo.booths.filter(
          (booth) => booth.id !== data.deleteOneBooth.id
        ),
      });

      setDeletePopup({ ...deletePopup, success: true });
      history.push(BASE_ROOT);
    },
  });

  useEffect(() => {
    if (eventSlugParam) {
      CookieService.setCookie(Identifiers.EVENT_SLUG, eventSlugParam);
    }

    const token = CookieService.getCookie(Identifiers.AccessToken);
    if (token) {
      currentBoothAdmin();
    } else {
      setInitialLoading(false);
      history.push(LOGIN);
    }

    return () => {
      socket.off("chatMessage");
    };
  }, []);

  useEffect(() => {
    if (userInfo.id) {
      if (!loggedInRef.current) {
        for (let booth of userInfo.booths) {
          socket.emit("joinChat", { roomId: booth.id });
        }

        socket.on("chatMessage", ({ roomId, messageData }) => {
          let newChatMessages;

          newChatMessages = {
            ...chatMessagesRef.current,
            [roomId]: [...(chatMessagesRef.current[roomId] ?? []), messageData],
          };

          setChatMessages(newChatMessages);
          chatMessagesRef.current = newChatMessages;
        });

        loggedInRef.current = true;
      }

      if (!isNaN(Number(boothId))) {
        currentBooth({ variables: { boothId: Number(boothId) } });
        if (matchRoot?.isExact) history.replace(INFO);
      } else {
        setInitialLoading(false);
      }
    }
  }, [userInfo, boothId]);

  const handleMobileNavClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileNavClose = (pathname?: string) => {
    if (pathname) history.push(pathname);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    setLoggedIn(false);
    setEmailVerified(false);
    setUserInfo({});
    history.push(LOGIN);
  };

  const isNotBoothOwner =
    boothData?.adminEmail && userInfo.email !== boothData?.adminEmail;

  const renderContent = () => {
    switch (true) {
      case !loggedIn:
        return (
          <Switch>
            <Route
              path={[BASE_ROOT, BASE_LOGIN]}
              exact
              render={() => (
                <BoothAdminLogin
                  setLoggedIn={setLoggedIn}
                  setUserInfo={setUserInfo}
                  setEmailVerified={setEmailVerified}
                />
              )}
            />
            <Route
              path={BASE_REGISTER}
              exact
              render={() => <BoothAdminRegister setLoggedIn={setLoggedIn} />}
            />
            <Route render={() => <Redirect to={ROOT} />} />
          </Switch>
        );
      case !emailVerified:
        return <VerifyEmail userType={USER_TYPES.BOOTH_ADMIN} />;
      case matchRoot?.isExact:
        return (
          <YourBooths
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            setBoothData={setBoothData}
          />
        );
      case !boothData || isNotBoothOwner:
        return <InvalidSlugScreen />;
      default:
        return (
          <>
            {deletePopup.open && (
              <ConfirmationPopup
                text="Once confirmed, this booth will be deleted"
                confirmButtonText="Delete Booth"
                closePopup={() => setDeletePopup({ open: false })}
                onConfirm={() =>
                  deleteBooth({ variables: { boothId: deletePopup.boothId } })
                }
                success={deletePopup.success}
                successMessage="The booth you selected has been removed"
              />
            )}
            <ReturnBar>
              <ReturnButton
                type="button"
                onClick={() => history.push(BASE_ROOT)}
              >
                Back To Booths
              </ReturnButton>
              <div>
                Viewing Booth Info For <b>{boothData.name ?? "New Booth"}</b>
                {boothData.event && (
                  <span>
                    {" "}
                    From <b>{boothData.event.name}</b>
                  </span>
                )}
              </div>
            </ReturnBar>
            <Container>
              {/* Desktop Navbar */}
              <Navbar>
                <NavbarLink
                  current={pathname === INFO}
                  onClick={() => history.push(INFO)}
                >
                  <NavbarLinkIcon
                    imageUrl={pathname === INFO ? boothActive : boothInactive}
                    current={pathname === INFO}
                  />
                  Booth Info
                </NavbarLink>
                {boothData.id && (
                  <>
                    <NavbarLink
                      current={pathname === CHAT}
                      onClick={() => history.push(CHAT)}
                    >
                      <NavbarLinkIcon
                        imageUrl={pathname === CHAT ? chatActive : chatInactive}
                        current={pathname === CHAT}
                      />
                      Chat
                    </NavbarLink>
                    <DeleteButton
                      type="button"
                      onClick={() =>
                        setDeletePopup({ open: true, boothId: boothData.id })
                      }
                    >
                      Delete Booth
                    </DeleteButton>
                  </>
                )}
              </Navbar>

              {/* Main Content */}
              <Switch>
                <Route
                  path={BASE_INFO}
                  exact
                  render={() => (
                    <BoothInfo
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      asEventAdmin={false}
                      boothData={boothData}
                      setBoothData={setBoothData}
                    />
                  )}
                />
                <Route
                  path={CHAT}
                  exact
                  render={() => (
                    <BoothAdminChat
                      boothData={boothData}
                      chatMessages={chatMessages}
                      setChatMessages={setChatMessages}
                      chatMessagesRef={chatMessagesRef}
                    />
                  )}
                />
                <Route render={() => <Redirect to={ROOT} />} />
              </Switch>
            </Container>
          </>
        );
    }
  };

  if (initialLoading || authLoading) return <Loading />;

  return (
    <>
      <Header>
        <Logo
          type="image"
          src={virtualFestLogo}
          alt=""
          onClick={() => history.push(BASE_ROOT.replace(":boothId", "default"))}
        />
        <div>Booth Admin Dashboard</div>
        {loggedIn ? (
          <TextLink type="button" onClick={handleLogout}>
            Sign Out
          </TextLink>
        ) : pathname === REGISTER ? (
          <>
            <SignupText>Already have an account?&nbsp;&nbsp;</SignupText>
            <TextLink
              type="button"
              withText
              onClick={() => history.push(LOGIN)}
            >
              Sign In
            </TextLink>
          </>
        ) : (
          <>
            <SignupText>Don't have an account?&nbsp;&nbsp;</SignupText>
            <TextLink
              type="button"
              withText
              onClick={() => history.push(REGISTER)}
            >
              Sign Up
            </TextLink>
          </>
        )}
      </Header>

      {/* Mobile Nav Dropdown */}
      <IconButton
        classes={{ root: classes.navbarRoot }}
        onClick={handleMobileNavClick}
      >
        <NavbarIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => handleMobileNavClose()}
      >
        {boothData?.id && (
          <MenuItem onClick={() => handleMobileNavClose(INFO)}>
            Booth Info
          </MenuItem>
        )}
        {boothData?.id && (
          <MenuItem onClick={() => handleMobileNavClose(CHAT)}>Chat</MenuItem>
        )}
        {loggedIn && <MenuItem onClick={handleLogout}>Logout</MenuItem>}
      </Menu>

      {renderContent()}

      <FooterLinks>
        <div>
          © 2021 VirtualFest. Developed by&nbsp;
          <FooterLink href="https://yeti.co" target="_blank">
            Yeti
          </FooterLink>
        </div>
        <div>
          <FooterLink href="https://virtualfest.app/terms" target="_blank">
            Terms
          </FooterLink>
          &nbsp;|&nbsp;
          <FooterLink href="https://virtualfest.app/privacy" target="_blank">
            Privacy Policy
          </FooterLink>
        </div>
      </FooterLinks>
    </>
  );
};

export default BoothAdmin;

const Header = styled.div({
  display: "flex",
  alignItems: "center",
  fontFamily: "Poppins, sans-serif",
  height: 60,
  width: "100%",
  padding: "0 20px",
  fontSize: 16,
  backgroundColor: "#2D2D2D",
  color: COLORS.white,
  [media(Breakpoint.sm)]: {
    padding: "0 50px",
  },
});

const Logo = styled.input({
  display: "none",
  margin: "0 15px 5px 0",
  [media(Breakpoint.sm)]: {
    display: "initial",
    marginRight: 25,
  },
});

const SignupText = styled.div({
  display: "none",
  color: COLORS.white,
  marginLeft: "auto",
  fontSize: 14,
  [media(Breakpoint.md)]: {
    display: "initial",
  },
});

const TextLink = styled.button<{ withText?: boolean }>(({ withText }) => ({
  display: withText ? "initial" : "none",
  textDecoration: "underline",
  fontSize: 14,
  color: COLORS.white,
  marginLeft: "auto",
  [media(Breakpoint.md)]: {
    display: "initial",
    marginLeft: withText ? 0 : "auto",
  },
}));

const NavbarIcon = styled(DehazeIcon)({
  transform: "scale(1.3)",
  color: COLORS.white,
});

const Container = styled.div({
  width: "100%",
  height: "calc(100vh - 170px)",
  fontFamily: "Poppins, sans-serif",
  [media(Breakpoint.md)]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
});

const ReturnBar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: "Poppins, sans-serif",
  width: "100%",
  height: 60,
  padding: "0 20px",
  fontSize: 16,
  backgroundColor: COLORS.white,
  color: COLORS.lightBlack,
  "& b": {
    fontWeight: 600,
  },
  [media(Breakpoint.sm)]: {
    padding: "0 50px",
  },
});

const ReturnButton = styled.button({
  fontWeight: 600,
  color: COLORS.blue,
});

const Navbar = styled.div({
  display: "none",
  minHeight: "100%",
  backgroundColor: COLORS.lighterGrey,
  [media(Breakpoint.md)]: {
    display: "initial",
    width: 220,
  },
  [media(Breakpoint.lg)]: {
    width: 270,
  },
});

const NavbarLink = styled.button<{ current: boolean }>(({ current }) => ({
  display: "none",
  fontSize: 16,
  fontWeight: 600,
  color: current ? COLORS.darkBlue : COLORS.darkGrey,
  backgroundColor: current ? COLORS.lightGrey : COLORS.lighterGrey,
  height: 60,
  width: "100%",
  paddingLeft: 50,
  [media(Breakpoint.md)]: {
    display: "flex",
    alignItems: "center",
  },
}));

const NavbarLinkIcon = styled.div<{ current: boolean; imageUrl: string }>(
  ({ current, imageUrl }) => ({
    display: "none",
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "50%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: 15,
    backgroundColor: current ? COLORS.darkBlue : COLORS.white,
    height: 30,
    width: 30,
    padding: 10,
    borderRadius: 30,
    [media(Breakpoint.lg)]: {
      display: "initial",
    },
  })
);

const DeleteButton = styled.button({
  fontWeight: 600,
  fontSize: 14,
  color: COLORS.red,
  margin: "15px 0 0 50px",
});

const FooterLinks = styled.div({
  display: "flex",
  flexDirection: "column",
  color: COLORS.lightBlack,
  width: "100%",
  position: "fixed",
  bottom: 0,
  padding: "25px 0",
  height: 50,
  fontFamily: "Poppins, sans-serif",
  backgroundColor: COLORS.grey,
  [media(Breakpoint.sm)]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
  },
});

export const FooterLink = styled.a({
  color: COLORS.lightBlack,
  textDecoration: "underline",
  fontFamily: "Poppins, sans-serif",
});

const useStyles = makeStyles({
  navbarRoot: {
    position: "absolute",
    top: "7px",
    right: "12px",
    [media(Breakpoint.md)]: {
      display: "none",
    },
  },
});
