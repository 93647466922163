import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import { useLazyQuery } from "@apollo/react-hooks";
import { AUTHENTICATE_YETI_ADMIN } from "./queries.yetiAdmin";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import virtualFestLogo from "shared/icons/vf-logo.svg";
import eventInactive from "../EventAdmin/images/Event.svg";
import eventActive from "../EventAdmin/images/Event_Active.svg";
import { Breakpoint } from "shared/types";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import Loading from "shared/components/Loading";
import Login from "./Login.yetiAdmin";
import EventInfo from "../EventAdmin/EventInfo";
import EventsList from "./EventsList";
import CreateEventAttempts from "./CreateEventAttempts";
import AccountsList from "./AccountsList";

const YetiAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const history = useHistory();

  const { pathname } = useLocation();

  const { ROOT, EVENTS, CREATE_EVENT, ACCOUNTS, LEADS } = ROUTES.YETI_ADMIN;

  const [authenticateYetiAdmin, { loading: authLoading }] = useLazyQuery(
    AUTHENTICATE_YETI_ADMIN,
    {
      onCompleted: (data) => {
        if (data.authenticateYetiAdmin) setLoggedIn(true);
      },
    }
  );

  useEffect(() => {
    const token = CookieService.getCookie(Identifiers.AccessToken);
    if (token) authenticateYetiAdmin();

    setLoading(false);
  }, []);

  const handleMobileNavClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileNavClose = (pathname?: string) => {
    if (pathname) history.push(pathname);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    window.location.reload();
  };

  if (loading || authLoading) return <Loading />;

  return (
    <>
      {!loggedIn ? (
        <Login setLoggedIn={setLoggedIn} />
      ) : (
        <>
          <Header>
            <Logo src={virtualFestLogo} alt="" />
            <div>Yeti Admin Dashboard</div>
            {loggedIn && (
              <TextLink type="button" onClick={handleLogout}>
                Sign Out
              </TextLink>
            )}
          </Header>

          {/* Mobile Nav Dropdown */}
          <IconButton
            classes={{ root: classes.navbarRoot }}
            onClick={handleMobileNavClick}
          >
            <NavbarIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => handleMobileNavClose()}
          >
            <MenuItem onClick={() => handleMobileNavClose(EVENTS)}>
              Events
            </MenuItem>
            <MenuItem onClick={() => handleMobileNavClose(CREATE_EVENT)}>
              Create Event
            </MenuItem>
            <MenuItem onClick={() => handleMobileNavClose(LEADS)}>
              Leads
            </MenuItem>
            {loggedIn && <MenuItem onClick={handleLogout}>Sign Out</MenuItem>}
          </Menu>

          <Container>
            {/* Desktop Side Navbar */}
            <Navbar>
              <NavbarLink
                type="button"
                current={pathname === ROOT || pathname === EVENTS}
                onClick={() => history.push(EVENTS)}
              >
                <NavbarLinkIcon
                  imageUrl={
                    pathname === ROOT || pathname === EVENTS
                      ? eventActive
                      : eventInactive
                  }
                  current={pathname === ROOT || pathname === EVENTS}
                />
                Events
              </NavbarLink>
              <NavbarLink
                type="button"
                current={pathname === CREATE_EVENT}
                onClick={() => history.push(CREATE_EVENT)}
              >
                <NavbarLinkIcon
                  imageUrl={
                    pathname === CREATE_EVENT ? eventActive : eventInactive
                  }
                  current={pathname === CREATE_EVENT}
                />
                Create Event
              </NavbarLink>
              <NavbarLink
                type="button"
                current={pathname === ACCOUNTS}
                onClick={() => history.push(ACCOUNTS)}
              >
                <NavbarLinkIcon
                  imageUrl={pathname === ACCOUNTS ? eventActive : eventInactive}
                  current={pathname === ACCOUNTS}
                />
                Accounts
              </NavbarLink>
              <NavbarLink
                type="button"
                current={pathname === LEADS}
                onClick={() => history.push(LEADS)}
              >
                <NavbarLinkIcon
                  imageUrl={pathname === LEADS ? eventActive : eventInactive}
                  current={pathname === LEADS}
                />
                Leads
              </NavbarLink>
            </Navbar>

            {/* Main Content */}
            <Switch>
              <Route
                path={[ROOT, EVENTS]}
                exact
                render={() => <EventsList />}
              />

              <Route
                path={CREATE_EVENT}
                exact
                render={() => <EventInfo eventData={{}} />}
              />

              <Route path={ACCOUNTS} exact render={() => <AccountsList />} />

              <Route
                path={LEADS}
                exact
                render={() => <CreateEventAttempts />}
              />

              <Route render={() => <Redirect to={ROOT} />} />
            </Switch>
          </Container>
        </>
      )}
    </>
  );
};

export default YetiAdmin;

const Header = styled.div({
  display: "flex",
  alignItems: "flex-end",
  fontFamily: "Poppins, sans-serif",
  height: 60,
  width: "100%",
  padding: "0 20px 21px",
  fontSize: 16,
  backgroundColor: COLORS.darkBlue,
  color: COLORS.white,
  boxSizing: "border-box",
  [media(Breakpoint.sm)]: {
    padding: "0 50px 21px",
  },
});

const Logo = styled.img({
  display: "none",
  marginRight: 15,
  [media(Breakpoint.sm)]: {
    display: "initial",
    marginRight: 25,
  },
});

const TextLink = styled.button({
  display: "none",
  textDecoration: "underline",
  fontSize: 14,
  color: COLORS.white,
  marginLeft: "auto",
  [media(Breakpoint.md)]: {
    display: "initial",
  },
});

const NavbarIcon = styled(DehazeIcon)({
  transform: "scale(1.3)",
  color: COLORS.white,
});

const Container = styled.div({
  width: "100%",
  height: "calc(100vh - 60px)",
  fontFamily: "Poppins, sans-serif",
  [media(Breakpoint.md)]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
});

const Navbar = styled.div({
  display: "none",
  minHeight: "100%",
  backgroundColor: COLORS.lighterGrey,
  [media(Breakpoint.md)]: {
    display: "initial",
    padding: "35px 0",
    width: 220,
  },
  [media(Breakpoint.lg)]: {
    width: 270,
    padding: "60px 0",
  },
});

const NavbarLink = styled.button<{ current: boolean }>(({ current }) => ({
  display: "none",
  fontSize: 16,
  fontWeight: "bold",
  color: current ? COLORS.darkBlue : COLORS.darkGrey,
  backgroundColor: current ? COLORS.lightGrey : COLORS.lighterGrey,
  height: 60,
  width: "100%",
  paddingLeft: 50,
  [media(Breakpoint.md)]: {
    display: "flex",
    alignItems: "center",
  },
}));

const NavbarLinkIcon = styled.div<{ current: boolean; imageUrl: string }>(
  ({ current, imageUrl }) => ({
    display: "none",
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "50%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: 15,
    backgroundColor: current ? COLORS.darkBlue : COLORS.white,
    height: 30,
    width: 30,
    padding: 10,
    borderRadius: 30,
    [media(Breakpoint.lg)]: {
      display: "initial",
    },
  })
);

const useStyles = makeStyles({
  navbarRoot: {
    position: "absolute",
    top: "7px",
    right: "12px",
    [media(Breakpoint.md)]: {
      display: "none",
    },
  },
});
