import React, { useRef, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { useEventSlug } from "shared/hooks/useEventSlug";
import {
  Title,
  Form,
  FieldLabel,
  InputField,
  Button,
  TextLink,
  LoginLink,
} from "./Onboarding";
import { ROUTES } from "@virtualfest/common";
import Spacer from "shared/components/Spacer";

interface Props {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  adminName: string;
  setAdminName: Dispatch<SetStateAction<string>>;
  orgName: string;
  setOrgName: Dispatch<SetStateAction<string>>;
}

const Names = ({
  setCurrentStep,
  adminName,
  setAdminName,
  orgName,
  setOrgName,
}: Props) => {
  const nameRef = useRef(adminName);
  const orgRef = useRef(orgName);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT_ADMIN.LOGIN });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!adminName) {
      nameInputRef.current?.focus();
      return;
    }

    setCurrentStep(1);
  };

  return (
    <>
      <Title>Welcome{nameRef.current ? ` ${nameRef.current}` : ""}!</Title>
      <div>
        <b>We're happy you're here! Let's set up your venue and first event.</b>
        <Spacer height={5} />
        {nameRef.current || orgRef.current
          ? "To start, are your name and your organization's name correct?"
          : "Start by entering your name and your organization's name"}
      </div>
      <LoginLink>
        Already Registered?&nbsp;
        <TextLink type="button" onClick={() => history.push(LOGIN)}>
          Log In
        </TextLink>
      </LoginLink>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FieldLabel>
          Your Name
          <InputField
            type="text"
            placeholder="Enter your name here"
            value={adminName}
            ref={nameInputRef}
            onChange={(e) => setAdminName(e.target.value)}
          />
        </FieldLabel>
        <FieldLabel>
          Your Organization's Name (optional)
          <InputField
            type="text"
            placeholder="Enter organization's name here"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />
        </FieldLabel>
        <Button type="submit">Continue</Button>
      </Form>
    </>
  );
};

export default Names;
