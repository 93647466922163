import gql from "graphql-tag";

export const YETI_ADMIN_LOGIN = gql`
  query YetiAdminLogin($password: String!) {
    yetiAdminLogin(password: $password)
  }
`;

export const AUTHENTICATE_YETI_ADMIN = gql`
  query AuthenticateYetiAdmin {
    authenticateYetiAdmin
  }
`;

export const CHECK_UNIQUE_SLUG = gql`
  query CheckUniqueSlug($slug: String!) {
    checkUniqueSlug(slug: $slug)
  }
`;

export const YETI_ADMIN_EVENTS = gql`
  query YetiAdminEvents($pageNum: Int, $searchFilter: String) {
    yetiAdminEvents(pageNum: $pageNum, searchFilter: $searchFilter) {
      events {
        id
        name
        slug
        startDateTime
        endDateTime
        admin {
          id
          name
          email
        }
      }
      count
    }
  }
`;

export const YETI_ADMIN_ACCOUNTS = gql`
  query YetiAdminAccounts($pageNum: Int, $searchFilter: String) {
    yetiAdminAccounts(pageNum: $pageNum, searchFilter: $searchFilter) {
      accounts {
        id
        name
        email
      }
      count
    }
  }
`;

export const ACCOUNT_DETAILS = gql`
  query AccountDetails($adminEmail: String) {
    eventAdmin(where: { email: $adminEmail }) {
      id
      name
      email
      hasVerifiedEmail
      events {
        id
        isDemo
        organizationName
      }
    }
    createEventAttempt(where: { email: $adminEmail }) {
      id
    }
  }
`;

export const GET_LEADS = gql`
  query CreateEventAttempts {
    createEventAttempts(orderBy: { name: asc }) {
      id
      name
      email
      date
    }
  }
`;
