import gql from "graphql-tag";

export const EVENTS_BY_ADMIN = gql`
  query EventsByAdmin($email: String!) {
    eventsByAdmin(email: $email) {
      id
      name
      startDateTime
      endDateTime
      slug
    }
  }
`;
