import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { VERIFY_GUEST_LIST } from "../queries.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useHistory, useParams } from "react-router-dom";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Loading from "shared/components/Loading";

interface Props {
  guestEmail: string;
  setIsOnGuestList: Dispatch<SetStateAction<boolean>>;
}

const RequireGuestList = ({ guestEmail, setIsOnGuestList }: Props) => {
  const history = useHistory();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT.LOGIN });

  const { data, loading } = useQuery(VERIFY_GUEST_LIST, {
    variables: {
      eventSlug,
      email: guestEmail.toLowerCase(),
    },
  });

  useEffect(() => {
    if (data?.verifyGuestList) setIsOnGuestList(true);
  }, [data]);

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    history.push(LOGIN);
    window.location.reload();
  };

  if (loading || data?.verifyGuestList) {
    return <Loading />;
  } else {
    return (
      <Container>
        <TextLink type="button" onClick={handleLogout}>
          Logout
        </TextLink>
        <Title>Guest List Required</Title>
        <div>
          This event requires users to be present on the guest list to
          participate.
        </div>
        <div>
          Please contact the event organizer to be added to the guest list.
        </div>
      </Container>
    );
  }
};

export default RequireGuestList;

const Container = styled.div({
  textAlign: "center",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "28px",
  position: "relative",
  color: COLORS.lightBlack,
  padding: "120px 40px",
  [media(Breakpoint.sm)]: {
    padding: "120px 80px",
  },
});

const Title = styled.div({
  fontSize: 36,
  marginBottom: 30,
});

const TextLink = styled.button({
  fontSize: 16,
  color: COLORS.blue,
  position: "absolute",
  top: 25,
  right: 35,
  [media(Breakpoint.sm)]: {
    right: 50,
    top: 35,
  },
});
