import React, { Dispatch, SetStateAction, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { USER_SIGN_UP } from "../ThreeJS/mutations.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { openInNewTab } from "shared/utils";
import { useForm, Controller } from "react-hook-form";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spacer from "shared/components/Spacer";

interface Props {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const BoothAdminLogin = ({ setLoggedIn }: Props) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const classes = useStyles();

  const { register, handleSubmit, control, errors } = useForm();

  const [userSignUp, { loading }] = useMutation(USER_SIGN_UP, {
    onError: (error) => {
      if (error.message.includes("Existing user")) {
        setError("Existing user");
      }
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.userSignUp);
      setLoggedIn(true);
    },
  });

  const validatePasswordMatch = (data) => {
    if (data !== password) return false;
    return true;
  };

  const onSubmit = (data) => {
    userSignUp({
      variables: {
        data: {
          name: data.name,
          email: data.email,
          passwordDigest: data.password,
          isEmailSubscribed: data.isEmailSubscribed,
          userType: "BOOTH_ADMIN",
        },
      },
    });
  };

  return (
    <Container>
      <Title>Booth Admin Sign Up</Title>
      <Text>Sign up to create booths for events in VirtualFest!</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldLabel>
          Full Name
          <InputField
            name="name"
            type="text"
            placeholder="Enter full name"
            ref={register({ required: true })}
          />
        </FieldLabel>
        {error === "Existing user" && (
          <ErrorMessage>
            A user with that email address already exists
          </ErrorMessage>
        )}
        <FieldLabel>
          Email
          <InputField
            name="email"
            type="text"
            placeholder="Enter email address"
            ref={register({ required: true })}
          />
        </FieldLabel>
        <FieldLabel>
          Password
          <InputField
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            ref={register({ required: true })}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ShowPassword
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </ShowPassword>
        </FieldLabel>
        {errors.reenterPassword?.type === "validate" && (
          <ErrorMessage>Passwords do not match</ErrorMessage>
        )}
        <FieldLabel>
          Confirm Password
          <InputField
            name="reenterPassword"
            type={showPassword ? "text" : "password"}
            placeholder="Reenter password"
            ref={register({
              required: true,
              validate: validatePasswordMatch,
            })}
          />
          <ShowPassword
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </ShowPassword>
        </FieldLabel>
        <CheckboxFields>
          <CheckboxLabel>
            <Controller
              name="hasAcceptedTerms"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={(props) => (
                <FormCheckbox
                  classes={{
                    root: errors.hasAcceptedTerms
                      ? classes.error
                      : classes.root,
                    checked: classes.checked,
                  }}
                  color="default"
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
            <div>
              I have read and agree to the&nbsp;
              <TextLink
                type="button"
                weight={600}
                onClick={() =>
                  openInNewTab("https://www.virtualfest.app/terms")
                }
              >
                terms of service
              </TextLink>
              .
            </div>
          </CheckboxLabel>
          <Spacer height={4} />
          <CheckboxLabel>
            <Controller
              name="isEmailSubscribed"
              control={control}
              defaultValue={true}
              render={(props) => (
                <FormCheckbox
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                  color="default"
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
            I would liked to stay up to date with any VirtualFest news.
          </CheckboxLabel>
        </CheckboxFields>
        <Button type="submit" isSubmitting={loading}>
          Sign Up
        </Button>
      </Form>
    </Container>
  );
};

export default BoothAdminLogin;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "calc(100vh - 60px)",
  width: "100%",
  position: "relative",
  padding: "75px 30px",
  backgroundColor: COLORS.lightGrey,
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    padding: "100px 30px",
  },
});

const Title = styled.div({
  fontSize: 45,
  lineHeight: "52px",
  fontWeight: "bold",
  marginBottom: 30,
  textAlign: "center",
  color: COLORS.darkBlue,
  [media(Breakpoint.md)]: {
    fontSize: 70,
    lineHeight: "70px",
  },
});

const Text = styled.div({
  textAlign: "center",
  lineHeight: "20px",
});

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 30,
  width: "100%",
  [media(Breakpoint.sm)]: {
    marginTop: 35,
  },
});

const ErrorMessage = styled.div({
  color: COLORS.red,
  fontSize: 14,
  lineHeight: "14px",
  margin: "-5px 0 15px",
});

const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
  [media(Breakpoint.sm)]: {
    width: 340,
  },
});

const ShowPassword = styled.button({
  color: COLORS.darkGrey,
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  position: "absolute",
  bottom: 40,
  right: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const CheckboxFields = styled.div({
  display: "flex",
  flexDirection: "column",
  marginTop: 5,
});

const CheckboxLabel = styled.label({
  display: "flex",
  alignItems: "flex-start",
  fontSize: 14,
  lineHeight: "20px",
  position: "relative",
  width: "100%",
  marginBottom: 8,
  [media(Breakpoint.sm)]: {
    alignItems: "center",
    marginBottom: 5,
  },
});

const FormCheckbox = styled(Checkbox)({
  transform: "scale(0.9)",
  position: "relative",
  top: -5,
  [media(Breakpoint.sm)]: {
    top: 0,
  },
});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    color: COLORS.white,
    backgroundColor: COLORS.green,
    borderBottom: `6px solid ${COLORS.darkGreen}`,
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: 600,
    height: "60px",
    width: "200px",
    marginTop: 40,
    opacity: isSubmitting ? 0.5 : 1,
  })
);

const TextLink = styled.button<{ weight?: number }>(({ weight }) => ({
  textDecoration: "underline",
  color: COLORS.lightBlack,
  lineHeight: "26px",
  fontWeight: weight ?? "normal",
}));

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: "1px 8px 0 0",
    "& svg": {
      height: 32,
      width: 32,
      stroke: COLORS.lightBlack,
      "& path": {
        stroke: "transparent",
        color: COLORS.lightBlack,
      },
    },
  },
  error: {
    padding: 0,
    margin: "1px 8px 0 0",
    "& svg": {
      height: 32,
      width: 32,
      stroke: COLORS.white,
      "& path": {
        color: COLORS.red,
        stroke: COLORS.red,
      },
    },
  },
  checked: {
    "& svg": {
      fill: COLORS.darkBlue,
      height: 30,
      width: 32,
      margin: "1px 0",
      "& path": {
        stroke: COLORS.white,
      },
    },
  },
});
