import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { VERIFY_EMAIL } from "../mutations.threeJs";
import { useSearchParams } from "shared/hooks/useSearchParams";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Loading from "shared/components/Loading";

const EmailVerification = () => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { emailToken } = useParams<{ emailToken: string }>();

  const searchParams = useSearchParams();
  const userType = searchParams.get("userType");
  const eventSlug = searchParams.get("event");

  let rootRoute;
  if (userType === USER_TYPES.GUEST && eventSlug) {
    rootRoute = ROUTES.EVENT.ROOT.replace(":eventSlug", eventSlug);
  } else if (userType) {
    rootRoute = ROUTES[userType].ROOT;
  }

  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    onError: () => {
      setLoading(false);
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.verifyEmail);
      history.push(rootRoute);
    },
  });

  useEffect(() => {
    verifyEmail({
      variables: { verificationToken: emailToken },
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <ErrorMessage>
            The link you have tried is either invalid or may have expired.
          </ErrorMessage>
          <TextLink type="button" onClick={() => history.push(rootRoute)}>
            Go Home
          </TextLink>
        </Container>
      )}
    </>
  );
};

export default EmailVerification;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 16,
  fontFamily: "Poppins, sans-serif",
  padding: "120px 40px",
  textAlign: "center",
  [media(Breakpoint.sm)]: {
    padding: "120px 80px",
  },
});

const ErrorMessage = styled.div({
  fontSize: 32,
  lineHeight: "42px",
});

const TextLink = styled.button({
  fontSize: 16,
  color: COLORS.blue,
  marginTop: 40,
});
