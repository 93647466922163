import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { CURRENT_BOOTH_ADMIN } from "./queries.boothAdmin";
import { USER_LOGIN } from "../ThreeJS/queries.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import ForgotPassword from "../ThreeJS/Auth/ForgotPassword";

interface Props {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
  setEmailVerified: Dispatch<SetStateAction<boolean>>;
  setUserInfo: Dispatch<SetStateAction<any>>;
}

const BoothAdminLogin = ({
  setLoggedIn,
  setEmailVerified,
  setUserInfo,
}: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const history = useHistory();

  const [currentBoothAdmin] = useLazyQuery(CURRENT_BOOTH_ADMIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLoggedIn(true);
      setEmailVerified(data.currentBoothAdmin.hasVerifiedEmail);
      setUserInfo(data.currentBoothAdmin);

      const eventSlugParam = CookieService.getCookie(Identifiers.EVENT_SLUG);
      if (eventSlugParam) {
        history.push(
          `${ROUTES.BOOTH_ADMIN.INFO.replace(
            ":boothId",
            "default"
          )}?event=${eventSlugParam}`
        );
      }
    },
  });

  const [userLogin] = useLazyQuery(USER_LOGIN, {
    onError: (error) => {
      if (error.message.includes("Invalid credentials")) {
        setError(true);
      }
      setIsSubmitting(false);
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.userLogin.token);
      currentBoothAdmin();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password || isSubmitting) return;

    setIsSubmitting(true);

    userLogin({
      variables: {
        email,
        password,
        userType: USER_TYPES.BOOTH_ADMIN,
      },
    });
  };

  return (
    <>
      {forgotPasswordOpen && (
        <ForgotPassword
          setForgotPasswordOpen={setForgotPasswordOpen}
          userType={USER_TYPES.BOOTH_ADMIN}
        />
      )}
      <Container>
        <Title>Booth Admin Login</Title>
        <Text>Log in with your credentials to access</Text>
        <ErrorMessage hide={!error}>Invalid email and/or password</ErrorMessage>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FieldLabel>
            Email
            <InputField
              name="email"
              type="email"
              placeholder="Enter email here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FieldLabel>
          <FieldLabel>
            Password
            <InputField
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password here"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ShowPassword
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </ShowPassword>
            <ForgotPasswordLink
              type="button"
              onClick={() => setForgotPasswordOpen(true)}
            >
              Forgot Password?
            </ForgotPasswordLink>
          </FieldLabel>
          <Button type="submit" isSubmitting={isSubmitting}>
            Sign In
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default BoothAdminLogin;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "calc(100vh - 60px)",
  width: "100%",
  position: "relative",
  padding: "75px 30px",
  backgroundColor: COLORS.lightGrey,
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    padding: "100px 30px",
  },
});

const Title = styled.div({
  fontSize: 45,
  lineHeight: "52px",
  fontWeight: "bold",
  marginBottom: 30,
  textAlign: "center",
  color: COLORS.darkBlue,
  [media(Breakpoint.md)]: {
    fontSize: 70,
    lineHeight: "70px",
  },
});

const Text = styled.div({
  textAlign: "center",
  lineHeight: "20px",
});

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 30,
  width: "100%",
  [media(Breakpoint.sm)]: {
    marginTop: 35,
  },
});

const ErrorMessage = styled.div<{ hide: boolean }>(({ hide }) => ({
  color: COLORS.red,
  display: hide ? "none" : "initial",
  fontSize: 16,
  lineHeight: "16px",
  margin: "16px 0 -22px",
}));

const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
  [media(Breakpoint.sm)]: {
    width: 340,
  },
});

const ShowPassword = styled.button({
  color: COLORS.darkGrey,
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  position: "absolute",
  bottom: 40,
  right: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const ForgotPasswordLink = styled.button({
  textDecoration: "underline",
  position: "absolute",
  bottom: -5,
  right: 2,
  color: COLORS.green,
});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    color: COLORS.white,
    backgroundColor: COLORS.green,
    borderBottom: `6px solid ${COLORS.darkGreen}`,
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: 600,
    height: "60px",
    width: "200px",
    marginTop: 40,
    opacity: isSubmitting ? 0.5 : 1,
  })
);
