import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { BASE_Z_INDEX } from "shared/constants";
import Responsive from "shared/components/Responsive";
import Loading from "shared/components/Loading";
import closeX from "shared/icons/white_x.png";
import { openInNewTab } from "shared/utils";

interface Props {
  setLiveStreamOpen: Dispatch<SetStateAction<boolean>>;
  liveStreamEmbedUrl: string;
}

const ASPECT_RATIO = 560 / 315;

const LiveStream = ({ setLiveStreamOpen, liveStreamEmbedUrl }: Props) => {
  return (
    <>
      <Dimmer onClick={() => setLiveStreamOpen(false)} />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setLiveStreamOpen(false)}
          />
          <iframe
            width="100%"
            height="100%"
            src={liveStreamEmbedUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Popup>
      </PopupContainer>
    </>
  );
};

export default LiveStream;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.7,
  zIndex: BASE_Z_INDEX + 11,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 12,
  overflowY: "auto",
  pointerEvents: "auto",
  width: "100vw",
  left: 0,
  height: `calc(${90 / ASPECT_RATIO}vw + 40px)`,
  top: "15%",
  [media(Breakpoint.sm)]: {
    width: "calc(80vw + 80px)",
    left: "calc(10% - 40px)",
    height: `calc(${80 / ASPECT_RATIO}vw + 80px)`,
    top: `calc(50% - ${40 / ASPECT_RATIO}vw - 40px)`,
  },
  [media(Breakpoint.md)]: {
    width: "calc(70vw + 80px)",
    left: "calc(15% - 40px)",
    height: `calc(${70 / ASPECT_RATIO}vw + 80px)`,
    top: `calc(50% - ${35 / ASPECT_RATIO}vw - 40px)`,
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.black,
  padding: 20,
  height: "100%",
  width: "100%",
  [media(Breakpoint.sm)]: {
    padding: 40,
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 7,
  right: 7,
  height: 10,
  width: 10,
  opacity: 0.8,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.sm)]: {
    top: 18,
    right: 16,
    height: 15,
    width: 15,
  },
});
