import React from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Card, IconButton, Typography } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import DragIndicator from "@material-ui/icons/DragIndicator";

const SortableMediaItem = SortableElement(({ value, removeFunc }) => {
  return (
    <MediaCard>
      <MediaImage src={value} alt="Preview" className={`MuiPaper-elevation1`} />
      <MediaItemActions>
        <TrashButton onClick={() => removeFunc(value)}>
          <RemoveIcon />
        </TrashButton>
        <DragButton>
          <DragIndicator />
        </DragButton>
      </MediaItemActions>
    </MediaCard>
  );
});

const SortableMediaList = SortableContainer(({ items, removeFunc }) => {
  return (
    <MediaList>
      {items.map((link, index) => (
        <SortableMediaItem
          key={link}
          index={index}
          value={link}
          removeFunc={removeFunc}
        />
      ))}
    </MediaList>
  );
});

export default SortableMediaList;

const MediaList = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gridGap: "20px",
  justifyItems: "center",
  marginTop: 30,
  [media(Breakpoint.sm)]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    justifyItems: "flex-start",
  },
});

const MediaCard = styled(Card)({
  display: "flex",
  alignItems: "center",
  height: "245px",
  width: "280px",
  padding: "20px",
  position: "relative",
  [media(Breakpoint.sm)]: {
    width: "300px",
  },
});

const MediaImage = styled.img({
  display: "block",
  minWidth: 100,
  minHeight: "50px",
  maxWidth: "100%",
  maxHeight: 180,
  margin: "0 auto 25px",
});

const MediaItemActions = styled.div({
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  bottom: "5px",
  width: "calc(100% - 40px)",
});

const TrashButton = styled(IconButton)({
  color: COLORS.red,
  padding: 5,
});

const DragButton = styled(IconButton)({
  color: COLORS.blue,
  padding: 5,
});
