import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { BASE_Z_INDEX } from "shared/constants";
import closeX from "shared/icons/Close_button.svg";
import upDownArrows from "./images/updownarrow_icon.svg";
import leftRightArrows from "./images/leftrightarrow.svg";
import click from "./images/Click_icon.svg";

interface Props {
  setInstructionsOpen: Dispatch<SetStateAction<boolean>>;
  initialEntry: boolean;
  setInitialEntry: Dispatch<SetStateAction<boolean>>;
  setSidebarOpen: (open: boolean) => void;
  isDemoEvent: boolean;
}

const InstructionsPopup = ({
  setInstructionsOpen,
  initialEntry,
  setInitialEntry,
  setSidebarOpen,
  isDemoEvent,
}: Props) => {
  const [shouldShowDemoMessage, setShouldShowDemoMessage] =
    useState(isDemoEvent);

  const onClick = () => {
    if (shouldShowDemoMessage) {
      setShouldShowDemoMessage(false);
    } else {
      if (initialEntry) {
        setSidebarOpen(true);
        setInitialEntry(false);
      }
      setInstructionsOpen(false);
    }
  };

  return (
    <>
      <Dimmer onClick={onClick} />
      <PopupContainer isDemoEventMessage={shouldShowDemoMessage}>
        <Popup>
          <CloseX type="image" src={closeX} alt="Close" onClick={onClick} />
          {!shouldShowDemoMessage ? (
            <>
              <Title>Here are some tips to better navigate VirtualFest</Title>
              <Instructions>
                <Image src={leftRightArrows} alt="Arrows" />
                <div>
                  To pan your view, use your left & right arrows on your
                  keyboard or click & drag your mouse (or finger if on mobile).
                </div>
                <Image src={upDownArrows} alt="Arrows" />
                <div>
                  To move your avatar, use your up arrow to go forward & down
                  arrow to go backwards.
                </div>
                <Image src={click} alt="Click" small />
                <div>
                  To view more details about an organization, click on a table
                  (or tap if on mobile)
                </div>
              </Instructions>
            </>
          ) : (
            <>
              <Title>Welcome to Your Demo World!</Title>
              <DemoMessageText>
                Feel free to explore and learn more of what VirtualFest has to
                offer. Want to create your own event? Click "To Dashboard" to
                get started- it's free!
              </DemoMessageText>
            </>
          )}
          <Button type="button" onClick={onClick}>
            Got It!
          </Button>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default InstructionsPopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 10,
  pointerEvents: "auto",
});

const PopupContainer = styled.div<{ isDemoEventMessage: boolean }>(
  ({ isDemoEventMessage }) => ({
    position: "fixed",
    zIndex: BASE_Z_INDEX + 11,
    top: isDemoEventMessage ? "16vh" : "9vh",
    left: "5%",
    width: "90%",
    maxHeight: "80vh",
    overflowY: "auto",
    pointerEvents: "auto",
    [media(Breakpoint.sm)]: {
      width: "500px",
      left: "calc(50% - 250px)",
    },
    [media(Breakpoint.md)]: {
      width: "550px",
      left: "calc(50% - 275px)",
    },
  })
);

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  padding: "60px 30px 45px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  color: COLORS.lightBlack,
  [media(Breakpoint.sm)]: {
    padding: "60px 50px 50px",
  },
  [media(Breakpoint.md)]: {
    padding: "60px 70px 55px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 20,
  fontWeight: "bold",
  marginBottom: 20,
  lineHeight: "30px",
  textAlign: "center",
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 24,
    lineHeight: "32px",
    marginBottom: 30,
  },
});

const Instructions = styled.div({
  display: "grid",
  gridTemplateColumns: "60px 1fr",
  gridTemplateRows: "repeat(3, 100px)",
  gridGap: 25,
  justifyItems: "center",
  alignItems: "center",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "20px",
  [media(Breakpoint.sm)]: {
    gridTemplateRows: "auto",
    gridGap: "40px 25px",
  },
});

const Image = styled.img<{ small?: boolean }>(({ small }) => ({
  width: small ? "80%" : "100%",
}));

const DemoMessageText = styled.div({
  lineHeight: "26px",
  textAlign: "center",
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 160,
  height: 42,
  fontSize: 14,
  margin: "30px auto 0",
  borderRadius: 5,
  letterSpacing: 0.3,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  [media(Breakpoint.sm)]: {
    margin: "35px auto 0",
  },
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
