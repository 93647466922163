import React, { Dispatch, SetStateAction, MutableRefObject } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { CHAT_MESSAGE_TYPES } from "@virtualfest/common";
import TabContainer from "shared/components/TabContainer";
import ChatMessages from "../ThreeJS/Sidebar/Chat/ChatMessages";

interface Props {
  boothData: any;
  chatMessages: any;
  setChatMessages: Dispatch<SetStateAction<any>>;
  chatMessagesRef: MutableRefObject<any>;
}

const BoothAdminChat = ({
  boothData,
  chatMessages,
  setChatMessages,
  chatMessagesRef,
}: Props) => {
  const addNewBoothChatMessage = (newMessage) => {
    const newChatMessages = {
      ...chatMessagesRef.current,
      [boothData.id]: [
        ...(chatMessagesRef.current[boothData.id] ?? []),
        newMessage,
      ],
    };

    setChatMessages(newChatMessages);
    chatMessagesRef.current = newChatMessages;
  };

  const chatUserInfo = {
    id: 0,
    name: boothData.name.split(" ")[0],
    nickname: "Booth Admin",
  };

  return (
    <TabContainer>
      <Title>Booth Chat</Title>
      <ChatContainer>
        <ChatMessages
          userInfo={chatUserInfo}
          type={CHAT_MESSAGE_TYPES.BOOTH}
          roomId={boothData.id}
          messages={chatMessages[boothData.id] ?? []}
          addMessage={addNewBoothChatMessage}
        />
      </ChatContainer>
    </TabContainer>
  );
};

export default BoothAdminChat;

const ChatContainer = styled.div({
  width: "100%",
  margin: "25px 0 -10px",
  height: "calc(100% - 10px)",
  border: `1px solid ${COLORS.grey}`,
  borderTop: "none",
  minHeight: 350,
  [media(Breakpoint.sm)]: {
    marginBottom: -20,
    height: "calc(100% - 20px)",
  },
  [media(Breakpoint.md)]: {
    marginBottom: -30,
    height: "calc(100% - 30px)",
  },
  [media(Breakpoint.lg)]: {
    marginBottom: -40,
    height: "calc(100% - 40px)",
  },
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 25px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});
