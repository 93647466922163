import S3 from "aws-sdk/clients/s3";
import { v4 as uuidv4 } from "uuid";

const {
  REACT_APP_AWS_S3_UPLOAD_ACCESS_KEY_ID,
  REACT_APP_AWS_S3_UPLOAD_SECRET_ACCESS_KEY,
  REACT_APP_AWS_UPLOAD_BUCKET_NAME,
} = process.env;

class AWSService {
  private s3: S3;

  constructor() {
    this.s3 = new S3({
      region: "us-east-1",
      accessKeyId: REACT_APP_AWS_S3_UPLOAD_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_AWS_S3_UPLOAD_SECRET_ACCESS_KEY,
    });
  }

  public async uploadOnboardingBanner(imageFile: any): Promise<string> {
    const key = `onboarding-banner-${uuidv4()}`;

    const params: S3.Types.PutObjectRequest = {
      Bucket: REACT_APP_AWS_UPLOAD_BUCKET_NAME as string,
      ACL: "public-read",
      Key: key,
      Body: imageFile,
      ContentType: imageFile.type,
    };

    // @ts-ignore
    const imageData = await this.s3.upload(params).promise();
    return imageData.Location;
  }

  public async uploadEventBanner(
    imageFile: any,
    eventSlug: string
  ): Promise<string> {
    const key = `event-${eventSlug}-banner`;

    const params: S3.Types.PutObjectRequest = {
      Bucket: REACT_APP_AWS_UPLOAD_BUCKET_NAME as string,
      ACL: "public-read",
      Key: key,
      Body: imageFile,
      ContentType: imageFile.type,
    };

    // @ts-ignore
    const imageData = await this.s3.upload(params).promise();
    return imageData.Location;
  }

  public async uploadBoothLogo(
    imageFile: any,
    boothId?: number
  ): Promise<string> {
    const key = `booth-logo-${boothId}`;

    const params: S3.Types.PutObjectRequest = {
      Bucket: REACT_APP_AWS_UPLOAD_BUCKET_NAME as string,
      ACL: "public-read",
      Key: key,
      Body: imageFile,
      ContentType: imageFile.type,
    };

    // @ts-ignore
    const imageData = await this.s3.upload(params).promise();
    return imageData.Location;
  }

  public async uploadBoothAsset(
    imageFile: any,
    boothId: number,
    existingMediaUrls: string[]
  ): Promise<string> {
    let assetIndex = 0;
    let imageKey = `booth-${boothId}-asset-${assetIndex}`;
    const existingImageKeys = existingMediaUrls.map(
      (url) => url.split("amazonaws.com/")[1]
    );

    while (existingImageKeys.includes(imageKey)) {
      assetIndex += 1;
      imageKey = `booth-${boothId}-asset-${assetIndex}`;
    }

    const params: S3.Types.PutObjectRequest = {
      Bucket: REACT_APP_AWS_UPLOAD_BUCKET_NAME as string,
      ACL: "public-read",
      Key: imageKey,
      Body: imageFile,
      ContentType: imageFile.type,
    };

    // @ts-ignore
    const imageData = await this.s3.upload(params).promise();
    return imageData.Location;
  }
}

export default new AWSService();
