import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import closeX from "shared/icons/Close_button.svg";
import { ROUTES } from "@virtualfest/common";
import { BASE_Z_INDEX } from "shared/constants";
import HiddenClipboardInput from "shared/components/HiddenClipboardInput";

interface Props {
  eventSlug: string;
  setInviteGuestModalOpen: Dispatch<SetStateAction<any>>;
}

const InviteGuestModal = ({ eventSlug, setInviteGuestModalOpen }: Props) => {
  const eventUrl =
    process.env.REACT_APP_PUBLIC_URL +
    ROUTES.EVENT.REGISTER.replace(":eventSlug", eventSlug);

  return (
    <>
      <Dimmer onClick={() => setInviteGuestModalOpen(false)} />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setInviteGuestModalOpen(false)}
          />
          <Title>Invite Guests</Title>
          <div>
            Copy and send this unique URL to friends to invite them to your
            virtual world!
            <br />
            <br />
            (These will not be counted towards your monthly registrations
            limit.)
          </div>
          <EventUrl>{eventUrl}</EventUrl>
          <Button>
            <HiddenClipboardInput
              id="eventUrl"
              defaultButtonText="Copy to Clipboard"
              contentToCopy={eventUrl}
            />
          </Button>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default InviteGuestModal;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 11,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 12,
  top: "18vh",
  left: "5%",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    top: "15vh",
    width: "500px",
    left: "calc(50% - 250px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  borderRadius: 10,
  textAlign: "center",
  padding: "80px 20px 60px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "22px",
  color: COLORS.lightBlack,
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "80px 50px 70px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 36,
  lineHeight: "32px",
  fontWeight: "bold",
  marginBottom: 25,
});

const EventUrl = styled.div({
  fontSize: 16,
  fontWeight: "bold",
  marginTop: 30,
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 180,
  height: 50,
  fontSize: 14,
  letterSpacing: 0.2,
  fontWeight: 600,
  borderRadius: 5,
  marginTop: 35,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
