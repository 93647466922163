import React, { useState, Dispatch, SetStateAction } from "react";
import { CHECK_UNIQUE_SLUG } from "../YetiAdmin/queries.yetiAdmin";
import { CREATE_EVENT, UPDATE_EVENT } from "./mutations.eventAdmin";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { ROUTES } from "@virtualfest/common";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import { useForm, Controller } from "react-hook-form";
import { format } from "date-fns";
import { getCombinedDateTime } from "shared/utils";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import {
  MAX_ASPECT_RATIO,
  MIN_ASPECT_RATIO,
  MIN_SIZE,
} from "./Onboarding/Banner";
import AWSService from "services/AWS/AWSService";
import Spacer from "shared/components/Spacer";
import Loading from "shared/components/Loading";
import TabContainer from "shared/components/TabContainer";
import CropPopup from "./Onboarding/CropPopup";

export const WORLD_OPTIONS = [
  { name: "The Patio", value: "PATIO" },
  { name: "Conference Hall", value: "CONFERENCE" },
  { name: "Arena", value: "ARENA" },
  { name: "The Ballroom", value: "BALLROOM" },
];

interface Props {
  userInfo?: any;
  setUserInfo?: Dispatch<SetStateAction<any>>;
  eventData: any;
  setEventData?: Dispatch<SetStateAction<any>>;
}

const CreateEvent = ({
  userInfo,
  setUserInfo,
  eventData,
  setEventData,
}: Props) => {
  const [buttonText, setButtonText] = useState("Save Info");
  const [eventName, setEventName] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState(
    eventData.bannerImageUrl
  );
  const [imageFile, setImageFile] = useState<File | Blob | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [cropPopupOpen, setCropPopupOpen] = useState(false);
  const [bannerErrorMessage, setBannerErrorMessage] = useState("");

  const history = useHistory();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const yetiAdminMatch = useRouteMatch(ROUTES.YETI_ADMIN.CREATE_EVENT);

  const { register, handleSubmit, control } = useForm();

  const [checkUniqueSlug, { data }] = useLazyQuery(CHECK_UNIQUE_SLUG);

  const [createEvent, { loading: isCreating }] = useMutation(CREATE_EVENT, {
    onCompleted: (data) => {
      setUserInfo &&
        setUserInfo({
          ...userInfo,
          events: [...userInfo.events, data.createOneEvent],
        });
      setButtonText("Saved!");

      if (!yetiAdminMatch) {
        history.push(
          ROUTES.EVENT_ADMIN.INFO.replace(
            ":eventSlug",
            data.createOneEvent.slug
          )
        );
      }
    },
  });

  const [updateEvent, { loading: isUpdating }] = useMutation(UPDATE_EVENT, {
    onCompleted: (data) => {
      setEventData && setEventData(data.updateOneEvent);
      setUserInfo &&
        setUserInfo({
          ...userInfo,
          events: [
            ...userInfo.events.filter(
              (event) => event.id !== data.updateOneEvent.id
            ),
            data.updateOneEvent,
          ],
        });
      setButtonText("Saved!");
    },
  });

  const validateSlugUnique = () => {
    return data?.checkUniqueSlug;
  };

  const onSubmit = async (data) => {
    let location = eventData.bannerImageUrl;
    if (imageFile) {
      setIsUploading(true);
      location = await AWSService.uploadEventBanner(
        imageFile,
        eventData.slug ? eventData.slug : data.slug.trim()
      );
      setIsUploading(false);
    } else if (!bannerImageUrl) {
      location = "";
    }

    const startDateTime = getCombinedDateTime(data.startDate, data.startTime);
    const endDateTime = getCombinedDateTime(data.endDate, data.endTime);

    if (eventData?.id) {
      updateEvent({
        variables: {
          eventId: eventData.id,
          data: {
            name: { set: data.name },
            organizationName: { set: data.organizationName },
            bannerImageUrl: { set: location },
            numPeople: { set: Number(data.numPeople) },
            numBooths: { set: Number(data.numBooths) },
            startDateTime: { set: startDateTime },
            endDateTime: { set: endDateTime },
            liveStreamEmbedUrl: { set: data.liveStreamEmbedUrl },
          },
        },
      });
    } else {
      createEvent({
        variables: {
          data: {
            name: data.name,
            adminEmail: data.adminEmail ?? userInfo?.email,
            organizationName: data.organizationName,
            bannerImageUrl: location,
            selectedWorld: WORLD_OPTIONS[0].value,
            numPeople: Number(data.numPeople),
            numBooths: Number(data.numBooths),
            startDateTime,
            endDateTime,
            liveStreamEmbedUrl: data.liveStreamEmbedUrl,
            slug: data.slug.trim(),
            customText: {
              create: {
                welcome: "",
                events: "",
                booths: "",
                chat: "",
              },
            },
          },
        },
      });
    }
  };

  const handleBannerImageSelect = async (e) => {
    setIsUploading(true);

    if (e.target.validity.valid) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);

      let img: HTMLImageElement | null = new Image();

      img.onload = async () => {
        if (img) {
          const aspectRatio = img.width / img.height;

          setBannerImageUrl(url);
          setImageFile(null);

          if (img.width < MIN_SIZE) {
            setBannerErrorMessage("Image is too small");
          } else if (aspectRatio > MAX_ASPECT_RATIO) {
            setCropPopupOpen(true);
            setBannerErrorMessage("Incorrect aspect ratio - Image is too wide");
          } else if (aspectRatio < MIN_ASPECT_RATIO) {
            setCropPopupOpen(true);
            setBannerErrorMessage(
              "Incorrect aspect ratio - Image is too narrow"
            );
          } else {
            setImageFile(file);
            setBannerErrorMessage("");

            URL.revokeObjectURL(img.src);
            img = null;
          }

          setIsUploading(false);
        }
      };

      img.src = url;
    } else {
      setBannerErrorMessage("File invalid");
      setIsUploading(false);
    }
  };

  const handleRemoveBanner = () => {
    setBannerImageUrl("");
    setImageFile(null);
  };

  if (!eventData.id && eventSlug !== "default" && !yetiAdminMatch)
    return <Loading />;

  return (
    <>
      {cropPopupOpen && (
        <CropPopup
          imageUrl={bannerImageUrl}
          setBannerImageUrl={setBannerImageUrl}
          setCropPopupOpen={setCropPopupOpen}
          setErrorMessage={setBannerErrorMessage}
          setImageFile={setImageFile}
        />
      )}
      <TabContainer>
        <Title>Basic Event Info</Title>
        <div>
          Enter information about your event. You can come back to edit this
          later.
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Fields>
            <FieldLabel>
              Event Name
              <CharacterLimit>{eventName.length}/40</CharacterLimit>
              <InputField
                name="name"
                type="text"
                placeholder="Enter event name"
                maxLength={40}
                defaultValue={eventData.name}
                ref={register({ required: true })}
                onChange={(e) => setEventName(e.target.value)}
              />
            </FieldLabel>
            {yetiAdminMatch && (
              <FieldLabel>
                Event Admin's Email
                <InputField
                  name="adminEmail"
                  type="email"
                  placeholder="Enter admin's email"
                  defaultValue={eventData.adminEmail}
                  ref={register({ required: true })}
                />
              </FieldLabel>
            )}
            <FieldLabel>
              Organization Name
              <InputField
                name="organizationName"
                type="text"
                placeholder="Enter organization name"
                defaultValue={eventData.organizationName}
                ref={register({ required: true })}
              />
            </FieldLabel>
            <FieldLabel>
              Number of People
              <InputField
                name="numPeople"
                type="number"
                min="1"
                placeholder="Enter number of guests"
                defaultValue={eventData.numPeople}
                ref={register({ required: true })}
              />
            </FieldLabel>
            <FieldLabel>
              Number of Booths
              <InputField
                name="numBooths"
                type="number"
                min="1"
                placeholder="Enter number of booths"
                defaultValue={eventData.numBooths}
                ref={register({ required: true })}
              />
            </FieldLabel>
            <DateTime>
              <FieldLabel>
                Start Date
                <Controller
                  name="startDate"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={
                    eventData.startDateTime
                      ? new Date(eventData.startDateTime)
                      : new Date()
                  }
                  render={({ value, onChange }) => (
                    <StyledDatePicker
                      clearIcon={null}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FieldLabel>
              <FieldLabel>
                Time
                <Controller
                  name="startTime"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={
                    eventData.startDateTime
                      ? format(new Date(eventData.startDateTime), "H:mm")
                      : "12:00"
                  }
                  render={({ value, onChange }) => (
                    <StyledTimePicker
                      disableClock
                      clearIcon={null}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FieldLabel>
            </DateTime>
            <DateTime>
              <FieldLabel>
                End Date
                <Controller
                  name="endDate"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={
                    eventData.endDateTime
                      ? new Date(eventData.endDateTime)
                      : new Date()
                  }
                  render={({ value, onChange }) => (
                    <StyledDatePicker
                      clearIcon={null}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FieldLabel>
              <FieldLabel>
                Time
                <Controller
                  name="endTime"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={
                    eventData.endDateTime
                      ? format(new Date(eventData.endDateTime), "H:mm")
                      : "12:00"
                  }
                  render={({ value, onChange }) => (
                    <StyledTimePicker
                      disableClock
                      clearIcon={null}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FieldLabel>
            </DateTime>
            {!eventData.id && (
              <FieldLabel>
                Unique Slug
                <p>
                  This should be simple, include relevant keywords, and words
                  should be separated with hyphens. Avoid spaces and other
                  special characters.
                </p>
                <p>
                  For example, if the slug is <b>fall-fest</b>, the event URL
                  will be&nbsp;
                  <b>join.virtualfest.app/event/fall-fest</b>.
                </p>
                <InputField
                  name="slug"
                  type="text"
                  placeholder="Choose a unique URL slug"
                  ref={register({
                    required: true,
                    validate: validateSlugUnique,
                  })}
                  onChange={(e) =>
                    checkUniqueSlug({ variables: { slug: e.target.value } })
                  }
                />
                {data?.checkUniqueSlug === false && (
                  <ErrorMessage>
                    An event with this slug already exists
                  </ErrorMessage>
                )}
              </FieldLabel>
            )}
          </Fields>
          <UploadLabelText>
            Event Banner
            <p>
              Images should be landscape with a roughly 4:1 aspect ratio and at
              least 512 pixels wide.
            </p>
          </UploadLabelText>
          {(imageFile || (eventData.bannerImageUrl && bannerImageUrl)) && (
            <BannerPreview>
              <PreviewImage
                src={imageFile ? bannerImageUrl : eventData.bannerImageUrl}
                alt="Preview"
              />
              <BannerRemoveIcon onClick={handleRemoveBanner}>
                <RemoveIcon />
              </BannerRemoveIcon>
            </BannerPreview>
          )}
          <UploadLabel>
            <UploadButton disabled={isUploading}>
              Upload Banner Image
            </UploadButton>
            {bannerErrorMessage && (
              <ErrorMessage>{bannerErrorMessage}</ErrorMessage>
            )}
            <HiddenInput
              name="bannerImageUrl"
              type="file"
              accept="image/*"
              onChange={(e) => handleBannerImageSelect(e)}
            />
          </UploadLabel>
          <Fields>
            <FieldLabel>
              Youtube Livestream Embed URL
              <p>
                The embed URL can be found under "Share", by clicking "Embed"
                and copying the URL that follows "src=" in the resulting popup.
              </p>
              <InputField
                name="liveStreamEmbedUrl"
                type="text"
                placeholder="Enter livestream embed URL here"
                defaultValue={eventData.liveStreamEmbedUrl}
                ref={register}
              />
            </FieldLabel>
          </Fields>
          <Button
            type="submit"
            disabled={isCreating || isUpdating || isUploading}
          >
            {buttonText}
          </Button>
        </Form>
      </TabContainer>
    </>
  );
};

export default CreateEvent;

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  marginBottom: 25,
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 43,
    lineHeight: "45px",
  },
});

const Form = styled.form({
  margin: "35px 0",
  width: "100%",
  maxWidth: 600,
  [media(Breakpoint.lg)]: {
    maxWidth: 800,
  },
});

const Fields = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: "0 50px",
  [media(Breakpoint.xl)]: {
    gridTemplateColumns: "1fr 1fr",
  },
});

const FieldLabel = styled.label({
  display: "block",
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 600,
  width: "100%",
  marginBottom: 40,
  position: "relative",
  "& p": {
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "22px",
    marginTop: 10,
  },
});

const CharacterLimit = styled.div({
  fontSize: 12,
  paddingRight: 4,
  textAlign: "right",
  margin: "-10px 0 -5px",
  fontWeight: "normal",
});

const InputField = styled.input({
  padding: "0 15px",
  border: `1px solid ${COLORS.grey}`,
  marginTop: 12,
  height: 50,
  width: "100%",
  fontSize: 16,
  fontFamily: "Poppins, sans-serif",
  borderRadius: 5,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: 16,
  },
});

const DateTime = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: 40,
});

const StyledDatePicker = styled(DatePicker)({
  display: "block",
  "& .react-date-picker__wrapper": {
    padding: "0 15px",
    border: `1px solid ${COLORS.grey}`,
    backgroundColor: COLORS.white,
    margin: "10px 0 0",
    height: 50,
    width: "100%",
    fontSize: 16,
    fontFamily: "Poppins, sans-serif",
    borderRadius: 5,
    "& input": {
      color: COLORS.lightBlack,
    },
  },
  "& .react-date-picker__calendar--open": {
    bottom: 0,
  },
  "& svg": {
    stroke: COLORS.lightBlack,
  },
});

const StyledTimePicker = styled(TimePicker)({
  display: "block",
  "& .react-time-picker__wrapper": {
    padding: "0 2px 0 15px",
    border: `1px solid ${COLORS.grey}`,
    backgroundColor: COLORS.white,
    boxSizing: "border-box",
    margin: "10px 0 0",
    height: 50,
    width: "100%",
    fontSize: 16,
    fontFamily: "Poppins, sans-serif",
    borderRadius: 5,
    "& .react-time-picker__inputGroup__hour": {
      letterSpacing: 1.2,
      color: COLORS.lightBlack,
      width: "17px !important",
      textAlign: "right",
    },
    "& .react-time-picker__inputGroup__divider": {
      color: COLORS.lightBlack,
      margin: "0 2px",
      "&:last-of-type": {
        display: "none",
      },
    },
    "& .react-time-picker__inputGroup__leadingZero": {
      marginTop: 1, // Alignment is off if zero
      color: COLORS.lightBlack,
    },
    "& .react-time-picker__inputGroup__minute": {
      color: COLORS.lightBlack,
      letterSpacing: 1.2,
      width: "22px !important",
    },
    "& .react-time-picker__inputGroup__input--hasLeadingZero": {
      width: "14px !important",
    },
    "& .react-time-picker__inputGroup__amPm": {
      color: COLORS.lightBlack,
    },
  },
});

const ErrorMessage = styled.div({
  color: COLORS.red,
  fontSize: 14,
  fontWeight: "normal",
  margin: "10px 0 -26px 4px",
  position: "absolute",
});

const UploadLabelText = styled.div({
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 600,
  marginBottom: 20,
  maxWidth: 400,
  "& p": {
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "22px",
    marginTop: 10,
  },
});

const UploadLabel = styled.label({
  display: "inline-block",
  marginBottom: 50,
});

const HiddenInput = styled.input({
  display: "none",
});

const UploadButton = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.blue,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  border: `2px solid ${COLORS.blue}`,
  backgroundColor: COLORS.lightGrey,
  borderRadius: 5,
  height: 50,
  width: 200,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));

const BannerPreview = styled.div({
  display: "flex",
  alignItems: "flex-end",
  margin: "20px 0 25px",
});

const PreviewImage = styled.img({
  width: "90%",
  marginRight: 12,
  [media(Breakpoint.sm)]: {
    height: 120,
    width: "auto",
  },
});

const BannerRemoveIcon = styled(IconButton)({
  color: COLORS.red,
  padding: 5,
});

const Button = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  display: "block",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  marginTop: 5,
  height: 50,
  width: 160,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));
