import * as THREE from "three";

const C = {
  MOCK_SCALE: 13,
  MOCK_START_HEIGHT: 0,
  BOOTH_SCALE: 13,
  PLAYER_START_X: 0,
  PLAYER_START_Y: 0,
  PLAYER_START_Z: 0,
  CAMERA_START_X: 59.3,
  CAMERA_START_Y: 2.1,
  CAMERA_START_Z: 60,
  BOOTH_HEIGHT: 1.2,
  PLAYER_SCALE: 0.2,
  PLAYER_HEIGHT: 1.7,
  PLATFORM_SCALE_X: 0.2,
  PLATFORM_SCALE_Y: 0.4,
  PLATFORM_SCALE_Z: 0.2,
};

const PLAYER_SPEED = {
  FORWARD: 0.1,
  ROTATION: 0.01,
  ACCELERATION: 0.3,
};

const PLAYER_CREATOR = {
  x: -20,
  y: 0,
  z: 0,
  camx: -3.2,
  camy: 0.5,
  camz: 0,
};

const BOUNDS = {
  //Towards the Water
  xmin: -35,
  //Towards the Bowl
  xmax: 19,
  zmin: -14,
  zmax: 15,
};

const CENTER_OF_QUAD = new THREE.Vector3(0, 0, 0);

const BOOTH_POSITIONS = [
  { x: -5, z: -9, rot: 0, guy_rot: Math.PI, guy_pos: { z: -8.8 } },
  { x: -2, z: -9, rot: 0, guy_rot: Math.PI, guy_pos: { z: -8.8 } },
  { x: 1, z: -9, rot: 0, guy_rot: Math.PI, guy_pos: { z: -8.8 } },
  { x: 4, z: -9, rot: 0, guy_rot: Math.PI, guy_pos: { z: -8.8 } },
  {
    x: 7,
    z: -8,
    rot: -0.5,
    guy_rot: Math.PI + 0.5,
    guy_pos: { x: 6.95, z: -7.8 },
  },
  {
    x: 10,
    z: -6,
    rot: -1,
    guy_rot: Math.PI + 1,
    guy_pos: { x: 9.88, z: -5.85 },
  },

  { x: -5, z: 9, rot: Math.PI, guy_rot: 0, guy_pos: { z: 8.8 } },
  { x: -2, z: 9, rot: Math.PI, guy_rot: 0, guy_pos: { z: 8.8 } },
  { x: 1, z: 9, rot: Math.PI, guy_rot: 0, guy_pos: { z: 8.8 } },
  { x: 4, z: 9, rot: Math.PI, guy_rot: 0, guy_pos: { z: 8.8 } },
  {
    x: 7,
    z: 8,
    rot: Math.PI + 0.5,
    guy_rot: -0.5,
    guy_pos: { x: 6.86, z: 7.85 },
  },
  { x: 10, z: 6, rot: Math.PI + 1, guy_rot: -1, guy_pos: { x: 9.8, z: 5.95 } },
];

const CameraSettings = {
  CharacterCreationDistance: 1,
  QuadMinZoom: 1.5,
  QuadMaxZoom: 3.5,
};

const InfoBoard = {
  position: {
    x: CENTER_OF_QUAD.x + -10,
    y: CENTER_OF_QUAD.y + 3,
    z: CENTER_OF_QUAD.z + -10,
  },
  rotation: { x: 0, y: Math.PI / -4, z: 0 },
  size: { x: 0.1, y: 3, z: 10 },
  fontSize: 60,
};

const FACES = [
  "faces/face-glasses-st1.png",
  "faces/face-glasses-st2.png",
  "faces/face-glasses-st3.png",
  "faces/face-glasses-st4.png",
  "faces/face-glasses-st5.png",
  "faces/face-plain-st1.png",
  "faces/face-plain-st2.png",
  "faces/face-plain-st3.png",
  "faces/face-plain-st4.png",
  "faces/face-plain-st5.png",
];

const SKINS = [
  "bodies/dots-c1.png",
  "bodies/dots-c2.png",
  "bodies/dots-c3.png",
  "bodies/dots-c4.png",
  "bodies/dots-c5.png",
  "bodies/plain-c1.png",
  "bodies/plain-c2.png",
  "bodies/plain-c3.png",
  "bodies/plain-c4.png",
  "bodies/plain-c5.png",
  "bodies/rainbow-c1.png",
  "bodies/rainbow-c2.png",
  "bodies/rainbow-c3.png",
  "bodies/rainbow-c4.png",
  "bodies/rainbow-c5.png",
];

const BODY_PART_MAP = {
  BODY: 0,
  EARS: 1,
  FACE: 2,
};

const LARGE_EMOJIS: string[] = [];

const SMALL_EMOJIS: string[] = [
  "grinning-face",
  "starstruck",
  "thinking",
  "sunglasses",
  "100",
  "fire",
  "red-heart",
  "thumbs-up",
];

const BANNER_NAMES: string[] = ["floor-batfan"];

const SIGN_NAMES: string[] = ["sqsign1", "sqsign2", "sqsign3", "sqsign4"];

const InfoBoardColors = [
  "#D54C51",
  "#33D5EA",
  "#AB7409",
  "#A16DEF",
  "#5BAB00",
  "#E59A0C",
  "#0098AB",
  "#94EA33",
  "#620CE5",
];

export {
  C,
  PLAYER_SPEED,
  InfoBoardColors,
  FACES,
  CENTER_OF_QUAD,
  SKINS,
  BOOTH_POSITIONS,
  BODY_PART_MAP,
  CameraSettings,
  InfoBoard,
  LARGE_EMOJIS,
  SMALL_EMOJIS,
  PLAYER_CREATOR,
  BOUNDS,
  BANNER_NAMES,
  SIGN_NAMES,
};
