import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { VALIDATE_FORGOT_PASSWORD_TOKEN } from "../queries.threeJs";
import { RESET_PASSWORD } from "../mutations.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Spacer from "shared/components/Spacer";
import Loading from "shared/components/Loading";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const { forgotPasswordToken } = useParams<{ forgotPasswordToken: string }>();

  const searchParams = useSearchParams();
  const userType = searchParams.get("userType");
  const eventSlug = searchParams.get("eventSlug") ?? "";

  const pushRootRoute = (): void => {
    if (userType === USER_TYPES.EVENT_ADMIN) {
      history.push(ROUTES.EVENT_ADMIN.ROOT);
    } else if (userType === USER_TYPES.BOOTH_ADMIN) {
      history.push(ROUTES.BOOTH_ADMIN.ROOT);
    } else {
      history.push(ROUTES.EVENT.ROOT.replace(":eventSlug", eventSlug));
    }
  };

  const { register, handleSubmit, errors } = useForm();

  const { data, loading } = useQuery(VALIDATE_FORGOT_PASSWORD_TOKEN, {
    variables: {
      token: forgotPasswordToken,
    },
  });

  const [resetPassword, { loading: submitting }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.resetPassword);

      pushRootRoute();
    },
  });

  const validatePasswordMatch = (data) => {
    if (data !== password) return false;
    return true;
  };

  const onSubmit = (data) => {
    resetPassword({
      variables: {
        token: forgotPasswordToken,
        newPassword: data.password,
      },
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : data?.validateForgotPasswordToken === "success" ? (
        <Container>
          <Title>Reset Password</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldLabel>
              Password
              <InputField
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                ref={register({ required: true })}
                onChange={(e) => setPassword(e.target.value)}
              />
              <ShowPassword
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </ShowPassword>
            </FieldLabel>
            {errors.reenterPassword?.type === "validate" && (
              <Error>Passwords do not match</Error>
            )}
            <FieldLabel>
              Reenter Password
              <InputField
                name="reenterPassword"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                ref={register({
                  required: true,
                  validate: validatePasswordMatch,
                })}
              />
              <ShowPassword
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </ShowPassword>
            </FieldLabel>
            <Button type="submit" disabled={submitting}>
              Reset Password
            </Button>
          </Form>
        </Container>
      ) : (
        <Container>
          <ErrorMessage>
            The link you have tried is either invalid or may have expired. For
            your security, your password reset link expires 24 hours after
            requesting it.
            <Spacer height={20} />
            Please request a new link to reset your password.
          </ErrorMessage>
          <TextLink type="button" onClick={pushRootRoute}>
            Go Home
          </TextLink>
        </Container>
      )}
    </>
  );
};

export default ResetPassword;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  width: "100%",
  position: "relative",
  padding: "75px 30px",
  backgroundColor: COLORS.lightGrey,
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    padding: "100px 30px",
  },
});

const Title = styled.div({
  fontSize: 40,
  lineHeight: "52px",
  fontWeight: "bold",
  textAlign: "center",
  color: COLORS.darkBlue,
  [media(Breakpoint.md)]: {
    fontSize: 60,
    lineHeight: "70px",
  },
});

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 30,
  width: "100%",
  [media(Breakpoint.sm)]: {
    marginTop: 35,
  },
});

const Error = styled.div({
  fontSize: 14,
  color: COLORS.red,
  margin: "-10px 0 12px",
});

const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
  [media(Breakpoint.sm)]: {
    width: 340,
  },
});

const ShowPassword = styled.button({
  color: COLORS.darkGrey,
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  position: "absolute",
  bottom: 40,
  right: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Button = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  color: COLORS.white,
  backgroundColor: COLORS.lightBlack,
  borderRadius: 5,
  fontSize: "14px",
  letterSpacing: 0.2,
  fontWeight: 600,
  height: "50px",
  width: "220px",
  margin: "15px 0 25px",
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  [media(Breakpoint.sm)]: {
    marginTop: 20,
  },
}));

const ErrorMessage = styled.div({
  fontSize: 24,
  lineHeight: "36px",
  maxWidth: 800,
  textAlign: "center",
  marginTop: 50,
});

const TextLink = styled.button({
  fontSize: 20,
  color: COLORS.blue,
  marginTop: 50,
  "&:hover": {
    textDecoration: "underline",
  },
});
