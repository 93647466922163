import React, { useState, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_GUEST_LIST_ITEM,
  ADD_MANY_GUEST_LIST_ITEMS,
} from "./mutations.eventAdmin";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { useForm } from "react-hook-form";
import Papa from "papaparse";
import closeX from "shared/icons/Close_button.svg";

interface Props {
  eventData: any;
  onSuccess: () => void;
  setInvitePopupOpen: Dispatch<SetStateAction<boolean>>;
}

const InvitePopup = ({ eventData, onSuccess, setInvitePopupOpen }: Props) => {
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [hasError, setHasError] = useState(false);

  const { register, handleSubmit } = useForm();

  const [inviteGuest] = useMutation(ADD_GUEST_LIST_ITEM, {
    onCompleted: () => onSuccess(),
    onError: () => {
      setHasError(true);
    },
  });

  const [inviteManyGuests] = useMutation(ADD_MANY_GUEST_LIST_ITEMS, {
    onCompleted: onSuccess,
  });

  const onSubmit = (data) => {
    inviteGuest({
      variables: {
        data: {
          name: data.name,
          email: data.email.toLowerCase(),
          event: {
            connect: { slug: eventData.slug },
          },
        },
      },
    });
  };

  const importCsv = () => {
    Papa.parse(csvFile, {
      complete: (res) => {
        const createData: { name: string; email: string; event: any }[] = [];

        for (let row of res.data) {
          if (row[0] && row[1])
            createData.push({
              name: row[0],
              email: row[1],
              event: {
                connect: { slug: eventData.slug },
              },
            });
        }

        inviteManyGuests({
          variables: { data: createData },
        });
      },
    });
  };

  return (
    <>
      <Dimmer />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setInvitePopupOpen(false)}
          />
          <Title>Add Guest(s)</Title>
          <Sections>
            <Section>
              <Text>
                Enter the user's name and email address to add to VirtualFest
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FieldLabel>
                  Name
                  <InputField
                    name="name"
                    type="text"
                    placeholder="Enter name here"
                    ref={register({ required: true })}
                  />
                </FieldLabel>
                <FieldLabel>
                  Email
                  <InputField
                    name="email"
                    type="email"
                    placeholder="Enter email address here"
                    ref={register({ required: true })}
                  />
                </FieldLabel>
                <Button type="submit">Add Guest</Button>
                {hasError && (
                  <ErrorText>
                    Something went wrong, this email may already exist.
                  </ErrorText>
                )}
              </form>
            </Section>
            <Divider />
            <Section>
              <Text>
                Want to invite in bulk? Upload a CSV with each user's name,
                followed by their email address
              </Text>
              {csvFile && (
                <CsvFileName>{csvFile.name.substr(0, 25)}</CsvFileName>
              )}
              <label>
                <UploadButton>Upload CSV</UploadButton>
                <input
                  type="file"
                  style={{ display: "none" }}
                  required
                  accept=".csv"
                  onChange={(e) => setCsvFile(e.target.files?.[0])}
                />
              </label>
              <Button type="button" onClick={importCsv} disabled={!csvFile}>
                Add Guests
              </Button>
            </Section>
          </Sections>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default InvitePopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: 10,
});

const PopupContainer = styled.div({
  position: "absolute",
  zIndex: 11,
  top: "10vh",
  left: "5%",
  width: "90%",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    width: "400px",
    left: "calc(50% - 200px)",
  },
  [media(Breakpoint.md)]: {
    width: "750px",
    left: "calc(50% - 375px)",
  },
  [media(Breakpoint.lg)]: {
    width: "900px",
    left: "calc(50% - 450px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  padding: "80px 0",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  lineHeight: "22px",
  color: COLORS.lightBlack,
  borderRadius: 20,
  [media(Breakpoint.sm)]: {
    padding: "80px 0",
  },
  [media(Breakpoint.md)]: {
    padding: "80px 20px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 30,
  fontWeight: "bold",
  marginBottom: 25,
  lineHeight: "36px",
  textAlign: "center",
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 36,
    lineHeight: "45px",
  },
});

const Text = styled.div({
  textAlign: "center",
  lineHeight: "24px",
  marginBottom: 15,
});

const Sections = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  [media(Breakpoint.md)]: {
    gridTemplateColumns: "1fr 1px 1fr",
  },
});

const Section = styled.div({
  padding: "0 60px",
  [media(Breakpoint.md)]: {
    padding: "0 80px",
  },
});

const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  textAlign: "left",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
});

const Button = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  display: "block",
  cursor: disabled ? "default" : "pointer",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  margin: "50px auto 0",
  height: 50,
  width: 160,
  opacity: disabled ? 0.5 : 1,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    marginTop: 40,
  },
}));

const CsvFileName = styled.div({
  textAlign: "center",
  fontWeight: 600,
  margin: "30px auto -10px",
});

const UploadButton = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  margin: "50px auto -10px",
  height: 50,
  width: 160,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    marginTop: 40,
  },
});

const ErrorText = styled.div({
  color: "red",
  fontSize: 14,
  marginTop: 15,
  textAlign: "center",
});

const Divider = styled.div({
  width: "80%",
  height: 0,
  margin: "50px auto 40px",
  borderBottom: `1px solid ${COLORS.grey}`,
  [media(Breakpoint.md)]: {
    width: 0,
    height: "100%",
    margin: 0,
    borderRight: `1px solid ${COLORS.grey}`,
    borderBottom: "none",
  },
});
