import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CURRENT_EVENT_ADMIN } from "./queries.eventAdmin";
import { DELETE_EVENT } from "./mutations.eventAdmin";
import { GET_EVENT } from "../ThreeJS/queries.threeJs";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import virtualFestLogo from "shared/icons/vf-logo.svg";
import boothInactive from "./images/Booth.svg";
import boothActive from "./images/Booth_Active.svg";
import guestInactive from "./images/Guests.svg";
import guestActive from "./images/GuestsActive.svg";
import eventInactive from "./images/Event.svg";
import eventActive from "./images/Event_Active.svg";
import textInactive from "./images/CustomText.svg";
import textActive from "./images/CustomText_active.svg";
import settingsInactive from "./images/Settings.svg";
import settingsActive from "./images/setting_active.svg";
import { Breakpoint } from "shared/types";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { openInNewTab } from "shared/utils";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import Loading from "shared/components/Loading";
import HiddenClipboardInput from "shared/components/HiddenClipboardInput";
import Onboarding from "./Onboarding/Onboarding";
import EventAdminLogin from "./EventAdminLogin";
import VerifyEmail from "../ThreeJS/Auth/VerifyEmail";
import YourEvents from "./YourEvents";
import EventInfo from "./EventInfo";
import BoothList from "./BoothList";
import GuestList from "./GuestList";
import BoothInfo from "../BoothAdmin/BoothInfo/BoothInfo";
import EventSchedule from "./EventSchedule";
import CustomText from "./CustomText";
import EventSettings from "./EventSettings";
import InvalidSlugScreen from "screens/ThreeJS/InvalidSlugScreen";
import ConfirmationPopup from "shared/components/ConfirmationPopup";
import UserAccount from "./UserAccount/UserAccount";

const MobileNavDropdownEventOptions = ({ handleMobileNavClose }) => {
  const { BOOTHS, GUESTS, EVENTS, CUSTOMIZE_TEXT, EVENT_SETTINGS }: any =
    useEventSlug(ROUTES.EVENT_ADMIN);

  return (
    <>
      <MenuItem onClick={() => handleMobileNavClose(GUESTS)}>Guests</MenuItem>
      <MenuItem onClick={() => handleMobileNavClose(BOOTHS)}>Booths</MenuItem>
      <MenuItem onClick={() => handleMobileNavClose(EVENTS)}>
        Event Schedule
      </MenuItem>
      <MenuItem onClick={() => handleMobileNavClose(CUSTOMIZE_TEXT)}>
        Customize Text
      </MenuItem>
      <MenuItem onClick={() => handleMobileNavClose(EVENT_SETTINGS)}>
        Event Settings
      </MenuItem>
    </>
  );
};

const MobileNavDropdownAccountOptions = ({ handleMobileNavClose }) => {
  const { CUSTOMER_PORTAL } = ROUTES.EVENT_ADMIN.USER_ACCOUNT;

  return (
    <>
      <MenuItem onClick={() => handleMobileNavClose(CUSTOMER_PORTAL)}>
        Plan Management
      </MenuItem>
    </>
  );
};

const EventAdmin = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [emailVerified, setEmailVerified] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventData, setEventData] = useState<any>({});
  const [boothData, setBoothData] = useState<any>({});
  const [deletePopup, setDeletePopup] = useState<{
    eventId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });

  const classes = useStyles();

  const history = useHistory();

  const { pathname } = useLocation();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const {
    ROOT: BASE_ROOT,
    INFO: BASE_INFO,
    BOOTHS: BASE_BOOTHS,
    GUESTS: BASE_GUESTS,
    EDIT: BASE_EDIT,
    EVENTS: BASE_EVENTS,
    CUSTOMIZE_TEXT: BASE_CUSTOMIZE_TEXT,
    EVENT_SETTINGS: BASE_EVENT_SETTINGS,
    USER_ACCOUNT,
  } = ROUTES.EVENT_ADMIN;

  if (eventSlug === "undefined" || eventSlug === ":eventSlug") {
    history.replace(BASE_ROOT.replace(":eventSlug", "default"));
  }

  const {
    ROOT,
    LOGIN,
    REGISTER,
    INFO,
    BOOTHS,
    GUESTS,
    EDIT,
    EVENTS,
    CUSTOMIZE_TEXT,
    EVENT_SETTINGS,
  }: any = useEventSlug(ROUTES.EVENT_ADMIN);

  const matchEdit = useRouteMatch({ path: EDIT });
  const matchUserAccount = useRouteMatch({ path: USER_ACCOUNT.ROOT });

  const [getEvent] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setEventData({
        ...data.getEvent.event,
        booths: [...data.getEvent.booths],
      });
      setInitialLoading(false);
    },
  });

  const [currentEventAdmin, { loading: authLoading }] = useLazyQuery(
    CURRENT_EVENT_ADMIN,
    {
      onError: () => setInitialLoading(false),
      onCompleted: (data) => {
        setLoggedIn(true);
        setEmailVerified(data.currentEventAdmin.hasVerifiedEmail);
        setUserInfo(data.currentEventAdmin);
      },
    }
  );

  const [deleteEvent] = useMutation(DELETE_EVENT, {
    onCompleted: (data) => {
      setUserInfo({
        ...userInfo,
        events: userInfo.events.filter(
          (event) => event.id !== data.deleteOneEvent.id
        ),
      });

      setDeletePopup({ ...deletePopup, success: true });
      history.push(BASE_ROOT);
    },
  });

  useEffect(() => {
    const token = CookieService.getCookie(Identifiers.AccessToken);
    if (token) {
      currentEventAdmin();
    } else {
      setInitialLoading(false);
      if (pathname !== REGISTER) history.push(LOGIN);
    }
  }, []);

  useEffect(() => {
    if (userInfo.id) {
      if (eventSlug !== "default") {
        getEvent({ variables: { eventSlug } });
      } else {
        setEventData({});
        setInitialLoading(false);
      }
    }
  }, [userInfo, eventSlug]);

  const handleMobileNavClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileNavClose = (pathname?: string) => {
    if (pathname) history.push(pathname);
    setAnchorEl(null);
  };

  const handleViewDemo = () => {
    const demoEvent = userInfo.events.filter((event) => event.isDemo)[0];

    const demoEventPath = ROUTES.EVENT.ROOT.replace(
      ":eventSlug",
      demoEvent?.slug
    );

    const queryParam = `?adminEmail=${userInfo.email}`;

    openInNewTab(process.env.REACT_APP_PUBLIC_URL + demoEventPath + queryParam);
  };

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    setLoggedIn(false);
    setEmailVerified(false);
    setUserInfo({});
    history.push(LOGIN);
  };

  const eventUrl =
    process.env.REACT_APP_PUBLIC_URL +
    ROUTES.EVENT.ROOT.replace(":eventSlug", eventSlug);

  const isNotEventOwner =
    eventData?.adminEmail &&
    userInfo.email !== eventData?.adminEmail &&
    eventSlug !== "default";

  const renderContent = () => {
    switch (true) {
      case !loggedIn:
        return (
          <EventAdminLogin
            setLoggedIn={setLoggedIn}
            setUserInfo={setUserInfo}
            setEmailVerified={setEmailVerified}
          />
        );
      case !emailVerified:
        return <VerifyEmail userType={USER_TYPES.EVENT_ADMIN} />;
      case !!matchUserAccount:
        return <UserAccount />;
      case !eventData || isNotEventOwner:
        return <InvalidSlugScreen />;
      case eventSlug === "default" && pathname !== INFO:
        if (pathname !== ROOT) return <Redirect to={ROOT} />;

        return (
          <YourEvents
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            setEventData={setEventData}
          />
        );
      default:
        return (
          <>
            {deletePopup.open && (
              <ConfirmationPopup
                text="Once confirmed, this event will be deleted"
                confirmButtonText="Delete Event"
                closePopup={() => setDeletePopup({ open: false })}
                onConfirm={() =>
                  deleteEvent({ variables: { eventId: deletePopup.eventId } })
                }
                success={deletePopup.success}
                successMessage="The event you selected has been removed"
              />
            )}
            <ReturnBar>
              <ReturnButton
                type="button"
                onClick={() =>
                  history.push(BASE_ROOT.replace(":eventSlug", "default"))
                }
              >
                Back To Events
              </ReturnButton>
              {eventData.id && (
                <>
                  <div>
                    Viewing Event Info For <b>{eventData.name}</b>
                  </div>
                  <CopyButtonContainer>
                    <HiddenClipboardInput
                      id="eventUrl"
                      defaultButtonText="Copy Event Link to Clipboard"
                      contentToCopy={eventUrl}
                    />
                  </CopyButtonContainer>
                </>
              )}
            </ReturnBar>

            <Container>
              {/* Desktop Side Navbar */}
              <Navbar>
                <NavbarLink
                  type="button"
                  current={pathname === ROOT || pathname === INFO}
                  onClick={() => history.push(INFO)}
                >
                  <NavbarLinkIcon
                    imageUrl={pathname === INFO ? guestActive : guestInactive}
                    current={pathname === ROOT || pathname === INFO}
                  />
                  Event Info
                </NavbarLink>
                {eventData.id && (
                  <>
                    <NavbarLink
                      type="button"
                      current={pathname === GUESTS}
                      onClick={() => history.push(GUESTS)}
                    >
                      <NavbarLinkIcon
                        imageUrl={
                          pathname === GUESTS ? guestActive : guestInactive
                        }
                        current={pathname === GUESTS}
                      />
                      Guests
                    </NavbarLink>
                    <NavbarLink
                      type="button"
                      current={pathname === BOOTHS}
                      onClick={() => history.push(BOOTHS)}
                    >
                      <NavbarLinkIcon
                        imageUrl={
                          pathname === BOOTHS ? boothActive : boothInactive
                        }
                        current={pathname === BOOTHS}
                      />
                      Booths
                    </NavbarLink>
                    {matchEdit && (
                      <NavbarLink type="button" noIcon current={true}>
                        {boothData.id ? "Edit Booth Info" : "New Booth"}
                      </NavbarLink>
                    )}
                    <NavbarLink
                      type="button"
                      current={pathname === EVENTS}
                      onClick={() => history.push(EVENTS)}
                    >
                      <NavbarLinkIcon
                        imageUrl={
                          pathname === EVENTS ? eventActive : eventInactive
                        }
                        current={pathname === EVENTS}
                      />
                      Event Schedule
                    </NavbarLink>
                    <NavbarLink
                      type="button"
                      current={pathname === CUSTOMIZE_TEXT}
                      onClick={() => history.push(CUSTOMIZE_TEXT)}
                    >
                      <NavbarLinkIcon
                        imageUrl={
                          pathname === CUSTOMIZE_TEXT
                            ? textActive
                            : textInactive
                        }
                        current={pathname === CUSTOMIZE_TEXT}
                      />
                      Customize Text
                    </NavbarLink>
                    <NavbarLink
                      type="button"
                      current={pathname === EVENT_SETTINGS}
                      onClick={() => history.push(EVENT_SETTINGS)}
                    >
                      <NavbarLinkIcon
                        imageUrl={
                          pathname === EVENT_SETTINGS
                            ? settingsActive
                            : settingsInactive
                        }
                        current={pathname === EVENT_SETTINGS}
                      />
                      Event Settings
                    </NavbarLink>
                  </>
                )}
              </Navbar>

              {/* Main Content */}
              <Switch>
                <Route
                  path={[BASE_ROOT, BASE_INFO]}
                  exact
                  render={() => {
                    if (pathname !== INFO) return <Redirect to={INFO} />;

                    return (
                      <EventInfo
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        eventData={eventData}
                        setEventData={setEventData}
                      />
                    );
                  }}
                />

                <Route
                  path={[BASE_GUESTS]}
                  exact
                  render={() => (
                    <GuestList
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      eventData={eventData}
                    />
                  )}
                />

                <Route
                  path={BASE_BOOTHS}
                  exact
                  render={() => (
                    <BoothList
                      eventData={eventData}
                      setEventData={setEventData}
                      setBoothData={setBoothData}
                    />
                  )}
                />

                <Route
                  path={BASE_EVENTS}
                  exact
                  render={() => (
                    <EventSchedule
                      eventData={eventData}
                      setEventData={setEventData}
                    />
                  )}
                />

                <Route
                  path={BASE_CUSTOMIZE_TEXT}
                  exact
                  render={() => (
                    <CustomText customText={eventData.customText} />
                  )}
                />

                <Route
                  path={BASE_EVENT_SETTINGS}
                  exact
                  render={() => (
                    <EventSettings
                      eventData={eventData}
                      setEventData={setEventData}
                    />
                  )}
                />

                <Route
                  path={BASE_EDIT}
                  exact
                  render={() => (
                    <BoothInfo
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      asEventAdmin={true}
                      boothData={boothData}
                      setBoothData={setBoothData}
                    />
                  )}
                />

                <Route render={() => <Redirect to={ROOT} />} />
              </Switch>
            </Container>
          </>
        );
    }
  };

  if (initialLoading || authLoading) return <Loading />;

  if (!loggedIn && pathname === REGISTER)
    return <Onboarding setUserInfo={setUserInfo} setLoggedIn={setLoggedIn} />;

  return (
    <>
      <Header>
        <Logo
          type="image"
          src={virtualFestLogo}
          alt=""
          onClick={() =>
            history.push(BASE_ROOT.replace(":eventSlug", "default"))
          }
        />
        <div>Event Admin Dashboard</div>
        {loggedIn ? (
          <>
            <HeaderButton type="button" onClick={handleViewDemo}>
              View Demo
            </HeaderButton>
            {/* TODO Add this button back when the functionality is done */}
            {/* <HeaderButton
              type="button"
              onClick={() => history.push(USER_ACCOUNT.ROOT)}
            >
              My Account
            </HeaderButton> */}
            <TextLink type="button" onClick={handleLogout}>
              Sign Out
            </TextLink>
          </>
        ) : (
          <>
            <SignupText>
              Don't have an account?&nbsp;&nbsp;
              <TextLink
                type="button"
                withText
                onClick={() => history.push(REGISTER)}
              >
                Sign Up
              </TextLink>
            </SignupText>
          </>
        )}

        {/* Mobile Nav Dropdown */}
        <IconButton
          classes={{ root: classes.navbarRoot }}
          onClick={handleMobileNavClick}
        >
          <NavbarIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => handleMobileNavClose()}
        >
          {loggedIn ? (
            <>
              {eventData.id && (
                <MobileNavDropdownEventOptions
                  handleMobileNavClose={handleMobileNavClose}
                />
              )}
              {matchUserAccount && (
                <MobileNavDropdownAccountOptions
                  handleMobileNavClose={handleMobileNavClose}
                />
              )}
              <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
            </>
          ) : (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                history.push(REGISTER);
              }}
            >
              Don't have an account?
            </MenuItem>
          )}
        </Menu>
      </Header>

      {renderContent()}

      <FooterLinks>
        <div>
          © 2021 VirtualFest. Developed by&nbsp;
          <FooterLink href="https://yeti.co" target="_blank">
            Yeti
          </FooterLink>
        </div>
        <div>
          <FooterLink href="https://virtualfest.app/terms" target="_blank">
            Terms
          </FooterLink>
          &nbsp;|&nbsp;
          <FooterLink href="https://virtualfest.app/privacy" target="_blank">
            Privacy Policy
          </FooterLink>
        </div>
      </FooterLinks>
    </>
  );
};

export default EventAdmin;

const Header = styled.div({
  display: "flex",
  alignItems: "center",
  fontFamily: "Poppins, sans-serif",
  height: 60,
  width: "100%",
  padding: "0 20px",
  fontSize: 16,
  backgroundColor: COLORS.darkBlue,
  color: COLORS.white,
  boxSizing: "border-box",
  [media(Breakpoint.sm)]: {
    padding: "0 50px",
  },
});

const Logo = styled.input({
  display: "none",
  margin: "0 15px 5px 0",
  [media(Breakpoint.sm)]: {
    display: "initial",
    marginRight: 25,
  },
});

const HeaderButton = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: COLORS.white,
  borderRadius: 15,
  fontSize: 14,
  fontWeight: 600,
  color: COLORS.darkBlue,
  height: 25,
  width: 120,
  marginLeft: 25,
});

const SignupText = styled.div({
  display: "none",
  color: COLORS.white,
  marginLeft: "auto",
  fontSize: 14,
  [media(Breakpoint.md)]: {
    display: "initial",
  },
});

const TextLink = styled.button<{ withText?: boolean }>(({ withText }) => ({
  display: withText ? "initial" : "none",
  textDecoration: "underline",
  fontSize: 14,
  color: COLORS.white,
  marginLeft: "auto",
  [media(Breakpoint.md)]: {
    display: "initial",
    marginLeft: withText ? 0 : "auto",
  },
}));

const NavbarIcon = styled(DehazeIcon)({
  transform: "scale(1.3)",
  color: COLORS.white,
});

export const Container = styled.div({
  width: "100%",
  height: "calc(100vh - 170px)",
  fontFamily: "Poppins, sans-serif",
  [media(Breakpoint.md)]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
});

export const ReturnBar = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: "Poppins, sans-serif",
  width: "100%",
  height: 60,
  padding: "0 20px",
  fontSize: 16,
  lineHeight: "16px",
  backgroundColor: COLORS.white,
  color: COLORS.lightBlack,
  textAlign: "right",
  "& b": {
    fontWeight: 600,
  },
  [media(Breakpoint.sm)]: {
    padding: "2px 50px 0",
  },
});

export const ReturnButton = styled.button({
  fontWeight: 600,
  color: COLORS.blue,
  marginRight: "auto",
});

const CopyButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: COLORS.darkBlue,
  color: COLORS.white,
  padding: "5px 20px",
  borderRadius: 20,
  fontSize: 14,
  margin: "-2px 0 0 25px",
  cursor: "pointer",
});

export const Navbar = styled.div({
  display: "none",
  minHeight: "100%",
  backgroundColor: COLORS.lighterGrey,
  [media(Breakpoint.md)]: {
    display: "initial",
    width: 220,
  },
  [media(Breakpoint.lg)]: {
    width: 270,
  },
});

export const NavbarLink = styled.button<{ current: boolean; noIcon?: boolean }>(
  ({ current, noIcon }) => ({
    display: "none",
    fontSize: 16,
    fontWeight: 600,
    color: current ? COLORS.darkBlue : COLORS.darkGrey,
    backgroundColor: current ? COLORS.lightGrey : COLORS.lighterGrey,
    height: 60,
    width: "100%",
    paddingLeft: 50,
    [media(Breakpoint.md)]: {
      display: "flex",
      alignItems: "center",
    },
    [media(Breakpoint.lg)]: {
      paddingLeft: noIcon ? 95 : 50,
    },
  })
);

export const NavbarLinkIcon = styled.div<{
  current: boolean;
  imageUrl: string;
}>(({ current, imageUrl }) => ({
  display: "none",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "50%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  marginRight: 15,
  backgroundColor: current ? COLORS.darkBlue : COLORS.white,
  height: 30,
  width: 30,
  padding: 10,
  borderRadius: 30,
  [media(Breakpoint.lg)]: {
    display: "initial",
  },
}));

const FooterLinks = styled.div({
  display: "flex",
  flexDirection: "column",
  color: COLORS.lightBlack,
  width: "100%",
  position: "fixed",
  bottom: 0,
  padding: "25px 0",
  height: 50,
  fontFamily: "Poppins, sans-serif",
  backgroundColor: COLORS.grey,
  [media(Breakpoint.sm)]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
  },
});

export const FooterLink = styled.a({
  color: COLORS.lightBlack,
  textDecoration: "underline",
  fontFamily: "Poppins, sans-serif",
});

const useStyles = makeStyles({
  navbarRoot: {
    padding: "0 0 0 25px",
    margin: "2px 0 0 auto",
    [media(Breakpoint.sm)]: {
      marginRight: -20,
    },
    [media(Breakpoint.md)]: {
      display: "none",
    },
  },
});
