import React from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

const TabContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default TabContainer;

const Container = styled.div({
  position: "relative",
  backgroundColor: COLORS.lightGrey,
  padding: "30px 20px 50px",
  color: COLORS.lightBlack,
  minHeight: "100%",
  minWidth: "100%",
  fontSize: 16,
  lineHeight: "24px",
  overflowY: "auto",
  fontFamily: "Poppins, sans-serif",
  [media(Breakpoint.sm)]: {
    padding: "48px 50px 60px",
  },
  [media(Breakpoint.md)]: {
    padding: "45px 65px 70px",
  },
  [media(Breakpoint.lg)]: {
    padding: "65px 80px 80px",
  },
});
