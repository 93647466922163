import React, { Dispatch, SetStateAction } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES } from "@virtualfest/common";
import { useQuery } from "@apollo/react-hooks";
import { BOOTH_DETAILS } from "./queries.eventAdmin";
import styled from "@emotion/styled";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import closeIcon from "shared/icons/close.png";
import * as COLORS from "shared/styles/colors";
import Loading from "shared/components/Loading";

interface Props {
  boothId: number;
  closePopup: () => void;
  setBoothData: Dispatch<SetStateAction<any>>;
}

const BoothDetails = ({ boothId, closePopup, setBoothData }: Props) => {
  const history = useHistory();

  const { EDIT }: any = useEventSlug({ EDIT: ROUTES.EVENT_ADMIN.EDIT });

  const { data, loading, error } = useQuery(BOOTH_DETAILS, {
    variables: { boothId },
    onCompleted: () => setBoothData(data.booth),
  });

  if (loading) {
    return (
      <>
        <Dimmer />
        <PopupContainer>
          <Popup>
            <Loading />
          </Popup>
        </PopupContainer>
      </>
    );
  }

  if (error || (data && !data?.booth)) {
    closePopup();
    return null;
  }

  const handleEditClick = () => {
    history.push(generatePath(EDIT, { boothId: data.booth.id }));
    closePopup();
  };

  const { booth } = data;

  return (
    <>
      <Dimmer />
      <PopupContainer>
        <Popup>
          <Buttons>
            <TextLink onClick={handleEditClick}>Edit</TextLink>
            <XButton src={closeIcon} onClick={closePopup} />
          </Buttons>
          <Title>{booth.name}</Title>
          <FieldHeader>Description</FieldHeader>
          <div>{booth.description}</div>
          <FieldHeader>Contacts</FieldHeader>
          <div>{booth.adminEmail}</div>
          <FieldHeader>External Resource Link</FieldHeader>
          <div>
            {booth.externalLinkUrl
              ? booth.externalLinkUrl +
                (booth.externalLinkDisplayText
                  ? ` \u00A0(${booth.externalLinkDisplayText})`
                  : "")
              : "None"}
          </div>
          <FieldHeader>Uploaded Media</FieldHeader>
          <BoothMedia>
            {booth.mediaUrls.length > 0 ? (
              booth.mediaUrls.map((media) => (
                <Media key={media} src={media} alt="Booth media" />
              ))
            ) : (
              <div>None</div>
            )}
          </BoothMedia>
          <FieldHeader>Zoom Link</FieldHeader>
          <div>{booth.zoomUrl ? booth.zoomUrl : "None"}</div>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default BoothDetails;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: 10,
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: 11,
  top: "8vh",
  left: "calc(50% - 150px)",
  width: "300px",
  maxHeight: "80vh",
  overflowY: "auto",
  [media(Breakpoint.sm)]: {
    width: "500px",
    left: "calc(50% - 250px)",
  },
  [media(Breakpoint.md)]: {
    width: "700px",
    left: "calc(50% - 350px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLORS.white,
  fontSize: 16,
  lineHeight: "22px",
  padding: "55px 30px",
  minHeight: "80vh",
  width: "100%",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "55px 40px",
  },
  [media(Breakpoint.md)]: {
    padding: "55px 70px",
  },
});

const Buttons = styled.div({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "12px",
  right: "14px",
});

const TextLink = styled.div({
  cursor: "pointer",
  fontSize: 16,
  color: COLORS.red,
});

const XButton = styled.img({
  height: "30px",
  opacity: 0.5,
  cursor: "pointer",
  marginLeft: 15,
});

const Title = styled.div({
  fontSize: "24px",
  lineHeight: "36px",
  fontWeight: "bold",
  textAlign: "center",
  color: COLORS.lightBlack,
  [media(Breakpoint.md)]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
});

const FieldHeader = styled.div({
  fontSize: 18,
  lineHeight: "22px",
  fontWeight: "bold",
  margin: "22px 0 5px",
});

const BoothMedia = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  gridGap: 20,
  margin: "7px 0",
});

const Media = styled.img({
  width: "100%",
});
