import React, { useState, useRef, Dispatch, SetStateAction } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { USER_SIGN_UP } from "../../ThreeJS/mutations.threeJs";
import { CURRENT_EVENT_ADMIN } from "../queries.eventAdmin";
import { useHistory } from "react-router-dom";
import { PLAN_TIERS, USER_TYPES, ROUTES } from "@virtualfest/common";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useForm, Controller } from "react-hook-form";
import { useSearchParams } from "shared/hooks/useSearchParams";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Spacer from "shared/components/Spacer";
import { openInNewTab, generateDemoSlug } from "shared/utils";
import { WORLD_OPTIONS } from "../EventInfo";
import {
  Title,
  Form,
  FieldLabel,
  InputField,
  Button,
  TOUR_WORLD,
  CREATE_EVENT,
} from "./Onboarding";

interface Props {
  adminName: string;
  orgName: string;
  bannerImageUrl: string;
  exitAction: string;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
  setUserInfo: Dispatch<SetStateAction<any>>;
}

const SignUp = ({
  adminName,
  orgName,
  bannerImageUrl,
  exitAction,
  setLoggedIn,
  setUserInfo,
}: Props) => {
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const searchParams = useSearchParams();
  const emailParam = searchParams.get("email");

  const classes = useStyles();

  const { register, handleSubmit, control, errors } = useForm();

  const demoSlugRef = useRef("");

  const [currentEventAdmin] = useLazyQuery(CURRENT_EVENT_ADMIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setUserInfo(data.currentEventAdmin);
      setLoggedIn(true);
      history.replace(ROUTES.EVENT_ADMIN.ROOT.replace(":eventSlug", "default"));
    },
  });

  const [userSignUp, { loading }] = useMutation(USER_SIGN_UP, {
    onError: (error) => {
      if (error.message.includes("Existing user")) {
        setError(true);
      }
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.userSignUp);

      currentEventAdmin();

      if (exitAction === TOUR_WORLD) {
        const demoEventPath = ROUTES.EVENT.ROOT.replace(
          ":eventSlug",
          demoSlugRef.current
        );

        const queryParam = `?adminEmail=${email}`;

        openInNewTab(
          process.env.REACT_APP_PUBLIC_URL + demoEventPath + queryParam
        );
      }
    },
  });

  const onSubmit = (data) => {
    demoSlugRef.current = generateDemoSlug(data.email);

    userSignUp({
      variables: {
        data: {
          name: adminName,
          email: data.email,
          passwordDigest: data.password,
          isEmailSubscribed: data.isEmailSubscribed,
          userType: USER_TYPES.EVENT_ADMIN,
          demoEventData: {
            name: orgName || adminName,
            adminEmail: data.email,
            organizationName: orgName,
            bannerImageUrl,
            mainStageMediaUrl: "",
            liveStreamEmbedUrl: "",
            selectedWorld: WORLD_OPTIONS[0].value,
            numPeople: PLAN_TIERS.FREE.maxEventGuests,
            numBooths: 10,
            startDateTime: new Date(),
            endDateTime: new Date(),
            slug: demoSlugRef.current,
          },
        },
      },
    });
  };

  return (
    <>
      <Title>Last thing...</Title>
      <div>
        To reserve and save your world,
        <br />
        let's create your event admin account
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldLabel>
          Email address
          <InputField
            name="email"
            type="email"
            placeholder="Enter email here"
            defaultValue={emailParam ?? ""}
            ref={register({ required: true })}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FieldLabel>
        {error && (
          <ErrorMessage>A user with this email already exists</ErrorMessage>
        )}
        <FieldLabel>
          Password
          <InputField
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter password password"
            ref={register({ required: true })}
          />
          <ShowPassword
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </ShowPassword>
        </FieldLabel>
        <CheckboxFields>
          <CheckboxLabel>
            <Controller
              name="hasAcceptedTerms"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={(props) => (
                <FormCheckbox
                  classes={{
                    root: errors.hasAcceptedTerms
                      ? classes.error
                      : classes.root,
                    checked: classes.checked,
                  }}
                  color="default"
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
            <div>
              I have read and agree to the&nbsp;
              <TextLink
                type="button"
                onClick={() =>
                  openInNewTab("https://www.virtualfest.app/terms")
                }
              >
                terms of service
              </TextLink>
              .
            </div>
          </CheckboxLabel>
          <Spacer height={4} />
          <CheckboxLabel>
            <Controller
              name="isEmailSubscribed"
              control={control}
              defaultValue={true}
              render={(props) => (
                <FormCheckbox
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                  color="default"
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
            I would liked to stay up to date with any VirtualFest news.
          </CheckboxLabel>
        </CheckboxFields>
        <Button type="submit" disabled={loading}>
          {exitAction === CREATE_EVENT ? "Create Event" : "Open World"}
        </Button>
      </Form>
    </>
  );
};

export default SignUp;

const ShowPassword = styled.button({
  color: COLORS.darkGrey,
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  position: "absolute",
  bottom: 40,
  right: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const ErrorMessage = styled.div({
  color: COLORS.red,
  fontSize: 14,
  lineHeight: "14px",
  margin: "-5px 0 15px",
});

const CheckboxFields = styled.div({
  display: "flex",
  flexDirection: "column",
  margin: "10px 0",
});

const CheckboxLabel = styled.label({
  display: "flex",
  alignItems: "flex-start",
  fontSize: 14,
  lineHeight: "20px",
  position: "relative",
  width: "100%",
  marginBottom: 8,
  textAlign: "left",
  [media(Breakpoint.sm)]: {
    alignItems: "center",
    marginBottom: 5,
  },
});

const FormCheckbox = styled(Checkbox)({
  transform: "scale(0.9)",
  position: "relative",
  top: -5,
  [media(Breakpoint.sm)]: {
    top: 0,
  },
});

const TextLink = styled.button({
  textDecoration: "underline",
  color: COLORS.lightBlack,
  lineHeight: "26px",
  fontWeight: 600,
});

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: "1px 8px 0 0",
    "& svg": {
      height: 32,
      width: 32,
      stroke: COLORS.lightBlack,
      "& path": {
        stroke: "transparent",
        color: COLORS.lightBlack,
      },
    },
  },
  error: {
    padding: 0,
    margin: "1px 8px 0 0",
    "& svg": {
      height: 32,
      width: 32,
      stroke: COLORS.white,
      "& path": {
        color: COLORS.red,
        stroke: COLORS.red,
      },
    },
  },
  checked: {
    "& svg": {
      fill: COLORS.darkBlue,
      height: 30,
      width: 32,
      margin: "1px 0",
      "& path": {
        stroke: COLORS.white,
      },
    },
  },
});
