import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Responsive from "shared/components/Responsive";
import Loading from "shared/components/Loading";

interface Props {
  setActiveTab?: (tabIndex: number) => void;
  customText: string;
}

const Welcome = ({ setActiveTab, customText }: Props) => {
  return (
    <Container>
      <Title>Welcome to Virtual Fest!</Title>
      <Text>{customText}</Text>
      <Button type="button" onClick={() => setActiveTab && setActiveTab(2)}>
        View Event Schedule
      </Button>
    </Container>
  );
};

export default Welcome;

const Container = styled.div({
  padding: "30px 25px",
  height: "100%",
  backgroundColor: COLORS.white,
});

const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginBottom: 25,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 28,
  },
});

const Text = styled.div({
  whiteSpace: "pre-wrap",
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 180,
  height: 42,
  fontSize: 14,
  marginTop: 25,
  borderRadius: 5,
  letterSpacing: 0.3,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
