import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import ChatMessages from "./ChatMessages";
import { formatDistanceToNowStrict } from "date-fns";
import { socket } from "services/SocketIo/SocketIOService";
import { truncateString } from "shared/utils";
import { ChatMessagesType, CHAT_MESSAGE_TYPES } from "@virtualfest/common";
import ConfirmationPopup from "shared/components/ConfirmationPopup";

interface Props {
  userInfo: any;
  booths: any[];
  chatMessages: ChatMessagesType;
  setChatMessages: Dispatch<SetStateAction<ChatMessagesType>>;
  setChatMessagesRef: (newCurrent: ChatMessagesType) => void;
  activeChat: any;
  setActiveChat: Dispatch<SetStateAction<any>>;
}

const BoothChatList = ({
  userInfo,
  booths,
  chatMessages,
  setChatMessages,
  setChatMessagesRef,
  activeChat,
  setActiveChat,
}: Props) => {
  const [leavePopupOpen, setLeavePopupOpen] = useState(false);

  useEffect(() => {
    const boothChatIds = Object.keys(chatMessages.booth);

    if (boothChatIds.length === 0) {
      setActiveChat({
        type: CHAT_MESSAGE_TYPES.EVENT,
      });
    }
  }, [chatMessages]);

  const addNewBoothChatMessage = (newMessage) => {
    const newChatMessages = {
      ...chatMessages,
      booth: {
        ...chatMessages.booth,
        [activeChat.id]: [...chatMessages.booth[activeChat.id], newMessage],
      },
    };

    setChatMessages(newChatMessages);
    setChatMessagesRef(newChatMessages);
  };

  const leaveConversation = () => {
    const newChatMessages = {
      ...chatMessages,
    };
    delete newChatMessages.booth[activeChat.id];

    socket.emit("leaveChat", {
      roomId: activeChat.id,
    });

    setChatMessages({
      ...newChatMessages,
    });
    setActiveChat({ type: CHAT_MESSAGE_TYPES.BOOTH });
    setLeavePopupOpen(false);
  };

  const activeBoothChat = booths.find((booth) => booth.id === activeChat.id);

  return (
    <>
      {leavePopupOpen && (
        <ConfirmationPopup
          text="Conversation history will not be saved."
          confirmButtonText="Leave"
          closePopup={() => setLeavePopupOpen(false)}
          onConfirm={leaveConversation}
        />
      )}
      {activeChat.id ? (
        <>
          <Header>
            Current Chat: &nbsp;{truncateString(activeBoothChat.name, 35)}
            <NavButtons>
              <NavButton
                type="button"
                onClick={() =>
                  setActiveChat({ type: CHAT_MESSAGE_TYPES.BOOTH })
                }
              >
                Back to Booth Chats
              </NavButton>
              <NavButton
                isLeave
                type="button"
                onClick={() => setLeavePopupOpen(true)}
              >
                Leave Conversation
              </NavButton>
            </NavButtons>
          </Header>
          <ChatMessages
            userInfo={userInfo}
            type={CHAT_MESSAGE_TYPES.BOOTH}
            roomId={activeChat.id}
            messages={chatMessages.booth[activeChat.id]}
            addMessage={addNewBoothChatMessage}
          />
        </>
      ) : (
        <ListContainer>
          {Object.keys(chatMessages.booth).map((boothChatId) => {
            const booth = booths.find(
              (booth) => booth.id === Number(boothChatId)
            );
            const [mostRecentMessage] =
              chatMessages.booth[boothChatId].slice(-1);

            return (
              <BoothChatListItem
                type="button"
                key={boothChatId}
                onClick={() =>
                  setActiveChat({ ...activeChat, id: Number(boothChatId) })
                }
              >
                <BoothChatName>
                  {truncateString(booth.name, mostRecentMessage ? 30 : 45)}
                </BoothChatName>
                {mostRecentMessage &&
                  formatDistanceToNowStrict(
                    new Date(mostRecentMessage.timestamp),
                    { addSuffix: true }
                  )}
              </BoothChatListItem>
            );
          })}
        </ListContainer>
      )}
    </>
  );
};

export default BoothChatList;

const Header = styled.div({
  borderTop: `1px solid ${COLORS.grey}`,
  fontSize: 14,
  padding: "12px 30px",
  fontWeight: 600,
});

const NavButtons = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 5,
  fontWeight: "normal",
});

const NavButton = styled.button<{ isLeave?: boolean }>(({ isLeave }) => ({
  color: isLeave ? COLORS.red : COLORS.blue,
  "&:hover": {
    fontWeight: 600,
  },
}));

const ListContainer = styled.div({
  height: "100%",
  overflowY: "hidden",
  backgroundColor: COLORS.lightGrey,
  position: "relative",
  borderTop: `1px solid ${COLORS.grey}`,
});

const BoothChatListItem = styled.button({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderBottom: `1px solid ${COLORS.grey}`,
  padding: "20px 25px 20px 30px",
  backgroundColor: COLORS.white,
  fontSize: 13,
  color: COLORS.grey,
  "&:hover": {
    backgroundColor: COLORS.grey,
  },
});

const BoothChatName = styled.div({
  color: COLORS.blue,
  fontWeight: 600,
  fontSize: 16,
});
