import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { YETI_ADMIN_EVENTS } from "./queries.yetiAdmin";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import _ from "lodash";
import { format } from "date-fns";
import { openInNewTab } from "shared/utils";
import { getCardColor } from "../BoothAdmin/YourBooths";
import Loading from "shared/components/Loading";
import Responsive from "shared/components/Responsive";
import TabContainer from "shared/components/TabContainer";

interface Props {}

const EventsList = ({}: Props) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventCount, setEventCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [searchbarInput, setSearchbarInput] = useState("");

  const [getEvents] = useLazyQuery(YETI_ADMIN_EVENTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setEvents(data.yetiAdminEvents.events);
      setEventCount(data.yetiAdminEvents.count);
      setInitialLoading(false);
    },
  });

  useEffect(() => {
    getEvents({
      variables: {
        pageNum,
      },
    });
  }, []);

  const handlePaginationClick = (nextPageNum: number): void => {
    getEvents({
      variables: {
        pageNum: nextPageNum,
      },
    });
    setPageNum(nextPageNum);
  };

  const handleSearchbarChange = (e) => {
    const searchFilter = e.target.value;

    setSearchbarInput(searchFilter);

    _.debounce(() => {
      getEvents({
        variables: {
          searchFilter,
        },
      });
      setPageNum(1);
    }, 300)();
  };

  const first = eventCount ? 1 + (pageNum - 1) * 10 : 0;

  const showingText = `Showing ${first} - ${Math.min(
    first + 9,
    eventCount
  )} of ${eventCount}`;

  const lastPageNum = Math.ceil(eventCount / 10);

  if (initialLoading) {
    return (
      <TabContainer>
        <Loading />
      </TabContainer>
    );
  }

  return (
    <TabContainer>
      <TopSection>
        <Title>Events</Title>
        <Searchbar
          value={searchbarInput}
          placeholder="Search list"
          onChange={(e) => handleSearchbarChange(e)}
        />
      </TopSection>
      {events.map((event: any) => {
        const { id, name, startDateTime, endDateTime, slug } = event;

        return (
          <EventCard
            key={id}
            borderColor={getCardColor(startDateTime, endDateTime)}
          >
            <div>
              <EventName>{name}</EventName>
              <div>
                <b>Date: </b>&nbsp;
                {format(new Date(startDateTime), "MMMM do, yyyy")}
                &nbsp;at&nbsp;
                {format(new Date(startDateTime), "h:mm a")}
              </div>
              {event.admin?.id && (
                <div>
                  <b>Admin: </b>&nbsp;{event.admin.name} ({event.admin.email})
                </div>
              )}
            </div>
            <EventButtons>
              <EventButton
                type="button"
                onClick={() =>
                  openInNewTab(
                    process.env.REACT_APP_PUBLIC_URL +
                      ROUTES.EVENT.ROOT.replace(":eventSlug", slug)
                  )
                }
              >
                View Event
              </EventButton>
            </EventButtons>
          </EventCard>
        );
      })}
      <Pagination>
        <Responsive minSize="sm">
          <TextLink
            type="button"
            hide={pageNum <= 2}
            onClick={() => handlePaginationClick(1)}
          >
            First
          </TextLink>
        </Responsive>
        <TextLink
          type="button"
          hide={pageNum <= 1}
          onClick={() => handlePaginationClick(pageNum - 1)}
        >
          Prev
        </TextLink>
        <div>{showingText}</div>
        <TextLink
          type="button"
          hide={pageNum >= lastPageNum}
          onClick={() => handlePaginationClick(pageNum + 1)}
        >
          Next
        </TextLink>
        <Responsive minSize="sm">
          <TextLink
            type="button"
            hide={pageNum >= lastPageNum - 1}
            onClick={() => handlePaginationClick(lastPageNum)}
          >
            Last
          </TextLink>
        </Responsive>
      </Pagination>
    </TabContainer>
  );
};

export default EventsList;

const TopSection = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 35,
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 0px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const Searchbar = styled.input({
  border: `1px solid ${COLORS.grey}`,
  fontSize: 14,
  padding: "8px 12px",
  height: 38,
  width: 250,
  marginRight: 20,
  "&::placeholder": {
    color: COLORS.grey,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 16,
    padding: "11px 17px",
  },
  [media(Breakpoint.xl)]: {
    width: 250,
  },
});

const EventCard = styled.div<{ borderColor?: string }>(({ borderColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: COLORS.white,
  width: "100%",
  lineHeight: "28px",
  borderLeft: `8px solid ${borderColor ?? COLORS.orange}`,
  padding: 40,
  marginTop: 25,
}));

const EventName = styled.div({
  fontSize: 24,
  lineHeight: "24px",
  fontWeight: 600,
  marginBottom: 20,
});

const EventButtons = styled.div({
  display: "flex",
  flexDirection: "column",
  alightItems: "center",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const EventButton = styled.button({
  color: COLORS.lightBlack,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.white,
  border: `2px solid ${COLORS.lightBlack}`,
  borderRadius: 5,
  height: 50,
  width: 150,
  marginBottom: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    margin: "0 40px 0 0",
  },
});

const Pagination = styled.div({
  display: "flex",
  justifyContent: "space-between",
  margin: "40px auto 0",
  width: "100%",
  maxWidth: 250,
  [media(Breakpoint.sm)]: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr 180px 1fr 1fr",
    maxWidth: 370,
  },
});

const TextLink = styled.button<{ hide?: boolean; isBoothName?: boolean }>(
  ({ hide, isBoothName }) => ({
    textDecoration: isBoothName ? "underline" : "none",
    color: isBoothName ? COLORS.blue : COLORS.lightBlack,
    fontWeight: 600,
    visibility: hide ? "hidden" : "visible",
    "&:hover": {
      color: COLORS.grey,
    },
  })
);
