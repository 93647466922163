import React, { Dispatch, SetStateAction, useState } from "react";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import { useHistory, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

interface Props {
  userType: string;
}

const VerifyEmail = ({ userType }: Props) => {
  const history = useHistory();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  let logoutRoute;
  if (userType === USER_TYPES.EVENT_ADMIN) {
    logoutRoute = ROUTES.EVENT_ADMIN.LOGIN;
  } else if (userType === USER_TYPES.BOOTH_ADMIN) {
    logoutRoute = ROUTES.BOOTH_ADMIN.LOGIN;
  } else if (userType === USER_TYPES.GUEST) {
    logoutRoute = ROUTES.EVENT.LOGIN.replace(":eventSlug", eventSlug);
  }

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    history.push(logoutRoute);
    window.location.reload();
  };

  return (
    <Container>
      {userType === USER_TYPES.GUEST && (
        <TextLink type="button" onClick={handleLogout}>
          Logout
        </TextLink>
      )}
      <Title>Verify Your Email</Title>
      <div>You must verify your email address.</div>
      <div>Please check your email to verify and access VirtualFest.</div>
    </Container>
  );
};

export default VerifyEmail;

const Container = styled.div({
  textAlign: "center",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "28px",
  position: "relative",
  color: COLORS.lightBlack,
  padding: "120px 40px",
  [media(Breakpoint.sm)]: {
    padding: "120px 80px",
  },
});

const Title = styled.div({
  fontSize: 36,
  marginBottom: 30,
});

const TextLink = styled.button({
  fontSize: 16,
  color: COLORS.blue,
  position: "absolute",
  top: 25,
  right: 35,
  [media(Breakpoint.sm)]: {
    right: 50,
    top: 35,
  },
});
