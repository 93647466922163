import React from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import closeX from "shared/icons/Close_button.svg";
import { BASE_Z_INDEX } from "shared/constants";

interface Props {
  title: string;
  message: string;
  buttonText: string;
  buttonFunction?: Function;
  closePopup: () => void;
}

const MessagePopup = ({
  title,
  message,
  buttonText,
  buttonFunction,
  closePopup,
}: Props) => {
  return (
    <>
      <Dimmer onClick={closePopup} />
      <PopupContainer>
        <Popup>
          <CloseX type="image" src={closeX} alt="Close" onClick={closePopup} />
          <Title>{title}</Title>
          <div>{message}</div>
          <Button
            type="button"
            onClick={() => (buttonFunction ? buttonFunction() : closePopup())}
          >
            {buttonText}
          </Button>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default MessagePopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 11,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 12,
  top: "18vh",
  left: "5%",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    top: "18vh",
    width: "500px",
    left: "calc(50% - 250px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  textAlign: "center",
  padding: "80px 20px 60px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "26px",
  color: COLORS.lightBlack,
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "80px 50px 55px",
  },
  [media(Breakpoint.sm)]: {
    padding: "80px 70px 55px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 26,
  lineHeight: "35px",
  fontWeight: "bold",
  marginBottom: 20,
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 160,
  height: 50,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  marginTop: 30,
  borderRadius: 5,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
