import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import {
  sortedEventScheduleDates,
  sortedEventScheduleTimes,
  formatToTwelveHourTime,
} from "shared/utils";

interface Props {
  eventSchedule: any;
  customText: string;
  setLiveStreamOpen?: Dispatch<SetStateAction<boolean>>;
}

const Events = ({ eventSchedule, customText, setLiveStreamOpen }: Props) => {
  const [activeDateTab, setActiveDateTab] = useState(
    sortedEventScheduleDates(eventSchedule)[0] ?? ""
  );

  return (
    <Container>
      <TopSection>
        <Title>Event Schedule</Title>
        <Text>{customText}</Text>
        <Button
          type="button"
          onClick={() => setLiveStreamOpen && setLiveStreamOpen(true)}
        >
          View Livestream
        </Button>
        <DateTabsContainer>
          <DateTabs>
            {sortedEventScheduleDates(eventSchedule).map((date) => (
              <DateTab
                key={date}
                current={activeDateTab === date}
                onClick={() => setActiveDateTab(date)}
              >
                {date}
              </DateTab>
            ))}
          </DateTabs>
        </DateTabsContainer>
      </TopSection>
      <EventsList>
        {sortedEventScheduleTimes(eventSchedule[activeDateTab]).map((time) => (
          <div key={`${activeDateTab}-${time}`}>
            <EventTime>{formatToTwelveHourTime(time)}</EventTime>
            <Event>
              {eventSchedule[activeDateTab][time].map((item) => (
                <div key={item.id}>
                  <EventItemTitle>{item.title}</EventItemTitle>
                  <div>{item.subtitle}</div>
                </div>
              ))}
            </Event>
          </div>
        ))}
      </EventsList>
    </Container>
  );
};

export default Events;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: COLORS.lightGrey,
});

const TopSection = styled.div({
  padding: "30px 25px 0",
  backgroundColor: COLORS.white,
});

const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginBottom: 25,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 28,
  },
});

const Text = styled.div({
  whiteSpace: "pre-wrap",
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 160,
  minHeight: 42,
  fontSize: 14,
  margin: "25px 0",
  borderRadius: 5,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  letterSpacing: 0.3,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const DateTabsContainer = styled.div({
  overflow: "hidden",
  minHeight: 42,
  height: 42,
  backgroundColor: COLORS.white,
});

const DateTabs = styled.div({
  display: "flex",
  overflowX: "scroll",
  overflowY: "hidden",
  boxSizing: "content-box",
  minHeight: 42,
  backgroundColor: COLORS.white,
  [media(Breakpoint.sm)]: {
    paddingTop: 13,
  },
});

const DateTab = styled.button<{ current: boolean }>(({ current }) => ({
  fontSize: 13,
  fontWeight: 600,
  marginRight: 20,
  padding: "0 3px 6px",
  whiteSpace: "nowrap",
  color: current ? COLORS.lightBlack : COLORS.darkGrey,
  borderBottom: `3px solid ${current ? COLORS.lightBlack : COLORS.white}`,
  "&:last-of-type": {
    marginRight: 0,
  },
}));

const EventsList = styled.div({
  borderTop: `1px solid ${COLORS.grey}`,
  width: "100%",
  overflowY: "auto",
  padding: "20px 25px 5px",
});

const EventTime = styled.div({
  fontWeight: 600,
  fontSize: 13,
  marginBottom: 2,
  paddingLeft: 15,
});

const Event = styled.div({
  backgroundColor: COLORS.white,
  borderRadius: 10,
  width: "100%",
  marginBottom: 20,
  padding: "1px 18px 14px",
  color: COLORS.darkGrey,
  fontSize: 14,
  lineHeight: "18px",
});

const EventItemTitle = styled.div({
  fontSize: 14,
  color: COLORS.blue,
  fontWeight: 600,
  margin: "13px 0 2px",
});
