import gql from "graphql-tag";

export const CURRENT_EVENT_ADMIN = gql`
  query CurrentEventAdmin {
    currentEventAdmin {
      id
      name
      email
      hasVerifiedEmail
      isEmailSubscribed
      planTier
      blockedGuestIds
      events {
        id
        name
        slug
        startDateTime
        endDateTime
        isDemo
      }
    }
  }
`;

export const EVENT_ADMIN_BOOTHS = gql`
  query EventAdminBooths(
    $eventSlug: String!
    $pageNum: Int
    $searchFilter: String
  ) {
    eventAdminBooths(
      eventSlug: $eventSlug
      pageNum: $pageNum
      searchFilter: $searchFilter
    ) {
      booths {
        id
        name
        adminEmail
      }
      count
    }
  }
`;

export const BOOTH_DETAILS = gql`
  query BoothDetails($boothId: Int!) {
    booth(where: { id: $boothId }) {
      id
      name
      boothDisplayName
      description
      contactEmails
      bannerImageUrl
      zoomUrl
      mediaUrls
      externalLinkUrl
      externalLinkDisplayText
      facebookHandle
      instagramHandle
      twitterHandle
      youtubeHandle
      tiktokHandle
      adminEmail
    }
  }
`;

export const REGISTERED_GUESTS = gql`
  query RegisteredGuests(
    $pageNum: Int
    $searchFilter: String
    $eventSlug: String!
  ) {
    registeredGuests(
      pageNum: $pageNum
      searchFilter: $searchFilter
      eventSlug: $eventSlug
    ) {
      guests {
        id
        name
        email
        nickname
      }
      count
    }
  }
`;

export const EVENT_ADMIN_GUEST_INFO = gql`
  query Guest($guestId: Int!) {
    guest(where: { id: $guestId }) {
      id
      name
      nickname
      email
      hasVerifiedEmail
      isEmailSubscribed
      bio
      location
      imageUrl
      facebookHandle
      instagramHandle
      twitterHandle
      linkedinHandle
      tiktokHandle
      youtubeHandle
    }
  }
`;

export const BOOTH_ADMIN_INVITES = gql`
  query BoothAdminInvites(
    $eventSlug: String!
    $pageNum: Int
    $searchFilter: String
  ) {
    boothAdminInvites(
      eventSlug: $eventSlug
      pageNum: $pageNum
      searchFilter: $searchFilter
    ) {
      boothAdminInvites {
        id
        name
        email
      }
      count
    }
  }
`;

export const GUEST_LIST_ITEMS = gql`
  query GuestListItems(
    $eventSlug: String!
    $pageNum: Int
    $searchFilter: String
  ) {
    guestListItems(
      eventSlug: $eventSlug
      pageNum: $pageNum
      searchFilter: $searchFilter
    ) {
      guests {
        id
        name
        email
      }
      count
    }
  }
`;

export const BLOCKED_GUESTS = gql`
  query BlockedGuests($pageNum: Int, $searchFilter: String) {
    blockedGuests(pageNum: $pageNum, searchFilter: $searchFilter) {
      guests {
        id
        name
        email
      }
      count
    }
  }
`;
