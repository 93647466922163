import React, { Dispatch, SetStateAction, useEffect } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { ChatMessagesType, CHAT_MESSAGE_TYPES } from "@virtualfest/common";
import ChatMessages from "./ChatMessages";
import BoothChatList from "./BoothChatList";

interface Props {
  userInfo: any;
  booths: any[];
  customText: string;
  chatMessages: ChatMessagesType;
  setChatMessages: Dispatch<SetStateAction<any>>;
  activeChat: any;
  setActiveChat: Dispatch<SetStateAction<any>>;
  setChatMessagesRef: (newCurrent: ChatMessagesType) => void;
  setHasUnreadMessage: Dispatch<SetStateAction<boolean>>;
}

const Chat = ({
  userInfo,
  booths,
  customText,
  chatMessages,
  setChatMessages,
  activeChat,
  setActiveChat,
  setChatMessagesRef,
  setHasUnreadMessage,
}: Props) => {
  useEffect(() => {
    setHasUnreadMessage(false);
  }, []);

  const addNewEventChatMessage = (newMessage) => {
    const newChatMessages = {
      ...chatMessages,
      event: [...chatMessages.event, newMessage],
    };

    setChatMessages(newChatMessages);
    setChatMessagesRef(newChatMessages);
  };

  return (
    <Container>
      <TopSection>
        <Title>Chat</Title>
        <Text>{customText}</Text>
        <Tabs>
          <Tab
            current={activeChat.type === CHAT_MESSAGE_TYPES.EVENT}
            onClick={() => setActiveChat({ type: CHAT_MESSAGE_TYPES.EVENT })}
          >
            Event Chat
          </Tab>
          {Object.keys(chatMessages.booth).length > 0 && (
            <Tab
              current={activeChat.type === CHAT_MESSAGE_TYPES.BOOTH}
              onClick={() => setActiveChat({ type: CHAT_MESSAGE_TYPES.BOOTH })}
            >
              Booth Chats
            </Tab>
          )}
        </Tabs>
      </TopSection>
      {activeChat.type === CHAT_MESSAGE_TYPES.EVENT && (
        <ChatMessages
          userInfo={userInfo}
          type={CHAT_MESSAGE_TYPES.EVENT}
          messages={chatMessages.event}
          addMessage={addNewEventChatMessage}
        />
      )}
      {activeChat.type === CHAT_MESSAGE_TYPES.BOOTH && (
        <BoothChatList
          userInfo={userInfo}
          booths={booths}
          chatMessages={chatMessages}
          setChatMessages={setChatMessages}
          setChatMessagesRef={setChatMessagesRef}
          activeChat={activeChat}
          setActiveChat={setActiveChat}
        />
      )}
    </Container>
  );
};

export default Chat;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: COLORS.white,
});

const TopSection = styled.div({
  padding: "30px 25px 0",
  backgroundColor: COLORS.white,
});

const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginBottom: 25,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 28,
  },
});

const Text = styled.div({
  whiteSpace: "pre-wrap",
});

const Tabs = styled.div({
  display: "flex",
  paddingRight: 15,
  marginTop: 20,
  fontSize: 12,
  color: COLORS.darkGrey,
});

const Tab = styled.button<{ current: boolean }>(({ current }) => ({
  fontSize: 13,
  fontWeight: 600,
  marginRight: 20,
  padding: "0 5px 8px",
  whiteSpace: "nowrap",
  color: current ? COLORS.lightBlack : COLORS.darkGrey,
  borderBottom: `3px solid ${current ? COLORS.lightBlack : COLORS.white}`,
}));
