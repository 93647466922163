import gql from "graphql-tag";

export const USER_LOGIN = gql`
  query UserLogin($email: String!, $password: String!, $userType: UserType!) {
    userLogin(email: $email, password: $password, userType: $userType) {
      token
      hasVerifiedEmail
    }
  }
`;

export const GET_EVENT = gql`
  query GetEvent($eventSlug: String!) {
    getEvent(slug: $eventSlug) {
      event {
        id
        name
        adminEmail
        organizationName
        bannerImageUrl
        mainStageMediaUrl
        liveStreamEmbedUrl
        selectedWorld
        numPeople
        numBooths
        startDateTime
        endDateTime
        intercomId
        slug
        isDemo
        enforceGuestList
        customText {
          id
          welcome
          events
          booths
          chat
        }
        eventScheduleItems {
          id
          date
          time
          title
          subtitle
        }
        admin {
          email
          blockedGuestIds
        }
      }
      booths {
        id
        name
        boothDisplayName
        description
        bannerImageUrl
      }
    }
  }
`;

export const GET_DEMO_EVENT_DATA = gql`
  query demoEventData {
    demoEventData {
      mainStageMediaUrl
      liveStreamEmbedUrl
      booths {
        id
        name
        boothDisplayName
        description
        bannerImageUrl
      }
      customText {
        id
        welcome
        events
        booths
        chat
      }
      eventScheduleItems {
        id
        date
        time
        title
        subtitle
      }
    }
  }
`;

export const GET_BOOTH_INFO = gql`
  query BoothQuery($id: Int!) {
    booth(where: { id: $id }) {
      id
      name
      description
      zoomUrl
      mediaUrls
      externalLinkUrl
      facebookHandle
      instagramHandle
      twitterHandle
      youtubeHandle
    }
  }
`;

export const GET_PROFILE_INFO = gql`
  query GuestQuery($guestId: Int!) {
    guest(where: { id: $guestId }) {
      id
      name
      bio
      location
      facebookHandle
      instagramHandle
      twitterHandle
      linkedinHandle
    }
  }
`;

export const CURRENT_GUEST = gql`
  query CurrentGuest {
    currentGuest {
      nickname
      id
      name
      email
      hasVerifiedEmail
      isEmailSubscribed
      bio
      location
      imageUrl
      avatarConfig {
        id
        top
        face
        body
      }
      facebookHandle
      instagramHandle
      twitterHandle
      linkedinHandle
      tiktokHandle
      youtubeHandle
    }
  }
`;

export const VERIFY_GUEST_LIST = gql`
  query VerifyGuestList($eventSlug: String!, $email: String!) {
    verifyGuestList(eventSlug: $eventSlug, email: $email)
  }
`;

export const VALIDATE_FORGOT_PASSWORD_TOKEN = gql`
  query ValitdateForgotPasswordToken($token: String!) {
    validateForgotPasswordToken(token: $token)
  }
`;
