import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { BASE_Z_INDEX } from "shared/constants";
import closeX from "shared/icons/Close_button.svg";

interface Props {
  imageUrl: any;
  setCropPopupOpen: Dispatch<SetStateAction<boolean>>;
  setBannerImageUrl: Dispatch<SetStateAction<string>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setImageFile: Dispatch<SetStateAction<File | Blob | null>>;
}

const CropPopup = ({
  imageUrl,
  setCropPopupOpen,
  setBannerImageUrl,
  setErrorMessage,
  setImageFile,
}: Props) => {
  const [crop, setCrop] = useState<any>({ aspect: 4, width: 512 });
  const [cropMinWidth, setCropMinWidth] = useState(512);

  useEffect(() => {
    let image: HTMLImageElement | null = new Image();

    image.onload = async () => {
      const cropEl = document.getElementById("crop");

      if (cropEl && image) {
        const scale = image.width / cropEl.clientWidth;

        setCropMinWidth(Math.max(512 / scale, 150));

        image = null;
      }
    };

    image.src = imageUrl;
  }, []);

  const onSave = async () => {
    let image: HTMLImageElement | null = new Image();
    image.crossOrigin = "*";

    image.onload = async () => {
      const cropEl = document.getElementById("crop");

      if (cropEl && image) {
        const scale = image.width / cropEl.offsetWidth;

        const canvas = document.createElement("canvas");
        canvas.width = crop.width * scale;
        canvas.height = crop.height * scale;

        const ctx = canvas.getContext("2d");

        ctx?.drawImage(
          image,
          crop.x * scale,
          crop.y * scale,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        canvas.toBlob(
          (blob: any) => {
            blob.name = imageUrl;

            const url = URL.createObjectURL(blob);

            setBannerImageUrl(url);
            setImageFile(blob);
            setErrorMessage("");
            setCropPopupOpen(false);

            if (image) {
              URL.revokeObjectURL(image.src);
              image = null;
            }
          },
          "image/jpeg",
          1
        );
      }
    };

    image.src = imageUrl;
  };

  return (
    <>
      <Dimmer onClick={() => setCropPopupOpen(false)} />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setCropPopupOpen(false)}
          />
          <Title>Edit Banner Image</Title>
          <div>
            Banner images should have a 4:1 aspect ratio and be at least 512
            pixels wide.
          </div>
          <div id="crop">
            <StyledCrop
              src={imageUrl}
              crop={crop}
              minWidth={cropMinWidth}
              keepSelection
              onImageError={() => setCropPopupOpen(false)}
              onChange={(newCrop) => setCrop(newCrop)}
            />
          </div>
          <Button type="button" onClick={onSave}>
            Save Image
          </Button>
          <TextLink
            type="button"
            onClick={() => {
              setBannerImageUrl("");
              setCropPopupOpen(false);
            }}
          >
            Choose a different picture
          </TextLink>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default CropPopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.6,
  zIndex: BASE_Z_INDEX + 10,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 11,
  top: "7vh",
  left: "5%",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    top: "5vh",
    width: "500px",
    left: "calc(50% - 250px)",
  },
  [media(Breakpoint.md)]: {
    width: "700px",
    left: "calc(50% - 350px)",
  },
  [media(Breakpoint.lg)]: {
    width: "1000px",
    left: "calc(50% - 500px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  padding: "60px 30px 45px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  color: COLORS.lightBlack,
  [media(Breakpoint.sm)]: {
    padding: "60px 50px 50px",
  },
  [media(Breakpoint.md)]: {
    padding: "60px 70px 55px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 20,
  fontWeight: "bold",
  marginBottom: 20,
  lineHeight: "30px",
  textAlign: "center",
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 32,
    lineHeight: "32px",
    marginBottom: 20,
  },
});

const StyledCrop = styled(ReactCrop)({
  border: `2px solid ${COLORS.lightBlack}`,
  marginTop: 30,
});

const Button = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 220,
  height: 60,
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 5,
  letterSpacing: 0.3,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  margin: "30px auto 0",
  [media(Breakpoint.sm)]: {
    marginTop: 35,
  },
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const TextLink = styled.button({
  display: "inline-block",
  color: COLORS.darkGrey,
  fontWeight: 600,
  marginTop: 25,
  "&:hover": {
    textDecoration: "underline",
  },
});
