import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

interface Props {
  active: boolean;
  handleClick: () => any;
}

const Switch = ({ active, handleClick }: Props) => {
  return (
    <SwitchEl type="button" onClick={handleClick}>
      <SwitchSlider active={active} />
      <SwitchLabel active={active}>{active ? "On" : "Off"}</SwitchLabel>
    </SwitchEl>
  );
};

export default Switch;

const SwitchEl = styled.button({
  position: "relative",
  width: 75,
  height: 30,
  borderRadius: 20,
  backgroundColor: COLORS.white,
  boxShadow: `0 0 8px 1px ${COLORS.grey}`,
  cursor: "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const SwitchSlider = styled.div<{ active: boolean }>(({ active }) => ({
  position: "absolute",
  left: active ? 48 : 5,
  top: 4,
  backgroundColor: active ? COLORS.green : COLORS.grey,
  borderRadius: 20,
  height: 22,
  width: 22,
  transition: ".2s",
}));

const SwitchLabel = styled.span<{ active: boolean }>(({ active }) => ({
  fontSize: 14,
  color: COLORS.darkGrey,
  position: "absolute",
  top: 7,
  left: active ? 18 : 39,
}));
