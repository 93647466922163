import React, { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { REQUEST_NEW_PASSWORD } from "../mutations.threeJs";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { BASE_Z_INDEX } from "shared/constants";
import { useForm } from "react-hook-form";
import closeX from "shared/icons/Close_button.svg";
import { USER_TYPES } from "@virtualfest/common";

interface Props {
  setForgotPasswordOpen: Dispatch<SetStateAction<boolean>>;
  userType: string;
}

const ForgotPassword = ({ setForgotPasswordOpen, userType }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const { register, handleSubmit } = useForm();

  const [requestNewPassword, { loading }] = useMutation(REQUEST_NEW_PASSWORD, {
    onCompleted: () => {
      setIsSubmitted(true);
    },
  });

  const onSubmit = (data) => {
    requestNewPassword({
      variables: {
        email: data.email,
        eventSlug,
        userType,
      },
    });
  };

  return (
    <>
      <Dimmer onClick={() => setForgotPasswordOpen(false)} />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setForgotPasswordOpen(false)}
          />
          {!isSubmitted ? (
            <>
              <Title>Forgot Password</Title>
              <div>
                Please enter your email address below and we'll send a link to
                reset your password
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FieldLabel>
                  Email Address
                  <InputField
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    ref={register({ required: true })}
                  />
                </FieldLabel>
                <Button type="submit" isSubmitting={loading}>
                  Submit
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Title>Request Received!</Title>
              <div>
                If the email entered is registered with VirtualFest, you should
                receive a link to reset your password.
              </div>
            </>
          )}
        </Popup>
      </PopupContainer>
    </>
  );
};

export default ForgotPassword;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 11,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 12,
  top: "18vh",
  left: "5%",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    top: "15vh",
    width: "500px",
    left: "calc(50% - 250px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  textAlign: "center",
  padding: "80px 40px 60px",
  width: "100%",
  minHeight: 250,
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "24px",
  borderRadius: 10,
  color: COLORS.lightBlack,
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "80px 50px 70px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 36,
  color: COLORS.lighterBlack,
  fontWeight: "bold",
  marginBottom: 40,
});

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 20,
  width: "100%",
});

const FieldLabel = styled.label({
  fontWeight: "bold",
  fontSize: 14,
  textAlign: "left",
  position: "relative",
  width: "100%",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: "10px 12px",
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
  [media(Breakpoint.sm)]: {
    width: 300,
  },
});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    color: COLORS.white,
    backgroundColor: COLORS.darkBlue,
    borderRadius: 5,
    fontSize: "14px",
    letterSpacing: 0.2,
    fontWeight: 600,
    height: "50px",
    width: "220px",
    marginTop: 15,
    opacity: isSubmitting ? 0.5 : 1,
  })
);
