import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { FACES, SKINS, CameraSettings, BODY_PART_MAP } from "./constants";

/**
 * A variety of helper functions that are used in the three js main function
 */

// Creates an image as a material
export function createImageMat(image_path) {
  return [
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(image_path),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(image_path),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(image_path),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(image_path),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(image_path),
    }),
    new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(image_path),
    }),
  ];
}

// Changes the face of a player given the player reference and the new face index
export function swapFace(player, index) {
  const texture = new THREE.TextureLoader().load(
    `../ThreeJS/texture/player/${FACES[index]}`
  );
  texture.flipY = false;
  player.children[BODY_PART_MAP.FACE].material = new THREE.MeshLambertMaterial({
    map: texture,
  });
}

// Changes the skin of a player given the player reference and the new skin index
export function swapSkin(player, index) {
  // Add Texture for the Body
  const texture = new THREE.TextureLoader().load(
    `../ThreeJS/texture/player/${SKINS[index]}`
  );
  texture.flipY = false;
  player.children[BODY_PART_MAP.BODY].material = new THREE.MeshLambertMaterial({
    map: texture,
  });

  const earSlug = SKINS[index].slice(-6).substring(0, 2);

  // Attach appropriate ears to the Body
  const texture1 = new THREE.TextureLoader().load(
    `../ThreeJS/texture/player/ears/ear-${earSlug}.png`
  );
  player.children[1].material = new THREE.MeshLambertMaterial({
    map: texture1,
  });
}

// Changes the text of the info board and the color of that text
export function ChangeInfoboardText(text, color, canvas, canvas_mesh, size) {
  const ctx = canvas.getContext("2d", { alpha: false });
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.font = `${size}px acumin-pro-wide`;
  ctx.fillStyle = "white";
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  ctx.fillText(`${text}`, canvas.width / 2, canvas.height / 2);
  canvas_mesh.material = new THREE.MeshBasicMaterial({
    map: new THREE.CanvasTexture(canvas),
  });
}

// Adds controls for the user player1
export function addControls(camera, renderer, position) {
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableRotate = true;
  controls.minDistance = CameraSettings.QuadMinZoom;
  controls.maxDistance = CameraSettings.QuadMaxZoom;
  controls.target = position;

  // Don't let it go below the ground
  controls.maxPolarAngle = Math.PI / 2 + 0.3;

  controls.enableDamping = true;
  return controls;
}

export function GetBoothTextSize(phrase: string) {
  if (phrase.length < 10) {
    return 30;
  } else if (phrase.length < 15) {
    return 20;
  } else {
    return 18;
  }
}

export function getPointInBetweenByLen(pointA, pointB, length) {
  const dir = pointB.clone().sub(pointA).normalize().multiplyScalar(length);
  return pointA.clone().add(dir);
}

export function getPointInBetweenByPerc(pointA, pointB, percentage) {
  let dir = pointB.clone().sub(pointA);
  const len = dir.length();
  dir = dir.normalize().multiplyScalar(len * percentage);
  return pointA.clone().add(dir);
}

export function wrapText(context, text, x, y, maxWidth, lineHeight) {
  const words = text.split(" ");
  const textLines: string[] = [];
  let line = "";

  for (let i = 0; i < words.length; i++) {
    var testLine = line + words[i] + " ";
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && i > 0) {
      line = line.substr(0, line.length - 1);
      textLines.push(line);

      line = words[i] + " ";
    } else {
      line = testLine;
    }
  }
  textLines.push(line.substr(0, line.length - 1));

  for (let i = 0; i < textLines.length; i += 1) {
    let yOffset =
      0.5 * lineHeight * Math.ceil(Math.abs(i - (textLines.length - 1) / 2));

    if (i < (textLines.length - 1) / 2) {
      yOffset = -1 * yOffset;
    }

    context.fillText(textLines[i], x, y + yOffset);
  }
}
