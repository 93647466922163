import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { BASE_Z_INDEX } from "shared/constants";
import Responsive from "shared/components/Responsive";
import Loading from "shared/components/Loading";
import closeX from "shared/icons/Close_button.svg";

interface Props {
  content: any;
  setNotification: Dispatch<SetStateAction<any>>;
}

const Notification = ({ content, setNotification }: Props) => {
  return (
    <>
      <Dimmer />
      <PopupContainer>
        <Popup>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setNotification({ open: false })}
          />
          <Title>{content.headline}</Title>
          <div>{content.message}</div>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default Notification;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 12,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 13,
  top: "15vh",
  left: "5%",
  width: "90%",
  maxHeight: "70vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    width: "450px",
    left: "calc(50% - 225px)",
  },
  [media(Breakpoint.md)]: {
    width: "500px",
    left: "calc(50% - 250px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  padding: "80px 50px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  lineHeight: "22px",
  color: COLORS.lightBlack,
  [media(Breakpoint.md)]: {
    padding: "80px 70px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 20,
  fontWeight: "bold",
  marginBottom: 20,
  lineHeight: "30px",
  textAlign: "center",
  [media(Breakpoint.sm)]: {
    fontSize: 28,
    lineHeight: "32px",
  },
});
