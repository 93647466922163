import { format, addMinutes } from "date-fns";

export const REGEX = {
  HAS_WHITESPACE: /^\S+$/,
  ONE_UPPERCASE: /.*[A-Z].*/,
  ONE_DIGIT: /.*\d.*/,
  HAS_LETTERS: /.*[a-z].*/,
};

// Used so that virtual interface components render correctly in relation to Intercom popup
export const BASE_Z_INDEX = 2147483002;

export const PLACEHOLDER_EVENT_SCHEDULE = {
  "Saturday, 03/20/21": {
    "12:00": [
      {
        id: 1,
        title: "Example event #1",
        subtitle: "The first example event",
      },
      {
        id: 2,
        title: "Example event #2",
        subtitle: "The second example event",
      },
    ],
    "14:00": [
      {
        id: 3,
        title: "Example event #3",
        subtitle: "The third example event",
      },
      {
        id: 4,
        title: "Example event #4",
        subtitle: "The fourth example event",
      },
    ],
    "16:00": [
      {
        id: 5,
        title: "Example event #5",
        subtitle: "The fifth example event",
      },
      {
        id: 6,
        title: "Example event #6",
        subtitle: "The sixth example event",
      },
    ],
  },
  "Sunday, 03/21/21": {
    "14:30": [
      {
        id: 7,
        title: "Example event #7",
        subtitle: "The seventh example event",
      },
      {
        id: 8,
        title: "Example event #8",
        subtitle: "The eigth example event",
      },
    ],
    "15:00": [
      {
        id: 9,
        title: "Example event #9",
        subtitle: "The ninth example event",
      },
      {
        id: 10,
        title: "Example event #10",
        subtitle: "The tenth example event",
      },
    ],
    "15:30": [
      {
        id: 11,
        title: "Example event #11",
        subtitle: "The eleventh example event",
      },
      {
        id: 12,
        title: "Example event #12",
        subtitle: "The twelfth example event",
      },
    ],
  },
};

export const PLACEHOLDER_BOOTHS = [
  {
    id: 1,
    name: "Example Booth #1",
    description: "Example description of Booth #1",
  },
  {
    id: 2,
    name: "Example Booth #2",
    description: "Example description of Booth #2",
  },
  {
    id: 3,
    name: "Example Booth #3",
    description: "Example description of Booth #3",
  },
  {
    id: 4,
    name: "Example Booth #4",
    description: "Example description of Booth #4",
  },
  {
    id: 5,
    name: "Example Booth #5",
    description: "Example description of Booth #5",
  },
  {
    id: 6,
    name: "Example Booth #6",
    description: "Example description of Booth #6",
  },
];

export const PLACEHOLDER_USER_INFO = {
  id: 1,
  name: "John Doe",
};

const exampleChatMessages = [
  {
    authorId: 2,
    authorName: "Emily Smith",
    message: "I'm loving VirtualFest!",
    timestamp: new Date(),
  },
  {
    authorId: 1,
    authorName: "John Doe",
    message:
      "Same here! It's great to connect in a new way that isn't just a normal video call.",
    timestamp: addMinutes(new Date(), 2),
  },
  {
    authorId: 1,
    authorName: "John Doe",
    message: "and it's kind of fun :)",
    timestamp: addMinutes(new Date(), 3),
  },
  {
    authorId: 2,
    authorName: "Emily Smith",
    message: "Totally agree. I hope our next event can use it, too!",
    timestamp: addMinutes(new Date(), 5),
  },
];

export const PLACEHOLDER_CHATS = {
  event: exampleChatMessages,
  booth: {
    1: exampleChatMessages,
  },
  direct: {
    1: exampleChatMessages,
  },
};

export enum EVENT_STATUS {
  HAS_NOT_STARTED = "HAS_NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  HAS_ENDED = "HAS_ENDED",
}
