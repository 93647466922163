import React, { Dispatch, SetStateAction, MutableRefObject } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES } from "@virtualfest/common";
import { useHistory } from "react-router-dom";
import { BASE_Z_INDEX } from "shared/constants";
import { ChatMessagesType } from "@virtualfest/common";
import virtualFestLogo from "shared/icons/virtualfest-logo.png";
import homeActive from "./images/Active_Home_Button.svg";
import homeInactive from "./images/Inactive_Home_Button.svg";
import eventActive from "./images/Active_Event_Button.svg";
import eventInactive from "./images/Inactive_Event_Button.svg";
import directoryActive from "./images/Active_Directory_Button.svg";
import directoryInactive from "./images/Inactive_Directory_Button.svg";
import chatActive from "./images/Active_Chat_Button.svg";
import chatInactive from "./images/Inactive_Chat Button.svg";
import chatNotification from "./images/Chat_Notification.svg";
import supportActive from "./images/Active_Support_Button.svg";
import supportInactive from "./images/Inactive_Support_Button.svg";
import closeMenu from "./images/CloseMenu_Button.svg";
import closeX from "shared/icons/Close_button.svg";
import Intro from "./Intro";
import Welcome from "./Welcome";
import Events from "./Events";
import Directory from "./Directory";
import Chat from "./Chat/Chat";
import Intercom from "./Intercom";

interface Props {
  setSidebarOpen: (open: boolean) => void;
  activeTab: number;
  setActiveTab: (tabIndex: number) => void;
  setBoothPopup: Dispatch<SetStateAction<any>>;
  setLiveStreamOpen: Dispatch<SetStateAction<boolean>>;
  enteredQuad: boolean;
  startWalk: () => boolean | undefined;
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
  avatarConfigRef: any;
  booths: any[];
  eventSchedule: any;
  customText: any;
  chatMessages: ChatMessagesType;
  setChatMessages: Dispatch<SetStateAction<ChatMessagesType>>;
  chatMessagesRef: MutableRefObject<ChatMessagesType>;
  activeChat: any;
  setActiveChat: Dispatch<SetStateAction<any>>;
  hasUnreadMessage: boolean;
  setHasUnreadMessage: Dispatch<SetStateAction<boolean>>;
}

const Sidebar = ({
  setSidebarOpen,
  activeTab,
  setActiveTab,
  setBoothPopup,
  setLiveStreamOpen,
  enteredQuad,
  startWalk,
  userInfo,
  setUserInfo,
  avatarConfigRef,
  booths,
  eventSchedule,
  customText,
  chatMessages,
  setChatMessages,
  chatMessagesRef,
  activeChat,
  setActiveChat,
  hasUnreadMessage,
  setHasUnreadMessage,
}: Props) => {
  const history = useHistory();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT.LOGIN });

  const renderMainContent = () => {
    if (activeTab === 0)
      return (
        <Intro
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          avatarConfigRef={avatarConfigRef}
          setActiveTab={setActiveTab}
          startWalk={startWalk}
        />
      );
    if (activeTab === 1)
      return (
        <Welcome setActiveTab={setActiveTab} customText={customText.welcome} />
      );
    if (activeTab === 2)
      return (
        <Events
          eventSchedule={eventSchedule}
          customText={customText.events}
          setLiveStreamOpen={setLiveStreamOpen}
        />
      );
    if (activeTab === 3)
      return (
        <Directory
          booths={booths}
          setBoothPopup={setBoothPopup}
          customText={customText.booths}
        />
      );
    if (activeTab === 4)
      return (
        <Chat
          userInfo={userInfo}
          booths={booths}
          customText={customText.chat}
          chatMessages={chatMessages}
          setChatMessages={setChatMessages}
          activeChat={activeChat}
          setActiveChat={setActiveChat}
          setChatMessagesRef={(newCurrent) =>
            (chatMessagesRef.current = newCurrent)
          }
          setHasUnreadMessage={setHasUnreadMessage}
        />
      );
    if (activeTab === 5) return <Intercom />;
    return null;
  };

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    history.push(LOGIN);
    window.location.reload();
  };

  return (
    <Container>
      {enteredQuad && (
        <CloseMenu
          type="image"
          src={closeMenu}
          alt="X"
          onClick={() => setSidebarOpen(false)}
        />
      )}
      <Header>
        <Logo src={virtualFestLogo} alt="NU Logo" />
        <TextLink type="button" onClick={handleLogout}>
          Log Out
        </TextLink>
        <CloseX
          type="image"
          enteredQuad={enteredQuad}
          src={closeX}
          alt="Close"
          onClick={() => setSidebarOpen(false)}
        />
      </Header>
      <MainContent enteredQuad={enteredQuad}>{renderMainContent()}</MainContent>
      {enteredQuad && (
        <Footer>
          <FooterButton
            type="image"
            alt="Home"
            src={activeTab === 1 ? homeActive : homeInactive}
            onClick={() => setActiveTab(1)}
          />
          <FooterButton
            type="image"
            alt="Events"
            src={activeTab === 2 ? eventActive : eventInactive}
            onClick={() => setActiveTab(2)}
          />
          <FooterButton
            type="image"
            alt="Booths"
            src={activeTab === 3 ? directoryActive : directoryInactive}
            onClick={() => setActiveTab(3)}
          />
          <FooterButton
            type="image"
            alt="Chat"
            src={
              activeTab === 4
                ? chatActive
                : hasUnreadMessage
                ? chatNotification
                : chatInactive
            }
            onClick={() => setActiveTab(4)}
          />
          <FooterButton
            type="image"
            alt="Support"
            src={activeTab === 5 ? supportActive : supportInactive}
            onClick={() => setActiveTab(5)}
          />
        </Footer>
      )}
    </Container>
  );
};

export default Sidebar;

const Container = styled.div({
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "20px",
  height: "100%",
  width: "100%",
  position: "fixed",
  top: 0,
  right: 0,
  color: COLORS.lightBlack,
  overflow: "none",
  pointerEvents: "auto",
  zIndex: BASE_Z_INDEX,
  [media(Breakpoint.sm)]: {
    width: 425,
    borderLeft: `3px solid ${COLORS.lightBlack}`,
  },
});

const CloseMenu = styled.input({
  position: "absolute",
  top: 40,
  left: -33,
  height: 30,
  width: 30,
});

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
  padding: "15px 28px 0 20px",
  backgroundColor: COLORS.white,
  marginBottom: -1,
});

const Logo = styled.img({
  height: 35,
});

const TextLink = styled.button({
  color: COLORS.blue,
  textDecoration: "underline",
  marginLeft: "auto",
  fontWeight: 600,
  "&:focus": {
    color: COLORS.textFocusBlue,
  },
  [media(Breakpoint.sm)]: {
    marginRight: 0,
    fontSize: 14,
  },
});

const CloseX = styled.input<{ enteredQuad: boolean }>(({ enteredQuad }) => ({
  display: enteredQuad ? "initial" : "none",
  height: 20,
  width: 20,
  marginLeft: 15,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.sm)]: {
    display: "none",
  },
}));

const MainContent = styled.div<{ enteredQuad: boolean }>(({ enteredQuad }) => ({
  width: "100%",
  height: "calc(100% - 59px)",
  paddingBottom: enteredQuad ? 70 : 0,
}));

const Footer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: 0,
  height: 70,
  width: "100%",
  backgroundColor: COLORS.white,
  boxShadow: `0 0 8px 1px ${COLORS.grey}`,
  clipPath: "inset(-15px 0px 0px 0px)",
});

const FooterButton = styled.input({
  margin: "0 8px",
  width: 43,
  "&:focus": {
    borderRadius: 50,
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
