import React from "react";

import { Col, Row, Container } from "@mverissimoo/emotion-grid";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { accentDarkPurple } from "shared/styles/colors";

interface Props {
  color?: string;
}

const Loading = ({ color }: Props) => {
  const cssOveride = css`
    display: flex;
    justify-content: center;
  `;

  return (
    <Container>
      <Row justify="center">
        <PrimaryCol>
          <PulseLoader css={cssOveride} size={20} color={color} />
        </PrimaryCol>
      </Row>
    </Container>
  );
};

export default Loading;

const PrimaryCol = styled(Col)({
  marginTop: 80,
});
