/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { useRef } from "react";

interface Props {
  children: any;
  hasNav?: boolean;
}

const Layout = ({ children, hasNav = true }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <LayoutComponent ref={ref}>
      <ChildrenWrapper hasNav={hasNav}>{children}</ChildrenWrapper>
    </LayoutComponent>
  );
};

export default Layout;

/* --------------------------- Emotion Components --------------------------- */

const LayoutComponent = styled.div({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

interface ChildrenWrapperProps {
  hasNav: boolean;
}
const ChildrenWrapper = styled.div<ChildrenWrapperProps>(({ hasNav }) => ({
  padding: `${hasNav ? "40px" : "0"} 0`,
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));
