import React, { useState, Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import {
  SAVE_PROFILE_INFO,
  CREATE_UPDATE_EVENT_ATTENDANCE,
} from "../mutations.threeJs";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { useForm } from "react-hook-form";
import { useDocumentDimensions } from "shared/hooks/useDocumentDimensions";
import { formatSocialMediaHandle } from "shared/utils";

interface Props {
  setActiveTab: (tabIndex: number) => void;
  startWalk: () => boolean | undefined;
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
  avatarConfigRef: any;
}

const Intro = ({
  userInfo,
  setUserInfo,
  avatarConfigRef,
  setActiveTab,
  startWalk,
}: Props) => {
  const { width } = useDocumentDimensions();

  const [showForm, setShowForm] = useState(width < 540 ? false : true);
  const [showSocialFields, setShowSocialFields] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const { register, handleSubmit } = useForm();

  const [trackAttendance] = useMutation(CREATE_UPDATE_EVENT_ATTENDANCE);

  const [saveProfileInfo, { loading }] = useMutation(SAVE_PROFILE_INFO, {
    onCompleted: (data) => {
      const {
        bio,
        location,
        facebookHandle,
        instagramHandle,
        twitterHandle,
        linkedinHandle,
      } = data.updateOneGuest;

      setUserInfo({
        ...userInfo,
        bio,
        location,
        facebookHandle,
        instagramHandle,
        twitterHandle,
        linkedinHandle,
      });

      trackAttendance({
        variables: {
          guestEmail: userInfo.email,
          eventSlug,
          updateData: {
            lastEntered: { set: new Date() },
          },
          createData: {
            guest: {
              connect: { email: userInfo.email },
            },
            event: {
              connect: { slug: eventSlug },
            },
            firstEntered: new Date(),
            lastEntered: new Date(),
          },
        },
      });

      if (!isSubmitting) {
        setActiveTab(1);
      }
    },
  });

  const onSubmit = (data) => {
    if (!startWalk()) {
      setIsSubmitting(true);

      const checkQuadLoaded = setInterval(() => {
        if (startWalk()) {
          clearInterval(checkQuadLoaded);
          setActiveTab(1);
        }
      }, 50);
    }

    if (!userInfo.asEventAdmin) {
      saveProfileInfo({
        variables: {
          guestId: userInfo.id,
          data: {
            bio: { set: data.bio },
            location: { set: data.location },
            ...(showSocialFields && {
              facebookHandle: {
                set: formatSocialMediaHandle(data.facebookHandle),
              },
              instagramHandle: {
                set: formatSocialMediaHandle(data.instagramHandle),
              },
              twitterHandle: {
                set: formatSocialMediaHandle(data.twitterHandle),
              },
              linkedinHandle: {
                set: formatSocialMediaHandle(data.linkedinHandle),
              },
            }),
            avatarConfig: {
              update: {
                body: { set: avatarConfigRef.current.body },
                face: { set: avatarConfigRef.current.face },
                top: { set: avatarConfigRef.current.top },
              },
            },
          },
        },
      });
    } else {
      setActiveTab(1);
    }
  };

  return (
    <Container showForm={showForm}>
      <TopSection>
        <Title>Hi there!</Title>
        <Text>
          Fill out your profile and choose your avatar before entering
          VirtualFest.
        </Text>
      </TopSection>
      {showForm ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldLabel>
            Short Bio (max 150 characters)
            <TextArea
              name="bio"
              rows={2}
              maxLength={150}
              defaultValue={userInfo.bio}
              placeholder="Enter a few sentences about yourself"
              ref={register}
            />
          </FieldLabel>
          <FieldLabel>
            Location (optional)
            <InputField
              name="location"
              type="text"
              placeholder="Enter your location"
              defaultValue={userInfo.location}
              ref={register}
            />
          </FieldLabel>
          {!showSocialFields ? (
            <TextLink type="button" onClick={() => setShowSocialFields(true)}>
              Add Social Media Links
            </TextLink>
          ) : (
            <>
              <FieldLabel>
                Facebook (optional)
                <SocialMediaInput>
                  facebook.com/ &nbsp;&nbsp;
                  <InputField
                    name="facebookHandle"
                    type="text"
                    placeholder="Enter handle"
                    defaultValue={userInfo.facebookHandle}
                    isSocial
                    ref={register}
                  />
                </SocialMediaInput>
              </FieldLabel>
              <FieldLabel>
                Instagram (optional)
                <SocialMediaInput>
                  instagram.com/ &nbsp;&nbsp;
                  <InputField
                    name="instagramHandle"
                    type="text"
                    placeholder="Enter handle"
                    defaultValue={userInfo.instagramHandle}
                    isSocial
                    ref={register}
                  />
                </SocialMediaInput>
              </FieldLabel>
              <FieldLabel>
                Twitter (optional)
                <SocialMediaInput>
                  twitter.com/ &nbsp;&nbsp;
                  <InputField
                    name="twitterHandle"
                    type="text"
                    placeholder="Enter handle"
                    defaultValue={userInfo.twitterHandle}
                    isSocial
                    ref={register}
                  />
                </SocialMediaInput>
              </FieldLabel>
              <FieldLabel>
                LinkedIn (optional)
                <SocialMediaInput>
                  linkedin.com/in/ &nbsp;&nbsp;
                  <InputField
                    name="linkedinHandle"
                    type="text"
                    placeholder="Enter handle"
                    defaultValue={userInfo.linkedinHandle}
                    isSocial
                    ref={register}
                  />
                </SocialMediaInput>
              </FieldLabel>
            </>
          )}
          <Button
            type="submit"
            showForm={showForm}
            isSubmitting={isSubmitting || loading}
          >
            Continue
          </Button>
        </Form>
      ) : (
        <Button type="button" onClick={() => setShowForm(true)}>
          Complete Profile
        </Button>
      )}
    </Container>
  );
};

export default Intro;

const Container = styled.div<{ showForm: Boolean }>(({ showForm }) => ({
  backgroundColor: showForm ? COLORS.white : "transparent",
  height: "calc(100% + 1px)",
  pointerEvents: "auto",
  overflowY: "auto",
  paddingBottom: 60,
  [media(Breakpoint.sm)]: {
    backgroundColor: COLORS.white,
    paddingBottom: 50,
  },
}));

const TopSection = styled.div({
  backgroundColor: COLORS.white,
  padding: "23px 0 5px",
  [media(Breakpoint.sm)]: {
    paddingTop: 25,
  },
});

const Title = styled.div({
  fontSize: 35,
  fontWeight: "bold",
  marginBottom: 20,
  textAlign: "center",
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    position: "static",
    padding: "0 25px",
    fontSize: 28,
    width: "auto",
    margin: "5px 0 25px",
    textAlign: "left",
  },
});

const Text = styled.div({
  marginBottom: 15,
  padding: "0 40px",
  textAlign: "center",
  [media(Breakpoint.sm)]: {
    padding: "0 25px",
    textAlign: "left",
    marginBottom: 0,
  },
});

const Form = styled.form({
  padding: "0 25px",
  backgroundColor: COLORS.white,
  [media(Breakpoint.sm)]: {
    marginTop: 15,
  },
});

const FieldLabel = styled.label({
  display: "block",
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
});

const TextArea = styled.textarea({
  width: "100%",
  margin: "8px 0 20px",
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lightGrey}`,
  fontSize: 14,
  lineHeight: "20px",
  padding: "10px 12px",
  resize: "none",
  fontFamily: "Poppins, sans-serif",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
  [media(Breakpoint.sm)]: {
    width: 300,
  },
});

const InputField = styled.input<{ isSocial?: Boolean }>(({ isSocial }) => ({
  display: isSocial ? "inline-block" : "block",
  padding: "10px 12px",
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lightGrey}`,
  fontSize: "14px",
  margin: isSocial ? 0 : "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
  [media(Breakpoint.sm)]: {
    width: isSocial ? "100%" : 300,
  },
}));

const SocialMediaInput = styled.div({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  alignItems: "center",
  color: COLORS.darkGrey,
  width: "100%",
  margin: "8px 0 25px",
  [media(Breakpoint.sm)]: {
    width: 300,
  },
});

const TextLink = styled.button({
  fontSize: 14,
  color: COLORS.blue,
  textDecoration: "underline",
});

const Button = styled.button<{ showForm?: Boolean; isSubmitting?: Boolean }>(
  ({ showForm, isSubmitting }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 160,
    height: 42,
    fontSize: 14,
    margin: "30px auto 0",
    position: showForm ? "static" : "absolute",
    bottom: 60,
    left: "calc(50% - 80px)",
    borderRadius: 5,
    letterSpacing: 0.3,
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    pointerEvents: "auto",
    opacity: isSubmitting ? 0.5 : 1,
    "&:focus": {
      boxShadow: `0 0 3px 3px ${COLORS.grey}`,
    },
    [media(Breakpoint.sm)]: {
      margin: "25px 0 0",
      position: "static",
    },
  })
);
