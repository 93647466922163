import { compareAsc, format } from "date-fns";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { EVENT_STATUS } from "./constants";

export const formatDateToTime = (date) => {
  return format(new Date(date), "h:mm aa");
};

export const getEventStatus = (
  startDateTime: Date,
  endDateTime: Date
): { status: EVENT_STATUS; popupTitle: string; popupMessage: string } => {
  const eventStatusObject = {
    status: EVENT_STATUS.IN_PROGRESS,
    popupTitle: "",
    popupMessage: "",
  };
  const eventDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);
  const today = new Date();

  const formattedStartDate = format(eventDate, "M/d/y");
  const formattedStartHours = format(eventDate, "h:m aa");

  if (compareAsc(eventDate, today) === 1) {
    eventStatusObject.status = EVENT_STATUS.HAS_NOT_STARTED;
    eventStatusObject.popupTitle = "This event has not started!";
    eventStatusObject.popupMessage = `Please refresh the page when the event starts on ${formattedStartDate} at ${formattedStartHours}. Feel free to walk around in the meantime!`;
  } else if (compareAsc(endDate, today) === -1) {
    eventStatusObject.status = EVENT_STATUS.HAS_ENDED;
    eventStatusObject.popupTitle = "This event has ended!";
    eventStatusObject.popupMessage = "Thanks for stopping by!";
  }
  return eventStatusObject;
};

export const isProduction = () => process.env.NODE_ENV === "production";

export function toggleItemInArray(array: Array<string>, item: any) {
  return _.xor(array, [item]);
}

export const useWindowDimensions = () => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = window.innerWidth;
    dimensions.current.height = window.innerHeight;
  }, []);

  return dimensions.current;
};

export const hideOverflow = () => {
  document.documentElement.style.overflow = "hidden";
};

export const showOverflow = () => {
  document.documentElement.style.overflow = "visible";
};

export const nameByUrl = (url) => {
  const splitUrl = url.split("/");
  const name = splitUrl[splitUrl.length - 1];
  return name;
};

export const cleanSpaces = (value) => {
  return value.replace(" ", "");
};

// See: https://stackoverflow.com/questions/25777826/onclick-works-but-ondoubleclick-is-ignored-on-react-component
export const useSimpleAndDoubleClick = (actionDoubleClick, delay = 250) => {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Can pass a handler for single click and call here if necessary
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick();

    return () => clearTimeout(timer);
  }, [click]);

  return () => setClick((prev) => prev + 1);
};

export const formattedExternalUrl = (url) => {
  if (url.startsWith("http")) return url;
  return `https://${url}`;
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(
    formattedExternalUrl(url),
    "_blank",
    "noreferrer"
  );

  if (newWindow) newWindow.opener = null;
};

// Returns an array of sorted objects nested by date and time
export const buildEventSchedule = (eventItems: any[]): any => {
  const eventsMap = {};

  for (let item of eventItems) {
    if (eventsMap[item.date]) {
      eventsMap[item.date][item.time] = eventsMap[item.date][item.time]
        ? [...eventsMap[item.date][item.time], item]
        : [item];
    } else {
      eventsMap[item.date] = {};
      eventsMap[item.date][item.time] = [item];
    }
  }

  return eventsMap;
};

export const sortedEventScheduleDates = (eventSchedule: any): string[] => {
  const dates = Object.keys(eventSchedule);

  const sortedDates = dates.sort((a, b) =>
    new Date(a) > new Date(b) ? 1 : -1
  );

  return sortedDates;
};

export const sortedEventScheduleTimes = (eventScheduleDate: any): string[] => {
  const times = Object.keys(eventScheduleDate || {});

  const sortedTimes = times.sort((a, b) => (a > b ? 1 : -1));

  return sortedTimes;
};

export const formatToTwelveHourTime = (twentyFourHourTime?: string): string => {
  if (!twentyFourHourTime) return "";

  const hours = twentyFourHourTime.split(":")[0];
  const minutes = twentyFourHourTime.split(":")[1];

  if (Number(hours) >= 12) {
    return `${Number(hours) - 12 || 12}:${minutes} PM`;
  } else {
    return `${hours || 12}:${minutes} AM`;
  }
};

export const getCombinedDateTime = (date: Date, time: string): Date => {
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];

  const dateInMilliseconds = date.setHours(Number(hours), Number(minutes));

  return new Date(dateInMilliseconds);
};

export const getInitials = (name: string): string => {
  if (!name) return "";

  const names = name.split(" ");
  const firstName = names[0];
  const lastName = names.length > 1 && names[names.length - 1];

  return (firstName[0] + (lastName[0] ?? "")).toUpperCase();
};

export const generateDemoSlug = (email: string): string => {
  const username = email.split("@")[0];

  const randomInt = Math.floor(Math.random() * 10000);

  return `${username}-${randomInt}`;
};

export const truncateString = (string: string, length: number): string => {
  if (string.length > length) {
    return `${string.substr(0, length - 3).trim()}...`;
  } else {
    return string;
  }
};

export const formatSocialMediaHandle = (userInput: string): string => {
  let handle = userInput;

  if (userInput.includes("facebook.com/")) {
    handle = userInput.split("facebook.com/")[1];
  } else if (userInput.includes("instagram.com/")) {
    handle = userInput.split("instagram.com/")[1];
  } else if (userInput.includes("twitter.com/")) {
    handle = userInput.split("twitter.com/")[1];
  } else if (userInput.includes("youtube.com/user/")) {
    handle = userInput.split("youtube.com/user/")[1];
  } else if (userInput.includes("youtube.com/")) {
    handle = userInput.split("youtube.com/")[1];
  } else if (userInput.includes("linkedin.com/in/")) {
    handle = userInput.split("linkedin.com/in/")[1];
  }

  return handle;
};
