import React, { Dispatch, SetStateAction, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { YETI_ADMIN_LOGIN } from "./queries.yetiAdmin";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

interface Props {
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const Login = ({ setLoggedIn }: Props) => {
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const [yetiAdminLogin] = useLazyQuery(YETI_ADMIN_LOGIN, {
    onError: (error) => {
      if (error.message.includes("Invalid credentials")) {
        setError(true);
      }
      setIsSubmitting(false);
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.yetiAdminLogin);
      setLoggedIn(true);
      setIsSubmitting(false);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password || isSubmitting) return;

    setIsSubmitting(true);

    yetiAdminLogin({
      variables: {
        password: password,
      },
    });
  };

  return (
    <Container>
      <Title>Yeti Admin Login</Title>
      <ErrorMessage hide={!error}>Incorrect password</ErrorMessage>
      <form onSubmit={(e) => handleSubmit(e)}>
        <FieldLabel>
          Password
          <InputField
            name="password"
            type="password"
            placeholder="Enter password here"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FieldLabel>
        <Button type="submit" isSubmitting={isSubmitting}>
          Sign In
        </Button>
      </form>
    </Container>
  );
};

export default Login;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  width: "100%",
  padding: "60px 30px",
  fontFamily: "Poppins, sans-serif",
  backgroundColor: COLORS.lightGrey,
  [media(Breakpoint.sm)]: {
    padding: "120px 30px",
  },
});

const Title = styled.div({
  fontSize: "32px",
  lineHeight: "36px",
  fontWeight: "bold",
  marginBottom: 15,
  textAlign: "center",
  [media(Breakpoint.md)]: {
    fontSize: "45px",
    lineHeight: "50px",
    marginBottom: 20,
  },
});

const ErrorMessage = styled.div<{ hide: boolean }>(({ hide }) => ({
  color: COLORS.red,
  visibility: hide ? "hidden" : "visible",
  fontSize: 16,
  lineHeight: "16px",
  margin: "-6px 0 10px",
}));

const FieldLabel = styled.label({
  fontSize: 16,
  marginTop: 20,
  fontWeight: "bold",
  lineHeight: "22px",
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.grey}`,
  fontSize: "14px",
  marginTop: 5,
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
  [media(Breakpoint.sm)]: {
    width: 300,
  },
});

const Button = styled.button<{ isSubmitting: boolean }>(({ isSubmitting }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.white,
  backgroundColor: COLORS.darkBlue,
  fontSize: "14px",
  letterSpacing: 0.2,
  fontWeight: "bold",
  height: "50px",
  width: "220px",
  margin: "40px auto",
  cursor: "pointer",
  opacity: isSubmitting ? 0.5 : 1,
}));
