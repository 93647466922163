import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@apollo/react-hooks";
import { TOGGLE_GUEST_LIST } from "./mutations.eventAdmin";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { socket } from "services/SocketIo/SocketIOService";
import TabContainer from "shared/components/TabContainer";
import Spacer from "shared/components/Spacer";
import Switch from "shared/components/Switch";
import ConfirmationPopup from "shared/components/ConfirmationPopup";
import Loading from "shared/components/Loading";

interface Props {
  eventData: any;
  setEventData: Dispatch<SetStateAction<any>>;
}

const EventSettings = ({ eventData, setEventData }: Props) => {
  const [announcement, setAnnouncement] = useState({
    headline: "",
    message: "",
  });
  const [guestListPopupOpen, setGuestListPopupOpen] = useState(false);

  const [toggleGuestList] = useMutation(TOGGLE_GUEST_LIST, {
    onCompleted: (data) => {
      setEventData({
        ...eventData,
        enforceGuestList: data.updateOneEvent.enforceGuestList,
      });
      setGuestListPopupOpen(false);
    },
  });

  const onAnnouncementSubmit = (e) => {
    e.preventDefault();
    if (!announcement.headline || !announcement.message) return;

    socket.emit("announcement", {
      announcement,
      eventSlug: eventData.slug,
    });

    setAnnouncement({
      headline: "",
      message: "",
    });
  };

  const { id: eventId, enforceGuestList } = eventData;

  return (
    <>
      {guestListPopupOpen && (
        <ConfirmationPopup
          text={`Once confirmed, ${
            enforceGuestList
              ? "any registered user will be able to enter VirtualFest"
              : "only users with an email address on the guest list will be able to enter VirtualFest"
          }`}
          confirmButtonText={`Turn Guest List ${
            enforceGuestList ? "Off" : "On"
          }`}
          closePopup={() => setGuestListPopupOpen(false)}
          onConfirm={() =>
            toggleGuestList({
              variables: {
                eventId,
                enforceGuestList: !enforceGuestList,
              },
            })
          }
        />
      )}
      <TabContainer>
        <Title>Event Settings</Title>
        <Sections>
          <div>
            <Header>Announcements</Header>
            <div>Send a pop up announcement to all of the event attendees:</div>
            <form onSubmit={(e) => onAnnouncementSubmit(e)}>
              <SubHeader>Headline</SubHeader>
              <Input
                type="text"
                placeholder="Enter headline here"
                onChange={(e) =>
                  setAnnouncement({ ...announcement, headline: e.target.value })
                }
                value={announcement.headline}
              />
              <SubHeader>Message</SubHeader>
              <TextArea
                placeholder="Enter message here"
                onChange={(e) =>
                  setAnnouncement({ ...announcement, message: e.target.value })
                }
                value={announcement.message}
              />
              <Button type="submit">Send Message</Button>
            </form>
          </div>
          <div>
            <Header>Guest List</Header>
            <div>
              Require a user's email address to be on a guest list in order to
              enter VirtualFest
            </div>
            <SubHeader>Require</SubHeader>
            <Switch
              active={enforceGuestList}
              handleClick={() => setGuestListPopupOpen(true)}
            />
          </div>
        </Sections>
      </TabContainer>
    </>
  );
};

export default EventSettings;

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 25px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const Sections = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: 50,
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "1fr 1fr",
    gridGap: "50px 5%",
  },
  [media(Breakpoint.xl)]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
});

const Header = styled.div({
  fontSize: 22,
  fontWeight: 600,
  margin: "5px 0 15px",
});

const SubHeader = styled.label({
  display: "block",
  fontSize: 16,
  fontWeight: 600,
  margin: "15px 0 10px",
});

const Input = styled.input({
  padding: "0 15px",
  border: `1px solid ${COLORS.grey}`,
  marginBottom: 5,
  height: 40,
  width: "100%",
  fontSize: "14px",
  fontFamily: "Poppins, sans-serif",
  borderRadius: 5,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
});

const TextArea = styled.textarea({
  padding: "10px 15px",
  border: `1px solid ${COLORS.grey}`,
  height: 160,
  width: "100%",
  fontSize: "14px",
  lineHeight: "20px",
  resize: "none",
  fontFamily: "Poppins, sans-serif",
  borderRadius: 5,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
});

const Button = styled.button({
  display: "block",
  cursor: "pointer",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  marginTop: 15,
  height: 42,
  width: 160,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
