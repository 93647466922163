import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_CUSTOM_TEXT } from "./mutations.eventAdmin";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Loading from "shared/components/Loading";
import TabContainer from "shared/components/TabContainer";
import Responsive from "shared/components/Responsive";
import homeBlue from "./images/home_blue.svg";
import eventBlue from "./images/Event_Blue.svg";
import organizationBlue from "./images/Org_blue.svg";
import chatBlue from "./images/Chat_blue.svg";
import upCarat from "./images/Up_Caret.svg";
import downCarat from "./images/Down_Caret.svg";
import Welcome from "../ThreeJS/Sidebar/Welcome";
import Events from "../ThreeJS/Sidebar/Events";
import Directory from "../ThreeJS/Sidebar/Directory";
import Chat from "../ThreeJS/Sidebar/Chat/Chat";
import homeActive from "../ThreeJS/Sidebar/images/Active_Home_Button.svg";
import eventActive from "../ThreeJS/Sidebar/images/Active_Event_Button.svg";
import directoryActive from "../ThreeJS/Sidebar/images/Active_Directory_Button.svg";
import chatActive from "../ThreeJS/Sidebar/images/Active_Chat_Button.svg";
import {
  PLACEHOLDER_EVENT_SCHEDULE,
  PLACEHOLDER_BOOTHS,
  PLACEHOLDER_USER_INFO,
  PLACEHOLDER_CHATS,
} from "shared/constants";
import { ChatMessagesType, CHAT_MESSAGE_TYPES } from "@virtualfest/common";

interface Props {
  customText: any;
}

export const FooterPreview = ({ activeTabIndex, src }) => {
  return (
    <FooterContainer>
      {[0, 1, 2, 3, 4].map((el) => (
        <FooterIcon key={el} active={activeTabIndex === el} imageUrl={src} />
      ))}
    </FooterContainer>
  );
};

const CustomText = ({ customText }: Props) => {
  const [openTabIndex, setOpenTabIndex] = useState<number | undefined>(0);
  const [previewTabIndex, setPreviewTabIndex] = useState(0);
  const [welcome, setWelcome] = useState(customText?.welcome ?? "");
  const [events, setEvents] = useState(customText?.events ?? "");
  const [booths, setBooths] = useState(customText?.booths ?? "");
  const [chat, setChat] = useState(customText?.chat ?? "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonText, setButtonText] = useState("Save Changes");

  const [updateCustomText] = useMutation(UPDATE_CUSTOM_TEXT, {
    onCompleted: () => {
      setIsSubmitting(false);
      setButtonText("Saved!");
    },
  });

  useEffect(() => {
    if (customText) {
      setWelcome(customText.welcome);
      setEvents(customText.events);
      setBooths(customText.booths);
      setChat(customText.chat);
    }
  }, [customText]);

  const handleCaratClick = (tabIndex: number) => {
    if (openTabIndex === tabIndex) {
      setOpenTabIndex(undefined);
    } else {
      setOpenTabIndex(tabIndex);
      setPreviewTabIndex(tabIndex);
    }
  };

  const handleTextAreaChange = (e, setStateFunc) => {
    setStateFunc(e.target.value);
    setButtonText("Save Changes");
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    updateCustomText({
      variables: {
        customTextId: customText.id,
        data: {
          welcome: { set: welcome },
          events: { set: events },
          booths: { set: booths },
          chat: { set: chat },
        },
      },
    });
  };

  const placeholderFunction = () => {
    return;
  };

  const renderPreviewTab = () => {
    if (previewTabIndex === 0) return <Welcome customText={welcome} />;
    if (previewTabIndex === 1)
      return (
        <Events
          eventSchedule={PLACEHOLDER_EVENT_SCHEDULE}
          customText={events}
        />
      );
    if (previewTabIndex === 2)
      return <Directory booths={PLACEHOLDER_BOOTHS} customText={booths} />;
    if (previewTabIndex === 3)
      return (
        <Chat
          userInfo={PLACEHOLDER_USER_INFO}
          booths={PLACEHOLDER_BOOTHS}
          customText={chat}
          chatMessages={PLACEHOLDER_CHATS}
          setChatMessages={
            placeholderFunction as Dispatch<SetStateAction<ChatMessagesType>>
          }
          setChatMessagesRef={
            placeholderFunction as (newCurrent: ChatMessagesType) => void
          }
          setHasUnreadMessage={
            placeholderFunction as Dispatch<SetStateAction<boolean>>
          }
          activeChat={{ type: CHAT_MESSAGE_TYPES.EVENT }}
          setActiveChat={placeholderFunction as Dispatch<SetStateAction<any>>}
        />
      );
    return;
  };

  const getFooterIcon = () => {
    if (previewTabIndex === 0) return homeActive;
    if (previewTabIndex === 1) return eventActive;
    if (previewTabIndex === 2) return directoryActive;
    if (previewTabIndex === 3) return chatActive;
    return;
  };

  return (
    <TabContainer>
      <Container>
        <MainContent>
          <Title>Customize Text</Title>
          <div>
            Personalize headers and text for all sections of the sidebar shown
            during your event.
          </div>
          <div>
            <SectionTab onClick={() => handleCaratClick(0)}>
              <TabIcon src={homeBlue} alt="" />
              <div>Welcome Tab</div>
              <ExpandIcon
                src={openTabIndex === 0 ? upCarat : downCarat}
                alt={openTabIndex === 0 ? "Collapse" : "Expand"}
              />
            </SectionTab>
            {openTabIndex === 0 && (
              <>
                <CharacterLimit>{welcome.length}/500</CharacterLimit>
                <TextArea
                  placeholder="Enter text for the welcome tab here"
                  value={welcome}
                  maxLength={500}
                  onChange={(e) => handleTextAreaChange(e, setWelcome)}
                />
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
              </>
            )}
            <SectionTab onClick={() => handleCaratClick(1)}>
              <TabIcon src={eventBlue} alt="" />
              <div>Event Schedule Tab</div>
              <ExpandIcon
                src={openTabIndex === 1 ? upCarat : downCarat}
                alt={openTabIndex === 1 ? "Collapse" : "Expand"}
                onClick={() => handleCaratClick(1)}
              />
            </SectionTab>
            {openTabIndex === 1 && (
              <>
                <CharacterLimit>{events.length}/200</CharacterLimit>
                <TextArea
                  placeholder="Enter text for the event schedule tab here"
                  value={events}
                  maxLength={200}
                  onChange={(e) => handleTextAreaChange(e, setEvents)}
                />
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
              </>
            )}
            <SectionTab onClick={() => handleCaratClick(2)}>
              <TabIcon src={organizationBlue} alt="" />
              <div>Booths Tab</div>
              <ExpandIcon
                src={openTabIndex === 2 ? upCarat : downCarat}
                alt={openTabIndex === 2 ? "Collapse" : "Expand"}
                onClick={() => handleCaratClick(2)}
              />
            </SectionTab>
            {openTabIndex === 2 && (
              <>
                <CharacterLimit>{booths.length}/200</CharacterLimit>
                <TextArea
                  placeholder="Enter text for the booths tab here"
                  value={booths}
                  maxLength={200}
                  onChange={(e) => handleTextAreaChange(e, setBooths)}
                />
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
              </>
            )}
            <SectionTab onClick={() => handleCaratClick(3)}>
              <TabIcon src={chatBlue} alt="" />
              <div>Chat Tab</div>
              <ExpandIcon
                src={openTabIndex === 3 ? upCarat : downCarat}
                alt={openTabIndex === 3 ? "Collapse" : "Expand"}
                onClick={() => handleCaratClick(3)}
              />
            </SectionTab>
            {openTabIndex === 3 && (
              <>
                <CharacterLimit>{chat.length}/200</CharacterLimit>
                <TextArea
                  placeholder="Enter text for the chat tab here"
                  value={chat}
                  maxLength={200}
                  onChange={(e) => handleTextAreaChange(e, setChat)}
                />
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
              </>
            )}
          </div>
        </MainContent>
        <SidebarPreview>
          <PreviewLogo>Your Logo</PreviewLogo>
          {renderPreviewTab()}
          <FooterPreview
            activeTabIndex={previewTabIndex}
            src={getFooterIcon()}
          />
        </SidebarPreview>
      </Container>
    </TabContainer>
  );
};

export default CustomText;

const FooterContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: 0,
  height: 70,
  width: "100%",
  backgroundColor: COLORS.white,
  boxShadow: `0 0 8px 1px ${COLORS.grey}`,
  clipPath: "inset(-15px 0px 0px 0px)",
});

const FooterIcon = styled.div<{ active: boolean; imageUrl: string }>(
  ({ active, imageUrl }) => ({
    backgroundImage: active ? `url(${imageUrl})` : "none",
    backgroundColor: active ? COLORS.white : COLORS.lighterGrey,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: "0 8px",
    width: 43,
    height: 43,
    borderRadius: 50,
  })
);

const Container = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  color: COLORS.lighterBlack,
  margin: "0 20px 25px 0",
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const MainContent = styled.div({
  width: "100%",
  [media(Breakpoint.xl)]: {
    minWidth: 380,
    marginRight: 50,
  },
});

const SectionTab = styled.button({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: COLORS.blue,
  width: "100%",
  fontSize: 18,
  fontWeight: 600,
  margin: "50px 0 5px",
  "&:first-of-type": {
    marginTop: 30,
  },
});

const TabIcon = styled.img({
  marginRight: 20,
  width: 25,
});

const ExpandIcon = styled.img({
  marginLeft: "auto",
});

const CharacterLimit = styled.div({
  fontSize: 12,
  paddingRight: 4,
  textAlign: "right",
});

const TextArea = styled.textarea({
  padding: "10px 15px",
  border: `1px solid ${COLORS.grey}`,
  height: 160,
  width: "100%",
  fontSize: "14px",
  lineHeight: "20px",
  resize: "none",
  fontFamily: "Poppins, sans-serif",
  borderRadius: 5,
  marginBottom: -6,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
});

const Button = styled.button<{ isSubmitting: boolean }>(({ isSubmitting }) => ({
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  height: 42,
  width: 160,
  marginTop: 25,
  opacity: isSubmitting ? 0.5 : 1,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));

const SidebarPreview = styled.div({
  display: "none",
  position: "relative",
  height: 700,
  maxHeight: 700,
  backgroundColor: COLORS.white,
  width: 425,
  minWidth: 425,
  border: `1px solid ${COLORS.grey}`,
  paddingBottom: 155,
  [media(Breakpoint.xl)]: {
    display: "flex",
    flexDirection: "column",
  },
});

const PreviewLogo = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.grey,
  borderRadius: 10,
  border: `2px solid ${COLORS.grey}`,
  width: 150,
  height: 50,
  minHeight: 50,
  margin: "30px 25px 5px",
});
