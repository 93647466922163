import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER_LOGIN, CURRENT_GUEST } from "../queries.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES, USER_TYPES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Responsive from "shared/components/Responsive";
import virtualFestLogo from "../images/virtaul_fest_logo.svg";
import { openInNewTab } from "shared/utils";
import { useForm } from "react-hook-form";
import ForgotPassword from "./ForgotPassword";

interface Props {
  setSignedIn: Dispatch<SetStateAction<boolean>>;
  setEmailVerified: Dispatch<SetStateAction<boolean>>;
  setUserInfo: Dispatch<SetStateAction<any>>;
}

const GuestLogin = ({ setSignedIn, setEmailVerified, setUserInfo }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const history = useHistory();

  const { ROOT, REGISTER }: any = useEventSlug({
    ROOT: ROUTES.EVENT.ROOT,
    REGISTER: ROUTES.EVENT.REGISTER,
  });

  const { register, handleSubmit } = useForm();

  const [currentGuest] = useLazyQuery(CURRENT_GUEST, {
    onCompleted: (data) => {
      setUserInfo(data.currentGuest);
      setSignedIn(true);
      setEmailVerified(data.currentGuest.hasVerifiedEmail);
      history.push(ROOT);
    },
  });

  const [userLogin] = useLazyQuery(USER_LOGIN, {
    onError: (error) => {
      if (error.message.includes("Invalid credentials")) {
        setError("Invalid credentials");
      }
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.userLogin.token);
      currentGuest();
    },
  });

  const onSubmit = (data) => {
    userLogin({
      variables: {
        email: data.email.toLowerCase(),
        password: data.password,
        userType: USER_TYPES.GUEST,
      },
    });
  };

  return (
    <>
      {forgotPasswordOpen && (
        <ForgotPassword
          setForgotPasswordOpen={setForgotPasswordOpen}
          userType={USER_TYPES.GUEST}
        />
      )}
      <Container>
        <VirtualFestLogo
          type="image"
          src={virtualFestLogo}
          alt="Powered by VirtualFest"
          onClick={() => openInNewTab("virtualfest.app")}
        />
        <Title>Welcome!</Title>
        <Text>Log in with your credentials to join the event.</Text>
        <RegisterLink>
          Don't have an account?&nbsp;
          <TextLink type="button" onClick={() => history.push(REGISTER)}>
            Register Now
          </TextLink>
        </RegisterLink>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {error === "Invalid credentials" && (
            <Error>Incorrect email and/or password</Error>
          )}
          <FieldLabel>
            Email Address
            <InputField
              name="email"
              type="email"
              placeholder="Enter email address"
              ref={register({ required: true })}
            />
          </FieldLabel>
          <FieldLabel>
            Password
            <InputField
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              ref={register({ required: true })}
            />
            <ShowPassword
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </ShowPassword>
            <ForgotPasswordLink
              type="button"
              onClick={() => setForgotPasswordOpen(true)}
            >
              Forgot Password?
            </ForgotPasswordLink>
          </FieldLabel>
          <Button type="submit">Enter Event</Button>
        </Form>
      </Container>
    </>
  );
};

export default GuestLogin;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  width: "100%",
  padding: "150px 30px",
  backgroundColor: COLORS.lightGrey,
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  color: COLORS.lightBlack,
});

const VirtualFestLogo = styled.input({
  position: "absolute",
  top: 25,
  left: 20,
  height: 40,
  [media(Breakpoint.sm)]: {
    left: 50,
  },
});

const Title = styled.div({
  fontSize: 48,
  fontWeight: "bold",
  marginBottom: 25,
  textAlign: "center",
  color: COLORS.darkBlue,
  [media(Breakpoint.md)]: {
    fontSize: 70,
  },
});

const Text = styled.div({
  textAlign: "center",
  lineHeight: "20px",
});

const RegisterLink = styled.div({
  marginTop: 10,
  [media(Breakpoint.sm)]: {
    position: "absolute",
    top: 40,
    right: 50,
    marginTop: 0,
  },
});

const TextLink = styled.button({
  color: COLORS.green,
  textDecoration: "underline",
  fontWeight: 600,
});

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 30,
  width: "100%",
  [media(Breakpoint.sm)]: {
    marginTop: 35,
  },
});

const Error = styled.div({
  fontSize: 14,
  color: COLORS.red,
  margin: "-20px 0 15px",
});

const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
  [media(Breakpoint.sm)]: {
    width: 340,
  },
});

const ShowPassword = styled.button({
  color: COLORS.darkGrey,
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  position: "absolute",
  bottom: 40,
  right: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const ForgotPasswordLink = styled.button({
  textDecoration: "underline",
  position: "absolute",
  bottom: -5,
  right: 2,
  color: COLORS.green,
});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    color: COLORS.white,
    backgroundColor: COLORS.green,
    borderBottom: `6px solid ${COLORS.darkGreen}`,
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: 600,
    height: "60px",
    width: "200px",
    marginTop: 40,
    opacity: isSubmitting ? 0.5 : 1,
  })
);
