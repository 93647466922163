import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { useMutation } from "@apollo/react-hooks";
import { USER_SIGN_UP } from "../mutations.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { format } from "date-fns";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Spacer from "shared/components/Spacer";
import Responsive from "shared/components/Responsive";
import { openInNewTab } from "shared/utils";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import virtualFestLogo from "../images/virtaul_fest_logo.svg";
import virtualFestImage from "../images/virtualfest-background.png";
import Filter from "bad-words";
import Footer from "shared/components/Footer";

interface Props {
  eventData: any;
  setSignedIn: Dispatch<SetStateAction<boolean>>;
}

const GuestRegister = ({ eventData, setSignedIn }: Props) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const filter = new Filter();

  const history = useHistory();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT.LOGIN });

  const classes = useStyles();

  const { register, handleSubmit, control, errors } = useForm();

  const [userSignUp, { loading }] = useMutation(USER_SIGN_UP, {
    onError: (error) => {
      if (error.message.includes("Existing user")) {
        setError("Existing user");
      }
    },
    onCompleted: (data) => {
      CookieService.setCookie(Identifiers.AccessToken, data.userSignUp);
      setSignedIn(true);
    },
  });

  const validatePasswordMatch = (data) => {
    if (data !== password) return false;
    return true;
  };

  const validateProfanity = (data) => {
    if (filter.isProfane(data)) return false;
    return true;
  };

  const onSubmit = (data) => {
    userSignUp({
      variables: {
        data: {
          name: data.name,
          nickname: data.nickname,
          email: data.email,
          passwordDigest: data.password,
          isEmailSubscribed: data.isEmailSubscribed,
          eventSlug,
          userType: "GUEST",
        },
      },
    });
  };

  const formattedDate = format(
    new Date(eventData.startDateTime),
    "MMMM do, yyyy"
  );
  const formattedTime = format(new Date(eventData.startDateTime), "h:mm a");

  const isNewSchoolEvent = eventData.admin?.email === "fearona@newschool.edu";

  return (
    <>
      <Container>
        <VirtualFestLogo
          type="image"
          src={virtualFestLogo}
          alt="Powered by VirtualFest"
          onClick={() => openInNewTab("virtualfest.app")}
        />
        <Title>You're invited to</Title>
        <EventText>
          <EventTitle>{eventData.name}</EventTitle>
          <Spacer height={15} />
          {eventData.slug === "yeti-university"
            ? "Happening Now"
            : `${formattedDate} - ${formattedTime}`}
        </EventText>
        {eventData.bannerImageUrl && (
          <EventLogo src={eventData.bannerImageUrl} alt="Event Banner" />
        )}
        <LoginLink>
          Already Registered?&nbsp;
          <TextLink
            type="button"
            color={COLORS.green}
            weight={600}
            onClick={() => history.push(LOGIN)}
          >
            Log In
          </TextLink>
        </LoginLink>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MainFormContent>
            <div>
              <Title color={COLORS.white}>Join us!</Title>
              {isNewSchoolEvent ? (
                <>
                  Welcome to the Fall Involvement Fair 2021! Create an account,
                  customize your avatar's look, then explore the worlds and chat
                  with fellow students. You can click on the main stage and the
                  different booths to learn more about the opportunities at The
                  New School. See you inside!
                </>
              ) : (
                <>
                  Check out our live demo! Start by picking your avatar and
                  giving yourself a custom look, from there you can explore the
                  world and say “hello” with our overhead chat. We’ll meet you
                  inside!
                </>
              )}
              <CheckboxFields>
                <CheckboxLabel>
                  <Controller
                    name="hasAcceptedTerms"
                    control={control}
                    defaultValue={false}
                    rules={{ required: true }}
                    render={(props) => (
                      <FormCheckbox
                        classes={{
                          root: errors.hasAcceptedTerms
                            ? classes.error
                            : classes.root,
                          checked: classes.checked,
                        }}
                        color="default"
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                  <div>
                    I have read and agree to the&nbsp;
                    <TextLink
                      type="button"
                      weight={600}
                      onClick={() =>
                        openInNewTab("https://www.virtualfest.app/terms")
                      }
                    >
                      terms of service
                    </TextLink>
                    .
                  </div>
                </CheckboxLabel>
                <Spacer height={4} />
                <CheckboxLabel>
                  <Controller
                    name="isEmailSubscribed"
                    control={control}
                    defaultValue={true}
                    render={(props) => (
                      <FormCheckbox
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                        color="default"
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                  I would liked to stay up to date with any VirtualFest news.
                </CheckboxLabel>
              </CheckboxFields>
            </div>
            <InputFields>
              <FieldLabel>
                Full Name
                <InputField
                  name="name"
                  type="text"
                  placeholder="Enter full name"
                  ref={register({ required: true })}
                />
              </FieldLabel>
              {errors.nickname?.type === "validate" && (
                <Error>Nickname contains profanity</Error>
              )}
              <FieldLabel>
                Nickname
                <InputField
                  name="nickname"
                  type="text"
                  placeholder="Enter nickname"
                  ref={register({
                    required: true,
                    validate: validateProfanity,
                  })}
                />
              </FieldLabel>
              {error === "Existing user" && (
                <Error>A user with that email or nickname already exists</Error>
              )}
              <FieldLabel>
                Email Address
                <InputField
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                  ref={register({ required: true })}
                />
              </FieldLabel>
              <PasswordField>
                Password
                <InputField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  ref={register({ required: true })}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <ShowPassword
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </ShowPassword>
              </PasswordField>
              {errors.reenterPassword?.type === "validate" && (
                <Error>Passwords do not match</Error>
              )}
              <PasswordField>
                Confirm Password
                <InputField
                  name="reenterPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Reenter password"
                  ref={register({
                    required: true,
                    validate: validatePasswordMatch,
                  })}
                />
                <ShowPassword
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </ShowPassword>
              </PasswordField>
            </InputFields>
          </MainFormContent>
          <Button type="submit" disabled={loading}>
            Register
          </Button>
          <TextLink
            type="button"
            onClick={() =>
              window.open(
                `mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`,
                "_blank"
              )
            }
          >
            Need Help?
          </TextLink>
        </Form>
      </Container>
      <BottomSection>
        <Title>What's VirtualFest?</Title>
        <Responsive maxSize="xs">
          <ImageText>
            A virtual event platform that’s not just another boring square
            video. Create an avatar, explore the event, meet people, and have
            fun!
            <Spacer height={35} />
            <TextLink
              type="button"
              color={COLORS.green}
              weight={600}
              onClick={() => openInNewTab("www.virtualfest.app")}
            >
              Learn more about VirtualFest
            </TextLink>
          </ImageText>
        </Responsive>
        <VirtualFestImage imageUrl={virtualFestImage}>
          <Responsive minSize="sm">
            <ImageText>
              A virtual event platform that’s not just another boring square
              video. Create an avatar, explore the event, meet people, and have
              fun!
              <Spacer height={35} />
              <TextLink
                type="button"
                color={COLORS.green}
                weight={600}
                onClick={() => openInNewTab("www.virtualfest.app")}
              >
                Learn more about VirtualFest
              </TextLink>
            </ImageText>
          </Responsive>
        </VirtualFestImage>
        <Footer />
      </BottomSection>
    </>
  );
};

export default GuestRegister;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  width: "100%",
  padding: "150px 40px",
  backgroundColor: COLORS.lightGrey,
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  color: COLORS.lightBlack,
  [media(Breakpoint.sm)]: {
    padding: "150px 60px",
  },
});

const VirtualFestLogo = styled.input({
  position: "absolute",
  top: 25,
  left: 20,
  height: 40,
  [media(Breakpoint.sm)]: {
    left: 50,
  },
});

const LoginLink = styled.div({
  marginTop: 30,
  [media(Breakpoint.sm)]: {
    position: "absolute",
    top: 40,
    right: 50,
    marginTop: 0,
  },
});

const Title = styled.div<{ color?: string }>(({ color }) => ({
  fontSize: 30,
  fontWeight: "bold",
  marginBottom: 60,
  color: color ?? COLORS.darkBlue,
  textAlign: "center",
  [media(Breakpoint.sm)]: {
    fontSize: 48,
  },
  [media(Breakpoint.md)]: {
    fontSize: 70,
  },
  [media(Breakpoint.lg)]: {
    textAlign: "left",
  },
}));

const EventLogo = styled.img({
  margin: "50px auto 30px",
  width: "100%",
  [media(Breakpoint.sm)]: {
    height: 100,
    width: "auto",
    maxWidth: "100%",
  },
});

const EventText = styled.div({
  color: COLORS.darkBlue,
  fontSize: 22,
  lineHeight: "36px",
  textAlign: "center",
  marginTop: 0,
});

const EventTitle = styled.div({
  fontWeight: 600,
  margin: 0,
});

const TextLink = styled.button<{ color?: string; weight?: number }>(
  ({ color, weight }) => ({
    color: color ?? COLORS.white,
    textDecoration: "underline",
    fontWeight: weight ?? "normal",
  })
);

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.darkBlue,
  color: COLORS.white,
  padding: "80px 40px 70px",
  borderRadius: 40,
  lineHeight: "30px",
  marginTop: 50,
  [media(Breakpoint.sm)]: {
    padding: "80px 100px 70px",
    marginTop: 80,
  },
});

const MainFormContent = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  alignItems: "center",
  gridGap: "40px 60px",
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "1fr min(50%, 400px)",
  },
});

const InputFields = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  maxWidth: 400,
  margin: "0 auto",
});

const FieldLabel = styled.label({
  width: "100%",
  fontSize: 14,
  fontWeight: 600,
});

const InputField = styled.input({
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  fontSize: 14,
  margin: "5px 0 25px",
  width: "100%",
  maxWidth: 400,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
  },
});

const PasswordField = styled.label({
  position: "relative",
  width: "100%",
  fontSize: 14,
  fontWeight: 600,
});

const ShowPassword = styled.button({
  color: COLORS.darkGrey,
  textDecoration: "underline",
  fontWeight: "bold",
  fontSize: 12,
  position: "absolute",
  top: 47,
  right: 25,
  padding: "1px 4px",
  borderRadius: 3,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Error = styled.div({
  color: COLORS.red,
  fontSize: 14,
  lineHeight: "14px",
  margin: "-5px 0 10px",
});

const CheckboxFields = styled.div({
  display: "flex",
  flexDirection: "column",
  marginTop: 35,
});

const CheckboxLabel = styled.label({
  display: "flex",
  alignItems: "flex-start",
  fontSize: 14,
  lineHeight: "20px",
  position: "relative",
  width: "100%",
  marginBottom: 8,
  [media(Breakpoint.sm)]: {
    alignItems: "center",
    marginBottom: 5,
  },
});

const FormCheckbox = styled(Checkbox)({
  transform: "scale(0.9)",
  position: "relative",
  top: -5,
  [media(Breakpoint.sm)]: {
    top: 0,
  },
});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    color: COLORS.white,
    backgroundColor: COLORS.green,
    borderBottom: `6px solid ${COLORS.darkGreen}`,
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: 600,
    height: "60px",
    width: "270px",
    margin: "40px 0 35px",
    opacity: isSubmitting ? 0.5 : 1,
  })
);

const BottomSection = styled.div({
  fontFamily: "Poppins, sans-serif",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.lightGrey,
});

const ImageText = styled.div({
  width: "100%",
  padding: "0 40px",
  textAlign: "center",
  margin: "-10px auto 0",
  fontSize: 16,
  lineHeight: "28px",
  [media(Breakpoint.sm)]: {
    position: "relative",
    marginTop: 0,
    padding: 0,
    width: "80%",
    maxWidth: 500,
    top: -100,
  },
  [media(Breakpoint.md)]: {
    top: -60,
  },
  [media(Breakpoint.lg)]: {
    top: -30,
    fontSize: 18,
  },
});

const VirtualFestImage = styled.div<{ imageUrl: string }>(({ imageUrl }) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "cover",
  height: "47vw",
  width: "100%",
  backgroundColor: COLORS.lightGrey,
  marginTop: 30,
  [media(Breakpoint.sm)]: {
    marginTop: 95,
  },
  [media(Breakpoint.md)]: {
    marginTop: 55,
  },
  [media(Breakpoint.lg)]: {
    marginTop: 25,
  },
}));

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: "1px 8px 0 0",
    "& svg": {
      height: 32,
      width: 32,
      stroke: COLORS.white,
      "& path": {
        stroke: "transparent",
        color: COLORS.white,
      },
    },
  },
  error: {
    padding: 0,
    margin: "1px 8px 0 0",
    "& svg": {
      height: 32,
      width: 32,
      stroke: COLORS.white,
      "& path": {
        color: COLORS.red,
        stroke: COLORS.red,
      },
    },
  },
  checked: {
    "& svg": {
      fill: COLORS.darkBlue,
      height: 30,
      width: 32,
      margin: "1px 0",
      "& path": {
        stroke: COLORS.white,
      },
    },
  },
});
