import React, {
  useState,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Loading from "shared/components/Loading";
import closeX from "shared/icons/Close_button.svg";
import facebook from "./Sidebar/images/facebook.png";
import instagram from "./Sidebar/images/instagram.png";
import twitter from "./Sidebar/images/twitter.png";
import youtube from "./Sidebar/images/youtube.png";
import { ChatMessagesType, CHAT_MESSAGE_TYPES } from "@virtualfest/common";
import { BASE_Z_INDEX } from "shared/constants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { openInNewTab } from "shared/utils";
import { useQuery } from "@apollo/react-hooks";
import { GET_BOOTH_INFO } from "./queries.threeJs";
import { socket } from "services/SocketIo/SocketIOService";

interface Props {
  setBoothPopup: Dispatch<SetStateAction<any>>;
  boothId: number | undefined;
  setSidebarOpen: (open: boolean) => void;
  setSidebarActiveTab: (tabIndex: number) => void;
  chatMessages: ChatMessagesType;
  setChatMessages: Dispatch<SetStateAction<ChatMessagesType>>;
  chatMessagesRef: MutableRefObject<ChatMessagesType>;
  setActiveChat: Dispatch<SetStateAction<any>>;
}

const BoothPopup = ({
  setBoothPopup,
  boothId,
  setSidebarOpen,
  setSidebarActiveTab,
  chatMessages,
  setChatMessages,
  chatMessagesRef,
  setActiveChat,
}: Props) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { data, loading, error } = useQuery(GET_BOOTH_INFO, {
    variables: { id: boothId },
  });

  const handleJoinBoothChat = () => {
    if (!boothId) return;

    if (!chatMessages.booth[boothId]) {
      const newChatMessages = {
        ...chatMessages,
        booth: {
          ...chatMessages.booth,
          [boothId]: [],
        },
      };

      setChatMessages(newChatMessages);
      chatMessagesRef.current = newChatMessages;

      socket.emit("joinChat", {
        roomId: boothId,
      });
    }

    setBoothPopup({ open: false });
    setSidebarActiveTab(4);
    setActiveChat({
      type: CHAT_MESSAGE_TYPES.BOOTH,
      id: boothId,
    });
    setSidebarOpen(true);
  };

  if (loading || error)
    return (
      <>
        <Dimmer />
        <PopupContainer>
          <Popup>
            <Loading />
          </Popup>
        </PopupContainer>
      </>
    );

  const { booth } = data;

  return (
    <>
      <Dimmer onClick={() => setBoothPopup({ open: false })} />
      <PopupContainer>
        <Popup>
          <JoinButtons>
            {booth.zoomUrl && (
              <JoinCallButton onClick={() => openInNewTab(booth.zoomUrl)}>
                Join Video Call
              </JoinCallButton>
            )}
            <JoinChatButton onClick={handleJoinBoothChat}>
              Join Booth Chat
            </JoinChatButton>
          </JoinButtons>
          <CloseX
            type="image"
            src={closeX}
            alt="Close"
            onClick={() => setBoothPopup({ open: false })}
          />
          <Title>{booth.name}</Title>
          <div>{booth.description}</div>
          <Links>
            {booth.externalLinkUrl && (
              <div>
                Visit:&nbsp;
                <TextLink onClick={() => openInNewTab(booth.externalLinkUrl)}>
                  {booth.externalLinkUrl}
                </TextLink>
              </div>
            )}
            <SocialIcons>
              {booth.facebookHandle && (
                <SocialIcon
                  type="image"
                  src={facebook}
                  alt="Facebook"
                  onClick={() =>
                    openInNewTab(
                      `https://www.facebook.com/${booth.facebookHandle}`
                    )
                  }
                />
              )}
              {booth.instagramHandle && (
                <SocialIcon
                  type="image"
                  src={instagram}
                  alt="Instagram"
                  onClick={() =>
                    openInNewTab(
                      `https://www.instagram.com/${booth.instagramHandle}`
                    )
                  }
                />
              )}
              {booth.twitterHandle && (
                <SocialIcon
                  type="image"
                  src={twitter}
                  alt="Twitter"
                  onClick={() =>
                    openInNewTab(`https://twitter.com/${booth.twitterHandle}`)
                  }
                />
              )}
              {booth.youtubeHandle && (
                <SocialIcon
                  type="image"
                  src={youtube}
                  alt="Youtube"
                  onClick={() =>
                    openInNewTab(
                      `https://www.youtube.com/${booth.youtubeHandle}`
                    )
                  }
                />
              )}
            </SocialIcons>
          </Links>
          {booth.mediaUrls.length > 0 && (
            <CarouselContainer>
              <CarouselEl
                showStatus={false}
                showArrows={true}
                showThumbs={false}
                showIndicators={false}
                selectedItem={carouselIndex}
                onChange={(newIndex) => setCarouselIndex(newIndex)}
                interval={999999} // So tapping on mobile doesn't move the carousel
              >
                {booth.mediaUrls.map((url) => (
                  <CarouselImage key={url} imageUrl={url} />
                ))}
              </CarouselEl>
              <CarouselDots>
                {booth.mediaUrls.map((url, i) => (
                  <CarouselDot
                    key={url}
                    current={i === carouselIndex}
                    onClick={() => setCarouselIndex(i)}
                  />
                ))}
              </CarouselDots>
            </CarouselContainer>
          )}
        </Popup>
      </PopupContainer>
    </>
  );
};

export default BoothPopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: BASE_Z_INDEX + 11,
  pointerEvents: "auto",
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: BASE_Z_INDEX + 12,
  top: "5vh",
  left: "5%",
  width: "90%",
  maxHeight: "90vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    width: "500px",
    left: "calc(50% - 250px)",
  },
  [media(Breakpoint.md)]: {
    width: "750px",
    left: "calc(50% - 375px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  textAlign: "center",
  padding: "110px 20px 45px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "22px",
  color: COLORS.lightBlack,
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "110px 40px 55px",
  },
  [media(Breakpoint.md)]: {
    padding: "110px 70px 55px",
  },
});

const JoinButtons = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: 25,
  left: 25,
});

const JoinCallButton = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 10,
  width: 160,
  height: 42,
  fontSize: 14,
  fontWeight: "bold",
  borderRadius: 5,
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const JoinChatButton = styled.button({
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 14,
  color: COLORS.lightBlack,
  "&:hover": {
    color: COLORS.grey,
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 36,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 28,
  lineHeight: "35px",
  fontWeight: "bold",
  marginBottom: 10,
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 32,
    marginBottom: 15,
    lineHeight: "40px",
  },
});

const Links = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 12,
});

const TextLink = styled.button({
  color: COLORS.blue,
  textDecoration: "underline",
  "&:focus": {
    color: COLORS.textFocusBlue,
  },
});

const SocialIcons = styled.div({
  display: "flex",
  marginTop: 15,
});

const SocialIcon = styled.input({
  height: 20,
  width: 20,
  margin: "0 5px",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const CarouselContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  height: 342,
  margin: "20px auto 0",
  backgroundColor: COLORS.lightGrey,
  padding: "25px 20px 22px",
  [media(Breakpoint.md)]: {
    width: "80%",
    maxWidth: 800,
    padding: "25px 30px 22px",
  },
});

const CarouselEl = styled(Carousel)({
  height: 270,
  "&&& button": {
    borderTop: `1px solid ${COLORS.lightGrey}`,
    borderBottom: `1px solid ${COLORS.lightGrey}`,
    backgroundColor: COLORS.darkGrey,
    opacity: 0.6,
    "&:hover": {
      backgroundColor: COLORS.lightBlack,
      opacity: 0.7,
    },
  },
});

const CarouselImage = styled.div<{ imageUrl: string }>(({ imageUrl }) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  height: 270, // Must be the same as CarouselEl
  width: "100%",
  border: `1px solid ${COLORS.lightGrey}`, // Otherwise images may be visibile when not selected
  backgroundColor: COLORS.lightGrey,
}));

const CarouselDots = styled.div({
  display: "flex",
  margin: "0 auto",
});

const CarouselDot = styled.button<{ current: boolean }>(({ current }) => ({
  height: 10,
  width: 10,
  borderRadius: 10,
  backgroundColor: current ? COLORS.blue : "transparent",
  border: `1px solid ${COLORS.blue}`,
  margin: "0 5px",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));
