import React, {
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { useEventSlug } from "shared/hooks/useEventSlug";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { Title, LoginLink, TextLink } from "./Onboarding";
import { ROUTES } from "@virtualfest/common";

interface Props {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setStepsCompleted: Dispatch<SetStateAction<boolean>>;
}

const ProgressBar = ({ setCurrentStep, setStepsCompleted }: Props) => {
  const [step, setStep] = useState(0);

  const stepRef = useRef(0);

  const history = useHistory();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT_ADMIN.LOGIN });

  const renderText = () => {
    switch (step) {
      case 1:
        return "Planting trees...";
      case 2:
        return "Hanging your banners...";
      case 3:
        return "Finishing touches...";
      case 4:
        return "Done!";
      default:
        return "Creating your world...";
    }
  };

  useEffect(() => {
    const incrementProgress = setInterval(() => {
      if (stepRef.current < 4) {
        setStep(stepRef.current + 1);
        stepRef.current = stepRef.current + 1;
      } else {
        clearInterval(incrementProgress);
        setTimeout(() => {
          setCurrentStep(3);
          setStepsCompleted(true);
        }, 500);
      }
    }, 2500);
  }, []);

  return (
    <Container>
      <div>
        <Title>Preparing venue</Title>
        <LoginLink>
          Already Registered?&nbsp;
          <TextLink type="button" onClick={() => history.push(LOGIN)}>
            Log In
          </TextLink>
        </LoginLink>
        <Bar>
          <Progress step={step} />
        </Bar>
        <Text>{renderText()}</Text>
      </div>
      <BottomText>
        Looking for a custom venue?
        <br />
        We’ve got you covered!&nbsp;&nbsp;
        <ContactLink
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
          target="_blank"
        >
          Let's chat.
        </ContactLink>
      </BottomText>
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "calc(100vh - 280px)",
  [media(Breakpoint.sm)]: {
    height: "calc(100vh - 300px)",
  },
});

const Bar = styled.div({
  height: 40,
  width: "90%",
  borderRadius: 20,
  backgroundColor: COLORS.grey,
  overflow: "hidden",
  margin: "30px auto 0",
  [media(Breakpoint.sm)]: {
    width: 340,
    marginTop: 40,
  },
});

const Progress = styled.div<{ step: number }>(({ step }) => ({
  height: "100%",
  width: `${step < 4 ? step * 27 : 100}%`,
  borderRadius: 20,
  backgroundColor: COLORS.green,
  transition: "width 1s ease-out",
}));

const Text = styled.div({
  fontWeight: 600,
  marginTop: 30,
});

const BottomText = styled.div({
  fontStyle: "italic",
  marginTop: 50,
  fontSize: 18,
});

const ContactLink = styled.a({
  textDecoration: "underline",
  fontStyle: "normal",
  "&:visited": {
    color: COLORS.lightBlack,
  },
});
