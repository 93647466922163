/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Col, Container, Row } from "@mverissimoo/emotion-grid";
import Layout from "shared/components/Layout";
import { H4, MontserratRegular } from "shared/components/Text";
import { accentDarkPurple, primaryDarkGrey } from "shared/styles/colors";

const NotFoundScreen = () => {
  return (
    <Layout hasNav={false}>
      <Container css={{ flex: 1 }}>
        <Row justify="center" align="center" css={{ height: "100%" }}>
          <Col xs={7} sm={6} md={6} lg={4} xl={4}>
            <h1
              css={[
                MontserratRegular,
                {
                  marginTop: 50,
                  marginBottom: 28,
                  textAlign: "center",
                  fontSize: 100,
                  color: accentDarkPurple,
                },
              ]}
            >
              404
            </h1>
            <H4
              css={{
                textAlign: "center",
                marginBottom: 28,
                color: primaryDarkGrey,
              }}
            >
              Sorry, the page you tried cannot be found.
            </H4>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFoundScreen;
