import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  GET_EVENT,
  CURRENT_GUEST,
  GET_DEMO_EVENT_DATA,
} from "./queries.threeJs";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import moment from "moment";
import { socket } from "services/SocketIo/SocketIOService";
import { ROUTES } from "@virtualfest/common";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { IntercomProvider } from "react-use-intercom";
import Loading from "shared/components/Loading";
import Canvas from "./Canvas";
import GuestLogin from "./Auth/GuestLogin";
import GuestRegister from "./Auth/GuestRegister";
import VerifyEmail from "./Auth/VerifyEmail";
import RequireGuestList from "./Auth/RequireGuestList";
import BlockedUser from "./Auth/BlockedUser";
import InvalidSlugScreen from "./InvalidSlugScreen";

const EVENT_ADMIN_PLACEHOLDER_USER_INFO = {
  id: 0,
  name: "",
  avatarConfig: {},
  asEventAdmin: true,
};

const ThreeJS = () => {
  const [eventData, setEventData] = useState<any>({});
  const [eventLoading, setEventLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isOnGuestList, setIsOnGuestList] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});

  const history = useHistory();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const {
    ROOT: BASE_ROOT,
    REGISTER: BASE_REGISTER,
    LOGIN: BASE_LOGIN,
  } = ROUTES.EVENT;

  if (eventSlug === "undefined" || eventSlug === ":eventSlug") {
    history.replace(BASE_REGISTER.replace(":eventSlug", "yeti-university"));
  }

  const { ROOT }: any = useEventSlug({ ROOT: ROUTES.EVENT.ROOT });

  const searchParams = useSearchParams();
  let adminEmail = searchParams.get("adminEmail");
  if (adminEmail?.includes(" ")) {
    adminEmail = adminEmail.replace(/ /g, "+");
  }

  useQuery(GET_EVENT, {
    variables: { eventSlug },
    onCompleted: (data) => {
      setEventData({
        ...data.getEvent.event,
        booths: [...data.getEvent.booths],
      });

      if (data.getEvent.event.isDemo) {
        getDemoEventData();
      } else {
        setEventLoading(false);
      }
    },
  });

  const [currentGuest, { loading: guestLoading }] = useLazyQuery(
    CURRENT_GUEST,
    {
      onCompleted: (data) => {
        setSignedIn(true);
        setEmailVerified(data.currentGuest.hasVerifiedEmail);
        setUserInfo(data.currentGuest);
        history.replace(ROOT);
      },
    }
  );

  const [getDemoEventData] = useLazyQuery(GET_DEMO_EVENT_DATA, {
    onCompleted: (data) => {
      setEventData({
        ...eventData,
        mainStageMediaUrl: data.demoEventData.mainStageMediaUrl,
        liveStreamEmbedUrl: data.demoEventData.liveStreamEmbedUrl,
        booths: data.demoEventData.booths,
        customText: data.demoEventData.customText ?? {},
        eventScheduleItems: data.demoEventData.eventScheduleItems,
      });
      setEventLoading(false);
    },
  });

  useEffect(() => {
    const token = CookieService.getCookie(Identifiers.AccessToken);
    if (token) {
      currentGuest();
    }
  }, []);

  useEffect(() => {
    if (userInfo && eventData) {
      if (eventData.admin?.blockedGuestIds.includes(userInfo.id)) {
        setIsBlocked(true);
      }
    }
  }, [userInfo, eventData]);

  const now = new Date();
  const nowEst = now.toLocaleDateString("en-US", { timeZone: "EST" });
  const dateEst = moment(nowEst).format("LL");
  const isEventDay = dateEst === "March 21, 2021";
  const isEventTime = isEventDay && now.getUTCHours() === 20;

  const isBlockQuad = window.location.hostname === "EXAMPLE" && !isEventDay;

  useEffect(() => {
    // For socket.io listeners to pass live info to the virtual world

    return () => {
      socket.close();
    };
  }, []);

  if (eventLoading || guestLoading) return <Loading />;

  const renderContent = () => {
    switch (true) {
      case !eventData:
        return <InvalidSlugScreen />;
      case eventData.adminEmail === adminEmail:
        return (
          <Canvas
            userInfo={EVENT_ADMIN_PLACEHOLDER_USER_INFO}
            setUserInfo={setUserInfo}
            isEventTime={isEventTime}
            eventData={eventData}
          />
        );
      case !signedIn:
        return (
          <Switch>
            <Route
              path={[BASE_ROOT, BASE_REGISTER]}
              exact
              render={() => (
                <GuestRegister
                  eventData={eventData ?? {}}
                  setSignedIn={setSignedIn}
                />
              )}
            />
            <Route
              path={BASE_LOGIN}
              exact
              render={() => (
                <GuestLogin
                  setSignedIn={setSignedIn}
                  setEmailVerified={setEmailVerified}
                  setUserInfo={setUserInfo}
                />
              )}
            />
            <Route render={() => <Redirect to={ROOT} />} />
          </Switch>
        );
      case !emailVerified:
        return <VerifyEmail userType="GUEST" />;
      case isBlocked:
        return <BlockedUser />;
      case eventData.enforceGuestList && !isOnGuestList:
        return (
          <RequireGuestList
            guestEmail={userInfo.email}
            setIsOnGuestList={setIsOnGuestList}
          />
        );
      default:
        return (
          <Canvas
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            isEventTime={isEventTime}
            eventData={eventData}
          />
        );
    }
  };

  return (
    <IntercomProvider
      appId={
        (eventData.intercomId || process.env.REACT_APP_DEFAULT_INTERCOM_ID) ??
        ""
      }
    >
      {renderContent()}
    </IntercomProvider>
  );
};

export default ThreeJS;
