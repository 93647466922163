import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, Observable } from "apollo-link";
import { onError } from "apollo-link-error";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(
      ({
        message,
        locations,
        path,
        nodes,
        source,
        originalError,
        extensions,
        // eslint-disable-next-line array-callback-return
      }) => {
        console.groupCollapsed(`%c GraphQL Error: `, "color: #F64744", message);
        console.log(
          `%c Location: `,
          "font-weight: bold",
          JSON.stringify(locations)
        );
        console.log(`%c Message: `, "font-weight: bold", message);
        if (extensions?.exception?.stacktrace) {
          console.log(
            `%c Stacktrace: `,
            "font-weight: bold",
            extensions.exception.stacktrace
          );
        }
        console.log(`%c Nodes: `, "font-weight: bold", nodes);
        console.log(`%c Source: `, "font-weight: bold", source);
        console.log(`%c Original Error: `, "font-weight: bold", originalError);
        console.log(`%c Path: `, "font-weight: bold", path);
        console.groupEnd();
      }
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const request = async (operation) => {
  const token = CookieService.getCookie(Identifiers.AccessToken);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
  link: ApolloLink.from([
    errorLink,
    requestLink,
    createUploadLink({
      uri: `${process.env.REACT_APP_BACKEND_API_URL}/api/graphql`,
      // credentials: "same-origin",
    }),
  ]),
  cache: new InMemoryCache(),
});

export default client;
