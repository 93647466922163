import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { BASE_Z_INDEX } from "shared/constants";
import Responsive from "shared/components/Responsive";
import Loading from "shared/components/Loading";
import { useIntercom } from "react-use-intercom";

const Intercom = () => {
  const [booted, setBooted] = useState(false);

  const checkBooted = useRef<any>();

  const { boot, shutdown, show } = useIntercom();

  useEffect(() => {
    boot({
      alignment: "right",
    });

    show();

    let intercomApp = document.querySelector(".intercom-app") as HTMLElement;

    checkBooted.current = setInterval(() => {
      if (intercomApp) {
        setBooted(true);
        clearInterval(checkBooted.current);
      } else {
        intercomApp = document.querySelector(".intercom-app") as HTMLElement;
      }
    }, 50);

    return () => {
      clearInterval(checkBooted.current);

      checkBooted.current = setInterval(() => {
        if (intercomApp) {
          shutdown();
          clearInterval(checkBooted.current);
        } else {
          intercomApp = document.querySelector(".intercom-app") as HTMLElement;
        }
      }, 10);
    };
  }, []);

  useEffect(() => {
    if (!booted) return;

    const intercomApp = document.querySelector(".intercom-app") as HTMLElement;
    const intercomWindow = intercomApp?.firstChild as HTMLElement;

    const onResize = () => {
      if (window.innerWidth < 540) {
        intercomWindow.style.right = "0px";
        intercomWindow.style.width = "calc(100% - 50px)";
        intercomWindow.style.margin = "0 auto";
      } else {
        intercomWindow.style.width = "376px";
        intercomWindow.style.right = "21px";
      }
    };

    if (intercomWindow) {
      intercomWindow.style.transitionDuration = "0s";
      intercomWindow.style.height = "calc(100% - 170px)";
      intercomWindow.style.position = "absolute";
      intercomWindow.style.top = "75px";
      intercomWindow.style.border = `2px solid ${COLORS.grey}`;
      intercomWindow.style.boxShadow = "none";
      onResize();

      window.addEventListener("resize", onResize);

      setTimeout(() => {
        intercomWindow.style.zIndex = `${BASE_Z_INDEX}`;
      }, 100);
    }

    const handleArrowKeyDown = (e) => {
      if ([37, 38, 39, 40].includes(e.keyCode)) {
        window.focus();
      }
    };

    const intercomMessengerFrame = document.getElementsByName(
      "intercom-messenger-frame"
    )[0] as HTMLIFrameElement;

    intercomMessengerFrame?.contentDocument?.body.addEventListener(
      "keydown",
      handleArrowKeyDown
    );

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [booted]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default Intercom;

const Container = styled.div({
  height: "100%",
  backgroundColor: COLORS.white,
});
