import React from "react";
import styled from "@emotion/styled";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

interface Props {
  children: any;
  minSize?: string;
  maxSize?: string;
}

const Responsive = ({ children, minSize, maxSize }: Props) => {
  return (
    <ResponsiveDisplay minSize={minSize} maxSize={maxSize}>
      {children}
    </ResponsiveDisplay>
  );
};

export default Responsive;

const ResponsiveDisplay = styled.div<{ minSize?: string; maxSize?: string }>(
  ({ minSize, maxSize }) => ({
    display: !minSize || minSize === "xs" ? "initial" : "none",
    [media(Breakpoint.sm)]: {
      display:
        (!minSize || ["xs", "sm"].includes(minSize)) &&
        (!maxSize || ["sm", "md", "lg", "xl"].includes(maxSize))
          ? "initial"
          : "none",
    },
    [media(Breakpoint.md)]: {
      display:
        (!minSize || ["xs", "sm", "md"].includes(minSize)) &&
        (!maxSize || ["md", "lg", "xl"].includes(maxSize))
          ? "initial"
          : "none",
    },
    [media(Breakpoint.lg)]: {
      display:
        (!minSize || ["xs", "sm", "md", "lg"].includes(minSize)) &&
        (!maxSize || ["lg", "xl"].includes(maxSize))
          ? "initial"
          : "none",
    },
    [media(Breakpoint.xl)]: {
      display: !maxSize || maxSize === "xl" ? "initial" : "none",
    },
  })
);
