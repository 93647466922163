/** @jsx jsx */
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { media } from "@mverissimoo/emotion-grid";
import { Link } from "react-router-dom";
import { Breakpoint } from "shared/types";
import {
  primaryBlack,
  primaryDarkGrey,
  accentLightPurple,
} from "shared/styles/colors";

const MontserratFontFamily = "Montserrat";

export const MontserratRegular = css({
  fontFamily: MontserratFontFamily,
  fontWeight: 400,
});

export const MontserratMedium = css({
  fontFamily: MontserratFontFamily,
  fontWeight: 500,
});

export const MontserratSemibold = css({
  fontFamily: MontserratFontFamily,
  fontWeight: 600,
});

const WorkSansFontFamily = "Work Sans";

export const WorkSansRegular = css({
  fontFamily: WorkSansFontFamily,
  fontWeight: 400,
});

export const WorkSansMedium = css({
  fontFamily: WorkSansFontFamily,
  fontWeight: 500,
});

export const WorkSansSemibold = css({
  fontFamily: WorkSansFontFamily,
  fontWeight: 600,
});

export const WorkSansBold = css({
  fontFamily: WorkSansFontFamily,
  fontWeight: 700,
});

export const H1 = styled.h1([
  MontserratRegular,
  {
    fontSize: 45,
    lineHeight: 1.24,
    [media(Breakpoint.md)]: {
      fontSize: 55,
    },
  },
]);

export const H2 = styled.h2([
  MontserratRegular,
  {
    fontSize: 32,
    [media(Breakpoint.md)]: {
      fontSize: 42,
    },
  },
]);

export const H3 = styled.h3([
  MontserratRegular,
  {
    fontSize: 27,
    [media(Breakpoint.md)]: {
      fontSize: 37,
    },
  },
]);

export const H4 = styled.h4([
  MontserratMedium,
  {
    fontSize: 18,
    lineHeight: 1.38,
    [media(Breakpoint.md)]: {
      fontSize: 28,
    },
  },
]);

export const Caption = styled.p([
  MontserratSemibold,
  {
    fontSize: 15,
    // lineHeight: Says 26px in Style Guide but that's clearly not accurate
    [media(Breakpoint.md)]: {
      fontSize: 16,
    },
  },
]);

export const CaptionSmall = styled.p([
  MontserratSemibold,
  {
    fontSize: 14,
    // lineHeight: Says 26px in Style Guide but that's clearly not accurate
    [media(Breakpoint.md)]: {
      fontSize: 15,
    },
  },
]);

export const LinkText = styled.p([
  WorkSansMedium,
  {
    fontSize: 14,
  },
]);

export const Body = styled.p([
  WorkSansRegular,
  {
    fontSize: 14,
    lineHeight: 1.5,
    [media(Breakpoint.md)]: {
      fontSize: 16,
      lineHeight: "1.625",
    },
  },
]);

export const PoppinsSemiBold = css({
  fontFamily: "Poppins, sans-serif",
  fontWeight: 600,
});

export const PoppinsBold = css({
  fontFamily: "Poppins, sans-serif",
  fontWeight: 700,
});

export const PoppinsRegular = css({
  fontFamily: "Poppins, sans-serif",
  fontWeight: 400,
});
