import React, { useState, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { useEventSlug } from "shared/hooks/useEventSlug";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import { Title, Button, TextLink, LoginLink } from "./Onboarding";
import { ROUTES } from "@virtualfest/common";
import Spacer from "shared/components/Spacer";
import AWSService from "services/AWS/AWSService";
import CropPopup from "./CropPopup";

interface Props {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  adminName: string;
  orgName: string;
  bannerImageUrl: string;
  setBannerImageUrl: Dispatch<SetStateAction<string>>;
}

export const MAX_ASPECT_RATIO = 5;
export const MIN_ASPECT_RATIO = 3;
export const MIN_SIZE = 512;

const Banner = ({
  setCurrentStep,
  adminName,
  orgName,
  bannerImageUrl,
  setBannerImageUrl,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [cropPopupOpen, setCropPopupOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File | Blob | null>(null);

  const history = useHistory();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT_ADMIN.LOGIN });

  const handleBannerImageSelect = async (e) => {
    setIsUploading(true);

    if (e.target.validity.valid) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);

      let img: HTMLImageElement | null = new Image();

      img.onload = async () => {
        if (img) {
          const aspectRatio = img.width / img.height;

          setBannerImageUrl(url);
          setImageFile(null);

          if (img.width < MIN_SIZE) {
            setErrorMessage("Image is too small");
          } else if (aspectRatio > MAX_ASPECT_RATIO) {
            setCropPopupOpen(true);
            setErrorMessage("Incorrect aspect ratio - Image is too wide");
          } else if (aspectRatio < MIN_ASPECT_RATIO) {
            setCropPopupOpen(true);
            setErrorMessage("Incorrect aspect ratio - Image is too narrow");
          } else {
            setImageFile(file);
            setErrorMessage("");

            URL.revokeObjectURL(img.src);
            img = null;
          }

          setIsUploading(false);
        }
      };

      img.src = url;
    } else {
      setErrorMessage("File invalid");
      setIsUploading(false);
    }
  };

  const handleCreateWorld = async () => {
    if (imageFile) {
      setIsUploading(true);
      const location = await AWSService.uploadOnboardingBanner(imageFile);
      setBannerImageUrl(location);
      setIsUploading(false);
    }

    setCurrentStep(2);
  };

  const handleSkip = () => {
    setBannerImageUrl("");
    setCurrentStep(2);
  };

  const handleRemove = () => {
    setBannerImageUrl("");
    setImageFile(null);
  };

  return (
    <>
      {cropPopupOpen && (
        <CropPopup
          imageUrl={bannerImageUrl}
          setBannerImageUrl={setBannerImageUrl}
          setCropPopupOpen={setCropPopupOpen}
          setErrorMessage={setErrorMessage}
          setImageFile={setImageFile}
        />
      )}
      <Title>Welcome!</Title>
      <div>
        <b>Nice to meet you {adminName ?? orgName}!</b>
        <Spacer height={5} />
        Before we create your venue, do you or your organization have a logo?
        <br />
        <br />
      </div>
      <Text>
        Images should be landscape with a roughly 4:1 aspect ratio and at least
        512 pixels wide.
      </Text>
      <LoginLink>
        Already Registered?&nbsp;
        <TextLink type="button" onClick={() => history.push(LOGIN)}>
          Log In
        </TextLink>
      </LoginLink>
      {imageFile && (
        <BannerPreview>
          <PreviewImage src={bannerImageUrl} alt="Preview" />
          <BannerRemoveIcon onClick={handleRemove}>
            <RemoveIcon />
          </BannerRemoveIcon>
        </BannerPreview>
      )}
      <BannerUpload>
        <Label>
          <UploadButton disabled={isUploading}>
            Upload Banner Image
          </UploadButton>
          <HiddenInput
            type="file"
            required
            accept="image/*"
            onChange={(e) => handleBannerImageSelect(e)}
          />
        </Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </BannerUpload>
      {imageFile && (
        <Button
          type="button"
          disabled={isUploading}
          onClick={handleCreateWorld}
        >
          Create World
        </Button>
      )}
      <div>
        <SkipLink type="button" onClick={handleSkip}>
          Skip and add later
        </SkipLink>
      </div>
    </>
  );
};

export default Banner;

const Text = styled.div({
  maxWidth: 500,
  margin: "0 auto",
});

const BannerPreview = styled.div({
  position: "relative",
});

const PreviewImage = styled.img({
  width: "90%",
  marginTop: 30,
  [media(Breakpoint.sm)]: {
    height: 120,
    width: "auto",
  },
});

const BannerRemoveIcon = styled(IconButton)({
  position: "absolute",
  color: COLORS.red,
  padding: 5,
  bottom: 5,
  right: -25,
  [media(Breakpoint.sm)]: {
    right: -45,
  },
});

const UploadButton = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.blue,
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: 0.2,
  border: `2px solid ${COLORS.blue}`,
  backgroundColor: COLORS.lightGrey,
  borderRadius: 5,
  height: 55,
  width: 220,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));

const BannerUpload = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "30px 0 5px",
});

const Label = styled.label({
  display: "inline-block",
});

const ErrorMessage = styled.div({
  color: COLORS.red,
  fontSize: 14,
  lineHeight: "14px",
  height: 14,
  marginTop: 20,
});

const HiddenInput = styled.input({
  display: "none",
});

const SkipLink = styled.button({
  display: "inline-block",
  color: COLORS.darkGrey,
  marginTop: 25,
  fontWeight: 600,
  "&:hover": {
    textDecoration: "underline",
  },
});
