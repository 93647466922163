import React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import {
  ReturnBar,
  ReturnButton,
  Container,
  Navbar,
  NavbarLink,
  NavbarLinkIcon,
} from "../EventAdmin";
import guestActive from "../images/GuestsActive.svg";
import guestInactive from "../images/Guests.svg";
import CustomerPortal from "./CustomerPortal";

interface Props {}

const UserAccount = ({}: Props) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const {
    ROOT: BASE_ROOT,
    USER_ACCOUNT: { ROOT, CUSTOMER_PORTAL },
  } = ROUTES.EVENT_ADMIN;

  return (
    <>
      <ReturnBar>
        <ReturnButton
          type="button"
          onClick={() =>
            history.push(BASE_ROOT.replace(":eventSlug", "default"))
          }
        >
          Back To Events
        </ReturnButton>
      </ReturnBar>

      <Container>
        {/* Desktop Side Navbar */}
        <Navbar>
          <NavbarLink
            type="button"
            current={pathname === ROOT || pathname === CUSTOMER_PORTAL}
            onClick={() => history.push(CUSTOMER_PORTAL)}
          >
            <NavbarLinkIcon
              imageUrl={
                pathname === ROOT || pathname === CUSTOMER_PORTAL
                  ? guestActive
                  : guestInactive
              }
              current={pathname === ROOT || pathname === CUSTOMER_PORTAL}
            />
            Plan Management
          </NavbarLink>
        </Navbar>

        {/* Main Content */}
        <Switch>
          <Route
            path={[ROOT, CUSTOMER_PORTAL]}
            exact
            render={() => <CustomerPortal />}
          />
        </Switch>
      </Container>
    </>
  );
};

export default UserAccount;
