import gql from "graphql-tag";

export const CREATE_BOOTH = gql`
  mutation CreateBooth($data: BoothCreateInput!) {
    createOneBooth(data: $data) {
      id
      name
      boothDisplayName
      description
      contactEmails
      bannerImageUrl
      zoomUrl
      mediaUrls
      facebookHandle
      instagramHandle
      twitterHandle
      youtubeHandle
      tiktokHandle
      externalLinkUrl
      externalLinkDisplayText
      adminEmail
      event {
        id
        name
        startDateTime
        endDateTime
      }
      eventSlug
    }
  }
`;

export const UPDATE_BOOTH = gql`
  mutation UpdateBooth($boothId: Int!, $data: BoothUpdateInput!) {
    updateOneBooth(where: { id: $boothId }, data: $data) {
      id
      name
      boothDisplayName
      description
      contactEmails
      bannerImageUrl
      zoomUrl
      mediaUrls
      facebookHandle
      instagramHandle
      twitterHandle
      youtubeHandle
      tiktokHandle
      externalLinkUrl
      externalLinkDisplayText
      adminEmail
      event {
        id
        name
        startDateTime
        endDateTime
      }
      eventSlug
    }
  }
`;
