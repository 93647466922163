import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { ROUTES } from "@virtualfest/common";
import { Breakpoint } from "shared/types";
import menuButton from "./images/menu-button.svg";
import soundOnIcon from "./images/sound-on.svg";
import soundOffIcon from "./images/sound-off.svg";
import backgroundMusic from "./audio/quad-ambience4.mp3";
import instructionsButton from "./images/Instructions_button.svg";
import chatSendButton from "./images/chat_send.svg";
import "./canvas.styles.css";
import { ChatMessagesType, CHAT_MESSAGE_TYPES } from "@virtualfest/common";
import { socket } from "services/SocketIo/SocketIOService";
import { buildEventSchedule } from "shared/utils";
import _ from "lodash";
import Filter from "bad-words";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import nipplejs from "nipplejs";
import { CSS2DRenderer, CSS2DObject } from "./CSS2DRenderer";
import {
  C,
  PLAYER_SPEED,
  FACES,
  CENTER_OF_QUAD,
  SKINS,
  BOOTH_POSITIONS,
  BODY_PART_MAP,
  LARGE_EMOJIS,
  SMALL_EMOJIS,
  PLAYER_CREATOR,
  BOUNDS,
  BANNER_NAMES,
  SIGN_NAMES,
} from "./constants";
import {
  createImageMat,
  swapFace,
  swapSkin,
  addControls,
  getPointInBetweenByLen,
  getPointInBetweenByPerc,
  wrapText,
  GetBoothTextSize,
} from "./utils";
import Instructions from "./Instructions/Instructions";
import Sidebar from "./Sidebar/Sidebar";
import BoothPopup from "./BoothPopup";
import ProfilePopup from "./ProfilePopup";
import Notification from "./Notification";
import InviteGuestModal from "./InviteGuestModal";
import LiveStream from "./LiveStream";
import EventStatusMessage from "./EventStatus/EventStatusMessage";

interface Props {
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
  eventData: any;
  isEventTime: boolean;
}

const Canvas = ({ userInfo, setUserInfo, eventData }: Props) => {
  const [enteredQuad, setEnteredQuad] = useState(false);
  const [walkingIntoBuilding, setWalkingIntoBuilding] = useState(false);
  const [initialEntry, setInitialEntry] = useState(true);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarActiveTab, setSidebarActiveTab] = useState(0);
  const [boothPopup, setBoothPopup] = useState<{
    open: boolean;
    boothId?: number;
  }>({ open: false });
  const [profilePopup, setProfilePopup] = useState<{
    open: boolean;
    guestId?: number;
  }>({ open: false });
  const [notification, setNotification] = useState<{
    open: boolean;
    content: any;
  }>({ open: false, content: { headline: "", message: "" } });
  const [currentEmoji, setCurrentEmoji] = useState(
    "../ThreeJS/texture/red-heart.png"
  );
  const [liveStreamOpen, setLiveStreamOpen] = useState(false);
  const [emojiMenuExpanded, setEmojiMenuExpanded] = useState(false);
  const [publicMessage, setPublicMessage] = useState("");
  const [soundOn, setSoundOn] = useState(false);
  const [inviteGuestModalOpen, setInviteGuestModalOpen] = useState(false);
  const [threeJsFuncs, setThreeJsFuncs] = useState<any>({});
  const [chatMessages, setChatMessages] = useState<ChatMessagesType>({
    event: [],
    booth: {},
    direct: {},
  });
  const [activeChat, setActiveChat] = useState<{ type: string; id?: number }>({
    type: CHAT_MESSAGE_TYPES.EVENT,
  });
  const [hasUnreadMessage, setHasUnreadMessage] = useState(false);

  const sidebarRef = useRef({
    open: true,
    activeTab: 0,
  });
  const publicMessageRef = useRef("");
  const avatarConfigRef = useRef({
    body: userInfo.avatarConfig.body,
    face: userInfo.avatarConfig.face,
    top: userInfo.avatarConfig.top,
  });
  const chatMessagesRef = useRef<ChatMessagesType>({
    event: [],
    booth: {},
    direct: {},
  });

  const history = useHistory();

  const handleSidebarOpen = (open: boolean) => {
    setSidebarOpen(open);
    sidebarRef.current = {
      ...sidebarRef.current,
      open,
    };
  };

  const handleSidebarActiveTab = (tabIndex: number) => {
    setSidebarActiveTab(tabIndex);
    sidebarRef.current = {
      ...sidebarRef.current,
      activeTab: tabIndex,
    };
  };

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const {
    booths,
    eventScheduleItems,
    customText,
    bannerImageUrl,
    mainStageMediaUrl,
    liveStreamEmbedUrl,
    isDemo,
    startDateTime,
    endDateTime,
  } = eventData;

  const filter = new Filter();

  useEffect(() => {
    // Import Loader for GLB Models
    const Loader = new GLTFLoader();

    // TODO REVIEW ALL variables
    let camera;
    let newCameraPosition = new THREE.Vector3();
    let scene;
    let renderer;
    let labelRenderer;
    let controls;
    let controlsTarget = new THREE.Vector3();
    let directionFinal;
    let prevPosition = new THREE.Vector3();
    let targetPosition;
    let mixer;
    let mixer2;

    let introAnimationTarget = new THREE.Vector3();
    let playerEnterAnimationAction;
    let cloudsAnimation;

    let labelList: any[] = [];

    let userGuide;
    let screen, video;

    let insideQuad = false;
    let characterCreator = true;
    let walkingIntoBuilding = false;
    let isQuadLoaded = false;

    let PLAYER_MODEL;
    let player;
    let currentFace = userInfo.avatarConfig.face ?? 0;
    let currentSkin = userInfo.avatarConfig.body ?? 0;
    let currentTop = userInfo.avatarConfig.top ?? 0;

    let clock = new THREE.Clock(true);

    // Clickable booths
    const boothTargets: {
      id: number;
      name: string;
      group: THREE.Group;
    }[] = [];

    // Emoji/Message Options
    let emojiList: THREE.Sprite[] = [];
    const EMOJI_NAMES = LARGE_EMOJIS.concat(SMALL_EMOJIS);

    // Currently displayed emojis/messages
    let liveEmojis: THREE.Sprite[] = [];
    let livePublicMessages: {
      messageEl: any;
      playerId: string;
      duration: number;
    }[] = [];
    let movingPlayers: any = {};

    // To keep all players in a hash so their movement can be updated.
    const players = {};
    const MAX_PLAYERS = 50;

    let PlayerForward = false;
    let PlayerBackward = false;
    let PlayerLeft = false;
    let PlayerRight = false;
    let turnModifier = new THREE.Vector3();
    let RotateClockwise = false;
    let RotateCounterClockwise = false;
    let playerRotation = new THREE.Matrix4();

    // Mobile Joystick
    let options = {
      zone: document.querySelector("#joystick"),
      mode: "static",
      position: { bottom: "75px" },
    };

    //@ts-ignore
    let manager: any = nipplejs.create(options);

    manager.on("move", function (evt, data) {
      // This is the threshold that allows the player to move forward and not
      // rotate. If the absolute value of the  x position (0 - 1) on the
      // joystick is less than the threshold the player will not rotate, only
      // move forward or backwards.
      let forward_threshold = 0.7;
      let side_threshold = 0.6;

      if (data.vector.y > forward_threshold) {
        PlayerForward = true;
        PlayerBackward = false;
      } else if (data.vector.y < forward_threshold * -1) {
        PlayerBackward = true;
        PlayerForward = false;
      } else {
        PlayerBackward = false;
        PlayerForward = false;
      }

      if (data.vector.x > side_threshold) {
        PlayerRight = true;
        PlayerLeft = false;
      } else if (data.vector.x < side_threshold * -1) {
        PlayerLeft = true;
        PlayerRight = false;
      } else if (Math.abs(data.vector.x) <= side_threshold) {
        PlayerLeft = false;
        PlayerRight = false;
      }
    });

    manager.on("end", function (evt, data) {
      PlayerForward = false;
      PlayerBackward = false;
      PlayerLeft = false;
      PlayerRight = false;
      RotateClockwise = false;
      RotateCounterClockwise = false;
    });

    //
    // Function to setup the scene
    //
    function init() {
      // SETUP RENDERER
      renderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      const canvasEl = document.getElementById("canvas");
      if (canvasEl) canvasEl.appendChild(renderer.domElement);

      labelRenderer = new CSS2DRenderer();
      labelRenderer.setSize(window.innerWidth, window.innerHeight);
      labelRenderer.domElement.style.position = "absolute";
      labelRenderer.domElement.style.top = "0px";

      if (canvasEl) canvasEl.appendChild(labelRenderer.domElement);

      // Create Scene and add Background Color
      scene = new THREE.Scene();

      const CubeLoader = new THREE.CubeTextureLoader();

      const texture = CubeLoader.load([
        "../ThreeJS/texture/skybox/posx.jpg",
        "../ThreeJS/texture/skybox/negx.jpg",
        "../ThreeJS/texture/skybox/posy.jpg",
        "../ThreeJS/texture/skybox/negy.jpg",
        "../ThreeJS/texture/skybox/posz.jpg",
        "../ThreeJS/texture/skybox/negz.jpg",
      ]);
      scene.background = texture;

      // SETUP THE CAMERA
      camera = new THREE.PerspectiveCamera(
        50,
        window.innerWidth / window.innerHeight,
        0.01,
        1000
      );

      if (window.innerWidth < 540) {
        camera.position.set(
          PLAYER_CREATOR.x + PLAYER_CREATOR.camx,
          PLAYER_CREATOR.y + PLAYER_CREATOR.camy + 0.5,
          PLAYER_CREATOR.z + PLAYER_CREATOR.camz
        );

        camera.lookAt(
          PLAYER_CREATOR.x,
          PLAYER_CREATOR.y + 0.55,
          PLAYER_CREATOR.z
        );
      } else {
        camera.position.set(
          PLAYER_CREATOR.x + PLAYER_CREATOR.camx,
          PLAYER_CREATOR.y + PLAYER_CREATOR.camy,
          PLAYER_CREATOR.z + PLAYER_CREATOR.camz
        );

        camera.lookAt(
          PLAYER_CREATOR.x,
          PLAYER_CREATOR.y + 0.3,
          PLAYER_CREATOR.z
        );
      }

      const light = new THREE.AmbientLight(0xffffff, 0.61); // soft white light
      light.matrixAutoUpdate = false;
      light.updateMatrix();
      scene.add(light);

      const directionalLightTargetObject = new THREE.Object3D();
      directionalLightTargetObject.matrixAutoUpdate = false;
      directionalLightTargetObject.updateMatrix();
      scene.add(directionalLightTargetObject);

      const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.41);
      directionalLight1.position.set(BOUNDS.xmax, 15, BOUNDS.zmax);
      directionalLight1.target = directionalLightTargetObject;
      directionalLight1.matrixAutoUpdate = false;
      directionalLight1.updateMatrix();
      scene.add(directionalLight1);

      const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.51);
      directionalLight2.position.set(BOUNDS.xmin, 15, BOUNDS.zmin);
      directionalLight2.target = directionalLightTargetObject;
      directionalLight2.matrixAutoUpdate = false;
      directionalLight2.updateMatrix();
      scene.add(directionalLight2);

      targetPosition = new THREE.Vector3(
        C.PLAYER_START_X,
        C.PLAYER_START_Y,
        C.PLAYER_START_Z
      );

      // Load in the Quad Model
      Loader.load(
        "../ThreeJS/models/forest-world40.glb",
        function (gltf) {
          gltf.scene.scale.set(C.MOCK_SCALE, C.MOCK_SCALE, C.MOCK_SCALE);
          gltf.scene.position.set(0, C.MOCK_START_HEIGHT, 0);

          gltf.scene.matrixAutoUpdate = false;
          gltf.scene.updateMatrix();

          scene.add(gltf.scene);

          mixer = new THREE.AnimationMixer(gltf.scene);
          for (let i = 0; i < gltf.animations.length; i++) {
            playerEnterAnimationAction = mixer.clipAction(gltf.animations[i]);
            playerEnterAnimationAction.play();
            playerEnterAnimationAction.loop = THREE.LoopOnce;
            playerEnterAnimationAction.clampWhenFinished = true;
          }

          for (let i = 0; i < gltf.scene.children.length; i++) {
            if (gltf.scene.children[i].name === "follow") {
              userGuide = gltf.scene.children[i];
              userGuide.visible = false;
            }

            if (BANNER_NAMES.includes(gltf.scene.children[i].name)) {
              let texture;

              if (bannerImageUrl) {
                texture = new THREE.TextureLoader().load(bannerImageUrl);
              } else {
                const temp_canvas = document.createElement("canvas");
                temp_canvas.height = 600;
                temp_canvas.width = 2400;

                const ctx = temp_canvas.getContext("2d", { alpha: false });

                const fontSize = 10 * Math.min(18, 900 / eventData.name.length);

                //@ts-ignore
                ctx.fillStyle = COLORS.green;
                //@ts-ignore
                ctx.fillRect(0, 0, temp_canvas.width, temp_canvas.height);
                //@ts-ignore
                ctx.font = `bold ${fontSize}px Poppins, sans-serif`;
                //@ts-ignore
                ctx.fillStyle = "white";
                //@ts-ignore
                ctx.textAlign = "center";
                //@ts-ignore
                ctx.textBaseline = "middle";
                //@ts-ignore
                wrapText(
                  ctx,
                  eventData.name,
                  temp_canvas.width / 2,
                  temp_canvas.height / 2 + 10,
                  temp_canvas.width * 0.85,
                  fontSize * 1.25
                );

                texture = new THREE.CanvasTexture(temp_canvas);
              }

              texture.flipY = false;

              //@ts-ignore
              gltf.scene.children[i].material.map = texture;
            }

            gltf.scene.children = gltf.scene.children.filter(
              (child) => !SIGN_NAMES.includes(child.name)
            );

            if (gltf.scene.children[i].name === "video") {
              screen = gltf.scene.children[i];

              // TODO handle both videos or images uploaded by the event admin

              if (mainStageMediaUrl) {
                video = document.createElement("video");
                video.crossOrigin = "anonymous";
                video.src = eventData.mainStageMediaUrl;
                video.autoplay = true;
                video.loop = true;
                video.playsInline = true;
                video.muted = true;
                video.load(); // must call after setting/changing source

                let videoTexture = new THREE.VideoTexture(video);
                videoTexture.minFilter = THREE.LinearFilter;
                videoTexture.magFilter = THREE.LinearFilter;
                videoTexture.wrapS = THREE.RepeatWrapping;
                videoTexture.repeat.x = -1;
                const videoMaterial = new THREE.MeshBasicMaterial({
                  map: videoTexture,
                  side: THREE.DoubleSide,
                  toneMapped: false,
                });

                //@ts-ignore
                screen.material = videoMaterial;
              } else if (liveStreamEmbedUrl) {
                const temp_canvas = document.createElement("canvas");

                const ctx = temp_canvas.getContext("2d", { alpha: false });
                //@ts-ignore
                ctx.fillStyle = COLORS.black;
                //@ts-ignore
                ctx.fillRect(0, 0, temp_canvas.width, temp_canvas.height);

                const texture = new THREE.CanvasTexture(temp_canvas);

                //@ts-ignore
                screen.material = new THREE.MeshBasicMaterial({ map: texture });
              } else {
                gltf.scene.children = gltf.scene.children.filter(
                  (child) => child.name !== "video"
                );
              }
            }
          }
          isQuadLoaded = true;
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );

      Loader.load(
        "../ThreeJS/models/clouds-animation.glb",
        function (gltf) {
          gltf.scene.scale.set(
            C.MOCK_SCALE * 2,
            C.MOCK_SCALE * 2,
            C.MOCK_SCALE * 2
          );
          gltf.scene.position.set(0, C.MOCK_START_HEIGHT + 10, 0);

          gltf.scene.matrixAutoUpdate = false;
          gltf.scene.updateMatrix();

          scene.add(gltf.scene);

          mixer2 = new THREE.AnimationMixer(gltf.scene);
          for (let i = 0; i < gltf.animations.length; i++) {
            cloudsAnimation = mixer2.clipAction(gltf.animations[i]);
            cloudsAnimation.play();
          }
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );

      // Load player model and configure
      Loader.load(
        "../ThreeJS/models/player-capsule.glb",
        function (gltf) {
          PLAYER_MODEL = gltf.scene;
          PLAYER_MODEL.scale.set(
            C.PLAYER_SCALE,
            C.PLAYER_SCALE,
            C.PLAYER_SCALE
          );

          player = PLAYER_MODEL.clone();
          player.name = userInfo.id;

          swapFace(player, currentFace);
          swapSkin(player, currentSkin);
          if (!userInfo.avatarConfig.top) toggleEars();

          player.position.set(
            PLAYER_CREATOR.x,
            PLAYER_CREATOR.y,
            PLAYER_CREATOR.z
          );
          player.rotation.y = Math.PI / -1.7;

          scene.add(player);

          // ADD GUYS TO BOOTHS
          for (let i = 0; i < booths.length; i++) {
            if (i >= BOOTH_POSITIONS.length) {
              break;
            }

            let booth_guy = player.clone();

            swapFace(booth_guy, Math.floor(Math.random() * FACES.length));
            swapSkin(booth_guy, Math.floor(Math.random() * SKINS.length));

            booth_guy.position.set(
              BOOTH_POSITIONS[i].guy_pos.x ?? BOOTH_POSITIONS[i].x,
              C.MOCK_START_HEIGHT + 0.1,
              BOOTH_POSITIONS[i].guy_pos.z ?? BOOTH_POSITIONS[i].z
            );

            booth_guy.rotation.y = BOOTH_POSITIONS[i].guy_rot;

            booth_guy.matrixAutoUpdate = false;
            booth_guy.updateMatrix();

            scene.add(booth_guy);
          }
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );

      Loader.load(
        "../ThreeJS/models/quad-outside-booth.glb",
        function (gltf) {
          gltf.scene.scale.set(C.BOOTH_SCALE, C.BOOTH_SCALE, C.BOOTH_SCALE);

          // CREATE BOOTHS
          for (let i = 0; i < booths.length; i++) {
            if (i >= BOOTH_POSITIONS.length) {
              break;
            }
            const booth = booths[i];
            const boothModel = gltf.scene.clone();

            boothModel.children.forEach((mesh) => {
              if (mesh.name == "boothsignback") {
                const boothBannerTexture = new THREE.TextureLoader().load(
                  booths[i].bannerImageUrl ||
                    "../ThreeJS/texture/default_booth_banner.png"
                );

                boothBannerTexture.flipY = false;

                //@ts-ignore
                mesh.material = new THREE.MeshLambertMaterial({
                  map: boothBannerTexture,
                });

                boothBannerTexture.dispose();
              } else if (mesh.name == "boothsignfront") {
                let temp_canvas = document.createElement("canvas");

                let ctx = temp_canvas.getContext("2d", { alpha: false });

                //@ts-ignore
                ctx.fillStyle = "#00707e";
                //@ts-ignore
                ctx.fillRect(0, 0, temp_canvas.width, temp_canvas.height);
                //@ts-ignore
                ctx.font = `bold ${GetBoothTextSize(
                  booths[i].boothDisplayName
                )}px arial`;
                //@ts-ignore
                ctx.fillStyle = "white";
                //@ts-ignore
                ctx.textBaseline = "middle";
                //@ts-ignore
                ctx.textAlign = "center";
                //@ts-ignore
                wrapText(
                  ctx,
                  booths[i].boothDisplayName,
                  temp_canvas.width / 2,
                  temp_canvas.height - 25,
                  temp_canvas.width * 0.8,
                  GetBoothTextSize(booths[i].boothDisplayName)
                );

                let TextTexture = new THREE.CanvasTexture(temp_canvas);
                TextTexture.flipY = false;

                //@ts-ignore
                mesh.material = new THREE.MeshBasicMaterial({
                  map: TextTexture,
                });
              }
            });

            boothModel.name = booth.id;

            boothModel.position.set(
              BOOTH_POSITIONS[i].x,
              C.MOCK_START_HEIGHT,
              BOOTH_POSITIONS[i].z
            );

            boothModel.rotation.y = BOOTH_POSITIONS[i].rot;

            boothModel.matrixAutoUpdate = false;
            boothModel.updateMatrix();

            boothTargets.push({
              id: booth.id,
              name: booth.name,
              group: boothModel,
            });

            scene.add(boothModel);
          }
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );

      for (let i = 0; i < EMOJI_NAMES.length; i++) {
        let emojiTexture = new THREE.TextureLoader().load(
          "../ThreeJS/texture/" + EMOJI_NAMES[i] + ".png"
        );
        let emojiMaterial = new THREE.SpriteMaterial({ map: emojiTexture });
        let emojiSprite = new THREE.Sprite(emojiMaterial);
        emojiSprite.scale.set(0.5, 0.5, 1);

        scene.add(emojiSprite);
        emojiSprite.visible = false;
        emojiList.push(emojiSprite);

        emojiTexture.dispose();
        emojiMaterial.dispose();
      }
    }

    window.addEventListener("resize", onWindowResize, false);

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    //
    // Animation Function (constantly running frame updates)
    //
    let radian = 0;

    function animate() {
      const delta = clock.getDelta();

      requestAnimationFrame(animate);

      // MAKE SURE OUR PLAYER HAS LOADED INTO THE GAME
      if (player) {
        playerRotation.makeRotationFromQuaternion(player.quaternion);

        if (mixer2) mixer2.update(delta);

        // INTRO ANIMATION
        if (!characterCreator && walkingIntoBuilding) {
          if (userGuide) {
            // Increase the delta to speed up the animation
            if (mixer) mixer.update(delta * 1.2);

            userGuide.getWorldPosition(introAnimationTarget);

            player.position.set(
              introAnimationTarget.x,
              introAnimationTarget.y - 0.2,
              introAnimationTarget.z
            );
            player.rotation.y = userGuide.rotation._y + 1.6;

            camera.position.set(
              introAnimationTarget.x - 3,
              introAnimationTarget.y + 1.2,
              introAnimationTarget.z
            );
            camera.lookAt(
              introAnimationTarget.x,
              introAnimationTarget.y + 0.5,
              introAnimationTarget.z
            );

            if (!playerEnterAnimationAction.isRunning()) {
              enterQuad();
            }
          } else {
            enterQuad();
          }
        }

        // MAIN ANIMATE FUNCTION
        if (!characterCreator && !walkingIntoBuilding) {
          controls.update();
          directionFinal = player.position
            .clone()
            .sub(camera.position)
            .normalize();
          directionFinal.y = 0;

          if (insideQuad) {
            controlsTarget.set(
              player.position.x,
              player.position.y + 1,
              player.position.z
            );
            controls.target = controlsTarget;

            // Determine whether booth is close enough for its label to be seen
            for (let i = 0; i < labelList.length; i++) {
              if (
                //@ts-ignore
                player.position.distanceTo(labelList[i].parent.position) < 17
              ) {
                //@ts-ignore
                labelList[i].visible = true;
              } else {
                //@ts-ignore
                labelList[i].visible = false;
              }
            }

            // Apply Player Movement
            const positionModifier = directionFinal
              .clone()
              .multiplyScalar(PLAYER_SPEED.FORWARD);

            if (PlayerForward) {
              targetPosition.add(positionModifier);
            }
            if (PlayerBackward) {
              targetPosition.add(positionModifier.multiplyScalar(-1));
            }
            if (PlayerLeft) {
              turnModifier.set(positionModifier.z, 0, positionModifier.x * -1);
              targetPosition.add(turnModifier);
            }
            if (PlayerRight) {
              turnModifier.set(positionModifier.z * -1, 0, positionModifier.x);
              targetPosition.add(turnModifier);
            }
          }

          // Animate Camera Rotation
          // The snapping that is being caused during the player rotation from
          // the orbit controls taking over
          // There is probably a way to keep updating it for a bit after someone
          // moves and takes their finger off the key, essentailly what needs
          // to be added is a rotation momentum thing that is also sent by key
          // movements not just the mouse movements

          // Math for rotating by keypress
          let radius = 3;
          let cameraDegrees = 0;

          if (RotateClockwise) {
            radian += PLAYER_SPEED.ROTATION;
          } else if (RotateCounterClockwise) {
            radian -= PLAYER_SPEED.ROTATION;
          } else {
            cameraDegrees = angleOf(directionFinal.x, directionFinal.z, 0, 0);
            radian = cameraDegrees * (Math.PI / 180) * -1;
          }

          let new_x = radius * Math.cos(radian);
          let new_y = radius * Math.sin(radian);
          newCameraPosition.set(
            player.position.x + new_x,
            camera.position.y,
            player.position.z + new_y
          );

          if (camera.position.distanceTo(newCameraPosition) > 0.01) {
            camera.position.lerp(newCameraPosition, 0.5);
          }

          // Animate Player Movement
          if (player.position.distanceTo(targetPosition) > 0.1) {
            player.position.lerp(targetPosition, PLAYER_SPEED.ACCELERATION);

            updateRotation(
              player,
              player.position.distanceTo(prevPosition) > 0.01
            );

            prevPosition.set(
              player.position.x,
              player.position.y,
              player.position.z
            );
          }

          // Prevent player from leaving arena
          if (
            player.position.x > CENTER_OF_QUAD.x + BOUNDS.xmax ||
            player.position.x < CENTER_OF_QUAD.x + BOUNDS.xmin ||
            player.position.z > CENTER_OF_QUAD.z + BOUNDS.zmax ||
            player.position.z < CENTER_OF_QUAD.z + BOUNDS.zmin
          ) {
            insideQuad = false;
            targetPosition = getPointInBetweenByPerc(
              player.position,
              CENTER_OF_QUAD,
              0.01
            );
          } else {
            insideQuad = true;
          }
        }
      }

      // Animate vanishing emojis/messages
      if (liveEmojis.length > 0) {
        updateEmojis(delta);
      }

      if (livePublicMessages.length > 0) {
        updatePublicMessages();
      }

      // Animate Other Players' Movements
      if (Object.values(movingPlayers).length > 0) {
        for (let socketId in movingPlayers) {
          const { player, newRotation, targetPosition } =
            movingPlayers[socketId];

          player.position.lerp(
            targetPosition,
            PLAYER_SPEED.ACCELERATION * 0.03
          );

          player.quaternion.setFromRotationMatrix(newRotation);

          if (player.position.distanceTo(targetPosition) < 1) {
            delete movingPlayers[socketId];
          }
        }
      }

      renderer.render(scene, camera);
      labelRenderer.render(scene, camera);
    }

    function angleOf(p1x, p1y, p2x, p2y) {
      // NOTE: Remember that most math has the Y axis as positive above the X.
      // However, for screens we have Y as positive below. For this reason,
      // the Y values are inverted to get the expected results.
      const deltaY = p1y - p2y;
      const deltaX = p2x - p1x;
      const result = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      return result < 0 ? 360 + result : result;
    }

    let PosToLookTo = new THREE.Vector3();

    function updateRotation(user, moving) {
      const p = user.position.clone();

      // This locks character movement
      if (moving) {
        p.sub(camera.position);
        PosToLookTo.set(
          user.position.x + p.x,
          user.position.y,
          user.position.z + p.z
        );
      }
      user.lookAt(PosToLookTo);
    }

    function updateEmojis(deltaTime) {
      liveEmojis.forEach((sprite) => {
        sprite.position.set(
          sprite.position.x,
          sprite.position.y + deltaTime,
          sprite.position.z
        );
        sprite.material.opacity -= deltaTime;

        if (sprite.position.y >= 3) {
          scene.remove(sprite);
          liveEmojis = liveEmojis.filter((liveSprite) => liveSprite !== sprite);
        }
      });
    }

    function updatePublicMessages() {
      livePublicMessages.forEach((messageObj) => {
        const { messageEl, duration, playerId } = messageObj;

        messageObj.duration -= 1;

        if (playerId !== "0") {
          // Other players in the world
          if (players[playerId]) {
            messageEl.position.set(
              players[playerId].player.position.x,
              messageEl.position.y,
              players[playerId].player.position.z
            );

            const size =
              1.5 *
              Math.sqrt(1 / player.position.distanceTo(messageEl.position));
            messageEl.element.firstChild.style.transform = `scale(${size})`;
          }
        } else {
          messageEl.position.set(
            player.position.x,
            messageEl.position.y,
            player.position.z
          );
        }

        if (duration === 0) {
          scene.remove(messageEl);

          livePublicMessages = livePublicMessages.filter(
            (liveMessageObj) => liveMessageObj !== messageObj
          );
        }
      });
    }

    //
    // ADD THE EVENT LISTENERS
    //
    window.addEventListener("click", onDocumentMouseDown, false);
    window.addEventListener("touchstart", onDocumentTouch, false);

    window.addEventListener("keydown", onDown, false);
    window.addEventListener("keyup", onUp, false);

    const mouse = new THREE.Vector2();
    const raycaster = new THREE.Raycaster();

    const throttledMovementEmit = _.throttle(
      () =>
        socket.emit("movement", {
          guestId: userInfo.id,
          newPosition: targetPosition,
          newRotation: playerRotation,
          imageData: {
            skin: currentSkin,
            face: currentFace,
            top: player.children[BODY_PART_MAP.EARS].visible,
          },
          eventSlug,
        }),
      1000,
      { leading: true }
    );

    //Movement rewrite
    function onDown(event) {
      if (!insideQuad || !player) return;

      const keyCode = event.which;

      // If we decide to use ASDW for movement again in the future,
      // 87: W, 83: S, 68: D, 65: A
      if (keyCode === 38) {
        // 38: Up Arrow
        PlayerForward = true;
      } else if (keyCode === 40) {
        // 40: Down Arrow
        PlayerBackward = true;
      } else if (keyCode === 39) {
        // 39: Right Arrow
        RotateClockwise = true;
        RotateCounterClockwise = false;
      } else if (keyCode === 37) {
        // 37: Left Arrow
        RotateCounterClockwise = true;
        RotateClockwise = false;
      }
      throttledMovementEmit();
    }

    //Movement rewrite
    function onUp(event) {
      if (!insideQuad || !player) return;

      const keyCode = event.which;
      if (
        keyCode === 87 ||
        keyCode === 38 ||
        keyCode === 83 ||
        keyCode === 40
      ) {
        PlayerForward = false;
        PlayerBackward = false;
      }
      if (keyCode === 65 || keyCode === 68) {
        PlayerRight = false;
        PlayerLeft = false;
      }
      if (keyCode === 37 || keyCode === 39) {
        RotateClockwise = false;
        RotateCounterClockwise = false;
      }
      throttledMovementEmit();
    }

    // CLICK MANAGER
    function onDocumentMouseDown(event) {
      mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
      touchClickHelper(event.target.nodeName);
    }

    // TOUCH MANAGER
    function onDocumentTouch(event) {
      const { changedTouches, target } = event;

      for (var i = 0; i < changedTouches.length; i++) {
        mouse.x =
          (changedTouches[i].pageX / renderer.domElement.clientWidth) * 2 - 1;
        mouse.y =
          -(changedTouches[i].pageY / renderer.domElement.clientHeight) * 2 + 1;
        touchClickHelper(target.nodeName);
      }
    }

    function touchClickHelper(target) {
      if (!insideQuad || walkingIntoBuilding || target !== "CANVAS") return;

      raycaster.setFromCamera(mouse, camera);

      const playerIntersects = raycaster.intersectObjects(
        [
          ...Object.values(players).map((playerData: any) => playerData.player),
          player, // User's own avatar
        ],
        true
      );
      const playerIntersectIds = playerIntersects.map(
        (intersect) => intersect?.object.id
      );

      const boothIntersects = raycaster.intersectObjects(
        boothTargets.map((boothTarget) => boothTarget.group),
        true
      );
      const boothIntersectIds = boothIntersects.map(
        (intersect) => intersect?.object.id
      );

      const mainStageIntersects = raycaster.intersectObject(screen);
      const mainStageIntersectIds = mainStageIntersects.map(
        (intersect) => intersect?.object.id
      );

      const allIntersects: THREE.Intersection[] = [];
      if (playerIntersects.length > 0) allIntersects.push(playerIntersects[0]);
      if (boothIntersects.length > 0) allIntersects.push(boothIntersects[0]);
      if (mainStageIntersects.length > 0)
        allIntersects.push(mainStageIntersects[0]);

      allIntersects.sort((a, b) => a.distance - b.distance);
      const nearestIntersectId = allIntersects[0]?.object.id;

      if (allIntersects[0]?.distance > 30) {
        return;
      } else if (playerIntersectIds.includes(nearestIntersectId)) {
        const clickedPlayerId = allIntersects[0]?.object?.parent?.name;

        if (clickedPlayerId) {
          setProfilePopup({
            open: true,
            guestId: Number(clickedPlayerId),
          });
        }
      } else if (boothIntersectIds.includes(nearestIntersectId)) {
        const clickedBoothId = allIntersects[0]?.object?.parent?.name;

        if (clickedBoothId) {
          setBoothPopup({
            open: true,
            boothId: Number(clickedBoothId),
          });
        }
      } else if (mainStageIntersectIds.includes(nearestIntersectId)) {
        liveStreamEmbedUrl && setLiveStreamOpen(true);
      }
    }

    //
    // Functions for use outside of ThreeJS
    //
    const startWalk = (): boolean | undefined => {
      if (!isQuadLoaded) return;

      const canvas = document.getElementById("canvas");
      if (canvas) {
        canvas.style.position = "static";
      }

      if (video) video.play();

      handleSidebarOpen(false);
      walkingIntoBuilding = true;
      setWalkingIntoBuilding(true);
      characterCreator = false;
      return true;
    };

    const enterQuad = (): boolean | undefined => {
      if (!player) return;

      targetPosition.set(
        player.position.x + 0.11,
        CENTER_OF_QUAD.y,
        player.position.z
      );

      controls = addControls(camera, renderer, player.position);
      controls.enablePan = false;
      controls.keys = {
        LEFT: 37, //left arrow
        RIGHT: 39, // right arrow
      };

      socket.emit("enterQuad", {
        guestInfo: userInfo,
        imageData: {
          skin: currentSkin,
          face: currentFace,
          top: currentTop,
        },
        eventSlug,
      });

      characterCreator = false;
      setWalkingIntoBuilding(false);
      walkingIntoBuilding = false;
      setInstructionsOpen(true);
      setEnteredQuad(true);
      window.dispatchEvent(new Event("resize"));
      return true;
    };

    const toggleEars = () => {
      if (!player) return;

      if (player.children[BODY_PART_MAP.EARS].visible) {
        player.children[BODY_PART_MAP.EARS].visible = false;
        avatarConfigRef.current = {
          ...avatarConfigRef.current,
          top: 0,
        };
      } else {
        player.children[BODY_PART_MAP.EARS].visible = true;
        avatarConfigRef.current = {
          ...avatarConfigRef.current,
          top: 1,
        };
      }
    };

    const prevFace = () => {
      if (!player) return;

      if (currentFace > 0) {
        currentFace -= 1;
      } else {
        currentFace = FACES.length - 1;
      }
      swapFace(player, currentFace);

      avatarConfigRef.current = {
        ...avatarConfigRef.current,
        face: currentFace,
      };
    };

    const nextFace = () => {
      if (!player) return;

      if (currentFace < FACES.length - 1) {
        currentFace += 1;
      } else {
        currentFace = 0;
      }
      swapFace(player, currentFace);

      avatarConfigRef.current = {
        ...avatarConfigRef.current,
        face: currentFace,
      };
    };

    const prevSkin = () => {
      if (!player) return;

      if (currentSkin > 0) {
        currentSkin -= 1;
      } else {
        currentSkin = SKINS.length - 1;
      }
      swapSkin(player, currentSkin);

      avatarConfigRef.current = {
        ...avatarConfigRef.current,
        body: currentSkin,
      };
    };

    const nextSkin = () => {
      if (!player) return;

      if (currentSkin < SKINS.length - 1) {
        currentSkin += 1;
      } else {
        currentSkin = 0;
      }
      swapSkin(player, currentSkin);

      avatarConfigRef.current = {
        ...avatarConfigRef.current,
        body: currentSkin,
      };
    };

    const throttledEmoteEmit = _.throttle(
      (emojiUrl) =>
        socket.emit("emote", {
          emojiType: emojiUrl,
          eventSlug,
        }),
      1000,
      { leading: true }
    );

    const emote = (e) => {
      const newEmojiUrl = e.currentTarget.name;

      const emoji = newEmojiUrl.substring(
        newEmojiUrl.lastIndexOf("/") + 1,
        newEmojiUrl.lastIndexOf(".png")
      );

      const index = EMOJI_NAMES.indexOf(emoji);
      let newSprite = emojiList[index].clone();
      if (LARGE_EMOJIS.includes(emoji)) {
        newSprite.scale.set(2, 0.5, 1);
      }

      newSprite.material = newSprite.material.clone();
      newSprite.visible = true;
      newSprite.position.set(
        player.position.x,
        player.position.y + 1.1,
        player.position.z
      );

      scene.add(newSprite);
      liveEmojis.push(newSprite);

      newSprite.material.dispose();

      setCurrentEmoji(newEmojiUrl);
      throttledEmoteEmit(newEmojiUrl);
    };

    const throttledPublicMessageEmit = _.throttle(
      () =>
        socket.emit("publicMessage", {
          messageData: {
            message: filter.clean(publicMessageRef.current),
            pos: player.position,
          },
          eventSlug,
        }),
      500,
      { leading: true }
    );

    const handlePublicMessageSubmit = () => {
      if (!publicMessageRef.current) return;

      const publicMessageEl = document.createElement("div");
      publicMessageEl.className = "public-message";
      publicMessageEl.textContent = filter.clean(publicMessageRef.current);

      const publicMessage = new CSS2DObject(publicMessageEl);

      publicMessage.position.set(
        player.position.x,
        player.position.y +
          Math.max(Math.sqrt(publicMessageRef.current.length) / 7.7, 1.2),
        player.position.z
      );

      scene.add(publicMessage);

      livePublicMessages.push({
        messageEl: publicMessage,
        playerId: "0",
        duration: Math.max(publicMessageRef.current.length * 2, 120),
      });

      throttledPublicMessageEmit();
      publicMessageRef.current = "";
      setPublicMessage("");
    };

    setThreeJsFuncs({
      emote,
      handlePublicMessageSubmit,
      startWalk,
      enterQuad,
      toggleEars,
      prevFace,
      nextFace,
      prevSkin,
      nextSkin,
    });

    //
    // Socket.io Handlers
    //
    socket.on("addPlayer", ({ id, guestId, imageData }) => {
      const numPlayers = Object.values(players).length;

      if (numPlayers < MAX_PLAYERS) {
        const newPlayer = PLAYER_MODEL.clone();
        newPlayer.name = guestId;
        swapFace(newPlayer, imageData.face);
        swapSkin(newPlayer, imageData.skin);
        newPlayer.children[BODY_PART_MAP.EARS].visible = false;

        players[id] = {
          player: newPlayer,
          targetPosition: new THREE.Vector3(),
          newRotation: new THREE.Matrix4(),
        };

        scene.add(newPlayer);
      }
    });

    socket.on("playerEmote", ({ id, emojiType }) => {
      if (players[id]) {
        const emoji = emojiType.substring(
          emojiType.lastIndexOf("/") + 1,
          emojiType.lastIndexOf(".png")
        );

        const index = EMOJI_NAMES.indexOf(emoji);
        let newSprite = emojiList[index].clone();
        if (LARGE_EMOJIS.includes(emoji)) {
          newSprite.scale.set(2, 0.5, 1);
        }

        const { position } = players[id].player;

        newSprite.material = newSprite.material.clone();
        newSprite.visible = true;
        newSprite.position.set(position.x, position.y + 1.1, position.z);

        scene.add(newSprite);
        liveEmojis.push(newSprite);

        newSprite.material.dispose();
      }
    });

    socket.on("playerPublicMessage", ({ id, messageData }) => {
      if (players[id]) {
        const publicMessageEl = document.createElement("div");
        publicMessageEl.className = "public-message";
        publicMessageEl.textContent = messageData.message;

        const publicMessage = new CSS2DObject(publicMessageEl);

        publicMessage.position.set(
          messageData.pos.x,
          messageData.pos.y +
            Math.max(Math.sqrt(messageData.message.length) / 6.8, 1.2),
          messageData.pos.z
        );

        scene.add(publicMessage);

        livePublicMessages.push({
          messageEl: publicMessage,
          playerId: id,
          duration: Math.max(messageData.message.length * 2, 120),
        });
      }
    });

    socket.on(
      "movePlayer",
      ({ id, guestId, newPosition, newRotation, imageData }) => {
        const numPlayers = Object.values(players).length;

        // Add player if they haven't been already
        if (!players[id] && numPlayers < MAX_PLAYERS) {
          const newPlayer = PLAYER_MODEL.clone();
          newPlayer.name = guestId;
          swapFace(newPlayer, imageData.face);
          swapSkin(newPlayer, imageData.skin);
          newPlayer.children[BODY_PART_MAP.EARS].visible = false;

          players[id] = {
            player: newPlayer,
            targetPosition: new THREE.Vector3(),
            newRotation: new THREE.Matrix4(),
          };

          scene.add(newPlayer);
        }

        if (players[id]?.player.position.distanceTo(newPosition) > 1) {
          players[id].targetPosition.set(
            newPosition.x,
            newPosition.y,
            newPosition.z
          );

          players[id].newRotation = newRotation;

          movingPlayers[id] = players[id];
        }
      }
    );

    socket.on("removePlayer", ({ id }) => {
      if (players[id]) {
        scene.remove(players[id].player);
        delete players[id];
      }
    });

    socket.on("blockGuest", ({ guestId }) => {
      if (userInfo.id === guestId) {
        window.location.reload();
      }

      if (players[guestId]) {
        scene.remove(players[guestId].player);
        delete players[guestId];
      }
    });

    socket.on("notification", (notification) => {
      setNotification({ open: true, content: notification });
    });

    socket.on("chatMessage", ({ type, roomId, messageData }) => {
      let newChatMessages;

      if (type === CHAT_MESSAGE_TYPES.EVENT) {
        newChatMessages = {
          ...chatMessagesRef.current,
          event: [...chatMessagesRef.current.event, messageData],
        };
      } else if (type === CHAT_MESSAGE_TYPES.BOOTH) {
        newChatMessages = {
          ...chatMessagesRef.current,
          booth: {
            ...chatMessagesRef.current.booth,
            [roomId]: [...chatMessagesRef.current.booth[roomId], messageData],
          },
        };
      }

      setChatMessages(newChatMessages);
      chatMessagesRef.current = newChatMessages;
      if (!sidebarRef.current.open || sidebarRef.current.activeTab !== 4) {
        setHasUnreadMessage(true);
      }
    });

    init();
    animate();

    return () => {
      socket.close();
    };
  }, []);

  const toggleMusic = () => {
    const backgroundMusic = document.getElementById(
      "background-music"
    ) as HTMLAudioElement;

    if (backgroundMusic) {
      if (!soundOn) {
        backgroundMusic.play();
        setSoundOn(true);
      } else {
        backgroundMusic.pause();
        setSoundOn(false);
      }
    }
  };

  return (
    <>
      {/* The actual virtual environment */}
      <div id="canvas" />

      <div
        id="joystick__container"
        style={{ display: enteredQuad ? "flex" : "none" }}
      >
        <div id="joystick" />
      </div>

      <div id="overlay">
        {/* Character Customization */}
        {!enteredQuad && !walkingIntoBuilding && (
          <>
            <div id="customize_controls2" className="customize_controls">
              <div>
                <div
                  className="arrow arrow--left"
                  onClick={threeJsFuncs.prevFace}
                />
                <p className="customizer__text">Face</p>
              </div>
              <div>
                <div
                  className="arrow arrow--right"
                  onClick={threeJsFuncs.nextFace}
                />
                <p className="customizer__text">Face</p>
              </div>
            </div>
            <div id="customize_controls3" className="customize_controls">
              <div>
                <div
                  className="arrow arrow--left"
                  onClick={threeJsFuncs.prevSkin}
                />
                <p className="customizer__text">Body</p>
              </div>
              <div className="button__container">
                <div
                  className="arrow arrow--right"
                  onClick={threeJsFuncs.nextSkin}
                />
                <p className="customizer__text">Body</p>
              </div>
            </div>
          </>
        )}

        <audio id="background-music" loop>
          <source type="audio/mp3" src={backgroundMusic} />
        </audio>

        {(enteredQuad || walkingIntoBuilding) && (
          <SoundIcon
            type="button"
            img={soundOn ? soundOnIcon : soundOffIcon}
            onClick={toggleMusic}
          />
        )}

        {/* Icons/Buttons in the Virtual world */}
        {enteredQuad && (
          <>
            <EmojiMenuIcon
              type="button"
              sidebarOpen={sidebarOpen}
              onClick={() => setEmojiMenuExpanded(!emojiMenuExpanded)}
            >
              <EmojiMenuCurrent src={currentEmoji} alt="" />
              <EmojiMenuArrow
                src="../ThreeJS/texture/arrow-u.svg"
                alt=""
                flip={emojiMenuExpanded}
              />
            </EmojiMenuIcon>

            {emojiMenuExpanded && (
              <EmojiMenu sidebarOpen={sidebarOpen}>
                {LARGE_EMOJIS.map((sprite) => (
                  <button
                    key={sprite}
                    type="button"
                    className="playable"
                    name={`../ThreeJS/texture/${sprite}.png`}
                    onClick={(e) => threeJsFuncs.emote(e)}
                  >
                    <img
                      className="emoji__menu--selected full"
                      src={`../ThreeJS/texture/${sprite}.png`}
                      alt=""
                    />
                  </button>
                ))}
                <SmallEmojis>
                  {SMALL_EMOJIS.map((sprite) => (
                    <button
                      key={sprite}
                      type="button"
                      className="playable"
                      name={`../ThreeJS/texture/${sprite}.png`}
                      onClick={(e) => threeJsFuncs.emote(e)}
                    >
                      <img
                        className="emoji__menu--selected quart"
                        src={`../ThreeJS/texture/${sprite}.png`}
                        alt=""
                      />
                    </button>
                  ))}
                </SmallEmojis>
                <div>Custom Shoutout</div>
                <CharacterLimit>
                  {publicMessage.length} of 140 characters
                </CharacterLimit>
                <ChatSendButton
                  type="image"
                  src={chatSendButton}
                  alt="Send"
                  onClick={threeJsFuncs.handlePublicMessageSubmit}
                />
                <PublicMessageInput
                  maxLength={140}
                  value={publicMessage}
                  onChange={(e) => {
                    publicMessageRef.current = e.target.value;
                    setPublicMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      threeJsFuncs.handlePublicMessageSubmit();
                    }
                  }}
                />
              </EmojiMenu>
            )}

            {isDemo && (
              <>
                <InviteGuestButton
                  type="button"
                  onClick={() => setInviteGuestModalOpen(true)}
                >
                  <InviteGuestButtonImage>Invite Guests</InviteGuestButtonImage>
                </InviteGuestButton>
                <ToDashboardButton
                  type="button"
                  onClick={() => history.push(ROUTES.EVENT_ADMIN.ROOT)}
                >
                  <InviteGuestButtonImage>To Dashboard</InviteGuestButtonImage>
                </ToDashboardButton>
              </>
            )}

            {inviteGuestModalOpen && (
              <InviteGuestModal
                setInviteGuestModalOpen={setInviteGuestModalOpen}
                eventSlug={eventData.slug}
              />
            )}

            <MenuIcon
              type="button"
              imageUrl={menuButton}
              onClick={() => handleSidebarOpen(!sidebarOpen)}
            >
              {hasUnreadMessage && <UnreadMessageDot />}
            </MenuIcon>

            <InstructionsIcon
              type="image"
              src={instructionsButton}
              alt="Instructions"
              onClick={() => setInstructionsOpen(true)}
            />
          </>
        )}

        {instructionsOpen && (
          <Instructions
            setInstructionsOpen={setInstructionsOpen}
            initialEntry={initialEntry}
            setInitialEntry={setInitialEntry}
            setSidebarOpen={handleSidebarOpen}
            isDemoEvent={isDemo}
          />
        )}

        {sidebarOpen && (
          <Sidebar
            setSidebarOpen={handleSidebarOpen}
            activeTab={sidebarActiveTab}
            setActiveTab={handleSidebarActiveTab}
            setBoothPopup={setBoothPopup}
            setLiveStreamOpen={setLiveStreamOpen}
            enteredQuad={enteredQuad}
            startWalk={threeJsFuncs.startWalk}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            avatarConfigRef={avatarConfigRef}
            booths={booths}
            eventSchedule={buildEventSchedule(eventScheduleItems)}
            customText={customText ?? {}}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            chatMessagesRef={chatMessagesRef}
            activeChat={activeChat}
            setActiveChat={setActiveChat}
            hasUnreadMessage={hasUnreadMessage}
            setHasUnreadMessage={setHasUnreadMessage}
          />
        )}

        {boothPopup.open && (
          <BoothPopup
            boothId={boothPopup.boothId}
            setBoothPopup={setBoothPopup}
            setSidebarOpen={handleSidebarOpen}
            setSidebarActiveTab={handleSidebarActiveTab}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            chatMessagesRef={chatMessagesRef}
            setActiveChat={setActiveChat}
          />
        )}

        <EventStatusMessage
          isDemoEvent={isDemo}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
        />

        {profilePopup.open && (
          <ProfilePopup
            guestId={profilePopup.guestId}
            setProfilePopup={setProfilePopup}
          />
        )}

        {notification.open && (
          <Notification
            content={notification.content}
            setNotification={setNotification}
          />
        )}

        {liveStreamOpen && (
          <LiveStream
            setLiveStreamOpen={setLiveStreamOpen}
            liveStreamEmbedUrl={liveStreamEmbedUrl}
          />
        )}
      </div>
    </>
  );
};

export default Canvas;

const InviteGuestButton = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: COLORS.white,
  borderRadius: 6,
  padding: "11px 15px",
  position: "fixed",
  top: 16,
  left: 110,
  height: 62,
  width: 180,
  pointerEvents: "auto",
  "&:focus": {
    boxShadow: `0 0 5px 3px ${COLORS.teal}`,
  },
});

const InviteGuestButtonImage = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  height: "100%",
  width: "100%",
  borderRadius: 5,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
});

const ToDashboardButton = styled(InviteGuestButton)({
  left: 320,
});

const MenuIcon = styled.button<{ imageUrl: string }>(({ imageUrl }) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "45%",
  backgroundPosition: "15px 18px",
  backgroundRepeat: "no-repeat",
  backgroundColor: COLORS.lightBlack,
  position: "fixed",
  top: 20,
  right: 20,
  height: 55,
  width: 55,
  borderRadius: 30,
  cursor: "pointer",
  pointerEvents: "auto",
  "&:focus": {
    boxShadow: `0 0 5px 3px ${COLORS.teal}`,
  },
  [media(Breakpoint.sm)]: {
    backgroundPosition: "16px 19px",
    height: 60,
    width: 60,
    right: 22,
  },
}));

const UnreadMessageDot = styled.div({
  height: 10,
  width: 10,
  backgroundColor: COLORS.accentRedError,
  borderRadius: 5,
  position: "absolute",
  top: 14,
  right: 14,
});

const SoundIcon = styled.button<{ img: string }>(({ img }) => ({
  backgroundImage: `url(${img})`,
  backgroundColor: COLORS.white,
  position: "fixed",
  top: 17,
  left: 20,
  height: 55,
  width: 55,
  borderRadius: 30,
  cursor: "pointer",
  pointerEvents: "auto",
  "&:focus": {
    boxShadow: `0 0 5px 3px ${COLORS.teal}`,
  },
  [media(Breakpoint.sm)]: {
    height: 60,
    width: 60,
    left: 22,
  },
}));

const InstructionsIcon = styled.input({
  position: "fixed",
  bottom: 20,
  left: 20,
  height: 55,
  width: 55,
  borderRadius: 30,
  cursor: "pointer",
  pointerEvents: "auto",
  "&:focus": {
    boxShadow: `0 0 5px 3px ${COLORS.teal}`,
  },
  [media(Breakpoint.sm)]: {
    height: 60,
    width: 60,
    left: 22,
  },
});

const EmojiMenuIcon = styled.button<{ sidebarOpen: boolean }>(
  ({ sidebarOpen }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    pointerEvents: "auto",
    backgroundColor: COLORS.white,
    bottom: 20,
    right: 20,
    width: 75,
    height: 50,
    padding: "10px 15px",
    borderRadius: 10,
    "&:focus": {
      boxShadow: `0 0 5px 3px ${COLORS.teal}`,
    },
    [media(Breakpoint.sm)]: {
      right: sidebarOpen ? 445 : 20,
    },
  })
);

const EmojiMenuCurrent = styled.img({
  marginTop: 2,
  width: "50%",
  height: "auto",
});

const EmojiMenuArrow = styled.img<{ flip: boolean }>(({ flip }) => ({
  height: 0,
  width: 0,
  borderTop: "3px solid transparent",
  borderRight: "8px solid transparent",
  borderLeft: "8px solid transparent",
  borderBottom: `8px solid ${COLORS.lightBlack}`,
  marginBottom: flip ? -5 : 2,
  transform: flip ? "scaleY(-1)" : "none",
  mozTransform: flip ? "scaleY(-1)" : "none",
  oTransform: flip ? "scaleY(-1)" : "none",
  WebkitTransform: flip ? "scaleY(-1)" : "none",
  filter: flip ? "FlipV" : "none",
  msFilter: flip ? "FlipV" : "none",
}));

const EmojiMenu = styled.div<{ sidebarOpen: boolean }>(({ sidebarOpen }) => ({
  position: "fixed",
  pointerEvents: "auto",
  backgroundColor: COLORS.white,
  bottom: 100,
  right: 20,
  width: 200,
  padding: "10px 15px 15px",
  borderRadius: 20,
  flexWrap: "wrap",
  fontSize: 14,
  fontWeight: 600,
  [media(Breakpoint.sm)]: {
    right: sidebarOpen ? 445 : 20,
  },
}));

const SmallEmojis = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridGap: "0 12px",
  width: "100%",
  marginBottom: 5,
  padding: "0 5px",
});

const PublicMessageInput = styled.textarea({
  width: "100%",
  height: 50,
  marginTop: 3,
  border: `1px solid ${COLORS.grey}`,
  fontSize: 12,
  padding: "5px 8px",
  resize: "none",
  fontFamily: "Poppins, sans-serif",
});

const CharacterLimit = styled.div({
  fontSize: 10,
  paddingLeft: 2,
  marginTop: 5,
  color: COLORS.lightBlack,
});

const ChatSendButton = styled.input({
  height: 28,
  position: "absolute",
  bottom: 70,
  right: 15,
});
