import gql from "graphql-tag";

export const DELETE_BOOTH = gql`
  mutation DeleteBooth($boothId: Int!) {
    deleteOneBooth(where: { id: $boothId }) {
      id
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($data: EventCreateInput!) {
    createOneEvent(data: $data) {
      id
      name
      adminEmail
      organizationName
      bannerImageUrl
      selectedWorld
      numPeople
      numBooths
      startDateTime
      endDateTime
      slug
      enforceGuestList
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($eventId: Int!, $data: EventUpdateInput!) {
    updateOneEvent(where: { id: $eventId }, data: $data) {
      id
      name
      adminEmail
      organizationName
      bannerImageUrl
      selectedWorld
      numPeople
      numBooths
      startDateTime
      endDateTime
      slug
      enforceGuestList
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($eventId: Int!) {
    deleteOneEvent(where: { id: $eventId }) {
      id
    }
  }
`;

export const BLOCK_GUEST = gql`
  mutation BlockGuest($guestId: Int!) {
    blockGuest(guestId: $guestId)
  }
`;

export const UNBLOCK_GUEST = gql`
  mutation UnblockGuest($guestId: Int!) {
    unblockGuest(guestId: $guestId)
  }
`;

export const ADD_GUEST_LIST_ITEM = gql`
  mutation CreateOneGuestListItem($data: GuestListItemCreateInput!) {
    createOneGuestListItem(data: $data) {
      email
    }
  }
`;

export const ADD_MANY_GUEST_LIST_ITEMS = gql`
  mutation CreateManyGuestListItems($data: [GuestListItemCreateInput!]!) {
    createManyGuestListItems(data: $data)
  }
`;

export const DELETE_GUEST_LIST_ITEM = gql`
  mutation DeleteOneGuestListItem($email: String!, $eventSlug: String!) {
    deleteOneGuestListItem(
      where: { email_eventSlug: { email: $email, eventSlug: $eventSlug } }
    ) {
      email
    }
  }
`;

export const ADD_BOOTH_ADMIN_INVITE = gql`
  mutation CreateOneBoothAdminInvite($data: BoothAdminInviteCreateInput!) {
    createOneBoothAdminInvite(data: $data) {
      email
    }
  }
`;

export const DELETE_BOOTH_ADMIN_INVITE = gql`
  mutation DeleteOneBoothAdminInvite($email: String!, $eventSlug: String!) {
    deleteOneBoothAdminInvite(
      where: { email_eventSlug: { email: $email, eventSlug: $eventSlug } }
    ) {
      email
    }
  }
`;

export const CREATE_EVENT_ITEM = gql`
  mutation CreateOneEventScheduleItem($data: EventScheduleItemCreateInput!) {
    createOneEventScheduleItem(data: $data) {
      id
      date
      time
      title
      subtitle
    }
  }
`;

export const DELETE_EVENT_ITEM = gql`
  mutation DeleteOneEventScheduleItem($id: Int!) {
    deleteOneEventScheduleItem(where: { id: $id }) {
      id
      date
      time
    }
  }
`;

export const UPDATE_CUSTOM_TEXT = gql`
  mutation UpdateOneCustomText(
    $customTextId: Int!
    $data: CustomTextUpdateInput!
  ) {
    updateOneCustomText(where: { id: $customTextId }, data: $data) {
      id
    }
  }
`;

export const TOGGLE_GUEST_LIST = gql`
  mutation UpdateOneEvent($eventId: Int!, $enforceGuestList: Boolean!) {
    updateOneEvent(
      where: { id: $eventId }
      data: { enforceGuestList: { set: $enforceGuestList } }
    ) {
      enforceGuestList
    }
  }
`;

export const SAVE_CREATE_ATTEMPT = gql`
  mutation CreateOneCreateEventAttempt($data: CreateEventAttemptCreateInput!) {
    createOneCreateEventAttempt(data: $data) {
      id
    }
  }
`;
