import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { EVENT_ADMIN_GUEST_INFO } from "./queries.eventAdmin";
import styled from "@emotion/styled";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import closeIcon from "shared/icons/close.png";
import * as COLORS from "shared/styles/colors";
import Loading from "shared/components/Loading";

interface Props {
  guestId?: number;
  closePopup: () => void;
}

const GuestInfoPopup = ({ guestId, closePopup }: Props) => {
  const { data, loading, error } = useQuery(EVENT_ADMIN_GUEST_INFO, {
    variables: { guestId },
  });

  if (loading) {
    return (
      <>
        <Dimmer />
        <PopupContainer>
          <Popup>
            <Loading />
          </Popup>
        </PopupContainer>
      </>
    );
  }

  if (error || (data && !data?.guest)) {
    closePopup();
    return null;
  }

  const { guest } = data;

  return (
    <>
      <Dimmer />
      <PopupContainer>
        <Popup>
          <XButton
            type="image"
            src={closeIcon}
            alt="clocse"
            onClick={closePopup}
          />
          <Title>{guest.name}</Title>
          <FieldHeader>Nickname</FieldHeader>
          <div>{guest.nickname}</div>
          <FieldHeader>Email</FieldHeader>
          <div>{guest.email}</div>
          <FieldHeader>Email Verified?</FieldHeader>
          <div>{guest.hasVerifiedEmail ? "Yes" : "No"}</div>
          <FieldHeader>Email Subscribed?</FieldHeader>
          <div>{guest.isEmailSubscribed ? "Yes" : "No"}</div>
          {guest.bio && (
            <>
              <FieldHeader>Bio</FieldHeader>
              <div>{guest.bio}</div>
            </>
          )}
          {guest.location && (
            <>
              <FieldHeader>Location</FieldHeader>
              <div>{guest.location}</div>
            </>
          )}
          {guest.facebookHandle && (
            <>
              <FieldHeader>Facebook</FieldHeader>
              <div>facebook.com/{guest.facebookHandle}</div>
            </>
          )}
          {guest.instagramHandle && (
            <>
              <FieldHeader>Instagram</FieldHeader>
              <div>instagram.com/{guest.instagramHandle}</div>
            </>
          )}
          {guest.twitterHandle && (
            <>
              <FieldHeader>Twitter</FieldHeader>
              <div>twitter.com/{guest.twitterHandle}</div>
            </>
          )}
          {guest.linkedinHandle && (
            <>
              <FieldHeader>LinkedIn</FieldHeader>
              <div>linkedin.com/in/{guest.linkedinHandle}</div>
            </>
          )}
        </Popup>
      </PopupContainer>
    </>
  );
};

export default GuestInfoPopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: 10,
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: 11,
  top: "6vh",
  left: "calc(50% - 150px)",
  width: "300px",
  maxHeight: "85vh",
  overflowY: "auto",
  [media(Breakpoint.sm)]: {
    width: "500px",
    left: "calc(50% - 250px)",
  },
  [media(Breakpoint.md)]: {
    width: "700px",
    left: "calc(50% - 350px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLORS.white,
  fontSize: 16,
  lineHeight: "22px",
  padding: "55px 30px",
  minHeight: "85vh",
  width: "100%",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "55px 40px",
  },
  [media(Breakpoint.md)]: {
    padding: "55px 70px",
  },
});

const XButton = styled.input({
  height: "30px",
  opacity: 0.5,
  position: "absolute",
  top: 25,
  right: 25,
});

const Title = styled.div({
  fontSize: "24px",
  lineHeight: "36px",
  fontWeight: "bold",
  textAlign: "center",
  color: COLORS.lightBlack,
  [media(Breakpoint.md)]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
});

const FieldHeader = styled.div({
  fontSize: 18,
  lineHeight: "22px",
  fontWeight: "bold",
  margin: "22px 0 5px",
});

const guestMedia = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  gridGap: 20,
  margin: "7px 0",
});

const Media = styled.img({
  width: "100%",
});
