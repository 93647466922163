import React from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import closeX from "shared/icons/Close_button.svg";

interface Props {
  text: string;
  confirmButtonText: string;
  closePopup: () => void;
  onConfirm: () => void;
  success?: boolean;
  successMessage?: string;
}

const ConfirmationPopup = ({
  text,
  confirmButtonText,
  closePopup,
  onConfirm,
  success,
  successMessage,
}: Props) => {
  return (
    <>
      <Dimmer />
      <PopupContainer>
        <CloseX type="image" src={closeX} alt="Close" onClick={closePopup} />
        <Popup>
          {!success ? (
            <>
              <Title>Are you sure?</Title>
              <Text>{text}</Text>
              <Button type="button" onClick={onConfirm}>
                {confirmButtonText}
              </Button>
              <Button type="button" invert onClick={closePopup}>
                Nevermind
              </Button>
            </>
          ) : (
            <>
              <Title>Success!</Title>
              <Text>{successMessage}</Text>
            </>
          )}
        </Popup>
      </PopupContainer>
    </>
  );
};

export default ConfirmationPopup;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: 10,
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: 11,
  top: "15vh",
  left: "5%",
  width: "90%",
  maxHeight: "70vh",
  overflowY: "auto",
  pointerEvents: "auto",
  [media(Breakpoint.sm)]: {
    width: "450px",
    left: "calc(50% - 225px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 10,
  backgroundColor: COLORS.white,
  padding: "80px 30px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  lineHeight: "24px",
  color: COLORS.lightBlack,
  [media(Breakpoint.sm)]: {
    padding: "80px 40px",
  },
});

const CloseX = styled.input({
  position: "absolute",
  top: 25,
  right: 25,
  height: 20,
  width: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Title = styled.div({
  fontSize: 28,
  fontWeight: "bold",
  marginBottom: 20,
  lineHeight: "24px",
  textAlign: "center",
  [media(Breakpoint.sm)]: {
    fontSize: 32,
    lineHeight: "32px",
  },
});

const Text = styled.div({
  margin: "5px 0 10px",
  padding: "0 15px",
  textAlign: "center",
  [media(Breakpoint.sm)]: {
    padding: "0 50px",
  },
});

const Button = styled.button<{ invert?: boolean }>(({ invert }) => ({
  display: "block",
  cursor: "pointer",
  color: invert ? COLORS.lightBlack : COLORS.white,
  fontSize: 14,
  letterSpacing: 0.2,
  fontWeight: invert ? "bold" : "normal",
  backgroundColor: invert ? COLORS.white : COLORS.blue,
  border: invert ? `2px solid ${COLORS.lightBlack}` : "none",
  borderRadius: 5,
  marginTop: 20,
  height: 42,
  width: 180,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));
