import gql from "graphql-tag";

export const CURRENT_BOOTH = gql`
  query CurrentBooth($boothId: Int!) {
    booth(where: { id: $boothId }) {
      id
      name
      boothDisplayName
      description
      contactEmails
      bannerImageUrl
      zoomUrl
      mediaUrls
      externalLinkUrl
      externalLinkDisplayText
      facebookHandle
      instagramHandle
      twitterHandle
      youtubeHandle
      tiktokHandle
      adminEmail
      event {
        id
        name
        startDateTime
        endDateTime
      }
    }
  }
`;

export const CURRENT_BOOTH_ADMIN = gql`
  query CurrentBoothAdmin {
    currentBoothAdmin {
      id
      name
      email
      hasVerifiedEmail
      isEmailSubscribed
      booths {
        id
        name
        eventSlug
        event {
          id
          name
          startDateTime
          endDateTime
        }
      }
    }
  }
`;

export const CHECK_ADMIN_INVITE = gql`
  query CheckAdminInvite($adminEmail: String!, $eventSlug: String!) {
    checkAdminInvite(adminEmail: $adminEmail, eventSlug: $eventSlug)
  }
`;
