import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { YETI_ADMIN_ACCOUNTS } from "./queries.yetiAdmin";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import _ from "lodash";
import Loading from "shared/components/Loading";
import TabContainer from "shared/components/TabContainer";
import Responsive from "shared/components/Responsive";
import AdminAccountDetails from "./AdminAccountDetails";

interface Props {}

const AccountsList = ({}: Props) => {
  const [accounts, setAccounts] = useState<any>([]);
  const [accountsCount, setAccountsCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(true);
  const [detailsPopupEmail, setDetailsPopupEmail] = useState("");
  const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);

  const [loadAccounts] = useLazyQuery(YETI_ADMIN_ACCOUNTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setAccountsCount(data.yetiAdminAccounts.count);
      setAccounts(data.yetiAdminAccounts.accounts);
      setLoading(false);
    },
  });

  useEffect(() => {
    loadAccounts({
      variables: {
        pageNum,
      },
    });
  }, []);

  const handleDetailsClick = (adminEmail: string): void => {
    setDetailsPopupEmail(adminEmail);
    setDetailsPopupOpen(true);
  };

  const handlePaginationClick = (nextPageNum: number): void => {
    loadAccounts({
      variables: {
        pageNum: nextPageNum,
      },
    });
    setPageNum(nextPageNum);
  };

  const handleSearchChange = (e) => {
    const searchFilter = e.target.value;

    setSearchInput(searchFilter);

    _.debounce(() => {
      loadAccounts({
        variables: {
          searchFilter,
        },
      });
      setPageNum(1);
    }, 300)();
  };

  const first = accountsCount ? 1 + (pageNum - 1) * 10 : 0;

  const showingText = `Showing ${first} - ${Math.min(
    first + 9,
    accountsCount
  )} of ${accountsCount}`;

  const lastPageNum = Math.ceil(accountsCount / 10);

  if (loading) {
    return (
      <TabContainer>
        <Loading />
      </TabContainer>
    );
  }

  return (
    <>
      {detailsPopupOpen && (
        <AdminAccountDetails
          adminEmail={detailsPopupEmail}
          closePopup={() => setDetailsPopupOpen(false)}
        />
      )}
      <TabContainer>
        <TopContent>
          <TopSection>
            <Title>Event Admin Accounts</Title>
            <Searchbar
              value={searchInput}
              placeholder="Search by name"
              onChange={(e) => handleSearchChange(e)}
            />
          </TopSection>
        </TopContent>
        <Headers>
          <div>Name</div>
          <Responsive minSize="lg">Email</Responsive>
        </Headers>
        <Accounts>
          {accounts.map((account) => (
            <AccountRow key={account.id}>
              <div>{account.name}</div>
              <Responsive minSize="lg">{account.email}</Responsive>
              <ActionButton
                type="button"
                onClick={() => handleDetailsClick(account.email)}
              >
                Details
              </ActionButton>
            </AccountRow>
          ))}
        </Accounts>
        <Pagination>
          <Responsive minSize="sm">
            <TextLink
              type="button"
              hide={pageNum <= 2}
              onClick={() => handlePaginationClick(1)}
            >
              First
            </TextLink>
          </Responsive>
          <TextLink
            type="button"
            hide={pageNum <= 1}
            onClick={() => handlePaginationClick(pageNum - 1)}
          >
            Prev
          </TextLink>
          <div>{showingText}</div>
          <TextLink
            type="button"
            hide={pageNum >= lastPageNum}
            onClick={() => handlePaginationClick(pageNum + 1)}
          >
            Next
          </TextLink>
          <Responsive minSize="sm">
            <TextLink
              type="button"
              hide={pageNum >= lastPageNum - 1}
              onClick={() => handlePaginationClick(lastPageNum)}
            >
              Last
            </TextLink>
          </Responsive>
        </Pagination>
      </TabContainer>
    </>
  );
};

export default AccountsList;

const TopContent = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  [media(Breakpoint.xl)]: {
    flexDirection: "row",
    marginBottom: 10,
  },
});

const TopSection = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 25,
});

const Title = styled.div({
  fontSize: 32,
  lineHeight: "36px",
  fontWeight: 600,
  margin: "0 20px 0px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.lg)]: {
    fontSize: 40,
    lineHeight: "50px",
  },
});

const Searchbar = styled.input({
  border: `1px solid ${COLORS.grey}`,
  fontSize: 14,
  padding: "8px 12px",
  height: 38,
  width: 200,
  "&::placeholder": {
    color: COLORS.grey,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 16,
    padding: "11px 17px",
  },
  [media(Breakpoint.xl)]: {
    width: 250,
  },
});

const Headers = styled.div({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gridGap: 25,
  fontWeight: 600,
  margin: "5px 0",
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "3fr 4fr 2fr",
  },
});

const Accounts = styled.div({
  marginBottom: 25,
});

const AccountRow = styled.div({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gridGap: 25,
  padding: "10px 0",
  borderBottom: `1px solid ${COLORS.grey}`,
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "3fr 4fr 2fr",
  },
});

const TextLink = styled.button<{ hide?: boolean }>(({ hide }) => ({
  color: COLORS.lightBlack,
  textAlign: "center",
  fontWeight: 600,
  visibility: hide ? "hidden" : "visible",
  "&:hover": {
    color: COLORS.grey,
  },
}));

const ActionButton = styled.button({
  color: COLORS.blue,
  fontWeight: 600,
  "&:hover": {
    color: COLORS.grey,
  },
});

const Pagination = styled.div({
  display: "flex",
  justifyContent: "space-between",
  margin: "0 auto",
  width: "100%",
  maxWidth: 250,
  [media(Breakpoint.sm)]: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr 180px 1fr 1fr",
    maxWidth: 370,
  },
});
