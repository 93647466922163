import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React from "react";
import { PoppinsRegular, PoppinsSemiBold } from "shared/components/Text";
import * as COLORS from "shared/styles/colors";
import newSchoolLogo from "../SplashPage/images/newschool_logo.jpg";
import footerImage from "./images/FooterImage.png";
import { EVENTS_BY_ADMIN } from "./queries.directory";
import { formatDateToTime, getEventStatus, openInNewTab } from "shared/utils";
import { format } from "date-fns";
import { ROUTES } from "@virtualfest/common";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { parseISO } from "date-fns/esm";
import Footer from "shared/components/Footer";
import Loading from "shared/components/Loading";
import { EVENT_STATUS } from "shared/constants";

interface Props {}

const DirectoryOfEvents = ({}: Props) => {
  const { data } = useQuery(EVENTS_BY_ADMIN, {
    variables: { email: "fearona@newschool.edu" },
  });

  if (data) {
    const events = data.eventsByAdmin;
    return (
      <DirectoryOfEventsContainer>
        <LogoContainer>
          <Logo src={newSchoolLogo} alt="New School Logo" />
        </LogoContainer>
        <TitlesContainer>
          <Title>Upcoming Events</Title>
          <SubTitle>
            at The New School via <Italic>VirtualFest</Italic>
          </SubTitle>
        </TitlesContainer>
        <ContentContainer>
          <EventText>Events ({events.length})</EventText>
          {events.length ? (
            events.map((event: any) => {
              const { id, name, startDateTime, endDateTime, slug } = event;
              const eventStatus = getEventStatus(
                startDateTime,
                endDateTime
              ).status;
              const borderColor =
                eventStatus === EVENT_STATUS.IN_PROGRESS
                  ? COLORS.green
                  : COLORS.white;
              return (
                <EventCard key={id} borderColor={borderColor}>
                  <DateContainer>
                    <EventDate>
                      {format(parseISO(startDateTime), "MMMM do")}
                    </EventDate>
                    <EventDate>
                      {format(parseISO(startDateTime), "yyyy")}
                    </EventDate>
                  </DateContainer>
                  <SpaceBetweenRow>
                    <EventInfo>
                      <EventName>{name}</EventName>
                      <EventTime>
                        {format(parseISO(startDateTime), "EEEE")}
                        {", "}
                        {formatDateToTime(startDateTime)} -{" "}
                        {formatDateToTime(endDateTime)}
                        {eventStatus === EVENT_STATUS.IN_PROGRESS &&
                          ": Event Currently Live"}
                      </EventTime>
                    </EventInfo>
                    <EventButtons>
                      <EventButton
                        isCurrent={eventStatus === EVENT_STATUS.IN_PROGRESS}
                        type="button"
                        onClick={() =>
                          openInNewTab(
                            process.env.REACT_APP_PUBLIC_URL +
                              ROUTES.EVENT.ROOT.replace(":eventSlug", slug)
                          )
                        }
                      >
                        {eventStatus === EVENT_STATUS.IN_PROGRESS
                          ? "Join"
                          : "View Event"}
                      </EventButton>
                    </EventButtons>
                  </SpaceBetweenRow>
                </EventCard>
              );
            })
          ) : (
            <NoEventsContainer>
              <NoEventsText>
                We curently have no upcoming events. Check back here for
                upcoming The New School events.
              </NoEventsText>
            </NoEventsContainer>
          )}
          <DescriptionText>
            VirtualFest is a virtual event platform that’s not just another
            boring square video. Create an avatar, explore{" "}
            <BlueText>The New School&nbsp;</BlueText>events, meet people, and
            have fun!
          </DescriptionText>
        </ContentContainer>
        <FooterContainer>
          <FooterImage src={footerImage} />
          <Footer backgroundColor={COLORS.directoryBlack} />
        </FooterContainer>
      </DirectoryOfEventsContainer>
    );
  }
  return <Loading />;
};

export default DirectoryOfEvents;

const DirectoryOfEventsContainer = styled.div({
  paddingTop: 91,
  backgroundColor: COLORS.lightGrey,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
});

const LogoContainer = styled.div({
  height: 202,
  width: 202,
  borderRadius: "50%",
  background: COLORS.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 16,
});

const Logo = styled.img({
  height: 120,
});

const FooterImage = styled.img({
  width: "100%",
});

const TitlesContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  padding: "0 20px",
  [media(Breakpoint.md)]: {
    alignItems: "center",
  },
});

const Title = styled.h1([
  PoppinsSemiBold,
  {
    fontSize: 45,
    color: COLORS.directoryBlack,
    marginBottom: 15,
    [media(Breakpoint.md)]: {
      fontSize: 70,
    },
  },
]);

const SubTitle = styled.h3([
  PoppinsRegular,
  {
    color: COLORS.directoryBlack,
    fontSize: 18,
    whiteSpace: "nowrap",
    textAlign: "left",
    marginBottom: 15,
    [media(Breakpoint.md)]: {
      fontSize: 28,
      textAlign: "center",
    },
  },
]);

const Italic = styled.span([
  PoppinsRegular,
  {
    fontSize: 18,
    fontStyle: "italic",
    color: COLORS.blue,
    [media(Breakpoint.md)]: {
      fontSize: 28,
    },
  },
]);

const ContentContainer = styled.div({
  width: "100%",
  maxWidth: 980,
  paddingRight: 20,
  paddingLeft: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const EventCard = styled.div<{ borderColor?: string }>(({ borderColor }) => ({
  display: "flex",
  alignItems: "flex-start",
  backgroundColor: COLORS.white,
  width: "100%",
  borderLeft: `8px solid ${borderColor ?? COLORS.orange}`,
  padding: 40,
  marginBottom: 39,
  flexDirection: "column",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

const EventInfo = styled.div({
  marginBottom: 10,
  [media(Breakpoint.md)]: {
    marginBottom: 0,
  },
});

const EventName = styled.div([
  PoppinsRegular,
  {
    fontSize: 24,
    lineHeight: "25px",
    marginBottom: 0,
    color: COLORS.directoryLightBlack,
    [media(Breakpoint.md)]: {
      marginBottom: 7,
    },
  },
]);

const EventDate = styled.div([
  PoppinsSemiBold,
  {
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 600,
    color: COLORS.directoryLightBlack,
  },
]);

const EventButtons = styled.div({
  display: "flex",
  flexDirection: "column",
  alightItems: "center",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const EventButton = styled.button(({ isCurrent }: { isCurrent?: boolean }) => ({
  color: isCurrent ? COLORS.white : COLORS.directoryLightBlack,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: isCurrent ? COLORS.green : COLORS.white,
  border: `2px solid`,
  borderColor: isCurrent ? COLORS.green : COLORS.directoryLightBlack,
  height: 50,
  width: 150,
  marginBottom: 20,
  transition: "box-shadow 300ms",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    margin: "0 40px 0 0",
  },
}));

const EventText = styled.div([
  PoppinsSemiBold,
  {
    fontSize: 18,
    marginBottom: 18,
    alignSelf: "flex-start",
  },
]);

const DateContainer = styled.div({
  marginRight: 52,
  marginBottom: 10,
  minWidth: 170,
  [media(Breakpoint.md)]: {
    flexDirection: "row",
    marginBottom: 0,
  },
});

const SpaceBetweenRow = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 1,
  flexDirection: "column",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const EventTime = styled.p([
  PoppinsSemiBold,
  {
    fontSize: 18,
    lineHeight: "25px",
    color: COLORS.directoryLightBlack,
  },
]);

const DescriptionText = styled.p([
  PoppinsRegular,
  {
    color: COLORS.directoryBlack,
    maxWidth: 455,
    textAlign: "center",
    lineHeight: "25px",
    fontSize: 16,
    marginTop: 30,
    marginBottom: 39,
    whiteSpace: "normal",
  },
]);

const BlueText = styled.span([
  {
    color: COLORS.blue,
    textAlign: "center",
  },
]);

const NoEventsContainer = styled.div({
  width: "100%",
  background: COLORS.white,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: 273,
});

const NoEventsText = styled.p([
  PoppinsRegular,
  {
    fontSize: 20,
    color: COLORS.directoryBlack,
    textAlign: "center",
    maxWidth: 582,
  },
]);

const FooterContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  justifySelf: "end",
  flexGrow: 1,
});
