import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";

interface Props {
  id: string;
  defaultButtonText: string;
  contentToCopy: string;
}

const HiddenClipboardInput = ({
  id,
  defaultButtonText,
  contentToCopy,
}: Props) => {
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const [buttonWidth, setButtonWidth] = useState<string | number>("auto");

  const copyButtonRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    copyButtonRef.current = document.getElementById("copy-button");
    setButtonWidth(Math.ceil(copyButtonRef.current?.clientWidth ?? 0) + 1);
  }, []);

  const handleCopy = () => {
    const hiddenInputEl = document.getElementById(id) as HTMLInputElement;

    if (hiddenInputEl) {
      hiddenInputEl.value = contentToCopy;

      hiddenInputEl.select();
      document.execCommand("copy");

      setButtonText("Copied!");
      setTimeout(() => {
        setButtonText(defaultButtonText);
      }, 2000);
    }
  };

  return (
    <>
      <HiddenInput id={id} type="text" />
      <ButtonContent id="copy-button" width={buttonWidth} onClick={handleCopy}>
        {buttonText}
      </ButtonContent>
    </>
  );
};

export default HiddenClipboardInput;

const HiddenInput = styled.input({
  opacity: 0,
  position: "fixed",
  zIndex: -1,
  bottom: 0,
  left: 0,
  height: 1,
  pointerEvents: "none",
});

const ButtonContent = styled.div<{ width: number | string }>(({ width }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  minWidth: "100%",
  width,
}));
