import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { UPDATE_BOOTH } from "../mutations.boothAdmin";
import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Spacer from "shared/components/Spacer";
import { IconButton } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import SortableMediaList from "shared/components/SortableMediaList";
import arrayMove from "array-move";
import AWSService from "services/AWS/AWSService";

interface Props {
  boothData: any;
  setBoothData: Dispatch<SetStateAction<any>>;
}

const MAX_ASPECT_RATIO = 1.25;
const MIN_ASPECT_RATIO = 0.8;
const MIN_SIZE = 512;
const MAX_SIZE = 2048;

const BoothMedia = ({ boothData, setBoothData }: Props) => {
  const [isUploading, setIsUploading] = useState({
    banner: false,
    gallery: false,
  });
  const [isSaving, setIsSaving] = useState({ banner: false, gallery: false });
  const [buttonTexts, setButtonTexts] = useState({
    banner: "Save Banner",
    gallery: "Save Gallery",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [updateBooth] = useMutation(UPDATE_BOOTH, {
    onCompleted: () => {
      setButtonTexts({
        ...buttonTexts,
        ...(isSaving.banner && { banner: "Saved!" }),
        ...(isSaving.gallery && { gallery: "Saved!" }),
      });

      setIsSaving({ banner: false, gallery: false });
    },
  });

  const handleBannerImageSelect = async (e) => {
    setIsUploading({ ...isUploading, banner: true });

    if (e.target.validity.valid) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);

      let img: HTMLImageElement | null = new Image();

      img.onload = async () => {
        if (img) {
          const aspectRatio = img.width / img.height;

          if (
            aspectRatio > MAX_ASPECT_RATIO ||
            aspectRatio < MIN_ASPECT_RATIO
          ) {
            setErrorMessage("Incorrect aspect ratio");
            return;
          } else if (img.width > MAX_SIZE) {
            setErrorMessage("Image is too large");
            return;
          } else if (img.width < MIN_SIZE) {
            setErrorMessage("Image is too small");
            return;
          } else {
            const photoLocation = await AWSService.uploadBoothLogo(
              file,
              boothData.id
            );

            setErrorMessage("");

            setBoothData({
              ...boothData,
              bannerImageUrl: photoLocation,
            });

            URL.revokeObjectURL(img.src);
            img = null;
          }

          setIsUploading({ banner: false, gallery: false });
        }
      };

      img.src = url;
    } else {
      setErrorMessage("File invalid");
      setIsUploading({ banner: false, gallery: false });
    }
  };

  const removeMedia = (url: string): void => {
    setBoothData({
      ...boothData,
      mediaUrls: [...boothData.mediaUrls.filter((item) => item !== url)],
    });
  };

  const handleGalleryImageSelect = async (e) => {
    if (isUploading.gallery) return;
    setIsUploading({ ...isUploading, gallery: true });

    const { files, validity } = e.target;
    if (validity.valid) {
      const photosToAdd: string[] = [];
      for (let i = 0; i < files.length; i += 1) {
        const photoLocation = await AWSService.uploadBoothAsset(
          files[i],
          boothData.id,
          boothData.mediaUrls
        );
        photosToAdd.push(photoLocation);
      }

      setBoothData({
        ...boothData,
        mediaUrls: [...boothData.mediaUrls, ...photosToAdd],
      });
    }

    setIsUploading({ ...isUploading, gallery: false });
  };

  const handleBannerDelete = () => {
    setBoothData({ ...boothData, bannerImageUrl: "" });

    updateBooth({
      variables: {
        boothId: boothData.id,
        data: {
          bannerImageUrl: { set: "" },
        },
      },
    });
  };

  const handleSaveClick = (inputType: string) => {
    if (inputType === "banner") setIsSaving({ ...isSaving, banner: true });
    if (inputType === "gallery") setIsSaving({ ...isSaving, gallery: true });

    updateBooth({
      variables: {
        boothId: boothData.id,
        data: {
          bannerImageUrl: { set: boothData.bannerImageUrl },
          mediaUrls: { set: boothData.mediaUrls },
        },
      },
    });
  };

  return (
    <Container>
      <Header>Booth Media</Header>
      <Section>
        <div>
          <SubHeader>
            Booth Banner<Required>*</Required>
          </SubHeader>
          <Text>
            Your image will give your booth a unique look. The image should be
            roughly square. Simple, bold graphics will display best.
          </Text>
          {boothData.bannerImageUrl && (
            <div>
              <BannerPreview src={boothData.bannerImageUrl} alt="Preview" />
              <BannerRemoveIcon onClick={handleBannerDelete}>
                <RemoveIcon />
              </BannerRemoveIcon>
              <Button
                type="button"
                disabled={isSaving.banner}
                onClick={() => handleSaveClick("banner")}
              >
                {isSaving.banner ? "Saving..." : buttonTexts.banner}
              </Button>
            </div>
          )}
        </div>
        <Label>
          <UploadButton disabled={isUploading.banner}>
            Upload Banner Image
          </UploadButton>
          <ErrorMessage show={!!errorMessage}>{errorMessage}</ErrorMessage>
          <HiddenInput
            type="file"
            required
            accept="image/*"
            onChange={(e) => handleBannerImageSelect(e)}
          />
        </Label>
      </Section>
      <Section>
        <div>
          <SubHeader>Booth Gallery</SubHeader>
          <Text>
            Upload any additional marketing material you would like visibile in
            your booth (i.e. posters, banners, etc. for upcoming events or
            resources.)
          </Text>
          {boothData.mediaUrls.length > 0 && (
            <>
              <Spacer height={25} />
              <div>Drag and drop to reorder.</div>
              <SortableMediaList
                items={boothData.mediaUrls}
                distance={20}
                axis="xy"
                removeFunc={removeMedia}
                onSortEnd={({ oldIndex, newIndex }) => {
                  setBoothData({
                    ...boothData,
                    mediaUrls: arrayMove(
                      boothData.mediaUrls,
                      oldIndex,
                      newIndex
                    ),
                  });
                }}
              />
              <Button
                type="button"
                disabled={isSaving.gallery}
                onClick={() => handleSaveClick("gallery")}
              >
                {isSaving.gallery ? "Saving..." : buttonTexts.gallery}
              </Button>
            </>
          )}
        </div>
        <Label>
          <UploadButton disabled={isUploading.gallery}>
            Upload Gallery Image(s)
          </UploadButton>
          <input
            style={{ display: "none" }}
            type="file"
            required
            multiple
            accept="image/*"
            onChange={(e) => handleGalleryImageSelect(e)}
          />
        </Label>
      </Section>
    </Container>
  );
};

export default BoothMedia;

const Container = styled.div({
  marginTop: 80,
});

const Header = styled.div({
  fontSize: 22,
  fontWeight: 600,
  margin: "5px 0 30px",
});

const Section = styled.div({
  position: "relative",
  padding: "40px",
  border: `2px solid ${COLORS.white}`,
  marginBottom: 40,
});

const SubHeader = styled.label({
  display: "block",
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 10,
});

const Required = styled.span({
  color: COLORS.red,
  fontWeight: 600,
  fontSize: 14,
});

const Text = styled.div({
  width: "calc(100% - 300px)",
});

const Button = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  height: 42,
  minWidth: 160,
  marginTop: 45,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));

const BannerPreview = styled.img({
  height: 120,
  margin: "40px 0 0 20px",
});

const BannerRemoveIcon = styled(IconButton)({
  margin: "0 0 -30px 20px",
  position: "relative",
  top: -22,
  color: COLORS.red,
  padding: 5,
});

const UploadButton = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 55,
  right: 40,
  color: COLORS.blue,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  border: `2px solid ${COLORS.blue}`,
  backgroundColor: COLORS.lightGrey,
  borderRadius: 5,
  height: 55,
  minWidth: 220,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
}));

const Label = styled.label({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ErrorMessage = styled.div<{ show: boolean }>(({ show }) => ({
  color: COLORS.red,
  fontSize: 14,
  lineHeight: "14px",
  height: 14,
  visibility: show ? "visible" : "hidden",
  margin: "7px 0 -12px",
}));

const HiddenInput = styled.input({
  display: "none",
});
