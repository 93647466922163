import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  REGISTERED_GUESTS,
  GUEST_LIST_ITEMS,
  BLOCKED_GUESTS,
} from "./queries.eventAdmin";
import {
  DELETE_GUEST_LIST_ITEM,
  BLOCK_GUEST,
  UNBLOCK_GUEST,
} from "./mutations.eventAdmin";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { socket } from "services/SocketIo/SocketIOService";
import _ from "lodash";
import Loading from "shared/components/Loading";
import TabContainer from "shared/components/TabContainer";
import Responsive from "shared/components/Responsive";
import ConfirmationPopup from "shared/components/ConfirmationPopup";
import GuestInvitePopup from "./GuestInvitePopup";
import GuestInfoPopup from "./GuestInfoPopup";

interface Props {
  eventData: any;
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
}

const GuestList = ({ eventData, userInfo, setUserInfo }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeList, setActiveList] = useState<any[]>([]);
  const [activeCount, setActiveCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [searchbarInput, setSearchbarInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);
  const [removePopup, setRemovePopup] = useState<{
    guestListEmail?: string;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [blockPopup, setBlockPopup] = useState<{
    guestId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [unblockPopup, setUnblockPopup] = useState<{
    guestId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [guestPopup, setGuestPopup] = useState<{
    guestId?: number;
    open: boolean;
  }>({ open: false });

  const [fetchGuests] = useLazyQuery(REGISTERED_GUESTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setActiveCount(data.registeredGuests.count);
      setActiveList(data.registeredGuests.guests);
      setLoading(false);
    },
  });

  const [fetchGuestList] = useLazyQuery(GUEST_LIST_ITEMS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setActiveCount(data.guestListItems.count);
      setActiveList(data.guestListItems.guests);
      setLoading(false);
    },
  });

  const [fetchBlockedGuests] = useLazyQuery(BLOCKED_GUESTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setActiveCount(data.blockedGuests.count);
      setActiveList(data.blockedGuests.guests);
      setLoading(false);
    },
  });

  const [blockGuest] = useMutation(BLOCK_GUEST, {
    onCompleted: (data) => {
      setUserInfo({
        ...userInfo,
        blockedGuestIds: [...userInfo.blockedGuestIds, data.blockGuest],
      });
      fetchGuests({
        variables: {
          pageNum,
          searchFilter: searchbarInput,
          eventSlug: eventData.slug,
        },
      });

      socket.emit("blockGuest", {
        guestId: data.blockGuest,
        eventSlug: eventData.slug,
      });

      setBlockPopup({ ...blockPopup, success: true });
    },
  });

  const [unblockGuest] = useMutation(UNBLOCK_GUEST, {
    onCompleted: () => {
      fetchBlockedGuests({
        variables: {
          pageNum,
          searchFilter: searchbarInput,
        },
      });
      setUnblockPopup({ ...unblockPopup, success: true });
    },
  });

  const [deleteGuestListItem] = useMutation(DELETE_GUEST_LIST_ITEM, {
    onCompleted: () => {
      fetchGuestList({
        variables: {
          eventSlug: eventData.slug,
          pageNum,
          searchFilter: searchbarInput,
        },
      });
      setRemovePopup({ ...removePopup, success: true });
    },
  });

  useEffect(() => {
    if (activeTabIndex === 0) {
      fetchGuests({
        variables: {
          pageNum,
          searchFilter: searchbarInput,
          eventSlug: eventData.slug,
        },
      });
    } else if (activeTabIndex === 1) {
      fetchGuestList({
        variables: {
          eventSlug: eventData.slug,
          pageNum,
          searchFilter: searchbarInput,
        },
      });
    } else if (activeTabIndex === 2) {
      fetchBlockedGuests({
        variables: {
          pageNum,
          searchFilter: searchbarInput,
        },
      });
    }
  }, [activeTabIndex]);

  const handlePaginationClick = (nextPageNum: number): void => {
    if (activeTabIndex === 0) {
      fetchGuests({
        variables: {
          pageNum: nextPageNum,
          eventSlug: eventData.slug,
        },
      });
    } else if (activeTabIndex === 1) {
      fetchGuestList({
        variables: {
          eventSlug: eventData.slug,
          pageNum: nextPageNum,
        },
      });
    } else if (activeTabIndex === 2) {
      fetchBlockedGuests({
        variables: {
          pageNum: nextPageNum,
        },
      });
    }
    setPageNum(nextPageNum);
  };

  const handleSearchbarChange = (e) => {
    const searchFilter = e.target.value;

    setSearchbarInput(searchFilter);

    _.debounce(() => {
      if (activeTabIndex === 0) {
        fetchGuests({
          variables: {
            searchFilter,
            eventSlug: eventData.slug,
          },
        });
      } else {
        fetchGuestList({
          variables: {
            eventSlug: eventData.slug,
            searchFilter,
          },
        });
      }
      setPageNum(1);
    }, 300)();
  };

  const onInviteSuccess = () => {
    fetchGuestList({
      variables: {
        eventSlug: eventData.slug,
        pageNum,
        searchFilter: searchbarInput,
      },
    });
    setInvitePopupOpen(false);
  };

  const first = activeCount ? 1 + (pageNum - 1) * 10 : 0;

  const showingText = `Showing ${first} - ${Math.min(
    first + 9,
    activeCount
  )} of ${activeCount}`;

  const lastPageNum = Math.ceil(activeCount / 10);

  if (loading) {
    return (
      <TabContainer>
        <Loading />
      </TabContainer>
    );
  }

  return (
    <>
      {removePopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this guest will be removed from the guest list"
          confirmButtonText="Remove Guest"
          closePopup={() => setRemovePopup({ open: false })}
          onConfirm={() =>
            deleteGuestListItem({
              variables: {
                email: removePopup.guestListEmail,
                eventSlug: eventData.slug,
              },
            })
          }
          success={removePopup.success}
          successMessage="The guest you selected has been removed from the guest list."
        />
      )}
      {blockPopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this guest will be blocked from all of your events.  This can be undone later."
          confirmButtonText="Block Guest"
          closePopup={() => setBlockPopup({ open: false })}
          onConfirm={() =>
            blockGuest({
              variables: { guestId: blockPopup.guestId },
            })
          }
          success={blockPopup.success}
          successMessage="The guest you selected has been blocked from your events."
        />
      )}
      {unblockPopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this guest will be able to access all of your events again."
          confirmButtonText="Unblock Guest"
          closePopup={() => setUnblockPopup({ open: false })}
          onConfirm={() =>
            unblockGuest({
              variables: { guestId: unblockPopup.guestId },
            })
          }
          success={unblockPopup.success}
          successMessage="The guest you selected has been unblocked from attending your events."
        />
      )}
      {invitePopupOpen && (
        <GuestInvitePopup
          eventData={eventData}
          onSuccess={onInviteSuccess}
          setInvitePopupOpen={setInvitePopupOpen}
        />
      )}
      {guestPopup.open && (
        <GuestInfoPopup
          guestId={guestPopup.guestId}
          closePopup={() => setGuestPopup({ open: false })}
        />
      )}
      <TabContainer>
        <TopContent>
          <Title>Guests</Title>
          <div>
            <Searchbar
              value={searchbarInput}
              placeholder="Search list"
              onChange={(e) => handleSearchbarChange(e)}
            />
            {activeTabIndex === 1 && (
              <Button type="button" onClick={() => setInvitePopupOpen(true)}>
                Add Guests
              </Button>
            )}
          </div>
        </TopContent>
        <ListTabs>
          <ListTab
            type="button"
            current={activeTabIndex === 0}
            onClick={() => setActiveTabIndex(0)}
          >
            Registered Guests
          </ListTab>
          <ListTab
            type="button"
            current={activeTabIndex === 1}
            onClick={() => setActiveTabIndex(1)}
          >
            Guest List
          </ListTab>
          <ListTab
            type="button"
            current={activeTabIndex === 2}
            onClick={() => setActiveTabIndex(2)}
          >
            Blocked Guests
          </ListTab>
        </ListTabs>
        <Headers>
          <div>NAME</div>
          {activeTabIndex !== 1 && (
            <Responsive minSize="lg">NICKNAME</Responsive>
          )}
          <Responsive minSize="lg">EMAIL</Responsive>
        </Headers>
        <Guests>
          {activeList.map((guest) => (
            <GuestRow key={guest.id}>
              <GuestName
                type="button"
                isGuestList={activeTabIndex === 1}
                onClick={() =>
                  activeTabIndex === 0 &&
                  setGuestPopup({ guestId: guest.id, open: true })
                }
              >
                {guest.name}
              </GuestName>
              {activeTabIndex !== 1 && (
                <Responsive minSize="lg">{guest.nickname}</Responsive>
              )}
              <Responsive minSize="lg">{guest.email}</Responsive>
              <GuestActions>
                {activeTabIndex === 0 && (
                  <GuestAction
                    type="button"
                    isRemove
                    onClick={() =>
                      setBlockPopup({ open: true, guestId: guest.id })
                    }
                  >
                    Block
                  </GuestAction>
                )}
                {activeTabIndex === 1 && (
                  <GuestAction
                    type="button"
                    isRemove
                    onClick={() =>
                      setRemovePopup({
                        open: true,
                        guestListEmail: guest.email,
                      })
                    }
                  >
                    Remove
                  </GuestAction>
                )}
                {activeTabIndex === 2 && (
                  <GuestAction
                    type="button"
                    onClick={() =>
                      setUnblockPopup({ open: true, guestId: guest.id })
                    }
                  >
                    Unblock
                  </GuestAction>
                )}
              </GuestActions>
            </GuestRow>
          ))}
        </Guests>
        <Pagination>
          <Responsive minSize="sm">
            <TextLink
              hide={pageNum <= 2}
              onClick={() => handlePaginationClick(1)}
            >
              First
            </TextLink>
          </Responsive>
          <TextLink
            hide={pageNum <= 1}
            onClick={() => handlePaginationClick(pageNum - 1)}
          >
            Prev
          </TextLink>
          <div>{showingText}</div>
          <TextLink
            hide={pageNum >= lastPageNum}
            onClick={() => handlePaginationClick(pageNum + 1)}
          >
            Next
          </TextLink>
          <Responsive minSize="sm">
            <TextLink
              hide={pageNum >= lastPageNum - 1}
              onClick={() => handlePaginationClick(lastPageNum)}
            >
              Last
            </TextLink>
          </Responsive>
        </Pagination>
      </TabContainer>
    </>
  );
};

export default GuestList;

const TopContent = styled.div({
  display: "flex",
  flexDirection: "column",
  marginBottom: 20,
  width: "100%",
  position: "relative",
  [media(Breakpoint.lg)]: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 0,
  },
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 25px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const Searchbar = styled.input({
  border: `1px solid ${COLORS.grey}`,
  fontSize: 15,
  padding: "8px 12px",
  width: 180,
  marginRight: 20,
  "&::placeholder": {
    color: COLORS.grey,
  },
  [media(Breakpoint.sm)]: {
    width: 230,
  },
  [media(Breakpoint.lg)]: {
    position: "absolute",
    top: 2,
    fontSize: 16,
    padding: "11px 17px",
  },
  [media(Breakpoint.xl)]: {
    width: 275,
  },
});

const Button = styled.button({
  cursor: "pointer",
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  height: 35,
  width: 135,
  [media(Breakpoint.md)]: {
    width: 150,
  },
  [media(Breakpoint.lg)]: {
    position: "absolute",
    right: 0,
    top: 2,
    height: 42,
  },
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const ListTabs = styled.div({
  display: "flex",
  margin: "-5px 0 25px",
});

const ListTab = styled.button<{ current: Boolean }>(({ current }) => ({
  fontSize: 14,
  fontWeight: 600,
  marginRight: 20,
  padding: "0 3px 5px",
  color: current ? COLORS.lightBlack : COLORS.darkGrey,
  borderBottom: `3px solid ${current ? COLORS.lightBlack : COLORS.lightGrey}`,
}));

const Headers = styled.div({
  display: "grid",
  gridTemplateColumns: "4fr 3fr",
  gridGap: 25,
  fontWeight: 600,
  margin: "5px 0",
  [media(Breakpoint.sm)]: {
    gridTemplateColumns: "4fr 3fr 3fr 3fr",
  },
});

const Guests = styled.div({
  marginBottom: 25,
});

const GuestRow = styled.div({
  display: "grid",
  gridTemplateColumns: "4fr 3fr",
  gridGap: 25,
  padding: "10px 0",
  borderBottom: `1px solid ${COLORS.grey}`,
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "4fr 3fr 3fr 3fr",
  },
});

const GuestName = styled.button<{ isGuestList: boolean }>(
  ({ isGuestList }) => ({
    cursor: isGuestList ? "default" : "pointer",
    textDecoration: isGuestList ? "none" : "underline",
    textAlign: "left",
  })
);

const TextLink = styled.div<{ hide?: boolean }>(({ hide }) => ({
  textDecoration: "underline",
  cursor: "pointer",
  display: "inline-block",
  visibility: hide ? "hidden" : "visible",
  "&:hover": {
    color: COLORS.grey,
  },
}));

const GuestActions = styled.div({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 20px",
  marginLeft: "auto",
});

const GuestAction = styled.button<{ isRemove?: boolean }>(({ isRemove }) => ({
  color: isRemove ? COLORS.red : COLORS.blue,
  fontWeight: 600,
  "&:hover": {
    color: COLORS.grey,
  },
}));

const RemoveButton = styled.button({
  color: COLORS.red,
  "&:hover": {
    color: COLORS.grey,
  },
});

const Pagination = styled.div({
  display: "flex",
  justifyContent: "space-between",
  margin: "0 auto",
  width: "100%",
  maxWidth: 250,
  [media(Breakpoint.sm)]: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr 180px 1fr 1fr",
    maxWidth: 370,
  },
});
