import React, { useState, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  DELETE_EVENT,
  SAVE_CREATE_ATTEMPT,
} from "../EventAdmin/mutations.eventAdmin";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { format } from "date-fns";
import { getCardColor } from "../BoothAdmin/YourBooths";
import TabContainer from "shared/components/TabContainer";
import ConfirmationPopup from "shared/components/ConfirmationPopup";
import MessagePopup from "../../shared/components/MessagePopup";

interface Props {
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
  setEventData: Dispatch<SetStateAction<any>>;
}

const YourEvents = ({ userInfo, setUserInfo, setEventData }: Props) => {
  const [deletePopup, setDeletePopup] = useState<{
    eventId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [tempMessageOpen, setTempMessageOpen] = useState(false);

  const history = useHistory();

  const [deleteEvent] = useMutation(DELETE_EVENT, {
    onCompleted: (data) => {
      setUserInfo({
        ...userInfo,
        events: userInfo.events.filter(
          (event) => event.id !== data.deleteOneEvent.id
        ),
      });

      setDeletePopup({ ...deletePopup, success: true });
    },
  });

  const [saveCreateAttempt] = useMutation(SAVE_CREATE_ATTEMPT, {
    // An error is thrown if an attempt already exists for this user
    onError: () => {
      // This takes the user to the create new event page
      setEventData({});
      history.push(ROUTES.EVENT_ADMIN.INFO.replace(":eventSlug", "default"));
    },
    onCompleted: () => {
      setEventData({});
      history.push(ROUTES.EVENT_ADMIN.INFO.replace(":eventSlug", "default"));
    },
  });

  const handleTempCreateEvent = () => {
    // TODO this is being used to track user interaction for marketing purposes and should probably be removed later
    if (!userInfo.email.includes("@yeti.co")) {
      saveCreateAttempt({
        variables: {
          data: {
            name: userInfo.name,
            email: userInfo.email,
            date: new Date(),
          },
        },
      });
    } else {
      setEventData({});
      history.push(ROUTES.EVENT_ADMIN.INFO.replace(":eventSlug", "default"));
    }
  };

  const nonDemoEvents = userInfo.events.filter((event) => !event.isDemo);

  return (
    <>
      {tempMessageOpen && (
        <MessagePopup
          title="White Glove Event Creation Services"
          message={`Our event creation wizard is currently under development and in beta, but feel free to get started in the meantime!\n\nWant some help setting up your event? Contact us at tony@virtualfest.yeti.co`}
          buttonText="Got It!"
          buttonFunction={handleTempCreateEvent}
          closePopup={() => setTempMessageOpen(false)}
        />
      )}
      {deletePopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this event will be deleted"
          confirmButtonText="Delete Event"
          closePopup={() => setDeletePopup({ open: false })}
          onConfirm={() =>
            deleteEvent({ variables: { eventId: deletePopup.eventId } })
          }
          success={deletePopup.success}
          successMessage="The event you selected has been removed"
        />
      )}
      <Container>
        <TabContainer>
          <Title>Your Events</Title>
          {nonDemoEvents.length > 0 ? (
            <>
              <Button
                type="button"
                onClick={() => {
                  setEventData({});
                  history.push(
                    ROUTES.EVENT_ADMIN.INFO.replace(":eventSlug", "default")
                  );
                }}
              >
                New Event
              </Button>
              <div>
                Here is a list of all your created events. You can view, edit,
                or delete an event, or create a new one.
              </div>
              <Header>EVENTS CREATED</Header>
              {nonDemoEvents.map((event) => {
                const { id, name, startDateTime, endDateTime, slug } = event;

                return (
                  <EventCard
                    key={id}
                    borderColor={getCardColor(startDateTime, endDateTime)}
                  >
                    <div>
                      <EventName>{name}</EventName>
                      <div>
                        {format(new Date(startDateTime), "MMMM do, yyyy")}
                        &nbsp;at&nbsp;
                        {format(new Date(startDateTime), "h:mm a")}
                      </div>
                    </div>
                    <EventButtons>
                      <EventButton
                        type="button"
                        onClick={() =>
                          history.push(
                            ROUTES.EVENT_ADMIN.INFO.replace(":eventSlug", slug)
                          )
                        }
                      >
                        View Info
                      </EventButton>
                      <DeleteButton
                        type="button"
                        onClick={() =>
                          setDeletePopup({ open: true, eventId: event.id })
                        }
                      >
                        Delete Event
                      </DeleteButton>
                    </EventButtons>
                  </EventCard>
                );
              })}
            </>
          ) : (
            <EmptyState>
              <div>
                Create your first event! You'll be able to come back and manage
                all your events here.
              </div>
              <Button
                type="button"
                isEmptyState
                onClick={() => setTempMessageOpen(true)}
              >
                Create Event
              </Button>
            </EmptyState>
          )}
        </TabContainer>
      </Container>
    </>
  );
};

export default YourEvents;

const Container = styled.div({
  height: "calc(100vh - 60px)",
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 25px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const Button = styled.button<{ isEmptyState?: boolean }>(
  ({ isEmptyState }) => ({
    color: COLORS.white,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.2,
    backgroundColor: COLORS.blue,
    borderRadius: 5,
    height: isEmptyState ? 55 : 35,
    width: isEmptyState ? 180 : 135,
    position: isEmptyState ? "static" : "absolute",
    right: 20,
    top: 25,
    marginTop: isEmptyState ? 25 : 0,
    [media(Breakpoint.sm)]: {
      width: isEmptyState ? 180 : 150,
      top: 42,
      right: 50,
    },
    [media(Breakpoint.md)]: {
      top: 40,
      right: 65,
    },
    [media(Breakpoint.lg)]: {
      right: 80,
      top: 68,
      height: isEmptyState ? 55 : 42,
    },
    [media(Breakpoint.xl)]: {
      top: 65,
    },
    "&:focus": {
      boxShadow: `0 0 3px 3px ${COLORS.grey}`,
    },
  })
);

const Header = styled.div({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: "18px",
  marginTop: 30,
});

const EventCard = styled.div<{ borderColor?: string }>(({ borderColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: COLORS.white,
  width: "100%",
  borderLeft: `8px solid ${borderColor ?? COLORS.orange}`,
  padding: 40,
  marginTop: 25,
}));

const EventName = styled.div({
  fontSize: 24,
  lineHeight: "24px",
  fontWeight: 600,
  marginBottom: 20,
});

const EventButtons = styled.div({
  display: "flex",
  flexDirection: "column",
  alightItems: "center",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const EventButton = styled.button({
  color: COLORS.lightBlack,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.white,
  border: `2px solid ${COLORS.lightBlack}`,
  borderRadius: 5,
  height: 50,
  width: 150,
  marginBottom: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    margin: "0 40px 0 0",
  },
});

const DeleteButton = styled.button({
  fontWeight: 600,
  fontSize: 14,
  color: COLORS.red,
  [media(Breakpoint.md)]: {
    marginRight: 5,
  },
});

const EmptyState = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 450,
  fontSize: 18,
  textAlign: "center",
  lineHeight: "26px",
  margin: "20vh auto 0",
});
