import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ACCOUNT_DETAILS } from "./queries.yetiAdmin";
import styled from "@emotion/styled";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import closeIcon from "shared/icons/close.png";
import * as COLORS from "shared/styles/colors";
import Loading from "shared/components/Loading";

interface Props {
  adminEmail: string;
  closePopup: () => void;
}

const AdminAccountDetails = ({ adminEmail, closePopup }: Props) => {
  const { data, loading, error } = useQuery(ACCOUNT_DETAILS, {
    variables: { adminEmail },
  });

  if (loading) {
    return (
      <>
        <Dimmer />
        <PopupContainer>
          <Popup>
            <Loading />
          </Popup>
        </PopupContainer>
      </>
    );
  }

  if (error || (data && !data.eventAdmin)) {
    closePopup();
    return null;
  }

  const { eventAdmin } = data;
  const demoEvent = eventAdmin.events.filter((event) => event.isDemo)[0];

  return (
    <>
      <Dimmer onClick={closePopup} />
      <PopupContainer>
        <Popup>
          <Buttons>
            <XButton src={closeIcon} onClick={closePopup} />
          </Buttons>
          <Title>Account Details</Title>
          <FieldHeader>Name</FieldHeader>
          <div>{eventAdmin.name}</div>
          <FieldHeader>Email</FieldHeader>
          <div>{eventAdmin.email}</div>
          <FieldHeader>Email Verified</FieldHeader>
          <div>{eventAdmin.hasVerifiedEmail ? "Yes" : "No"}</div>
          <FieldHeader>Organization Name</FieldHeader>
          <div>{demoEvent?.organizationName || "None"}</div>
          <FieldHeader>Attempted Create Event</FieldHeader>
          <div>{data.createEventAttempt.id ? "Yes" : "No"}</div>
        </Popup>
      </PopupContainer>
    </>
  );
};

export default AdminAccountDetails;

const Dimmer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: COLORS.black,
  opacity: 0.5,
  zIndex: 10,
});

const PopupContainer = styled.div({
  position: "fixed",
  zIndex: 11,
  top: "8vh",
  left: "calc(50% - 150px)",
  width: "300px",
  maxHeight: "80vh",
  overflowY: "auto",
  [media(Breakpoint.sm)]: {
    width: "500px",
    left: "calc(50% - 250px)",
  },
  [media(Breakpoint.md)]: {
    width: "700px",
    left: "calc(50% - 350px)",
  },
});

const Popup = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLORS.white,
  color: COLORS.lightBlack,
  fontSize: 16,
  lineHeight: "22px",
  padding: "55px 30px",
  minHeight: "80vh",
  width: "100%",
  wordBreak: "break-word",
  [media(Breakpoint.sm)]: {
    padding: "55px 40px",
  },
  [media(Breakpoint.md)]: {
    padding: "55px 70px",
  },
});

const Buttons = styled.div({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "12px",
  right: "14px",
});

const XButton = styled.img({
  height: "30px",
  opacity: 0.5,
  cursor: "pointer",
  marginLeft: 15,
});

const Title = styled.div({
  fontSize: "24px",
  color: COLORS.lighterBlack,
  lineHeight: "36px",
  fontWeight: "bold",
  textAlign: "center",
  [media(Breakpoint.md)]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
});

const FieldHeader = styled.div({
  fontSize: 18,
  lineHeight: "22px",
  fontWeight: "bold",
  margin: "22px 0 5px",
});
