import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Responsive from "shared/components/Responsive";
import Loading from "shared/components/Loading";

interface Props {
  booths: any[];
  setBoothPopup?: Dispatch<SetStateAction<any>>;
  customText: string;
}

const renderBoothDescription = (booth: any): string => {
  if (booth.description.length > 40) {
    return booth.description.substr(0, 40) + "...";
  } else {
    return booth.description;
  }
};

const Directory = ({ booths, setBoothPopup, customText }: Props) => {
  return (
    <Container>
      <TopSection>
        <Title>Booths</Title>
        <Text>{customText}</Text>
        <Headers>
          <MainHeader>Participants</MainHeader>
          <div>Total of {booths.length} Organizations</div>
        </Headers>
      </TopSection>
      <Booths>
        {booths.map((booth) => (
          <Booth key={booth.id}>
            <BoothName
              onClick={() =>
                setBoothPopup &&
                setBoothPopup({ open: true, boothId: booth.id })
              }
            >
              {booth.name}
            </BoothName>
            {renderBoothDescription(booth)}
          </Booth>
        ))}
      </Booths>
    </Container>
  );
};

export default Directory;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: COLORS.lightGrey,
});

const TopSection = styled.div({
  backgroundColor: COLORS.white,
  padding: "30px 25px 0",
});

const Title = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  marginBottom: 25,
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 28,
  },
});

const Text = styled.div({
  whiteSpace: "pre-wrap",
});

const Headers = styled.div({
  display: "flex",
  justifyContent: "space-between",
  paddingRight: 15,
  marginTop: 20,
  fontSize: 14,
  color: COLORS.darkGrey,
});

const MainHeader = styled.div({
  fontWeight: 600,
  padding: "0 1px 6px",
  color: COLORS.lightBlack,
  borderBottom: `3px solid ${COLORS.lightBlack}`,
});

const Booths = styled.div({
  borderTop: `1px solid ${COLORS.grey}`,
  width: "100%",
  overflowY: "auto",
  padding: "20px 25px 5px",
});

const Booth = styled.div({
  backgroundColor: COLORS.white,
  borderRadius: 10,
  width: "100%",
  marginBottom: 12,
  padding: "14px 18px",
  color: COLORS.darkGrey,
  fontSize: 14,
  lineHeight: "18px",
});

const BoothName = styled.button({
  display: "block",
  fontSize: 14,
  fontWeight: 600,
  color: COLORS.blue,
  marginBottom: 4,
  textAlign: "left",
  "&:focus": {
    color: COLORS.textFocusBlue,
  },
});
