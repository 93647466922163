export const black = "#000";
export const white = "#ffffff";
export const primaryBlack = "#2d2d2d";
export const primaryDarkGrey = "#747474";
export const primaryLightGrey = "#ecebed";
export const accentNeutralGrey = "#f7f5f9";
export const accentLightPurple = "#694376";
export const accentDarkPurple = "#4b3356";
export const accentGreyPurple = "#D0C9D6";
export const accentRedError = "#eb5757";
export const accentRedInputError = "#ff0303";

export const lightBlack = "#444444";
export const lighterBlack = "#050923";
export const darkGrey = "#7A7A7A";
export const grey = "#C4C4C4";
export const lighterGrey = "#EEEEEE";
export const lightGrey = "#F8F8F8";
export const red = "#D41B2C";
export const green = "#00A868";
export const darkGreen = "#008040";
export const teal = "#1AA5B0";
export const blue = "#0063d8";
export const darkBlue = "#181F63";
export const textFocusBlue = "#003878";
export const purple = "#6834AA";
export const darkPurple = "#502875";
export const orange = "#FF9900";

export const directoryBlack = "#0b0b0b";
export const directoryLightBlack = "#383838";

export const backgroundGrey = "#e2e2e2";
export const brightGreen = "#00CB89";
