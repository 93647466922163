import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import chatSendButton from "../../images/chat_send.svg";
import Filter from "bad-words";
import { format } from "date-fns";
import { getInitials } from "shared/utils";
import { socket } from "services/SocketIo/SocketIOService";

interface Props {
  userInfo: any;
  type: string;
  roomId?: string | number;
  messages: any[];
  addMessage: (newMessage: any) => void;
}

const ChatMessages = ({
  userInfo,
  type,
  roomId,
  messages,
  addMessage,
}: Props) => {
  const [chatInput, setChatInput] = useState("");

  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const filter = new Filter();

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = () => {
    if (!chatInput) return;

    const messageData = {
      authorId: userInfo.id,
      authorName: userInfo.name,
      nickname: userInfo.nickname,
      message: filter.clean(chatInput),
      timestamp: new Date(),
    };

    addMessage(messageData);

    socket.emit("chatMessage", {
      type,
      roomId,
      messageData,
      eventSlug,
    });

    setChatInput("");
  };

  const isSelf = (message): boolean => {
    return message?.authorId === userInfo.id;
  };

  return (
    <Container>
      <ChatItems>
        {messages.map((message, i) => (
          <ChatItem
            key={`message-${i}`}
            isSelf={isSelf(message)}
            isPreviousSelf={messages[i - 1]?.authorId === userInfo.id}
          >
            {!isSelf(message) && (
              <ProfileInitials>
                {getInitials(message.authorName)}
              </ProfileInitials>
            )}
            <div>
              <Nickname isSelf={isSelf(message)}>{message.nickname}</Nickname>
              <Message isSelf={isSelf(message)}>{message.message}</Message>
              <Timestamp isSelf={isSelf(message)}>
                {format(new Date(message.timestamp), "h:mm a")}
              </Timestamp>
            </div>
          </ChatItem>
        ))}
        <div ref={endOfMessagesRef} />
      </ChatItems>
      <ChatInput
        placeholder="Type your message..."
        value={chatInput}
        rows={2}
        onChange={(e) => setChatInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
      />
      <SendButton
        type="image"
        src={chatSendButton}
        alt="Send"
        onClick={handleSubmit}
      />
    </Container>
  );
};

export default ChatMessages;

const Container = styled.div({
  height: "100%",
  overflowY: "hidden",
  backgroundColor: COLORS.lightGrey,
  position: "relative",
  borderTop: `1px solid ${COLORS.grey}`,
});

const ChatItems = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "10px 25px 5px",
  height: "calc(100% - 104px)",
  overflowY: "auto",
});

const ChatItem = styled.div<{ isSelf: Boolean; isPreviousSelf: Boolean }>(
  ({ isSelf, isPreviousSelf }) => ({
    display: "flex",
    alignItems: "flex-end",
    marginTop: isPreviousSelf ? 15 : 20,
    marginLeft: isSelf ? "auto" : 0,
    wordBreak: "break-word",
  })
);

const Message = styled.div<{ isSelf: Boolean }>(({ isSelf }) => ({
  display: "inline-block",
  color: COLORS.white,
  backgroundColor: isSelf ? COLORS.blue : COLORS.darkGrey,
  borderRadius: 5,
  padding: "10px 12px",
  fontSize: 14,
  lineHeight: "20px",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
}));

const ProfileInitials = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 40,
  minWidth: 40,
  borderRadius: 50,
  backgroundColor: COLORS.grey,
  color: COLORS.darkGrey,
  fontWeight: 600,
  fontSize: 16,
  paddingTop: 2,
  marginRight: 10,
});

const Timestamp = styled.div<{ isSelf: Boolean }>(({ isSelf }) => ({
  fontSize: 12,
  lineHeight: "12px",
  color: COLORS.darkGrey,
  textAlign: isSelf ? "right" : "left",
  marginTop: 5,
}));

const Nickname = styled.div<{ isSelf: Boolean }>(({ isSelf }) => ({
  fontSize: 12,
  lineHeight: "12px",
  color: COLORS.darkGrey,
  textAlign: isSelf ? "right" : "left",
  marginTop: 5,
  marginBottom: 5,
  display: isSelf ? "none" : "flex",
}));

const ChatInput = styled.textarea({
  resize: "none",
  borderRadius: 5,
  padding: "12px 60px 12px 15px",
  fontSize: 14,
  lineHeight: "20px",
  fontFamily: "Poppins, sans-serif",
  border: "none",
  boxShadow: `0 0 8px 1px ${COLORS.grey}`,
  msOverflowStyle: "none",
  margin: "20px 25px",
  width: "calc(100% - 50px)",
  "&::placeholder": {
    color: COLORS.grey,
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const SendButton = styled.input({
  position: "absolute",
  bottom: 30,
  right: 35,
  height: 40,
});
