import styled from "@emotion/styled";
import React from "react";
import { openInNewTab } from "shared/utils";
import * as COLORS from "shared/styles/colors";
import { PoppinsRegular } from "./Text";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

interface Props {
  backgroundColor?: string;
  position?: string;
  color?: string;
}

const Footer = ({ backgroundColor, position, color }: Props) => {
  return (
    <FooterContainer position={position} backgroundColor={backgroundColor}>
      <Text color={color}>
        © 2021 VirtualFest. Developed by&nbsp;
        <TextLink
          color={color}
          type="button"
          onClick={() => openInNewTab("yeti.co")}
        >
          Yeti
        </TextLink>
      </Text>
      <Text color={color}>
        <TextLink
          color={color}
          type="button"
          onClick={() => openInNewTab("virtualfest.app/terms")}
        >
          Terms
        </TextLink>
        &nbsp;|&nbsp;
        <TextLink
          color={color}
          type="button"
          onClick={() => openInNewTab("virtualfest.app/privacy")}
        >
          Privacy Policy
        </TextLink>
      </Text>
    </FooterContainer>
  );
};

export default Footer;

// @ts-ignore
const FooterContainer = styled.div(({ backgroundColor, position }: Props) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: backgroundColor ?? COLORS.darkBlue,
  color: COLORS.white,
  fontSize: 14,
  padding: "15px 15px",
  bottom: 0,
  [media(Breakpoint.md)]: {
    padding: "15px 60px",
    position: position ?? "inherit",
    backgroundColor: backgroundColor ?? COLORS.darkBlue,
  },
}));

const TextLink = styled.button<{ color?: string; weight?: number }>(
  ({ color, weight }) => ({
    color: color ?? COLORS.white,
    textDecoration: "underline",
    fontWeight: weight ?? "normal",
  })
);

const Text = styled.div(({ color }: Props) => [
  PoppinsRegular,
  {
    fontSize: 16,
    color: color ?? COLORS.white,
    fontWeight: 400,
  },
]);
