import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  EVENT_ADMIN_BOOTHS,
  BOOTH_DETAILS,
  BOOTH_ADMIN_INVITES,
} from "./queries.eventAdmin";
import {
  DELETE_BOOTH,
  DELETE_BOOTH_ADMIN_INVITE,
} from "./mutations.eventAdmin";
import { useHistory, useParams, generatePath } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import { useEventSlug } from "shared/hooks/useEventSlug";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import _ from "lodash";
import Loading from "shared/components/Loading";
import TabContainer from "shared/components/TabContainer";
import Responsive from "shared/components/Responsive";
import BoothDetails from "./BoothDetails";
import BoothInvitePopup from "./BoothInvitePopup";
import ConfirmationPopup from "../../shared/components/ConfirmationPopup";
import MessagePopup from "../../shared/components/MessagePopup";

interface Props {
  eventData: any;
  setEventData: Dispatch<SetStateAction<any>>;
  setBoothData: Dispatch<SetStateAction<any>>;
}

const BoothList = ({ eventData, setEventData, setBoothData }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeList, setActiveList] = useState<any[]>([]);
  const [activeCount, setActiveCount] = useState(0);
  const [boothAdminCount, setBoothAdminCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [searchbarInput, setSearchbarInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [boothPopupId, setBoothPopupId] = useState(0);
  const [boothPopupOpen, setBoothPopupOpen] = useState(false);
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);
  const [removeBoothPopup, setRemoveBoothPopup] = useState<{
    boothId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [removeInvitePopup, setRemoveInvitePopup] = useState<{
    boothAdminEmail?: string;
    open?: boolean;
    success?: boolean;
  }>({ open: false });
  const [boothLimitPopupOpen, setBoothLimitPopupOpen] = useState(false);

  const history = useHistory();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const { EDIT }: any = useEventSlug({ EDIT: ROUTES.EVENT_ADMIN.EDIT });

  const [getBooths] = useLazyQuery(EVENT_ADMIN_BOOTHS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setEventData({
        ...eventData,
        booths: [...data.eventAdminBooths.booths],
      });

      setActiveCount(data.eventAdminBooths.count);
      setActiveList(data.eventAdminBooths.booths);
      setLoading(false);
    },
  });

  const [getBoothInvites] = useLazyQuery(BOOTH_ADMIN_INVITES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (activeTabIndex === 1) {
        setActiveCount(data.boothAdminInvites.count);
        setActiveList(data.boothAdminInvites.boothAdminInvites);
        setLoading(false);
      }

      setBoothAdminCount(data.boothAdminInvites.count);
    },
  });

  const [deleteBooth] = useMutation(DELETE_BOOTH, {
    onCompleted: (data) => {
      getBooths({
        variables: {
          eventSlug,
          pageNum,
          searchFilter: searchbarInput,
        },
      });

      setEventData({
        ...eventData,
        booths: eventData.booths.filter(
          (booth) => booth.id !== data.deleteOneBooth.id
        ),
      });

      setRemoveBoothPopup({ ...removeBoothPopup, success: true });
    },
  });

  const [getBoothDetails] = useLazyQuery(BOOTH_DETAILS, {
    onCompleted: (data) => {
      setBoothData(data.booth);
      history.push(
        generatePath(ROUTES.EVENT_ADMIN.EDIT, {
          eventSlug,
          boothId: data.booth.id,
        })
      );
    },
  });

  const [deleteBoothInvite] = useMutation(DELETE_BOOTH_ADMIN_INVITE, {
    onCompleted: () => {
      getBooths({
        variables: {
          eventSlug: eventData.slug,
          pageNum,
          searchFilter: searchbarInput,
        },
      });
      getBoothInvites({
        variables: {
          eventSlug: eventData.slug,
          pageNum,
          searchFilter: searchbarInput,
        },
      });
      setRemoveInvitePopup({ ...removeBoothPopup, success: true });
    },
  });

  useEffect(() => {
    if (activeTabIndex === 0) {
      getBooths({
        variables: {
          eventSlug,
          pageNum,
        },
      });
    }

    getBoothInvites({
      variables: {
        eventSlug: eventData.slug,
        pageNum,
        searchFilter: searchbarInput,
      },
    });
  }, [activeTabIndex]);

  const isBoothLimitReached = eventData.booths.length + boothAdminCount >= 12;

  const handleInviteBoothAdmin = () => {
    if (isBoothLimitReached) {
      setBoothLimitPopupOpen(true);
      return;
    }

    setInvitePopupOpen(true);
  };

  const handleAddNewBooth = () => {
    if (isBoothLimitReached) {
      setBoothLimitPopupOpen(true);
      return;
    }

    setBoothData({});
    history.push(EDIT.replace(":boothId", "new"));
  };

  const handleBoothNameClick = (boothId: number): void => {
    setBoothPopupId(boothId);
    setBoothPopupOpen(true);
  };

  const handleBoothEditClick = (boothId: any): void => {
    getBoothDetails({ variables: { boothId } });
  };

  const handlePaginationClick = (nextPageNum: number): void => {
    if (activeTabIndex === 0) {
      getBooths({
        variables: {
          eventSlug,
          pageNum: nextPageNum,
        },
      });
    } else if (activeTabIndex === 1) {
      getBoothInvites({
        variables: {
          eventSlug: eventData.slug,
          pageNum: nextPageNum,
        },
      });
    }
    setPageNum(nextPageNum);
  };

  const handleSearchbarChange = (e) => {
    const searchFilter = e.target.value;

    setSearchbarInput(searchFilter);

    _.debounce(() => {
      if (activeTabIndex === 0) {
        getBooths({
          variables: {
            eventSlug,
            searchFilter,
          },
        });
      } else {
        getBoothInvites({
          variables: {
            eventSlug: eventData.slug,
            searchFilter,
          },
        });
      }
      setPageNum(1);
    }, 300)();
  };

  const onInviteSuccess = () => {
    getBooths({
      variables: {
        eventSlug: eventData.slug,
        pageNum,
        searchFilter: searchbarInput,
      },
    });
    getBoothInvites({
      variables: {
        eventSlug: eventData.slug,
        pageNum,
        searchFilter: searchbarInput,
      },
    });
  };

  const first = activeCount ? 1 + (pageNum - 1) * 10 : 0;

  const showingText = `Showing ${first} - ${Math.min(
    first + 9,
    activeCount
  )} of ${activeCount}`;

  const lastPageNum = Math.ceil(activeCount / 10);

  if (loading) {
    return (
      <TabContainer>
        <Loading />
      </TabContainer>
    );
  }

  return (
    <>
      {boothLimitPopupOpen && (
        <MessagePopup
          title="Booth Limit Reached"
          message="Each event can host up to twelve booths at a time.  To add more booths, remove an existing booth first, or create a new event."
          buttonText="Got It!"
          closePopup={() => setBoothLimitPopupOpen(false)}
        />
      )}
      {boothPopupOpen && (
        <BoothDetails
          boothId={boothPopupId}
          closePopup={() => setBoothPopupOpen(false)}
          setBoothData={setBoothData}
        />
      )}
      {removeBoothPopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this booth will be deleted"
          confirmButtonText="Delete Booth"
          closePopup={() => setRemoveBoothPopup({ open: false })}
          onConfirm={() =>
            deleteBooth({ variables: { boothId: removeBoothPopup.boothId } })
          }
          success={removeBoothPopup.success}
          successMessage="The booth you selected has been removed"
        />
      )}
      {removeInvitePopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this booth admin will be removed from the invite list"
          confirmButtonText="Remove Booth Admin"
          closePopup={() => setRemoveInvitePopup({ open: false })}
          onConfirm={() =>
            deleteBoothInvite({
              variables: {
                email: removeInvitePopup.boothAdminEmail,
                eventSlug: eventData.slug,
              },
            })
          }
          success={removeInvitePopup.success}
          successMessage="The booth admin you selected has been removed from the invite list."
        />
      )}
      {invitePopupOpen && (
        <BoothInvitePopup
          eventData={eventData}
          onSuccess={onInviteSuccess}
          setInvitePopupOpen={setInvitePopupOpen}
        />
      )}
      <TabContainer>
        <TopContent>
          <TopSection>
            <Title>Booths</Title>
            <Searchbar
              value={searchbarInput}
              placeholder="Search list"
              onChange={(e) => handleSearchbarChange(e)}
            />
          </TopSection>
          <TopSection>
            <Button type="button" onClick={handleInviteBoothAdmin}>
              Invite Booth Admin
            </Button>
            <Button type="button" onClick={handleAddNewBooth}>
              Add New Booth
            </Button>
          </TopSection>
        </TopContent>
        <ListTabs>
          <ListTab
            type="button"
            current={activeTabIndex === 0}
            onClick={() => setActiveTabIndex(0)}
          >
            Created Booths
          </ListTab>
          <ListTab
            type="button"
            current={activeTabIndex === 1}
            onClick={() => setActiveTabIndex(1)}
          >
            Booths Admins
          </ListTab>
        </ListTabs>
        <Headers>
          <div>NAME</div>
          <Responsive minSize="lg">
            {activeTabIndex === 0 ? "PRIMARY CONTACT" : "EMAIL"}
          </Responsive>
        </Headers>
        <Booths>
          {activeList.map((booth) => (
            <BoothRow key={booth.id}>
              {/* Extra divs are so only the text is clickable */}
              <div>
                <TextLink
                  type="button"
                  isBoothName
                  onClick={() => handleBoothNameClick(booth.id)}
                >
                  {booth.name}
                </TextLink>
              </div>
              <Responsive minSize="lg">
                {activeTabIndex === 0 ? booth.adminEmail : booth.email}
              </Responsive>
              <BoothActions>
                {activeTabIndex === 0 && (
                  <BoothAction
                    type="button"
                    onClick={() => handleBoothEditClick(booth.id)}
                  >
                    Edit Info
                  </BoothAction>
                )}
                <BoothAction
                  type="button"
                  isRemove
                  onClick={() =>
                    activeTabIndex === 0
                      ? setRemoveBoothPopup({ open: true, boothId: booth.id })
                      : setRemoveInvitePopup({
                          open: true,
                          boothAdminEmail: booth.email,
                        })
                  }
                >
                  Remove
                </BoothAction>
              </BoothActions>
            </BoothRow>
          ))}
        </Booths>
        <Pagination>
          <Responsive minSize="sm">
            <TextLink
              type="button"
              hide={pageNum <= 2}
              onClick={() => handlePaginationClick(1)}
            >
              First
            </TextLink>
          </Responsive>
          <TextLink
            type="button"
            hide={pageNum <= 1}
            onClick={() => handlePaginationClick(pageNum - 1)}
          >
            Prev
          </TextLink>
          <div>{showingText}</div>
          <TextLink
            type="button"
            hide={pageNum >= lastPageNum}
            onClick={() => handlePaginationClick(pageNum + 1)}
          >
            Next
          </TextLink>
          <Responsive minSize="sm">
            <TextLink
              type="button"
              hide={pageNum >= lastPageNum - 1}
              onClick={() => handlePaginationClick(lastPageNum)}
            >
              Last
            </TextLink>
          </Responsive>
        </Pagination>
      </TabContainer>
    </>
  );
};

export default BoothList;

const TopContent = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  [media(Breakpoint.xl)]: {
    flexDirection: "row",
  },
});

const TopSection = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 25,
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 0px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const Searchbar = styled.input({
  border: `1px solid ${COLORS.grey}`,
  fontSize: 14,
  padding: "8px 12px",
  height: 38,
  width: 200,
  "&::placeholder": {
    color: COLORS.grey,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 16,
    padding: "11px 17px",
  },
});

const Button = styled.button({
  cursor: "pointer",
  color: COLORS.white,
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  height: 35,
  padding: "0 20px",
  marginRight: 30,
  [media(Breakpoint.lg)]: {
    height: 42,
    fontSize: 14,
  },
  [media(Breakpoint.xl)]: {
    margin: "0 0 0 30px",
  },
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const ListTabs = styled.div({
  display: "flex",
  margin: "-5px 0 25px",
});

const ListTab = styled.button<{ current: Boolean }>(({ current }) => ({
  fontSize: 14,
  fontWeight: 600,
  marginRight: 20,
  padding: "0 3px 5px",
  color: current ? COLORS.lightBlack : COLORS.darkGrey,
  borderBottom: `3px solid ${current ? COLORS.lightBlack : COLORS.lightGrey}`,
}));

const Headers = styled.div({
  display: "grid",
  gridTemplateColumns: "4fr 3fr",
  gridGap: 25,
  fontWeight: 600,
  margin: "5px 0",
  [media(Breakpoint.sm)]: {
    gridTemplateColumns: "4fr 3fr 3fr",
  },
});

const Booths = styled.div({
  marginBottom: 25,
});

const BoothRow = styled.div({
  display: "grid",
  gridTemplateColumns: "4fr 3fr",
  gridGap: 25,
  padding: "10px 0",
  borderBottom: `1px solid ${COLORS.grey}`,
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "4fr 3fr 3fr",
  },
});

const TextLink = styled.button<{ hide?: boolean; isBoothName?: boolean }>(
  ({ hide, isBoothName }) => ({
    textDecoration: isBoothName ? "underline" : "none",
    color: isBoothName ? COLORS.blue : COLORS.lightBlack,
    textAlign: isBoothName ? "left" : "center",
    fontWeight: 600,
    visibility: hide ? "hidden" : "visible",
    "&:hover": {
      color: COLORS.grey,
    },
  })
);

const BoothActions = styled.div({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 20px",
});

const BoothAction = styled.button<{ isRemove?: boolean }>(({ isRemove }) => ({
  color: isRemove ? COLORS.red : COLORS.blue,
  fontWeight: 600,
  marginLeft: "auto",
  "&:hover": {
    color: COLORS.grey,
  },
}));

const Pagination = styled.div({
  display: "flex",
  justifyContent: "space-between",
  margin: "0 auto",
  width: "100%",
  maxWidth: 250,
  [media(Breakpoint.sm)]: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr 180px 1fr 1fr",
    maxWidth: 370,
  },
});
