import React, { useEffect } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import TabContainer from "shared/components/TabContainer";

const CustomerPortal = () => {
  useEffect(() => {
    (window as any).servicebotSettings = {
      billing_page_id: "2AtRM81s06NhkFpvyCSn",
      email: "// change this to your customer's email",
      loader: "1",
    };

    (function () {
      var s = document.createElement("script");
      s.src = "https://js.billflow.io/billflow-embed.js";
      s.async = true;
      s.type = "text/javascript";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode?.insertBefore(s, x);
    })();
  }, []);

  return (
    <TabContainer>
      <div id="billflow-embed" />
    </TabContainer>
  );
};

export default CustomerPortal;
