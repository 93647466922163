import React, { useState } from "react";
import { useEffect } from "react";
import { getEventStatus } from "shared/utils";
import MessagePopup from "shared/components/MessagePopup";
import { EVENT_STATUS } from "shared/constants";

interface Props {
  isDemoEvent: boolean;
  startDateTime: Date;
  endDateTime: Date;
}

const EventStatusPopup = ({
  isDemoEvent,
  startDateTime,
  endDateTime,
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const currentStatus = getEventStatus(startDateTime, endDateTime);
    if (currentStatus?.status !== EVENT_STATUS.IN_PROGRESS) {
      setIsVisible(true);
      setTitle(currentStatus?.popupTitle);
      setMessage(currentStatus?.popupMessage);
    }
  }, [startDateTime]);

  if (isVisible && !isDemoEvent) {
    return (
      <MessagePopup
        title={title}
        message={message}
        buttonText="Close"
        closePopup={() => setIsVisible(false)}
      />
    );
  }
  return null;
};

export default EventStatusPopup;
