import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { VERIFY_GUEST_LIST } from "../queries.threeJs";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import { useHistory, useParams } from "react-router-dom";
import { useEventSlug } from "shared/hooks/useEventSlug";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import Loading from "shared/components/Loading";

interface Props {}

const BlockedUser = ({}: Props) => {
  const history = useHistory();

  const { LOGIN }: any = useEventSlug({ LOGIN: ROUTES.EVENT.LOGIN });

  const handleLogout = () => {
    CookieService.removeCookie(Identifiers.AccessToken);
    history.push(LOGIN);
    window.location.reload();
  };

  return (
    <Container>
      <TextLink type="button" onClick={handleLogout}>
        Logout
      </TextLink>
      <Title>Access Denied</Title>
      <div>
        Unfortunately, you have been blocked from participating in this event.
      </div>
      <div>
        Please contact the event organizer if you believe this to be a mistake.
      </div>
    </Container>
  );
};

export default BlockedUser;

const Container = styled.div({
  textAlign: "center",
  fontFamily: "Poppins, sans-serif",
  fontSize: 16,
  lineHeight: "28px",
  position: "relative",
  color: COLORS.lightBlack,
  padding: "120px 40px",
  [media(Breakpoint.sm)]: {
    padding: "120px 80px",
  },
});

const Title = styled.div({
  fontSize: 36,
  marginBottom: 30,
});

const TextLink = styled.button({
  fontSize: 16,
  color: COLORS.blue,
  position: "absolute",
  top: 25,
  right: 35,
  [media(Breakpoint.sm)]: {
    right: 50,
    top: 35,
  },
});
