import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_LEADS } from "./queries.yetiAdmin";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { format } from "date-fns";
import Loading from "shared/components/Loading";
import TabContainer from "shared/components/TabContainer";
import Responsive from "shared/components/Responsive";

interface Props {}

const CreateEventAttempts = ({}: Props) => {
  const [searchInput, setSearchInput] = useState("");
  const [pageNum, setPageNum] = useState(1);

  const { data, loading } = useQuery(GET_LEADS);

  if (loading) {
    return (
      <TabContainer>
        <Loading />
      </TabContainer>
    );
  }

  const filteredLeads = data.createEventAttempts.filter(
    (lead) =>
      lead.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      lead.email.split("@")[0].toLowerCase().includes(searchInput.toLowerCase())
  );

  const paginatedLeads = filteredLeads.slice(10 * (pageNum - 1), 10 * pageNum);

  const count = filteredLeads.length;

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);

    setPageNum(1);
  };

  const first = count ? 1 + (pageNum - 1) * 10 : 0;

  const showingText = `Showing ${first} - ${Math.min(
    first + 9,
    count
  )} of ${count}`;

  const lastPageNum = Math.ceil(count / 10);

  return (
    <>
      <TabContainer>
        <TopContent>
          <TopSection>
            <Title>Create Event Attempts</Title>
            <Searchbar
              value={searchInput}
              placeholder="Search list"
              onChange={(e) => handleSearchChange(e)}
            />
          </TopSection>
        </TopContent>
        <Headers>
          <div>Name</div>
          <Responsive minSize="md">Email</Responsive>
          <Responsive minSize="lg">Date</Responsive>
        </Headers>
        <Organizations>
          {paginatedLeads.map((lead) => (
            <OrganizationRow key={lead.id}>
              <div>{lead.name}</div>
              <Responsive minSize="md">{lead.email}</Responsive>
              <Responsive minSize="lg">
                {format(new Date(lead.date), "M/d/Y")}
              </Responsive>
            </OrganizationRow>
          ))}
        </Organizations>
        <Pagination>
          <Responsive minSize="sm">
            <TextLink
              type="button"
              hide={pageNum <= 2}
              onClick={() => setPageNum(1)}
            >
              First
            </TextLink>
          </Responsive>
          <TextLink
            type="button"
            hide={pageNum <= 1}
            onClick={() => setPageNum(pageNum - 1)}
          >
            Prev
          </TextLink>
          <div>{showingText}</div>
          <TextLink
            type="button"
            hide={pageNum >= lastPageNum}
            onClick={() => setPageNum(pageNum + 1)}
          >
            Next
          </TextLink>
          <Responsive minSize="sm">
            <TextLink
              type="button"
              hide={pageNum >= lastPageNum - 1}
              onClick={() => setPageNum(lastPageNum)}
            >
              Last
            </TextLink>
          </Responsive>
        </Pagination>
      </TabContainer>
    </>
  );
};

export default CreateEventAttempts;

const TopContent = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  [media(Breakpoint.xl)]: {
    flexDirection: "row",
    marginBottom: 10,
  },
});

const TopSection = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 25,
});

const Title = styled.div({
  fontSize: 32,
  lineHeight: "36px",
  fontWeight: 600,
  margin: "0 20px 0px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.lg)]: {
    fontSize: 40,
    lineHeight: "50px",
  },
});

const Searchbar = styled.input({
  border: `1px solid ${COLORS.grey}`,
  fontSize: 14,
  padding: "8px 12px",
  height: 38,
  width: 200,
  "&::placeholder": {
    color: COLORS.grey,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 16,
    padding: "11px 17px",
  },
  [media(Breakpoint.xl)]: {
    width: 250,
  },
});

const Headers = styled.div({
  display: "grid",
  gridTemplateColumns: "2fr 3fr",
  gridGap: 25,
  fontWeight: 600,
  margin: "5px 0",
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "3fr 4fr 2fr",
  },
});

const Organizations = styled.div({
  marginBottom: 25,
});

const OrganizationRow = styled.div({
  display: "grid",
  gridTemplateColumns: "2fr 3fr",
  gridGap: 25,
  padding: "10px 0",
  borderBottom: `1px solid ${COLORS.grey}`,
  [media(Breakpoint.lg)]: {
    gridTemplateColumns: "3fr 4fr 2fr",
  },
});

const TextLink = styled.button<{ hide?: boolean; isBoothName?: boolean }>(
  ({ hide, isBoothName }) => ({
    textDecoration: isBoothName ? "underline" : "none",
    color: isBoothName ? COLORS.blue : COLORS.lightBlack,
    textAlign: isBoothName ? "left" : "center",
    fontWeight: 600,
    visibility: hide ? "hidden" : "visible",
    "&:hover": {
      color: COLORS.grey,
    },
  })
);

const Pagination = styled.div({
  display: "flex",
  justifyContent: "space-between",
  margin: "0 auto",
  width: "100%",
  maxWidth: 250,
  [media(Breakpoint.sm)]: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr 1fr 180px 1fr 1fr",
    maxWidth: 370,
  },
});
