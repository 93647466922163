import styled from "@emotion/styled";
import React from "react";
import Footer from "shared/components/Footer";
import { PoppinsBold, PoppinsRegular } from "shared/components/Text";
import * as COLORS from "shared/styles/colors";
import { media, Visible } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import BeanIcon from "shared/icons/Bean.png";
import TadaIcon from "shared/icons/Tada.png";
import PencilIcon from "shared/icons/Pencil.png";
import { useHistory } from "react-router";
import { ROUTES } from "@virtualfest/common";
import virtualFestLogo from "screens/ThreeJS/images/virtaul_fest_logo.svg";
import SignUp from "./assets/SignUp.mp4";
import SignUpVideoThumbnail from "./assets/Thumbnail.jpg";

interface Props {}

const Intro = ({}: Props) => {
  const history = useHistory();

  return (
    <IntroContainer>
      <HeaderContainer>
        <Logo src={virtualFestLogo} />
        <LoginLink>
          Already Registered?&nbsp;
          <TextLink
            type="button"
            color={COLORS.darkBlue}
            weight={600}
            onClick={() =>
              history.push(
                ROUTES.EVENT_ADMIN.LOGIN.replace(":eventSlug", "default")
              )
            }
          >
            Log In
          </TextLink>
        </LoginLink>
      </HeaderContainer>
      <InnerContainer>
        <LeftContainer>
          <VideoContainer>
            <video poster={SignUpVideoThumbnail} width="100%" controls>
              <source src={SignUp} type="video/mp4" />
            </video>
          </VideoContainer>
        </LeftContainer>
        <RightContainer>
          <Title>Welcome!</Title>
          <Text>
            Looking to stand out from the crowd with an amazing virtual event?
            VirtualFest is here to help you become an event planning allstar!
          </Text>
          <Visible md lg xl>
            <IconRow>
              <OuterIconContainer>
                <IconContainer>
                  <Icon src={BeanIcon} />
                </IconContainer>
                <IconText>Sign Up &#38; Explore</IconText>
              </OuterIconContainer>
              <OuterIconContainer>
                <IconContainer>
                  <Icon src={PencilIcon} />
                </IconContainer>
                <IconText>Customize Your Event</IconText>
              </OuterIconContainer>
              <OuterIconContainer>
                <IconContainer>
                  <Icon src={TadaIcon} />
                </IconContainer>
                <IconText>Invite Guests and Enjoy!</IconText>
              </OuterIconContainer>
            </IconRow>
          </Visible>
          <Button
            onClick={() =>
              history.push(
                ROUTES.EVENT_ADMIN.REGISTER.replace(":eventSlug", "default")
              )
            }
          >
            Sign Up
          </Button>
        </RightContainer>
      </InnerContainer>
      <Spacer />
      <Footer
        color={COLORS.darkBlue}
        position="absolute"
        backgroundColor={"transparent"}
      />
    </IntroContainer>
  );
};

export default Intro;

const IntroContainer = styled.div({
  minHeight: "100vh",
  minWidth: "100vw",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  [media(Breakpoint.md)]: {
    maxHeight: "100vh",
    maxWidth: "100vw",
  },
});

const InnerContainer = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const LeftContainer = styled.div({
  background: COLORS.backgroundGrey,
  height: "50%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 150,
  [media(Breakpoint.md)]: {
    height: "100%",
    width: "50%",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: "100vh",
  },
});

const VideoContainer = styled.div({
  maxWidth: 511,
  width: "100%",
  alignSelf: "flex-end",
  [media(Breakpoint.md)]: {
    alignSelf: "center",
  },
});

const RightContainer = styled.div({
  backgroundColor: COLORS.white,
  height: "50%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 15px 0px",
  [media(Breakpoint.md)]: {
    height: "100%",
    width: "50%",
    paddingLeft: 0,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  [media(Breakpoint.lg)]: {
    height: "100%",
    width: "50%",
    paddingLeft: 97,
    alignItems: "flex-start",
  },
});

const Title = styled.h1([
  PoppinsBold,
  {
    color: COLORS.darkBlue,
    fontSize: 45,
    textAlign: "center",
    marginBottom: 11,
    lineHeight: "140%",
    [media(Breakpoint.md)]: {
      textAlign: "left",
      fontSize: 70,
    },
  },
]);

const Text = styled.p([
  PoppinsRegular,
  {
    textAlign: "center",
    fontSize: 16,
    marginBottom: 36,
    lineHeight: "25px",
    [media(Breakpoint.md)]: {
      fontSize: 18,
      maxWidth: 444,
      lineHeight: "36px",
    },
    [media(Breakpoint.lg)]: {
      textAlign: "left",
    },
  },
]);

const IconRow = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: 298,
  marginBottom: 61,
});

const OuterIconContainer = styled.div({
  width: 80,
});

const IconText = styled.p([
  PoppinsRegular,
  {
    fontSize: 12,
    lineHeight: "18px",
  },
]);

const IconContainer = styled.div({
  height: 64,
  width: 64,
  backgroundColor: COLORS.brightGreen,
  borderRadius: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 13,
});

const Icon = styled.img({});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    color: COLORS.white,
    backgroundColor: COLORS.green,
    borderBottom: `6px solid ${COLORS.darkGreen}`,
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: 600,
    height: "60px",
    width: "270px",
    opacity: isSubmitting ? 0.5 : 1,
  })
);

const HeaderContainer = styled.div({
  position: "absolute",
  top: 0,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 39,
  paddingLeft: 41,
  paddingRight: 41,
  flexDirection: "column",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const LoginLink = styled.p([
  PoppinsRegular,
  {
    marginTop: 30,
    [media(Breakpoint.md)]: {
      position: "absolute",
      top: 40,
      right: 50,
      marginTop: 0,
    },
  },
]);

const TextLink = styled.button<{ color?: string; weight?: number }>(
  ({ color, weight }) => ({
    color: color ?? COLORS.white,
    textDecoration: "underline",
    fontWeight: weight ?? "normal",
  })
);

const Logo = styled.img({
  maxWidth: 183,
});

const Spacer = styled.div({
  flexGrow: 1,
});
