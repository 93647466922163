import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import virtualFestLogo from "../../ThreeJS/images/virtaul_fest_logo.svg";
import DoneRounded from "@material-ui/icons/DoneRounded";
import { openInNewTab } from "shared/utils";
import { useSearchParams } from "shared/hooks/useSearchParams";
import Names from "./Names";
import Banner from "./Banner";
import ProgressBar from "./ProgressBar";
import Success from "./Success";
import SignUp from "./SignUp";

interface Props {
  setUserInfo: Dispatch<SetStateAction<any>>;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

export const TOUR_WORLD = "TOUR_WORLD";
export const CREATE_EVENT = "CREATE_EVENT";

const Onboarding = ({ setUserInfo, setLoggedIn }: Props) => {
  const searchParams = useSearchParams();
  const firstName = searchParams.get("first_name");
  const lastName = searchParams.get("last_name");
  const organization = searchParams.get("org_name");

  const fullName = `${firstName ?? ""}${firstName && lastName ? " " : ""}${
    lastName ?? ""
  }`;

  const [currentStep, setCurrentStep] = useState(0);
  const [adminName, setAdminName] = useState(fullName);
  const [orgName, setOrgName] = useState(organization ?? "");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [exitAction, setExitAction] = useState("");
  const [stepsCompleted, setStepsCompleted] = useState(false);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Banner
            setCurrentStep={setCurrentStep}
            adminName={adminName}
            orgName={orgName}
            bannerImageUrl={bannerImageUrl}
            setBannerImageUrl={setBannerImageUrl}
          />
        );
      case 2:
        return (
          <ProgressBar
            setCurrentStep={setCurrentStep}
            setStepsCompleted={setStepsCompleted}
          />
        );
      case 3:
        return (
          <Success
            bannerImageUrl={bannerImageUrl}
            adminName={adminName}
            orgName={orgName}
            setExitAction={setExitAction}
            setCurrentStep={setCurrentStep}
          />
        );
      case 4:
        return (
          <SignUp
            adminName={adminName}
            orgName={orgName}
            bannerImageUrl={bannerImageUrl}
            exitAction={exitAction}
            setLoggedIn={setLoggedIn}
            setUserInfo={setUserInfo}
          />
        );
      default:
        return (
          <Names
            setCurrentStep={setCurrentStep}
            adminName={adminName}
            setAdminName={setAdminName}
            orgName={orgName}
            setOrgName={setOrgName}
          />
        );
    }
  };

  return (
    <Container>
      <VirtualFestLogo
        type="image"
        src={virtualFestLogo}
        alt="Powered by VirtualFest"
        onClick={() => openInNewTab("virtualfest.app")}
      />
      <Steps>
        <StepLine />
        <Step active={currentStep === 0}>
          <StepLabel completed={currentStep > 0}>Your Info</StepLabel>
          {currentStep > 0 && (
            <CompletedStep type="button" onClick={() => setCurrentStep(0)}>
              <DoneIcon />
            </CompletedStep>
          )}
        </Step>
        <Step active={currentStep === 1}>
          <StepLabel completed={currentStep > 1}>Banner Upload</StepLabel>
          {currentStep > 1 && (
            <CompletedStep type="button" onClick={() => setCurrentStep(1)}>
              <DoneIcon />
            </CompletedStep>
          )}
        </Step>
        <Step active={currentStep === 2}>
          <StepLabel completed={stepsCompleted}>Create Venue</StepLabel>
          {stepsCompleted && (
            <CompletedStep isLastStep>
              <DoneIcon />
            </CompletedStep>
          )}
        </Step>
      </Steps>
      <div>{renderStep()}</div>
      <FooterLinks>
        <div>
          © 2021 VirtualFest. Developed by&nbsp;
          <TextLink
            type="button"
            isFooter
            onClick={() => openInNewTab("yeti.co")}
          >
            Yeti
          </TextLink>
        </div>
        <div>
          <TextLink
            type="button"
            isFooter
            onClick={() => openInNewTab("virtualfest.app/terms")}
          >
            Terms
          </TextLink>
          &nbsp;|&nbsp;
          <TextLink
            type="button"
            isFooter
            onClick={() => openInNewTab("virtualfest.app/privacy")}
          >
            Privacy Policy
          </TextLink>
        </div>
      </FooterLinks>
    </Container>
  );
};

export default Onboarding;

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontFamily: "Poppins, sans-serif",
  color: COLORS.lightBlack,
  backgroundColor: COLORS.lightGrey,
  fontSize: 16,
  position: "relative",
  textAlign: "center",
  lineHeight: "24px",
  minHeight: "100vh",
  padding: "80px 40px 120px",
  [media(Breakpoint.sm)]: {
    padding: "85px 40px 80px",
  },
  "& b": {
    fontWeight: 600,
  },
});

const VirtualFestLogo = styled.input({
  position: "absolute",
  top: 25,
  left: 20,
  height: 40,
  [media(Breakpoint.sm)]: {
    left: 50,
  },
});

const Steps = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  width: "80%",
  maxWidth: 450,
  margin: "25px auto 55px",
  [media(Breakpoint.sm)]: {
    margin: "0 auto 70px",
  },
});

const Step = styled.div<{ active: boolean }>(({ active }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: 25,
  height: 25,
  borderRadius: 25,
  backgroundColor: active ? COLORS.green : COLORS.grey,
  color: COLORS.white,
  zIndex: 1,
}));

const StepLabel = styled.div<{ completed: boolean }>(({ completed }) => ({
  position: "absolute",
  bottom: -32,
  fontSize: 14,
  fontWeight: 600,
  width: 150,
  margin: "0 auto",
  color: completed ? COLORS.green : COLORS.darkGrey,
}));

const CompletedStep = styled.button<{ isLastStep?: boolean }>(
  ({ isLastStep }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: 35,
    height: 35,
    borderRadius: 30,
    backgroundColor: COLORS.green,
    zIndex: 1,
    cursor: isLastStep ? "default" : "pointer",
  })
);

const DoneIcon = styled(DoneRounded)({
  transform: "scale(0.9)",
  color: COLORS.white,
});

const StepLine = styled.div({
  position: "absolute",
  width: "100%",
  border: `5px solid ${COLORS.white}`,
});

export const Title = styled.div({
  fontSize: 50,
  lineHeight: "60px",
  fontWeight: "bold",
  marginBottom: 25,
  color: COLORS.darkBlue,
  [media(Breakpoint.sm)]: {
    fontSize: 60,
    lineHeight: "60px",
  },
});

export const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 20,
  width: "100%",
  [media(Breakpoint.sm)]: {
    marginTop: 25,
  },
});

export const FieldLabel = styled.label({
  fontWeight: 600,
  fontSize: 14,
  position: "relative",
  width: "100%",
  textAlign: "left",
  [media(Breakpoint.sm)]: {
    width: "auto",
  },
});

export const InputField = styled.input({
  display: "block",
  padding: 15,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.lightGrey}`,
  borderRadius: 5,
  boxShadow: `0 3px 10px 3px ${COLORS.lighterGrey}`,
  fontSize: "14px",
  margin: "8px 0 25px",
  width: "100%",
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
  [media(Breakpoint.sm)]: {
    width: 340,
  },
});

export const Button = styled.button<{ disabled?: boolean }>(({ disabled }) => ({
  color: COLORS.white,
  backgroundColor: COLORS.green,
  borderBottom: `6px solid ${COLORS.darkGreen}`,
  fontSize: 16,
  letterSpacing: 0.2,
  fontWeight: 600,
  height: "60px",
  width: "220px",
  margin: "20px auto 10px",
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
}));

export const LoginLink = styled.div({
  margin: "20px 0 5px",
  [media(Breakpoint.sm)]: {
    position: "absolute",
    top: 30,
    right: 50,
    marginTop: 0,
  },
});

export const TextLink = styled.button<{ isFooter?: boolean }>(
  ({ isFooter }) => ({
    color: isFooter ? COLORS.lightBlack : COLORS.green,
    textDecoration: "underline",
    fontWeight: isFooter ? "normal" : 600,
  })
);

const FooterLinks = styled.div({
  display: "flex",
  flexDirection: "column",
  color: COLORS.lightBlack,
  width: "100%",
  marginTop: 20,
  position: "absolute",
  bottom: 0,
  padding: "25px 40px",
  [media(Breakpoint.sm)]: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "30px 40px",
  },
});
