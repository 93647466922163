"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHAT_MESSAGE_TYPES = exports.USER_TYPES = void 0;
var USER_TYPES;
(function (USER_TYPES) {
    USER_TYPES["EVENT_ADMIN"] = "EVENT_ADMIN";
    USER_TYPES["BOOTH_ADMIN"] = "BOOTH_ADMIN";
    USER_TYPES["GUEST"] = "GUEST";
})(USER_TYPES = exports.USER_TYPES || (exports.USER_TYPES = {}));
var CHAT_MESSAGE_TYPES;
(function (CHAT_MESSAGE_TYPES) {
    CHAT_MESSAGE_TYPES["EVENT"] = "EVENT";
    CHAT_MESSAGE_TYPES["BOOTH"] = "BOOTH";
})(CHAT_MESSAGE_TYPES = exports.CHAT_MESSAGE_TYPES || (exports.CHAT_MESSAGE_TYPES = {}));
