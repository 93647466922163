"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLAN_TIERS = exports.ROUTES = void 0;
exports.ROUTES = {
    BASE: {
        ROOT: "/",
        EVENT_ADMIN: "/event-admin",
        BOOTH_ADMIN: "/booth-admin",
        EVENT: "/event",
        NEWSCHOOL: "/newschool",
        WELCOME: "/welcome",
    },
    VERIFY_EMAIL: "/verify-email/:emailToken",
    RESET_PASSWORD: "/reset-password/:forgotPasswordToken",
    EVENT: {
        ROOT: "/event/:eventSlug",
        LOGIN: "/event/:eventSlug/login",
        REGISTER: "/event/:eventSlug/register",
    },
    BOOTH_ADMIN: {
        ROOT: "/booth-admin/:boothId",
        LOGIN: "/booth-admin/:boothId/login",
        REGISTER: "/booth-admin/:boothId/register",
        INFO: "/booth-admin/:boothId/info",
        ZOOM: "/booth-admin/:boothId/zoom",
        CHAT: "/booth-admin/:boothId/chat",
    },
    EVENT_ADMIN: {
        ROOT: "/event-admin/:eventSlug",
        INFO: "/event-admin/:eventSlug/info",
        LOGIN: "/event-admin/:eventSlug/login",
        REGISTER: "/event-admin/:eventSlug/register",
        BOOTHS: "/event-admin/:eventSlug/booths",
        GUESTS: "/event-admin/:eventSlug/guests",
        EDIT: "/event-admin/:eventSlug/edit/:boothId",
        EVENTS: "/event-admin/:eventSlug/events",
        CUSTOMIZE_TEXT: "/event-admin/:eventSlug/customize-text",
        EVENT_SETTINGS: "/event-admin/:eventSlug/event-settings",
        USER_ACCOUNT: {
            ROOT: "/event-admin/default/account",
            CUSTOMER_PORTAL: "/event-admin/default/account/customer-portal",
        },
    },
    YETI_ADMIN: {
        ROOT: "/yeti-admin",
        EVENTS: "/yeti-admin/events",
        CREATE_EVENT: "/yeti-admin/create-event",
        ACCOUNTS: "/yeti-admin/accounts",
        LEADS: "/yeti-admin/leads",
    },
};
exports.PLAN_TIERS = {
    FREE: {
        maxEventGuests: 5,
        maxRegistrations: 10,
        maxDuration: "1d",
    },
    SMALL: {
        maxEventGuests: 50,
        maxRegistrations: 100,
        maxDuration: "1d",
    },
    MEDIUM: {
        maxEventGuests: 75,
        maxRegistrations: 225,
        maxDuration: "1d",
    },
    LARGE: {
        maxEventGuests: 100,
        maxRegistrations: 500,
        maxDuration: "2d",
    },
};
