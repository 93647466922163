import React from "react";
import { ROUTES } from "@virtualfest/common";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";

const InvalidSlugScreen = () => {
  const history = useHistory();

  const asEventAdmin = useRouteMatch({ path: ROUTES.EVENT_ADMIN.ROOT });
  const asBoothAdmin = useRouteMatch({ path: ROUTES.BOOTH_ADMIN.ROOT });
  const asGuest = useRouteMatch({ path: ROUTES.EVENT.ROOT });

  const handleHomeClick = () => {
    if (asEventAdmin)
      history.push(ROUTES.EVENT_ADMIN.ROOT.replace(":eventSlug", "default"));
    if (asBoothAdmin)
      history.push(ROUTES.BOOTH_ADMIN.ROOT.replace(":boothId", "default"));
    if (asGuest)
      history.push(ROUTES.EVENT.ROOT.replace(":eventSlug", "yeti-university"));
  };

  return (
    <Container>
      <Title>Not Found</Title>
      <div>
        Sorry, the {asBoothAdmin ? "booth" : "event"} you tried does not exist
        {asGuest ? " " : ", or you do not have permission to access it"}. Please
        check the URL and try again.
      </div>
      <TextLink type="button" onClick={handleHomeClick}>
        Go Home
      </TextLink>
    </Container>
  );
};

export default InvalidSlugScreen;

const Container = styled.div({
  textAlign: "center",
  fontFamily: "Poppins, sans-serif",
  fontSize: 20,
  lineHeight: "40px",
  position: "relative",
  color: COLORS.lightBlack,
  padding: "120px 40px",
  [media(Breakpoint.sm)]: {
    padding: "120px 80px",
  },
});

const Title = styled.div({
  fontSize: 48,
  fontWeight: "bold",
  marginBottom: 50,
});

const TextLink = styled.button({
  fontSize: 18,
  textDecoration: "underline",
  color: COLORS.blue,
  marginTop: 40,
});
