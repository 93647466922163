import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_EVENT_ITEM, DELETE_EVENT_ITEM } from "./mutations.eventAdmin";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import { useEventSlug } from "shared/hooks/useEventSlug";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import moment from "moment";
import {
  buildEventSchedule,
  sortedEventScheduleDates,
  sortedEventScheduleTimes,
  formatToTwelveHourTime,
} from "shared/utils";
import closeX from "shared/icons/Close_button.svg";
import TabContainer from "shared/components/TabContainer";
import Spacer from "shared/components/Spacer";
import Loading from "shared/components/Loading";
import { FooterPreview } from "./CustomText";
import eventActive from "../ThreeJS/Sidebar/images/Active_Event_Button.svg";

interface Props {
  eventData: any;
  setEventData: Dispatch<SetStateAction<any>>;
}

const EventSchedule = ({ eventData, setEventData }: Props) => {
  const [eventSchedule, setEventSchedule] = useState<any>({});
  const [date, setDate] = useState<Date | Date[]>(new Date());
  const [time, setTime] = useState("12:00");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeDateTab, setActiveDateTab] = useState("");

  const history = useHistory();

  const { CUSTOMIZE_TEXT }: any = useEventSlug({
    CUSTOMIZE_TEXT: ROUTES.EVENT_ADMIN.CUSTOMIZE_TEXT,
  });

  const [createEventItem] = useMutation(CREATE_EVENT_ITEM, {
    onCompleted: (data) => {
      const newItem = data.createOneEventScheduleItem;

      setEventData({
        ...eventData,
        eventScheduleItems: [...eventData.eventScheduleItems, newItem],
      });

      setTitle("");
      setSubtitle("");
      setIsSubmitting(false);
    },
  });

  const [deleteEventItem] = useMutation(DELETE_EVENT_ITEM, {
    onCompleted: (data) => {
      const deletedItem = data.deleteOneEventScheduleItem;
      const filteredItemList = eventData.eventScheduleItems.filter(
        (item) => item.id !== deletedItem.id
      );

      setEventData({
        ...eventData,
        eventScheduleItems: filteredItemList,
      });
    },
  });

  useEffect(() => {
    const schedule = buildEventSchedule(eventData.eventScheduleItems ?? []);
    setEventSchedule(schedule);

    if (!activeDateTab || !schedule[activeDateTab]) {
      setActiveDateTab(sortedEventScheduleDates(schedule)[0]);
    }
  }, [eventData.eventScheduleItems]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!date || !time || !title) return;

    setIsSubmitting(true);

    createEventItem({
      variables: {
        data: {
          date: `${moment(date.toString()).format("dddd")}, ${moment(
            date.toString()
          ).format("L")}`,
          time,
          title,
          subtitle,
          event: {
            connect: { id: eventData.id },
          },
        },
      },
    });
  };

  return (
    <TabContainer>
      <Container>
        <MainContent>
          <Title>Event Schedule</Title>
          <div>Add events to create your event schedule.</div>
          <Header>Add a New Event</Header>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <DateTime>
              <InputLabel>
                Date
                <StyledDatePicker
                  minDate={new Date()}
                  clearIcon={null}
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                />
              </InputLabel>
              <InputLabel>
                Time
                <StyledTimePicker
                  disableClock
                  clearIcon={null}
                  value={time}
                  onChange={(e) => setTime(e)}
                />
              </InputLabel>
            </DateTime>
            <InputLabel>
              Event Title
              <Input
                type="text"
                placeholder="Enter event title here"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputLabel>
            <InputLabel>
              Event Subtitle
              <Input
                type="text"
                placeholder="Enter event subtitle here"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </InputLabel>
            <Button type="submit" isSubmitting={isSubmitting}>
              Add Event
            </Button>
          </Form>
        </MainContent>

        <SidebarPreview>
          <TopSection>
            <PreviewLogo>Your Logo</PreviewLogo>
            <PreviewTitle>Event Schedule</PreviewTitle>
            <div>
              Additional information about the event schedule will appear here.
              Edit it in&nbsp;
              <TextLink
                type="button"
                onClick={() => history.push(CUSTOMIZE_TEXT)}
              >
                Customize Text
              </TextLink>
              .
            </div>
            <DateTabsContainer>
              <DateTabs>
                {Object.keys(eventSchedule).length > 0 ? (
                  sortedEventScheduleDates(eventSchedule).map((date) => (
                    <DateTab
                      key={date}
                      current={activeDateTab === date}
                      onClick={() => setActiveDateTab(date)}
                    >
                      {date}
                    </DateTab>
                  ))
                ) : (
                  <DateTab current>Sample date</DateTab>
                )}
              </DateTabs>
            </DateTabsContainer>
          </TopSection>
          <EventsList>
            {Object.keys(eventSchedule).length > 0 ? (
              sortedEventScheduleTimes(eventSchedule[activeDateTab]).map(
                (time) => (
                  <div key={`${activeDateTab}-${time}`}>
                    <EventTime>{formatToTwelveHourTime(time)}</EventTime>
                    <Event>
                      {eventSchedule[activeDateTab][time].map((item) => (
                        <EventItem key={item.id}>
                          <EventItemTitle>{item.title}</EventItemTitle>
                          <div>{item.subtitle}</div>
                          <DeleteIcon
                            type="image"
                            src={closeX}
                            alt="Remove"
                            onClick={() =>
                              deleteEventItem({ variables: { id: item.id } })
                            }
                          />
                        </EventItem>
                      ))}
                    </Event>
                  </div>
                )
              )
            ) : (
              <div key={`${activeDateTab}-${time}`}>
                <EventTime>Sample time</EventTime>
                <Event>
                  <EventItem>
                    <EventItemTitle>Sample title</EventItemTitle>
                    <div>This is an example of a subtitle</div>
                  </EventItem>
                </Event>
              </div>
            )}
          </EventsList>
          <FooterPreview activeTabIndex={1} src={eventActive} />
        </SidebarPreview>
      </Container>
    </TabContainer>
  );
};

export default EventSchedule;

const Container = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  marginBottom: 15,
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 43,
    lineHeight: "45px",
  },
});

const MainContent = styled.div({
  width: "100%",
  [media(Breakpoint.xl)]: {
    minWidth: 335,
    marginRight: 50,
  },
});

const Header = styled.div({
  fontSize: 22,
  fontWeight: 600,
  margin: "30px 0 10px",
});

const Form = styled.form({
  width: "100%",
  maxWidth: 400,
});

const InputLabel = styled.label({
  display: "block",
  fontSize: 16,
  lineHeight: "16px",
  fontWeight: 600,
  marginTop: 15,
  width: "100%",
});

const DateTime = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: 20,
  [media(Breakpoint.lg)]: {
    gridGap: 40,
  },
});

const StyledDatePicker = styled(DatePicker)({
  display: "block",
  "& .react-date-picker__wrapper": {
    padding: "0 15px",
    border: `1px solid ${COLORS.grey}`,
    backgroundColor: COLORS.white,
    margin: "10px 0 5px",
    height: 40,
    width: "100%",
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
    borderRadius: 5,
    "& input": {
      color: COLORS.lightBlack,
    },
  },
  "& .react-date-picker__calendar--open": {
    bottom: 0,
  },
  "& svg": {
    stroke: COLORS.lightBlack,
  },
});

const StyledTimePicker = styled(TimePicker)({
  display: "block",
  "& .react-time-picker__wrapper": {
    padding: "0 2px 0 15px",
    border: `1px solid ${COLORS.grey}`,
    backgroundColor: COLORS.white,
    boxSizing: "border-box",
    margin: "10px 0 5px",
    height: 40,
    width: "100%",
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
    borderRadius: 5,
    "& .react-time-picker__inputGroup__hour": {
      letterSpacing: 1.2,
      color: COLORS.lightBlack,
      width: "17px !important",
      textAlign: "right",
    },
    "& .react-time-picker__inputGroup__divider": {
      color: COLORS.lightBlack,
      margin: "0 2px",
      "&:last-of-type": {
        display: "none",
      },
    },
    "& .react-time-picker__inputGroup__leadingZero": {
      marginTop: 1, // Alignment is off if zero
      color: COLORS.lightBlack,
    },
    "& .react-time-picker__inputGroup__minute": {
      color: COLORS.lightBlack,
      letterSpacing: 1.2,
      width: "22px !important",
    },
    "& .react-time-picker__inputGroup__input--hasLeadingZero": {
      width: "14px !important",
    },
    "& .react-time-picker__inputGroup__amPm": {
      color: COLORS.lightBlack,
    },
  },
});

const Input = styled.input({
  padding: "0 15px",
  border: `1px solid ${COLORS.grey}`,
  margin: "12px 0 5px",
  height: 40,
  width: "100%",
  fontSize: "14px",
  fontFamily: "Poppins, sans-serif",
  borderRadius: 5,
  "&::placeholder": {
    color: COLORS.grey,
    fontSize: "14px",
  },
});

const Button = styled.button<{ isSubmitting?: boolean }>(
  ({ isSubmitting }) => ({
    display: "block",
    color: COLORS.white,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.2,
    backgroundColor: COLORS.blue,
    borderRadius: 5,
    marginTop: 25,
    height: 42,
    width: 160,
    opacity: isSubmitting ? 0.5 : 1,
    "&:focus": {
      boxShadow: `0 0 3px 3px ${COLORS.grey}`,
    },
  })
);

const SidebarPreview = styled.div({
  display: "none",
  position: "relative",
  height: 700,
  backgroundColor: COLORS.lightGrey,
  width: 425,
  minWidth: 425,
  border: `1px solid ${COLORS.grey}`,
  paddingBottom: 70,
  [media(Breakpoint.xl)]: {
    display: "flex",
    flexDirection: "column",
  },
});

const TopSection = styled.div({
  padding: "30px 25px 0",
  backgroundColor: COLORS.white,
});

const PreviewLogo = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.grey,
  borderRadius: 10,
  border: `2px solid ${COLORS.grey}`,
  width: 150,
  height: 50,
  marginBottom: 25,
});

const PreviewTitle = styled.div({
  fontSize: 24,
  fontWeight: "bold",
  margin: "10px 0 25px",
  color: COLORS.lighterBlack,
  [media(Breakpoint.sm)]: {
    fontSize: 28,
  },
});

const TextLink = styled.button({
  color: COLORS.blue,
  fontWeight: 600,
});

const DateTabsContainer = styled.div({
  overflow: "hidden",
  minHeight: 42,
  height: 42,
  marginTop: 10,
  backgroundColor: COLORS.white,
});

const DateTabs = styled.div({
  display: "flex",
  alignItems: "flex-end",
  overflowX: "scroll",
  overflowY: "hidden",
  boxSizing: "content-box",
  minHeight: 42,
  maxWidth: "calc(100vw - 92px)",
  backgroundColor: COLORS.white,
  [media(Breakpoint.sm)]: {
    paddingTop: 15,
  },
});

const DateTab = styled.button<{ current: boolean }>(({ current }) => ({
  fontSize: 13,
  fontWeight: "bold",
  marginRight: 20,
  height: 27,
  padding: "0 3px 8px",
  whiteSpace: "nowrap",
  color: current ? COLORS.lightBlack : COLORS.darkGrey,
  borderBottom: `3px solid ${current ? COLORS.lightBlack : COLORS.white}`,
  "&:last-of-type": {
    marginRight: 0,
  },
}));

const EventsList = styled.div({
  borderTop: `1px solid ${COLORS.grey}`,
  width: "100%",
  overflowY: "auto",
  padding: "20px 25px 5px",
  backgroundColor: COLORS.lightGrey,
});

const EventTime = styled.div({
  fontWeight: "bold",
  fontSize: 13,
  marginBottom: 2,
  paddingLeft: 15,
});

const Event = styled.div({
  backgroundColor: COLORS.white,
  borderRadius: 10,
  width: "100%",
  marginBottom: 20,
  padding: "1px 18px 14px",
  color: COLORS.darkGrey,
  fontSize: 14,
  lineHeight: "18px",
});

const EventItem = styled.div({
  position: "relative",
});

const EventItemTitle = styled.div({
  fontSize: 14,
  color: COLORS.blue,
  fontWeight: 600,
  margin: "13px 0 2px",
  paddingRight: 20,
});

const DeleteIcon = styled.input({
  position: "absolute",
  right: 0,
  top: 2,
  height: 12,
  width: 12,
  opacity: 0.5,
});
