import React, { useState, Dispatch, SetStateAction } from "react";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_BOOTH } from "../EventAdmin/mutations.eventAdmin";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@virtualfest/common";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { format } from "date-fns";
import CookieService, { Identifiers } from "services/Cookie/CookieService";
import TabContainer from "shared/components/TabContainer";
import ConfirmationPopup from "shared/components/ConfirmationPopup";

interface Props {
  userInfo: any;
  setUserInfo: Dispatch<SetStateAction<any>>;
  setBoothData: Dispatch<SetStateAction<any>>;
}

export const getCardColor = (
  startDateTime: Date,
  endDateTime: Date
): string => {
  const now = new Date();
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);

  if (now > start && now < end) return COLORS.green;
  if (now < start) return COLORS.lightBlack;
  return COLORS.orange;
};

const YourBooths = ({ userInfo, setUserInfo, setBoothData }: Props) => {
  const [deletePopup, setDeletePopup] = useState<{
    boothId?: number;
    open?: boolean;
    success?: boolean;
  }>({ open: false });

  const history = useHistory();

  const [deleteBooth] = useMutation(DELETE_BOOTH, {
    onCompleted: (data) => {
      setUserInfo({
        ...userInfo,
        booths: userInfo.booths.filter(
          (booth) => booth.id !== data.deleteOneBooth.id
        ),
      });

      setDeletePopup({ ...deletePopup, success: true });
    },
  });

  // Redirect to new booth if user is presumably coming from email verification
  const eventSlugParam = CookieService.getCookie(Identifiers.EVENT_SLUG);
  if (userInfo.booths.length === 0 && eventSlugParam) {
    history.push(
      `${ROUTES.BOOTH_ADMIN.INFO.replace(
        ":boothId",
        "default"
      )}?event=${eventSlugParam}`
    );
  }

  return (
    <>
      {deletePopup.open && (
        <ConfirmationPopup
          text="Once confirmed, this booth will be deleted"
          confirmButtonText="Delete Booth"
          closePopup={() => setDeletePopup({ open: false })}
          onConfirm={() =>
            deleteBooth({ variables: { boothId: deletePopup.boothId } })
          }
          success={deletePopup.success}
          successMessage="The booth you selected has been removed"
        />
      )}
      <Container>
        <TabContainer>
          <Title>Your Booths</Title>
          <Button
            type="button"
            onClick={() => {
              setBoothData({});
              history.push(
                ROUTES.BOOTH_ADMIN.INFO.replace(":boothId", "default")
              );
            }}
          >
            New Booth
          </Button>
          <div>
            Here is a list of all your created booths. You can view, edit, or
            delete a booth, or create a new one.
          </div>
          <Header>BOOTHS CREATED</Header>
          {userInfo.booths.map((booth) => {
            const { name, event } = booth;

            return (
              <BoothCard
                key={booth.id}
                borderColor={getCardColor(
                  event?.startDateTime,
                  event?.endDateTime
                )}
              >
                <div>
                  <BoothName>{name}</BoothName>
                  <div>
                    Event: {event?.name || "None"}
                    {event && (
                      <>
                        <br />
                        {format(new Date(event.startDateTime), "MMMM do, yyyy")}
                        &nbsp;|&nbsp;
                        {format(new Date(event.startDateTime), "h:mm a")}
                      </>
                    )}
                  </div>
                </div>
                <BoothButtons>
                  <BoothButton
                    type="button"
                    onClick={() =>
                      history.push(
                        ROUTES.BOOTH_ADMIN.INFO.replace(":boothId", booth.id)
                      )
                    }
                  >
                    View Info
                  </BoothButton>
                  <DeleteButton
                    type="button"
                    onClick={() =>
                      setDeletePopup({ open: true, boothId: booth.id })
                    }
                  >
                    Delete Booth
                  </DeleteButton>
                </BoothButtons>
              </BoothCard>
            );
          })}
        </TabContainer>
      </Container>
    </>
  );
};

export default YourBooths;

const Container = styled.div({
  height: "calc(100vh - 60px)",
});

const Title = styled.div({
  fontSize: 32,
  fontWeight: "bold",
  margin: "0 20px 25px 0",
  color: COLORS.lighterBlack,
  [media(Breakpoint.md)]: {
    fontSize: 38,
    marginLeft: -3,
  },
  [media(Breakpoint.lg)]: {
    fontSize: 45,
    lineHeight: "45px",
  },
});

const Button = styled.button({
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.blue,
  borderRadius: 5,
  height: 35,
  width: 135,
  position: "absolute",
  right: 20,
  top: 25,
  [media(Breakpoint.sm)]: {
    width: 150,
    top: 42,
    right: 50,
  },
  [media(Breakpoint.md)]: {
    top: 40,
    right: 65,
  },
  [media(Breakpoint.lg)]: {
    right: 80,
    top: 68,
    height: 42,
  },
  [media(Breakpoint.xl)]: {
    top: 65,
  },
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});

const Header = styled.div({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: "18px",
  marginTop: 30,
});

const BoothCard = styled.div<{ borderColor?: string }>(({ borderColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: COLORS.white,
  width: "100%",
  borderLeft: `8px solid ${borderColor ?? COLORS.orange}`,
  padding: 40,
  marginTop: 25,
}));

const BoothName = styled.div({
  fontSize: 24,
  lineHeight: "24px",
  fontWeight: 600,
  marginBottom: 20,
});

const BoothButtons = styled.div({
  display: "flex",
  flexDirection: "column",
  alightItems: "center",
  [media(Breakpoint.md)]: {
    flexDirection: "row",
  },
});

const BoothButton = styled.button({
  color: COLORS.lightBlack,
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.2,
  backgroundColor: COLORS.white,
  border: `2px solid ${COLORS.lightBlack}`,
  borderRadius: 5,
  height: 50,
  width: 150,
  marginBottom: 20,
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
  [media(Breakpoint.md)]: {
    margin: "0 40px 0 0",
  },
});

const DeleteButton = styled.button({
  fontWeight: 600,
  fontSize: 14,
  color: COLORS.red,
  [media(Breakpoint.md)]: {
    marginRight: 5,
  },
});
