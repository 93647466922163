import React from "react";
import styled from "@emotion/styled";

interface Props {
  height?: number;
  width?: number;
}

const Spacer = ({ height, width }: Props) => {
  return <SpacerElement height={height} width={width} />;
};

export default Spacer;

const SpacerElement = styled.div<{ height?: number; width?: number }>(
  ({ height, width }) => ({
    display: height ? "block" : "inline-block",
    minHeight: height ?? 1,
    minWidth: width ?? 1,
    visibility: "hidden",
    margin: 0,
    padding: 0,
  })
);
