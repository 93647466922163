const theme = {
  grid: {
    container: {
      xs: "full",
      sm: "full",
      md: "full",
      lg: "full",
      xl: "full",
    },
    padding: {
      // multiply by 8 to get px
      xs: 3,
      sm: 3,
      md: 5,
      lg: 5,
      xl: 7,
    },
    columns: {
      xs: 8,
      sm: 8,
      md: 12,
      lg: 12,
      xl: 12,
    },
    gutter: {
      xs: 0.5,
      sm: 0.5,
      md: 0.834,
      lg: 0.834,
      xl: 0.834,
    },
    breakpoints: {
      xs: 1, // essentially 0px
      sm: 33.75, // 540px
      md: 48, // 768px
      lg: 64, // 1024px
      xl: 80, // 1280px
    },
    debug: {
      color: "77, 117, 218",
    },
  },
};

export default theme;
