import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import * as COLORS from "shared/styles/colors";
import { media } from "@mverissimoo/emotion-grid";
import { Breakpoint } from "shared/types";
import { Title, Button, TOUR_WORLD, CREATE_EVENT } from "./Onboarding";
import backgroundImg from "../images/banner_background.png";

interface Props {
  bannerImageUrl: string;
  adminName: string;
  orgName: string;
  setExitAction: Dispatch<SetStateAction<string>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const getBannerText = (text: string): string => {
  if (text.length > 40) return text.substr(0, 40) + "...";
  return text;
};

const Success = ({
  bannerImageUrl,
  adminName,
  orgName,
  setExitAction,
  setCurrentStep,
}: Props) => {
  return (
    <>
      <Title>Success!</Title>
      <div>
        <b>Your venue is ready to go!</b>
        <br />
        Jump in and explore, and when you're ready
        <br />
        you can create your first event!
      </div>
      <PreviewImage imageUrl={backgroundImg}>
        {bannerImageUrl ? (
          <BannerImage src={bannerImageUrl} alt="Banner" />
        ) : (
          <BannerText>{getBannerText(orgName || adminName)}</BannerText>
        )}
      </PreviewImage>
      <Button
        type="button"
        onClick={() => {
          setExitAction(TOUR_WORLD);
          setCurrentStep(4);
        }}
      >
        Tour World
      </Button>
      <CreateButton
        type="button"
        onClick={() => {
          setExitAction(CREATE_EVENT);
          setCurrentStep(4);
        }}
      >
        Create Event
      </CreateButton>
    </>
  );
};

export default Success;

const PreviewImage = styled.div<{ imageUrl: string }>(({ imageUrl }) => ({
  width: 280,
  height: 155.6,
  margin: "35px auto 20px",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  border: `1px solid ${COLORS.lightGrey}`,
  [media(Breakpoint.sm)]: {
    height: 250,
    width: 450,
  },
}));

const BannerImage = styled.img({
  height: 28,
  width: 121,
  margin: "28px auto",
  position: "relative",
  left: 1,
  backgroundColor: COLORS.white,
  [media(Breakpoint.sm)]: {
    margin: "48px auto",
    height: 45,
    width: 193,
  },
});

const BannerText = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 28,
  width: 121,
  backgroundColor: COLORS.green,
  color: COLORS.white,
  letterSpacing: 0.5,
  fontSize: 9,
  lineHeight: "12px",
  fontWeight: "bold",
  margin: "28px auto",
  position: "relative",
  left: 1,
  textAlign: "center",
  wordBreak: "break-word",
  padding: "0 25px",
  [media(Breakpoint.sm)]: {
    margin: "48px auto",
    fontSize: 13,
    lineHeight: "16px",
    height: 45,
    width: 193,
  },
});

const CreateButton = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.blue,
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: 0.2,
  border: `2px solid ${COLORS.blue}`,
  backgroundColor: COLORS.lightGrey,
  borderRadius: 5,
  height: 55,
  width: 220,
  margin: "20px auto 5px",
  "&:focus": {
    boxShadow: `0 0 3px 3px ${COLORS.grey}`,
  },
});
