import gql from "graphql-tag";

export const USER_SIGN_UP = gql`
  mutation UserSignUp($data: UserSignUpInputType!) {
    userSignUp(data: $data)
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($verificationToken: String!) {
    verifyEmail(verificationToken: $verificationToken)
  }
`;

export const REQUEST_NEW_PASSWORD = gql`
  mutation RequestNewPassword(
    $email: String!
    $eventSlug: String
    $userType: UserType!
  ) {
    requestNewPassword(
      email: $email
      eventSlug: $eventSlug
      userType: $userType
    )
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(forgotPasswordToken: $token, password: $newPassword)
  }
`;

export const SAVE_PROFILE_INFO = gql`
  mutation UpdateOneGuest($guestId: Int!, $data: GuestUpdateInput!) {
    updateOneGuest(where: { id: $guestId }, data: $data) {
      id
      bio
      location
      facebookHandle
      instagramHandle
      twitterHandle
      linkedinHandle
    }
  }
`;

export const CREATE_UPDATE_EVENT_ATTENDANCE = gql`
  mutation UpsertOneEventAttendance(
    $guestEmail: String!
    $eventSlug: String!
    $updateData: EventAttendanceUpdateInput!
    $createData: EventAttendanceCreateInput!
  ) {
    upsertOneEventAttendance(
      create: $createData
      where: {
        guestEmail_eventSlug: { guestEmail: $guestEmail, eventSlug: $eventSlug }
      }
      update: $updateData
    ) {
      id
    }
  }
`;
